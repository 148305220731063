<template>
  <div>
    <PageLoader :storage="appLoading" />
    <div class="pb-15">
      <div class="">
        <div class="topnav mt-4">
          <a @click="$router.push('profile')">My Profile</a>
          <a @click="$router.push('orders')">My Orders</a>
          <a @click="$router.push('evaluation')">My Evaluation</a>
          <a class="active">My Address</a>
        </div>
        <!-- <div class="sub-heading mt-8" style="text-transform: none">
                      Select Address 
                    </div> -->
        <!-- <div class="service-content ">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam
                    </div> -->
        <div class="row">
          <div class="col-md-6 mt-6">
            <div
              class="h-100 text-center address-box"
              @click="dialog = true"
              style="border: 1px solid #d3d3d9"
            >
              <button class="add-address-btn mt-1">Add Address</button>
            </div>
          </div>
          <div
            class="col-md-6 mt-6"
            v-for="(item, index) in address"
            :key="index"
          >
            <div class="address-box h-100" style="border: 1px solid #d3d3d9">
              <div class="d-flex">
                <div class="full-flex service-content f-16">
                  Address {{ index + 1 }}
                </div>
                <div @click="editAddressData(item)">
                  <svg
                    width="10"
                    height="12"
                    viewBox="0 0 10 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.37516 10.7965H0.627462C0.461745 10.7965 0.302816 10.8599 0.185636 10.9728C0.068457 11.0856 0.00262615 11.2387 0.00262615 11.3983C0.00262615 11.5579 0.068457 11.7109 0.185636 11.8238C0.302816 11.9366 0.461745 12 0.627462 12H9.37516C9.54088 12 9.69981 11.9366 9.81699 11.8238C9.93417 11.7109 10 11.5579 10 11.3983C10 11.2387 9.93417 11.0856 9.81699 10.9728C9.69981 10.8599 9.54088 10.7965 9.37516 10.7965ZM0.627462 9.59305H0.683697L3.28926 9.36439C3.57469 9.33701 3.84164 9.2159 4.04531 9.0214L9.66884 3.60576C9.8871 3.3837 10.0051 3.08737 9.99686 2.7817C9.98866 2.47603 9.85498 2.18596 9.6251 1.97505L7.91305 0.326287C7.6896 0.124161 7.3968 0.00818336 7.09035 0.000416858C6.78389 -0.00734964 6.48516 0.0936365 6.25098 0.284165L0.627462 5.69981C0.425494 5.89595 0.299738 6.15304 0.271305 6.42791L0.00262615 8.93715C-0.00579103 9.02529 0.0060842 9.11413 0.0374053 9.19734C0.0687263 9.28055 0.118722 9.35608 0.183829 9.41854C0.242213 9.47431 0.311455 9.51844 0.387584 9.54838C0.463712 9.57833 0.545229 9.59351 0.627462 9.59305ZM7.04453 1.16872L8.75033 2.81146L7.50066 3.98485L5.8261 2.3722L7.04453 1.16872ZM1.48349 6.5302L5.00131 3.16649L6.68837 4.79118L3.18929 8.16091L1.31478 8.3294L1.48349 6.5302Z"
                      fill="#8A8A8A"
                    />
                  </svg>
                </div>
                <div
                  class="ml-5"
                  @click="(deleteAddId = item.id), (deleteDialog = true)"
                >
                  <svg
                    width="12"
                    height="13"
                    viewBox="0 0 12 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.625 1.17H3.5C3.56875 1.17 3.625 1.1115 3.625 1.04V1.17H8.375V1.04C8.375 1.1115 8.43125 1.17 8.5 1.17H8.375V2.34H9.5V1.04C9.5 0.466375 9.05156 0 8.5 0H3.5C2.94844 0 2.5 0.466375 2.5 1.04V2.34H3.625V1.17ZM11.5 2.34H0.5C0.223437 2.34 0 2.57237 0 2.86V3.38C0 3.4515 0.05625 3.51 0.125 3.51H1.06875L1.45469 12.0087C1.47969 12.5629 1.92031 13 2.45312 13H9.54688C10.0813 13 10.5203 12.5645 10.5453 12.0087L10.9312 3.51H11.875C11.9438 3.51 12 3.4515 12 3.38V2.86C12 2.57237 11.7766 2.34 11.5 2.34ZM9.42656 11.83H2.57344L2.19531 3.51H9.80469L9.42656 11.83Z"
                      fill="#8A8A8A"
                    />
                  </svg>
                </div>
              </div>
              <div class="service-content mt-2">
                {{ item.apartment }}, {{ item.floor }}, {{ item.building }},
                {{ item.block }}, {{ item.street }}, {{ item.avenue }},
                {{ item.area.name }},
                {{ item.governorate.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="dialog" scrollable width="1112">
      <div class="ad-model">
        <div>
          <div class="modal-side">
            <svg
              width="110"
              height="28"
              viewBox="0 0 110 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M98.6775 5.75269H103.58V27.035H98.6775V18.8001H92.5311V27.035H87.629V5.75269H92.5311V13.898H98.6775V5.75269Z"
                fill="white"
              />
              <path
                d="M15.8422 20.8029C15.8422 24.543 13.563 27.1809 8.35826 27.1809H0V5.7229H8.04814C12.2964 5.7229 14.5756 8.08429 14.5756 11.6376C14.5756 12.8631 14.1272 14.6416 12.7485 15.7438C15.0239 16.7265 15.8422 18.8413 15.8422 20.8029ZM5.3729 10.9389V13.8831H8.07429C9.28488 13.8831 9.84533 13.2405 9.84533 12.411C9.84533 11.5815 9.28114 10.9389 8.07429 10.9389H5.3729V10.9389ZM10.9999 20.6161C10.9999 19.6035 10.2414 18.8077 8.86267 18.8077H5.3729V22.4245H8.86267C10.2414 22.4245 10.9999 21.595 10.9999 20.6161Z"
                fill="white"
              />
              <path
                d="M30.6981 27.0356H18.0243V5.72339H22.9264V22.1335H30.6981V27.0356Z"
                fill="white"
              />
              <path
                d="M46.3235 10.6511H37.5692V13.8681H44.6721V18.7702H37.5692V22.1329H46.3235V27.035H32.6671V5.77884L46.3235 5.75269V10.6511Z"
                fill="white"
              />
              <path
                d="M77.8771 27.0052C76.5059 27.0052 75.1645 26.7511 73.8605 26.2355C72.5566 25.7236 71.3796 24.9465 70.3371 23.9003C69.3321 22.8765 68.5624 21.722 68.0318 20.4367C67.5012 19.1513 67.236 17.795 67.236 16.364C67.236 14.9143 67.5012 13.5468 68.0318 12.2615C68.5624 10.9762 69.3321 9.83284 70.3371 8.82402C71.3796 7.78158 72.5603 6.99694 73.8755 6.47385C75.1907 5.95075 76.5283 5.68921 77.8921 5.68921C79.2559 5.68921 80.5897 5.95075 81.8937 6.47385C83.1977 6.99694 84.371 7.77784 85.4171 8.82402L81.9647 12.2727C81.4043 11.7122 80.7654 11.2938 80.0517 11.0135C79.3381 10.7333 78.6132 10.5913 77.8771 10.5913C77.1411 10.5913 76.4237 10.7333 75.7175 11.0135C75.0113 11.2938 74.3799 11.7122 73.8194 12.2727C73.2777 12.8331 72.8629 13.4609 72.5715 14.1558C72.2801 14.8508 72.1381 15.5869 72.1381 16.3603C72.1381 17.1337 72.2838 17.8623 72.5715 18.5498C72.8629 19.2373 73.2777 19.8613 73.8194 20.4217C74.3799 20.9822 75.0113 21.4044 75.7175 21.6846C76.4237 21.9648 77.1448 22.1031 77.8771 22.1031C78.6095 22.1031 79.3381 21.9648 80.0517 21.6846C80.7654 21.4044 81.4043 20.9859 81.9647 20.4217L85.4171 23.9003C84.3747 24.9427 83.2052 25.7236 81.9087 26.2355C80.6122 26.7474 79.2708 27.0052 77.8771 27.0052Z"
                fill="white"
              />
              <path
                d="M60.6862 6.73193L60.3686 5.74927H55.728L55.3917 6.73193L48.4757 27.0316H53.6655L54.5361 24.4498H61.2354L62.0761 27.0316H67.236L60.6862 6.73193ZM60.4919 17.8663C60.447 18.0382 60.3835 18.2026 60.3088 18.3558C59.9352 19.118 59.214 19.6822 58.3659 19.8354C56.6434 20.1455 55.1377 18.8228 55.1377 17.1564C55.1377 16.6445 55.2871 16.1401 55.5823 15.6581C55.8513 15.221 56.2586 14.7502 56.6882 14.2495C57.0731 13.8049 57.4766 13.3341 57.8615 12.811C58.2463 13.3304 58.6498 13.7974 59.031 14.242C59.4718 14.7539 59.8754 15.2247 60.1407 15.6544C60.5741 16.3606 60.6936 17.1116 60.4919 17.8663Z"
                fill="white"
              />
              <g clip-path="url(#clip0_1683_1019)">
                <path
                  d="M106.608 2.96942H107.233C107.478 2.96942 107.66 2.91407 107.778 2.79547C107.905 2.67687 107.96 2.51873 107.96 2.32106C107.96 2.12339 107.905 1.95734 107.786 1.84665C107.667 1.73595 107.486 1.6806 107.225 1.6806H106.6V2.96942H106.608ZM107.944 4.51916L107.177 3.22244H106.608V4.51916H106.3V1.41968H107.233C107.58 1.41968 107.841 1.49875 108.023 1.66479C108.205 1.83083 108.292 2.04432 108.292 2.31315C108.292 2.53454 108.229 2.73221 108.102 2.89035C107.976 3.04849 107.786 3.15128 107.533 3.19872L108.332 4.51916H107.96H107.944Z"
                  fill="white"
                />
                <path
                  d="M107.185 5.78421C105.635 5.78421 104.37 4.51912 104.37 2.96938C104.37 1.41964 105.635 0.154541 107.185 0.154541C108.735 0.154541 110 1.41964 110 2.96938C110 4.51912 108.735 5.78421 107.185 5.78421ZM107.185 0.360119C105.746 0.360119 104.576 1.53033 104.576 2.96938C104.576 4.40842 105.746 5.57864 107.177 5.57864C108.608 5.57864 109.786 4.40842 109.786 2.96938C109.786 1.53033 108.624 0.360119 107.185 0.360119Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_1683_1019">
                  <rect
                    width="5.62967"
                    height="5.62967"
                    fill="white"
                    transform="translate(104.37 0.154541)"
                  />
                </clipPath>
              </defs>
            </svg>

            <!-- <div class="model-side-text mt-10 ">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, 
                  </div> -->
            <div class="text-center py-20">
              <svg
                width="94"
                height="108"
                viewBox="0 0 94 108"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M92 106V90.9986C92 87.0591 91.418 83.1582 90.2873 79.5186C89.1566 75.879 87.4993 72.572 85.41 69.7864C83.3207 67.0008 80.8404 64.7911 78.1106 63.2835C75.3809 61.7759 72.4551 61 69.5004 61H24.4996C21.5449 61 18.6192 61.7759 15.8894 63.2835C13.1596 64.7911 10.6793 67.0008 8.58998 69.7864C4.37049 75.4122 2 83.0425 2 90.9986V105.998"
                  stroke="white"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M72.1826 27.0913C72.1826 32.0538 70.7111 36.9049 67.9541 41.0311C65.1971 45.1573 61.2785 48.3733 56.6938 50.2725C52.109 52.1716 47.0641 52.6686 42.1969 51.7006C37.3297 50.7326 32.8589 48.3431 29.3497 44.8342C25.8406 41.3253 23.4507 36.8546 22.4824 31.9875C21.514 27.1204 22.0106 22.0754 23.9095 17.4905C25.8083 12.9057 29.024 8.98681 33.15 6.22952C37.276 3.47224 42.127 2.00036 47.0895 2C50.3847 1.99976 53.6477 2.64859 56.6921 3.90944C59.7365 5.17029 62.5028 7.01847 64.8329 9.34843C67.163 11.6784 69.0114 14.4445 70.2725 17.4889C71.5335 20.5332 72.1826 23.7961 72.1826 27.0913Z"
                  stroke="white"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <v-form v-model="validateAddAddress" ref="addAddressForm">
          <div
            class="full-flex pa-10"
            style="background: #fff; position: relative"
          >
            <div class="sub-heading mmt-15" style="text-transform: none">
              Add New address
            </div>
            <!-- <div class="service-content ">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam
                    </div> -->
            <v-alert v-if="errorFlag" class="mt-5" dense text type="error">
              <span class="red-color"> Something went wrong try again</span>
            </v-alert>
            <v-card flat color="p-0 mt-5">
              <div class="row mt-5">
                <div class="col-md-4">
                  <v-select
                    v-validate="'required'"
                    name="Location"
                    :items="locationType"
                    item-text="name"
                    item-value="name"
                    :rules="[
                      (v) =>
                        Boolean(Object.keys(v || {})[0]) ||
                        'This field is required',
                    ]"
                    v-model="newaddress.location"
                    append-icon="mdi-chevron-down"
                  >
                    <template v-slot:label>
                      <span class="add-text">Location</span>
                    </template>
                  </v-select>
                  <!-- <div style="margin-top: -10px" class="red-color">
                  {{ errors.first("Location") }}
                </div> -->
                </div>
                <div class="col-md-4">
                  <v-select
                    v-validate="'required'"
                    name="Governorate"
                    :rules="[
                      (v) =>
                        Boolean(Object.keys(v || {})[0]) ||
                        'This field is required',
                    ]"
                    @input="getAreas()"
                    :items="governorates"
                    item-text="name"
                    return-object
                    v-model="newaddress.governorate"
                    append-icon="mdi-chevron-down"
                  >
                    <template v-slot:label>
                      <span class="add-text">Governorate</span>
                    </template>
                  </v-select>
                  <!-- <div style="margin-top: -10px" class="red-color">
                  {{ errors.first("Governorate") }}
                </div> -->
                </div>
                <div class="col-md-4">
                  <v-select
                    v-validate="'required'"
                    name="Location"
                    :items="areas"
                    item-text="name"
                    return-object
                    :rules="[
                      (v) =>
                        Boolean(Object.keys(v || {})[0]) ||
                        'This field is required',
                    ]"
                    v-model="newaddress.area"
                    append-icon="mdi-chevron-down"
                  >
                    <template v-slot:label>
                      <span class="add-text">Area</span>
                    </template>
                  </v-select>
                  <!-- <div style="margin-top: -10px" class="red-color">
                  {{ errors.first("Area") }}
                </div> -->
                </div>
                <!-- <div class="col-md-12">
                        <v-text-field   class="mt-5" >
                             <template  v-slot:label>
                            <span class="add-text">Address Line 1</span>
                          </template>
                        </v-text-field>
                      </div>
                      <div class="col-md-12">
                        <v-text-field   class="mt-5" >
                            <template  v-slot:label>
                            <span class="add-text">Address Line 2</span>
                          </template>
                        </v-text-field>
                      </div> -->
                <div class="col-md-4">
                  <v-text-field v-model="newaddress.floor" class="mt-5">
                    <template v-slot:label>
                      <span class="add-text">Floor</span>
                    </template>
                  </v-text-field>
                </div>
                <div class="col-md-4">
                  <v-text-field
                    v-validate="'required'"
                    name="Apartment"
                    :rules="[
                      (val) =>
                        (val || '').length > 0 || 'This field is required',
                    ]"
                    v-model="newaddress.apartment"
                    class="mt-5"
                  >
                    <template v-slot:label>
                      <span class="add-text">Apartment</span>
                    </template>
                  </v-text-field>
                  <!-- <div style="margin-top: -10px" class="red-color">
                  {{ errors.first("Apartment") }}
                </div> -->
                </div>
                <div class="col-md-4">
                  <v-text-field
                    v-validate="'required'"
                    name="Building"
                    :rules="[
                      (val) =>
                        (val || '').length > 0 || 'This field is required',
                    ]"
                    v-model="newaddress.building"
                    class="mt-5"
                  >
                    <template v-slot:label>
                      <span class="add-text">Building</span>
                    </template>
                  </v-text-field>
                  <!-- <div style="margin-top: -10px" class="red-color">
                  {{ errors.first("Building") }}
                </div> -->
                </div>
                <div class="col-md-4">
                  <v-text-field
                    v-validate="'required'"
                    name="Block"
                    :rules="[
                      (val) =>
                        (val || '').length > 0 || 'This field is required',
                    ]"
                    v-model="newaddress.block"
                    class="mt-5"
                  >
                    <template v-slot:label>
                      <span class="add-text">Block</span>
                    </template>
                  </v-text-field>
                  <!-- <div style="margin-top: -10px" class="red-color">
                  {{ errors.first("Block") }}
                </div> -->
                </div>
                <div class="col-md-4">
                  <v-text-field
                    v-validate="'required'"
                    name="Street"
                    :rules="[
                      (val) =>
                        (val || '').length > 0 || 'This field is required',
                    ]"
                    v-model="newaddress.street"
                    class="mt-5"
                  >
                    <template v-slot:label>
                      <span class="add-text">Street</span>
                    </template>
                  </v-text-field>
                  <!-- <div style="margin-top: -10px" class="red-color">
                  {{ errors.first("Street") }}
                </div> -->
                </div>
                <div class="col-md-4">
                  <v-text-field v-model="newaddress.avenue" class="mt-5">
                    <template v-slot:label>
                      <span class="add-text">Avenue</span>
                    </template>
                  </v-text-field>
                </div>
              </div>
            </v-card>
           <div class="hidden-xs-only">
             <br>
            <br>
            <br>
           </div>
            <div class="dialog-btn-section">
              <button
                @click="dialog = false"
                type="button"
                class="align-self-end bl-cancel-btn ma-3"
                style="min-width: 162.37px"
              >
                Cancel
              </button>
              <v-progress-circular
                v-if="loader"
                indeterminate
                color="primary"
              ></v-progress-circular>
              <button
                v-else
                @click="addNewSubmit()"
                type="button"
                class="align-self-end bl-primary-btn"
                style="min-width: 162.37px"
              >
                Save
              </button>
            </div>
          </div>
        </v-form>
      </div>
    </v-dialog>
    <v-dialog v-model="deleteDialog" scrollable width="418">
      <div class="w-100 bg-white pa-8">
        <div class="text-center sub-heading">Delete ?</div>
        <div class="service-content text-center mt-4">
          Do you want to delete ?
        </div>
        <div class="row mt-5">
          <div class="col-md-6">
            <button
              @click="deleteDialog = false"
              type="button"
              class="align-self-end bl-cancel-btn w-100"
              style="margin-top: 20px"
            >
              No
            </button>
          </div>
          <div class="col-md-6">
            <button
              @click="deleteAddress()"
              type="button"
              class="align-self-end bl-primary-btn w-100"
              style="margin-top: 20px"
            >
              YES
            </button>
          </div>
        </div>
      </div>
    </v-dialog>
    <v-dialog v-model="editdialog" scrollable width="1112">
      <div class="ad-model">
        <div>
          <div class="modal-side">
            <svg
              width="110"
              height="28"
              viewBox="0 0 110 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M98.6775 5.75269H103.58V27.035H98.6775V18.8001H92.5311V27.035H87.629V5.75269H92.5311V13.898H98.6775V5.75269Z"
                fill="white"
              />
              <path
                d="M15.8422 20.8029C15.8422 24.543 13.563 27.1809 8.35826 27.1809H0V5.7229H8.04814C12.2964 5.7229 14.5756 8.08429 14.5756 11.6376C14.5756 12.8631 14.1272 14.6416 12.7485 15.7438C15.0239 16.7265 15.8422 18.8413 15.8422 20.8029ZM5.3729 10.9389V13.8831H8.07429C9.28488 13.8831 9.84533 13.2405 9.84533 12.411C9.84533 11.5815 9.28114 10.9389 8.07429 10.9389H5.3729V10.9389ZM10.9999 20.6161C10.9999 19.6035 10.2414 18.8077 8.86267 18.8077H5.3729V22.4245H8.86267C10.2414 22.4245 10.9999 21.595 10.9999 20.6161Z"
                fill="white"
              />
              <path
                d="M30.6981 27.0356H18.0243V5.72339H22.9264V22.1335H30.6981V27.0356Z"
                fill="white"
              />
              <path
                d="M46.3235 10.6511H37.5692V13.8681H44.6721V18.7702H37.5692V22.1329H46.3235V27.035H32.6671V5.77884L46.3235 5.75269V10.6511Z"
                fill="white"
              />
              <path
                d="M77.8771 27.0052C76.5059 27.0052 75.1645 26.7511 73.8605 26.2355C72.5566 25.7236 71.3796 24.9465 70.3371 23.9003C69.3321 22.8765 68.5624 21.722 68.0318 20.4367C67.5012 19.1513 67.236 17.795 67.236 16.364C67.236 14.9143 67.5012 13.5468 68.0318 12.2615C68.5624 10.9762 69.3321 9.83284 70.3371 8.82402C71.3796 7.78158 72.5603 6.99694 73.8755 6.47385C75.1907 5.95075 76.5283 5.68921 77.8921 5.68921C79.2559 5.68921 80.5897 5.95075 81.8937 6.47385C83.1977 6.99694 84.371 7.77784 85.4171 8.82402L81.9647 12.2727C81.4043 11.7122 80.7654 11.2938 80.0517 11.0135C79.3381 10.7333 78.6132 10.5913 77.8771 10.5913C77.1411 10.5913 76.4237 10.7333 75.7175 11.0135C75.0113 11.2938 74.3799 11.7122 73.8194 12.2727C73.2777 12.8331 72.8629 13.4609 72.5715 14.1558C72.2801 14.8508 72.1381 15.5869 72.1381 16.3603C72.1381 17.1337 72.2838 17.8623 72.5715 18.5498C72.8629 19.2373 73.2777 19.8613 73.8194 20.4217C74.3799 20.9822 75.0113 21.4044 75.7175 21.6846C76.4237 21.9648 77.1448 22.1031 77.8771 22.1031C78.6095 22.1031 79.3381 21.9648 80.0517 21.6846C80.7654 21.4044 81.4043 20.9859 81.9647 20.4217L85.4171 23.9003C84.3747 24.9427 83.2052 25.7236 81.9087 26.2355C80.6122 26.7474 79.2708 27.0052 77.8771 27.0052Z"
                fill="white"
              />
              <path
                d="M60.6862 6.73193L60.3686 5.74927H55.728L55.3917 6.73193L48.4757 27.0316H53.6655L54.5361 24.4498H61.2354L62.0761 27.0316H67.236L60.6862 6.73193ZM60.4919 17.8663C60.447 18.0382 60.3835 18.2026 60.3088 18.3558C59.9352 19.118 59.214 19.6822 58.3659 19.8354C56.6434 20.1455 55.1377 18.8228 55.1377 17.1564C55.1377 16.6445 55.2871 16.1401 55.5823 15.6581C55.8513 15.221 56.2586 14.7502 56.6882 14.2495C57.0731 13.8049 57.4766 13.3341 57.8615 12.811C58.2463 13.3304 58.6498 13.7974 59.031 14.242C59.4718 14.7539 59.8754 15.2247 60.1407 15.6544C60.5741 16.3606 60.6936 17.1116 60.4919 17.8663Z"
                fill="white"
              />
              <g clip-path="url(#clip0_1683_1019)">
                <path
                  d="M106.608 2.96942H107.233C107.478 2.96942 107.66 2.91407 107.778 2.79547C107.905 2.67687 107.96 2.51873 107.96 2.32106C107.96 2.12339 107.905 1.95734 107.786 1.84665C107.667 1.73595 107.486 1.6806 107.225 1.6806H106.6V2.96942H106.608ZM107.944 4.51916L107.177 3.22244H106.608V4.51916H106.3V1.41968H107.233C107.58 1.41968 107.841 1.49875 108.023 1.66479C108.205 1.83083 108.292 2.04432 108.292 2.31315C108.292 2.53454 108.229 2.73221 108.102 2.89035C107.976 3.04849 107.786 3.15128 107.533 3.19872L108.332 4.51916H107.96H107.944Z"
                  fill="white"
                />
                <path
                  d="M107.185 5.78421C105.635 5.78421 104.37 4.51912 104.37 2.96938C104.37 1.41964 105.635 0.154541 107.185 0.154541C108.735 0.154541 110 1.41964 110 2.96938C110 4.51912 108.735 5.78421 107.185 5.78421ZM107.185 0.360119C105.746 0.360119 104.576 1.53033 104.576 2.96938C104.576 4.40842 105.746 5.57864 107.177 5.57864C108.608 5.57864 109.786 4.40842 109.786 2.96938C109.786 1.53033 108.624 0.360119 107.185 0.360119Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_1683_1019">
                  <rect
                    width="5.62967"
                    height="5.62967"
                    fill="white"
                    transform="translate(104.37 0.154541)"
                  />
                </clipPath>
              </defs>
            </svg>

            <div class="text-center py-20">
              <svg
                width="94"
                height="108"
                viewBox="0 0 94 108"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M92 106V90.9986C92 87.0591 91.418 83.1582 90.2873 79.5186C89.1566 75.879 87.4993 72.572 85.41 69.7864C83.3207 67.0008 80.8404 64.7911 78.1106 63.2835C75.3809 61.7759 72.4551 61 69.5004 61H24.4996C21.5449 61 18.6192 61.7759 15.8894 63.2835C13.1596 64.7911 10.6793 67.0008 8.58998 69.7864C4.37049 75.4122 2 83.0425 2 90.9986V105.998"
                  stroke="white"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M72.1826 27.0913C72.1826 32.0538 70.7111 36.9049 67.9541 41.0311C65.1971 45.1573 61.2785 48.3733 56.6938 50.2725C52.109 52.1716 47.0641 52.6686 42.1969 51.7006C37.3297 50.7326 32.8589 48.3431 29.3497 44.8342C25.8406 41.3253 23.4507 36.8546 22.4824 31.9875C21.514 27.1204 22.0106 22.0754 23.9095 17.4905C25.8083 12.9057 29.024 8.98681 33.15 6.22952C37.276 3.47224 42.127 2.00036 47.0895 2C50.3847 1.99976 53.6477 2.64859 56.6921 3.90944C59.7365 5.17029 62.5028 7.01847 64.8329 9.34843C67.163 11.6784 69.0114 14.4445 70.2725 17.4889C71.5335 20.5332 72.1826 23.7961 72.1826 27.0913Z"
                  stroke="white"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <v-form v-model="validateEditAddress" ref="editAddressForm">
          <div
            class="full-flex pa-10"
            style="background: #fff; position: relative"
          >
            <div class="sub-heading mmt-15" style="text-transform: none">
              Edit address
            </div>
            <!-- <div class="service-content ">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam
                    </div> -->
            <v-alert v-if="errorFlag" class="mt-5" dense text type="error">
              <span class="red-color"> Something went wrong try again</span>
            </v-alert>
            <v-card flat color="p-0 mt-5">
              <div class="row mt-5">
                <div class="col-md-4">
                  <v-select
                    v-validate="'required'"
                    name="Location"
                    :items="locationType"
                    item-text="name"
                    item-value="name"
                    :rules="[
                      (v) =>
                        Boolean(Object.keys(v || {})[0]) ||
                        'This field is required',
                    ]"
                    v-model="editaddress.location"
                    append-icon="mdi-chevron-down"
                  >
                    <template v-slot:label>
                      <span class="add-text">Location</span>
                    </template>
                  </v-select>
                </div>
                <div class="col-md-4">
                  <v-select
                    v-validate="'required'"
                    name="Governorate"
                    :rules="[
                      (v) =>
                        Boolean(Object.keys(v || {})[0]) ||
                        'This field is required',
                    ]"
                    @input="getAreasEdit(editaddress.governorate.id)"
                    :items="governorates"
                    item-text="name"
                    return-object
                    v-model="editaddress.governorate"
                    append-icon="mdi-chevron-down"
                  >
                    <template v-slot:label>
                      <span class="add-text">Governorate</span>
                    </template>
                  </v-select>
                  <!-- <div style="margin-top: -10px" class="red-color">
                  {{ errors.first("Governorate") }}
                </div> -->
                </div>
                <div class="col-md-4">
                  <v-select
                    v-validate="'required'"
                    name="Area"
                    :rules="[
                      (v) =>
                        Boolean(Object.keys(v || {})[0]) ||
                        'This field is required',
                    ]"
                    :items="areas"
                    item-text="name"
                    return-object
                    append-icon="mdi-chevron-down"
                    v-model="editaddress.area"
                  >
                    <template v-slot:label>
                      <span class="add-text">Area</span>
                    </template>
                  </v-select>
                  <!-- <div style="margin-top: -10px" class="red-color">
                  {{ errors.first("Area") }}
                </div> -->
                </div>

                <div class="col-md-4">
                  <v-text-field
                    v-validate="'required'"
                    name="Apartment"
                    :rules="[
                      (val) =>
                        (val || '').length > 0 || 'This field is required',
                    ]"
                    v-model="editaddress.apartment"
                    class="mt-5"
                  >
                    <template v-slot:label>
                      <span class="add-text">Apartment</span>
                    </template>
                  </v-text-field>
                  <!-- <div style="margin-top: -10px" class="red-color">
                  {{ errors.first("Apartment") }}
                </div>-->
                </div>
                <div class="col-md-4">
                  <v-text-field
                    v-validate="'required'"
                    name="Building"
                    :rules="[
                      (val) =>
                        (val || '').length > 0 || 'This field is required',
                    ]"
                    v-model="editaddress.building"
                    class="mt-5"
                  >
                    <template v-slot:label>
                      <span class="add-text">Building</span>
                    </template>
                  </v-text-field>
                  <!-- <div style="margin-top: -10px" class="red-color">
                  {{ errors.first("Building") }}
                </div> -->
                </div>
                <div class="col-md-4">
                  <v-text-field
                    v-validate="'required'"
                    name="Block"
                    :rules="[
                      (val) =>
                        (val || '').length > 0 || 'This field is required',
                    ]"
                    v-model="editaddress.block"
                    class="mt-5"
                  >
                    <template v-slot:label>
                      <span class="add-text">Block</span>
                    </template>
                  </v-text-field>
                  <!-- <div style="margin-top: -10px" class="red-color">
                  {{ errors.first("Block") }}
                </div> -->
                </div>
                <div class="col-md-4">
                  <v-text-field
                    v-validate="'required'"
                    name="Street"
                    :rules="[
                      (val) =>
                        (val || '').length > 0 || 'This field is required',
                    ]"
                    v-model="editaddress.street"
                    class="mt-5"
                  >
                    <template v-slot:label>
                      <span class="add-text">Street</span>
                    </template>
                  </v-text-field>
                  <!-- <div style="margin-top: -10px" class="red-color">
                  {{ errors.first("Street") }}
                </div> -->
                </div>
                <div class="col-md-4">
                  <v-text-field v-model="editaddress.avenue" class="mt-5">
                    <template v-slot:label>
                      <span class="add-text">Avenue</span>
                    </template>
                  </v-text-field>
                </div>
              </div>
            </v-card>
             <div class="hidden-xs-only">
             <br>
            <br>
            <br>
           </div>
            <div class="dialog-btn-section">
              <button
                @click="editdialog = false"
                type="button"
                class="align-self-end bl-cancel-btn ma-3"
                style="min-width: 162.37px"
              >
                Cancel
              </button>
              <button
                @click="editSubmit()"
                type="button"
                class="align-self-end bl-primary-btn"
                style="min-width: 162.37px"
              >
                Save
              </button>
            </div>
          </div>
        </v-form>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import service from "../../services/service";
import utils from "../../utils/utils";
export default {
  data() {
    return {
      items: ["Foo", "Bar", "Fizz", "Buzz"],
      mainloader: false,
      deleteAddId: null,
      editdialog: false,
      selectedAddress: null,
      loader: false,
      deleteDialog: false,
      dialog: false,
      errorFlag: false,
      address: [],
      governorates: [],
      areas: [],
      locationType: [],
      newaddress: {
        location: "",
        currently_active: "true",
        area: null,
        governorate: null,
        block: "",
        avenue: "",
        building: "",
        street: "",
        floor: "",
        apartment: "",
      },
      editaddress: {
        address_id: null,
        location: "",
        currently_active: "true",
        area: null,
        governorate: null,
        block: "",
        avenue: "",
        building: "",
        street: "",
        floor: "",
        apartment: "",
      },
      validateAddAddress: true,
      validateEditAddress: true,
      appLoading:false,
    };
  },
  mounted() {
    this.listaddress();
    this.getAddressDatas();
    this.getCustomerData();
  },
  methods: {
    async getCustomerData() {
      let result = await service._get(
        "api/customer-details/" + localStorage.getItem("bleach-tk") + "/"
      );
      console.log(result);
      if (result.status == 200) {
        this.customer = result.data.customer_data;
      }
    },
    addNewSubmit() {
      if (this.$refs["addAddressForm"].validate()) this.addNewAddress();
      else window.scrollTo(0, 0);
      // console.log('addvali', this.$validator)

      // this.$validator.validateAll().then((result) => {
      //   if (!result) {
      //     return;
      //   }
      //   this.addNewAddress();
      // });
    },
    editSubmit() {
      if (this.$refs["editAddressForm"].validate()) this.saveAddress();
      else window.scrollTo(0, 0);
      // console.log('editvali', this.$validator)
      // this.$validator.validateAll().then((result) => {
      //   if (!result) {
      //     return;
      //   }
      //   this.saveAddress();
      // });
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    changeAdd(item) {
      this.selectedAddress = item.id;
    },
    editAddressData(data) {
      this.getAreasEdit(data.governorate.id);
      this.editaddress.address_id = data.id;
      this.editaddress.location = data.location;
      this.editaddress.apartment = data.apartment;
      this.editaddress.area = data.area;
      this.editaddress.avenue = data.avenue;
      this.editaddress.block = data.block;
      this.editaddress.building = data.building;
      this.editaddress.floor = data.floor;
      this.editaddress.governorate = data.governorate;
      this.editaddress.street = data.street;
      this.editdialog = true;
    },
    async addNewAddress() {
      this.loader = true;
      this.errorFlag = false;

      this.newaddress.governorate = this.newaddress.governorate.id;
      this.newaddress.area = this.newaddress.area.id;
 if(!(this.newaddress.block.includes("Block") || this.newaddress.block.includes("block")))
      this.newaddress.block = this.newaddress.block + " Block";
      if(!(this.newaddress.avenue.includes("Avenue") || this.newaddress.avenue.includes("avenue")))
      this.newaddress.avenue = this.newaddress.avenue + " Avenue";
      if(!(this.newaddress.apartment.includes("Apartment") || this.newaddress.apartment.includes("apartment")))
      this.newaddress.apartment = this.newaddress.apartment + " Apartment";
      if(!(this.newaddress.street.includes("Street") || this.newaddress.street.includes("street")))
      this.newaddress.street = this.newaddress.street + " Street";
      if(!(this.newaddress.building.includes("Building") || this.newaddress.building.includes("building")))
      this.newaddress.building = this.newaddress.building + " Building";
      if(!(this.newaddress.floor.includes("Floor") || this.newaddress.floor.includes("floor")))
      this.newaddress.floor = this.newaddress.floor + " Floor";
        this.appLoading=true
      let result = await service._post(
        "api/customer-addresses/" +
          localStorage.getItem("bleach-tk") +
          "/?action=add_address",
        { addresses: [this.newaddress] }
      );
      if (result.status == 200) {
        
          this.$vs.notification({
          duration: "3000",
          position: "top-right",
          border: "success",
          title: result.data.message,
        });
        this.$refs["addAddressForm"].reset();
        this.appLoading=false

        this.dialog = false;
        this.listaddress();
      } else if (result.status == 500) {
        this.errorFlag = true;
      }
      this.loader = false;
    },
    async saveAddress() {
      this.editaddress.governorate = this.editaddress.governorate.id;
      this.editaddress.area = this.editaddress.area.id;
      this.loader = true;
      this.errorFlag = false;
      if(!(this.editaddress.block.includes("Block") || this.editaddress.block.includes("block")))
      this.editaddress.block = this.editaddress.block + " Block";
      if(!(this.editaddress.avenue.includes("Avenue") || this.editaddress.avenue.includes("avenue")))
      this.editaddress.avenue = this.editaddress.avenue + " Avenue";
      if(!(this.editaddress.apartment.includes("Apartment") || this.editaddress.apartment.includes("apartment")))
      this.editaddress.apartment = this.editaddress.apartment + " Apartment";
      if(!(this.editaddress.street.includes("Street") || this.editaddress.street.includes("street")))
      this.editaddress.street = this.editaddress.street + " Street";
      if(!(this.editaddress.building.includes("Building") || this.editaddress.building.includes("building")))
      this.editaddress.building = this.editaddress.building + " Building";
      if(!(this.editaddress.floor.includes("Floor") || this.editaddress.floor.includes("floor")))
      this.editaddress.floor = this.editaddress.floor + " Floor";
      this.appLoading=true

      let result = await service._post(
        "api/customer-addresses/" +
          localStorage.getItem("bleach-tk") +
          "/?action=edit_address",
        this.editaddress
      );
      if (result.status == 200) {
        this.appLoading=false
        this.$refs["editAddressForm"].reset();
        this.editdialog = false;
        this.editaddress = {
          address_id: null,
          location: "",
          currently_active: "true",
          area: null,
          governorate: null,
          block: "",
          avenue: "",
          building: "",
          street: "",
          floor: "",
          apartment: "",
        };
        this.listaddress();
      } else if (result.status == 500) {
        this.errorFlag = true;
      }
      this.loader = false;
    },
    async deleteAddress() {
      let result = await service._post(
        "api/customer-addresses/" +
          localStorage.getItem("bleach-tk") +
          "/?action=disable_address",
        {
          address_id: this.deleteAddId,
        }
      );
      if (utils.isReqSuccess(result)) {
        this.listaddress();
        this.deleteDialog = false;
      } else {
        this.$vs.notification({
          duration: "3000",
          position: "top-right",
          text: "Try again",
          border: "danger",
          title: "Address delete failed",
        });
      }
    },
    async getAreas() {
      let result = await service._get(
        "api/areas/" + this.newaddress.governorate.id + "/"
      );
      if (result.status == 200) {
        this.areas = result.data.areas;
      }
    },
    async getAreasEdit(id) {
      let result = await service._get("api/areas/" + id + "/");
      if (result.status == 200) {
        this.areas = result.data.areas;
      }
    },
    async getAddressDatas() {
      let result2 = await service._get("api/location-types/");
      if (result2.status == 200) {
        this.locationType = result2.data.location_types;
      }
      let result = await service._get("api/governorates/");
      if (result.status == 200) {
        this.governorates = result.data.governorates;
      }
    },
    async listaddress() {
      this.mainloader = true;
      let result = await service._get(
        "api/customer-addresses/" + localStorage.getItem("bleach-tk") + "/"
      );
      this.mainloader = false;
      this.address = [];
      if (result.status == 200) {
        var address = result.data.addresses;
        for (var i = 0; i < address.length; i++) {
          if (address[i].currently_active) {
            this.address.push(address[i]);
          }
        }
        if (this.address.length > 0) {
          if (this.evaluation) {
            if (this.evaluation.address_id != null) {
              this.selectedAddress = this.evaluation.address_id;
            } else {
              this.selectedAddress = this.address[0].id;
            }
          }
        } else {
          this.dialog = true;
        }
      }
    },
  },
};
</script>

<style>
.add-address-btn {
  background: rgba(218, 218, 218, 0.6);
  border-radius: 4px;
  cursor: pointer;
  width: fit-content;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #6d6d6e;
  padding: 7px 50px;
}
</style>