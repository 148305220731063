<template>
<div>
    <div v-if="selectlan == 'EN'">
      <div class="gray-bg-2">
      <div class="bcontainer">
         <div class="row py-15">
             <div class="col-md-4 col-lg-3">
                 <ul class="service-ui show-head">
                    <li class="service-ui-active" >
                    <div class="dot-li-active"></div>
                        Domestic Sector
                    </li> 
                     <li  @click="$router.push('babycare')">
                        <span class="dot-li"></span>
                       Baby Care
                    </li>
                    <li @click="$router.push('moving')" >
                         <span class="dot-li"></span>
                        Moving In/Out
                    </li>
                     <li @click="$router.push('events')" >
                        <span class="dot-li"></span>
                        Special Events Care
                    </li>
                
                     
                 </ul>
                 <resmenu :name="'Domestic Sector'"/>
                  <div class="mt-5">
                    <div class="recommended-head">
                        RECOMMENDED SERVICES
                    </div>
                    <div class="rec-item-name mt-3" @click="goto('/service/detailedcleaning/generalcleaning')">
                        <img src="../../assets/icons/recommend/general.png" alt="" class="ml-2 mr-2">  General Cleaning
                    </div>
                    <div class="rec-item-name mt-3" @click="goto('/service/specialcare/upholsterycleaning')">
                        <img src="../../assets/icons/recommend/up.png" alt="" class="ml-2 mr-2">  Upholstery Cleaning
                    </div>
                     <div class="rec-item-name mt-3" @click="goto('/service/kitchencleaning/kitchencleaning')">
                        <img src="../../assets/icons/recommend/kit.png" alt="" class="ml-2 mr-2">  Kitchen Cleaning
                    </div>
                 </div>
             </div>
             <div class="col-md-8 col-lg-9">
                 <div class="cleaning-head show-head">Domestic <span class="w400">Sector</span> </div>
                   <div class="sub-content mt-2">
        We believe ‘cleanliness is paramount to a great experience’. That's why BLEACH® offers a
full range of cleaning services to the domestic sector – from Oven Cleaning, Carpet &
Upholstery Cleaning, Building Maintenance & Special Events to Kitchen Cleaning and Surface
Disinfection.
<br><br>
BLEACH®’s professional & trusted cleaners provide a deep & detailed cleaning experience.
Our technicians use high-grade cleaning products, tools, and equipment to assure fine care
of your assets while you leave your property to us.
                 </div>
                 <div class="bold-heading mt-10">
                     DID YOU KNOW ?
                 </div>
                 <div class="mt-3 sub-content">
                    What if we tell you that Volatile Organic Compounds (VOC) found in regular cleaning
products negatively impact indoor and outdoor air quality? Environmental Protection
Agency (EPA) states that these ingredients are dangerous and irritate the eyes and skin of
janitorial and other employees who clean regularly. Also, pollution from disposing of
nongreen chemicals in waterways causes headache, throat irritation, eye irritation, and
asthma.
<br><br>
Our tailor-made cleaning products are safe-to-use by cleaners and free from ammonia,
ethylene glycol monobutyl acetate, sodium hypochlorite, and/or trisodium phosphate, and
other harmful chemicals. We safeguard our cleaners who re-energize your home, for you to
create happy and healthy memories.

                 </div>
                  <div class="bold-heading mt-10">
                   WHY DOES YOUR HOME NEED A PROFESSIONAL CLEAN-UP?
                 </div>
                 <div class="mt-5 sub-content">
                     A cleaner home means a healthier you. Frequent cleaning of the home by trained & skilled
cleaners reduces the chances of developing allergies or aggravating existing symptoms.
According to the American Cleaning Institute, the easiest way to subdue asthma flares is to
control triggers such as animal dander, cockroaches, dust mites, mold/mildew, and pollen.
Our cleaners assure you that your house is free from germs and bacteria that may make
your family ill.
                 </div>
             </div>
         </div>
      </div>
  </div>
</div>
  <div v-if="selectlan == 'ARABIC'">
      <div class="gray-bg-2">
      <div class="bcontainer">
         <div class="row py-15">
             <div class="col-md-4 col-lg-3">
                 <ul class="service-ui-ar show-head">
                    <li class="service-ui-active arabic-font" >
                    <div class="dot-li-ar-active"></div>
                        قطاع األعمال المنزلية 
                    </li> 
                     <li class="arabic-font"  @click="$router.push('babycare')">
                        <span class="dot-li-ar"></span>
                       رعاية األطفال
                    </li>
                    <li class="arabic-font" @click="$router.push('moving')" >
                         <span class="dot-li-ar"></span>
                        أعمال انتقال المأجر
                    </li>
                     <li class="arabic-font" @click="$router.push('events')" >
                        <span class="dot-li-ar"></span>
                        رعاية المناسبات الخاصة
                    </li>
                
                     
                 </ul>
                 <resmenu :name="'قطاع األعمال المنزلية'"/>
             </div>
             <div class="col-md-8 col-lg-9">
                 <div class="cleaning-head show-head arabic-font">قطاع الأعمال <span class="w400">المنزلية</span> </div>
                   <div class="sub-content mt-2 arabic-font">
        لدينا إيمان راسخ بأن "النظافة أمر بالغ الأهمية للتمتع بتجربة رائعة". لهذا السبب توفر بليتش® مجموعة متكاملة من خدمات التنظيف لقطاع الأعمال المنزلية - من تنظيف الأفران، وتنظيف الزل والسجاد والمفروشات، وصيانة المباني والإعداد للمناسبات الخاصة - إلى تنظيف المطابخ وتطهير الأسطح. 

  <br><br>
يوفر عمال التنظيف المحترفين ذوو الثقة العاملين لدى بليتش® تجربة تنظيف عميقة ومفصلة. يستخدم الفنيون لدينا منتجات وأدوات ومعدات تنظيف عالية الجودة لضمان العناية الدقيقة بأصولك،
                 </div>
                 <div class="bold-heading mt-10 arabic-font">
                     هل تعلم؟
                 </div>
                 <div class="mt-3 sub-content arabic-font">
                     ماذا لو قلنا لك أن المركبات العضوية المتطايرة (م.ع.م.) الموجودة في منتجات التنظيف العادية تؤثر سلبًا على جودة الهواء الداخلي والخارجي؟ تنص توجيهات الهيئة العامة للبيئة على أن هذه المكونات خطرة، وتتسبب في تهيج العيون والجلد لدى عمال النظافة وغيرهم من الموظفين الذين ينظفون بانتظام، ناهيك التلوث الناتج عن التخلص من المواد الكيميائية غير الصديقة للبيئة في المجاري المائية والمتسبب في المعاناة من الصداع، وتهيج الحلق، وتهيج العين والربو. 

  <br><br>

لتكوّن وتتمتع بذكريات سعيدة وصحية في منزلك الذي عادت اليه روح النشاط الحيوية على يد عمال التنظيف العاملين لدينا، نقوم نحن بحمايتهم باستخدام مواد التنظيف آمنة ومصنعة خصيصاً لنا، فهي خالية من الأمونيا وأسيتات الإيثيلين جليكول، وهيبوكلوريت الصوديوم، و/أو فوسفات ثلاثي الصوديوم، والمواد الكيميائية الضارة الأخرى. 
                 </div>
                  <div class="bold-heading mt-10 arabic-font">
                   لماذا يحتاج منزلك الى خدمات تنظيف إحترافية؟ 
                 </div>
                 <div class="mt-5 sub-content arabic-font">
                  نظافة مضاعفة تسعدك وتغنيك وتتركك أكثر صحة. التنظيف المتكرر للمنزل بواسطة عمال نظافة مدربين ومهرة يقلل من فرص الإصابة بالحساسية أو تفاقم الأعراض القائمة. 
<br><br>
فوفقًا لمعهد التنظيف الأمريكي، فإن أسهل السبل لإخضاع نوبات الربو هي التحكم في المحفزات مثل وبر الحيوانات، والصراصير، وعث الغبار، والعفن/ العفن الفطري، وحبوب اللقاح. يضمن لكم عمال نظافتنا أن منزلكم خالٍ من الجراثيم والبكتيريا التي قد تصيب عائلتك بالمرض
                 </div>
             </div>
         </div>
      </div>
  </div>
  
</div>

</div>
</template>

<script>
import resmenu from '../../components/menu/resmenu.vue'
export default {
    components:{
        resmenu
    },
    data(){
        return{
            selectlan:localStorage.getItem('bleach-lang')
        }
    },
    methods:{
        goto(loc) {
         window.location = loc;
        },
    }
}
</script>

<style>

</style>