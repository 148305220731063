<template>
    <div class="pb-15">
        <div class="topnav mt-4">
                <a class="active"  >My Profile</a>
                <a  @click="$router.push('orders')">My Orders</a>
                <a @click="$router.push('evaluation')" >My Evaluation</a>
                <a @click="$router.push('address')">My Address</a>
            </div>
           <div v-if="customer != null" >
            <div class="row mt-10">
            <div class="col-md-11">
                <div class="pro-name">
                    {{customer.name}}
                </div>
                <div class="pro-dflex mt-1" style="color:#707070;font-family: 'Poppins'">
                    <div><v-icon color="#707070" size="16" class="mt-n1"> mdi-phone </v-icon> {{customer.mobile_number}}</div>
                    <div> <v-icon color="#707070" size="16" class="mt-n1"> mdi-email </v-icon> {{customer.email}}</div>

                </div>
            </div>
            <div class="col-md-1 text-right">
                <button
                style="width:"
                @click="editDataFill()"
                  type="button"
                  class=" bl-secondary-btn w-100"
                 
                  
                >
                <!-- <v-icon size="18" class="mt-n1">mdi-pencil</v-icon> Edit -->
                Edit
                </button>
            </div>
           </div>
            <div class="mt-10 service-content" style="color:#707070;font-family: 'Poppins'">
                <div class="d-flex mb-5 ">
                    <div class="min-wid"> Date of Birth</div>
                    <div class="min-wid"> - <span v-if="customer.date_day != ''">{{customer.date_day}}-{{customer.date_month}}-{{customer.date_year}} </span></div>

                </div>
                <div class="d-flex mb-5">
                    <div class="min-wid"> Nationality</div>
                    <div class="min-wid"> - {{getCountryname(customer.nationality)}}</div>

                </div>
                <div class="d-flex mb-5">
                    <div class="min-wid"> Customer Type</div>
                    <div class="min-wid"> - {{customer.customer_type}}</div>

                </div>
                <div class="d-flex mb-5" v-if="customer.customer_type == 'CORPORATE'" >
                    <div class="min-wid"> Company</div>
                    <div class="min-wid"> - {{customer.company}}</div>

                </div>
                <div class="d-flex mb-5" v-if="customer.customer_type == 'CORPORATE'" >
                    <div class="min-wid"> Job Title</div>
                    <div class="min-wid"> - {{customer.job_title}}</div>

                </div>
                <div class="d-flex mb-5">
                    <div class="min-wid"> Communication Language</div>
                    <div class="min-wid"> - {{customer.sms_preference}}</div>

                </div>
                <div class="d-flex mb-5">
                    <div class="min-wid"> Contact Platform</div>
                    <div class="min-wid"> - <span v-if="customer.is_sms">SMS &nbsp;</span> <span v-if="customer.is_email">EMAIL</span> </div>

                </div>
            </div>
           </div>
           <v-dialog  v-model="dialog" scrollable width="1112">
      <div class="ad-model">
        <div >
            <div class="modal-side" >
                      <svg width="110" height="28" viewBox="0 0 110 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M98.6775 5.75269H103.58V27.035H98.6775V18.8001H92.5311V27.035H87.629V5.75269H92.5311V13.898H98.6775V5.75269Z" fill="white"/>
                          <path d="M15.8422 20.8029C15.8422 24.543 13.563 27.1809 8.35826 27.1809H0V5.7229H8.04814C12.2964 5.7229 14.5756 8.08429 14.5756 11.6376C14.5756 12.8631 14.1272 14.6416 12.7485 15.7438C15.0239 16.7265 15.8422 18.8413 15.8422 20.8029ZM5.3729 10.9389V13.8831H8.07429C9.28488 13.8831 9.84533 13.2405 9.84533 12.411C9.84533 11.5815 9.28114 10.9389 8.07429 10.9389H5.3729V10.9389ZM10.9999 20.6161C10.9999 19.6035 10.2414 18.8077 8.86267 18.8077H5.3729V22.4245H8.86267C10.2414 22.4245 10.9999 21.595 10.9999 20.6161Z" fill="white"/>
                          <path d="M30.6981 27.0356H18.0243V5.72339H22.9264V22.1335H30.6981V27.0356Z" fill="white"/>
                          <path d="M46.3235 10.6511H37.5692V13.8681H44.6721V18.7702H37.5692V22.1329H46.3235V27.035H32.6671V5.77884L46.3235 5.75269V10.6511Z" fill="white"/>
                          <path d="M77.8771 27.0052C76.5059 27.0052 75.1645 26.7511 73.8605 26.2355C72.5566 25.7236 71.3796 24.9465 70.3371 23.9003C69.3321 22.8765 68.5624 21.722 68.0318 20.4367C67.5012 19.1513 67.236 17.795 67.236 16.364C67.236 14.9143 67.5012 13.5468 68.0318 12.2615C68.5624 10.9762 69.3321 9.83284 70.3371 8.82402C71.3796 7.78158 72.5603 6.99694 73.8755 6.47385C75.1907 5.95075 76.5283 5.68921 77.8921 5.68921C79.2559 5.68921 80.5897 5.95075 81.8937 6.47385C83.1977 6.99694 84.371 7.77784 85.4171 8.82402L81.9647 12.2727C81.4043 11.7122 80.7654 11.2938 80.0517 11.0135C79.3381 10.7333 78.6132 10.5913 77.8771 10.5913C77.1411 10.5913 76.4237 10.7333 75.7175 11.0135C75.0113 11.2938 74.3799 11.7122 73.8194 12.2727C73.2777 12.8331 72.8629 13.4609 72.5715 14.1558C72.2801 14.8508 72.1381 15.5869 72.1381 16.3603C72.1381 17.1337 72.2838 17.8623 72.5715 18.5498C72.8629 19.2373 73.2777 19.8613 73.8194 20.4217C74.3799 20.9822 75.0113 21.4044 75.7175 21.6846C76.4237 21.9648 77.1448 22.1031 77.8771 22.1031C78.6095 22.1031 79.3381 21.9648 80.0517 21.6846C80.7654 21.4044 81.4043 20.9859 81.9647 20.4217L85.4171 23.9003C84.3747 24.9427 83.2052 25.7236 81.9087 26.2355C80.6122 26.7474 79.2708 27.0052 77.8771 27.0052Z" fill="white"/>
                          <path d="M60.6862 6.73193L60.3686 5.74927H55.728L55.3917 6.73193L48.4757 27.0316H53.6655L54.5361 24.4498H61.2354L62.0761 27.0316H67.236L60.6862 6.73193ZM60.4919 17.8663C60.447 18.0382 60.3835 18.2026 60.3088 18.3558C59.9352 19.118 59.214 19.6822 58.3659 19.8354C56.6434 20.1455 55.1377 18.8228 55.1377 17.1564C55.1377 16.6445 55.2871 16.1401 55.5823 15.6581C55.8513 15.221 56.2586 14.7502 56.6882 14.2495C57.0731 13.8049 57.4766 13.3341 57.8615 12.811C58.2463 13.3304 58.6498 13.7974 59.031 14.242C59.4718 14.7539 59.8754 15.2247 60.1407 15.6544C60.5741 16.3606 60.6936 17.1116 60.4919 17.8663Z" fill="white"/>
                          <g clip-path="url(#clip0_1683_1019)">
                          <path d="M106.608 2.96942H107.233C107.478 2.96942 107.66 2.91407 107.778 2.79547C107.905 2.67687 107.96 2.51873 107.96 2.32106C107.96 2.12339 107.905 1.95734 107.786 1.84665C107.667 1.73595 107.486 1.6806 107.225 1.6806H106.6V2.96942H106.608ZM107.944 4.51916L107.177 3.22244H106.608V4.51916H106.3V1.41968H107.233C107.58 1.41968 107.841 1.49875 108.023 1.66479C108.205 1.83083 108.292 2.04432 108.292 2.31315C108.292 2.53454 108.229 2.73221 108.102 2.89035C107.976 3.04849 107.786 3.15128 107.533 3.19872L108.332 4.51916H107.96H107.944Z" fill="white"/>
                          <path d="M107.185 5.78421C105.635 5.78421 104.37 4.51912 104.37 2.96938C104.37 1.41964 105.635 0.154541 107.185 0.154541C108.735 0.154541 110 1.41964 110 2.96938C110 4.51912 108.735 5.78421 107.185 5.78421ZM107.185 0.360119C105.746 0.360119 104.576 1.53033 104.576 2.96938C104.576 4.40842 105.746 5.57864 107.177 5.57864C108.608 5.57864 109.786 4.40842 109.786 2.96938C109.786 1.53033 108.624 0.360119 107.185 0.360119Z" fill="white"/>
                          </g>
                          <defs>
                          <clipPath id="clip0_1683_1019">
                          <rect width="5.62967" height="5.62967" fill="white" transform="translate(104.37 0.154541)"/>
                          </clipPath>
                          </defs>
                          </svg>

           
                  <div class="text-center py-20">
                    <svg width="94" height="108" viewBox="0 0 94 108" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M92 106V90.9986C92 87.0591 91.418 83.1582 90.2873 79.5186C89.1566 75.879 87.4993 72.572 85.41 69.7864C83.3207 67.0008 80.8404 64.7911 78.1106 63.2835C75.3809 61.7759 72.4551 61 69.5004 61H24.4996C21.5449 61 18.6192 61.7759 15.8894 63.2835C13.1596 64.7911 10.6793 67.0008 8.58998 69.7864C4.37049 75.4122 2 83.0425 2 90.9986V105.998" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M72.1826 27.0913C72.1826 32.0538 70.7111 36.9049 67.9541 41.0311C65.1971 45.1573 61.2785 48.3733 56.6938 50.2725C52.109 52.1716 47.0641 52.6686 42.1969 51.7006C37.3297 50.7326 32.8589 48.3431 29.3497 44.8342C25.8406 41.3253 23.4507 36.8546 22.4824 31.9875C21.514 27.1204 22.0106 22.0754 23.9095 17.4905C25.8083 12.9057 29.024 8.98681 33.15 6.22952C37.276 3.47224 42.127 2.00036 47.0895 2C50.3847 1.99976 53.6477 2.64859 56.6921 3.90944C59.7365 5.17029 62.5028 7.01847 64.8329 9.34843C67.163 11.6784 69.0114 14.4445 70.2725 17.4889C71.5335 20.5332 72.1826 23.7961 72.1826 27.0913Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                  </div>               

              </div>   
        </div>
            <div class="full-flex pa-9" style="background:#fff">
                <div class="sub-heading  text-left mt-10"  style="text-transform: none">
                    Edit Personal details
                    </div>
                    <div class="mpr-15">
                        <div class="row mt-5">
                        <div class="col-md-6">
                            <v-text-field v-model="editData.name"   class="mt-2"  v-validate="'required'" name="name" data-vv-as="Name">
                           <template  v-slot:label>
                            <span class="add-text">Name</span>
                          </template>
                        </v-text-field>
                        <div style="margin-top:-10px" class="red-color">{{ errors.first("name") }}</div>
                        </div>
                        <!-- <div class="col-md-6">
                            <v-text-field   class="mt-5" >
                           <template  v-slot:label>
                            <span class="add-text">Last Name</span>
                          </template>
                        </v-text-field>
                        </div> -->
                        <div class="col-md-6">
                            <v-text-field  type="email" v-model="editData.email"   class="mt-2"  v-validate="'required|email'" name="mail" data-vv-as="Email" >
                           <template  v-slot:label>
                            <span class="add-text">Email</span>
                          </template>
                        </v-text-field>
                        <div style="margin-top:-10px" class="red-color">{{ errors.first("mail") }}</div>
                        </div>
                        <div class="col-md-6">
                            <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :nudge-bottom="-20"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                            v-validate="'required'" name="date" data-vv-as="Date of birth"
                            class="mt-2" 
                                v-model="date"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            >
                            <template  v-slot:label>
                            <span class="add-text">Date of birth</span>
                          </template>
                        </v-text-field>
                            </template>
                            <v-date-picker
                            header-color="transparent"
                color="primary"
                            v-model="date"
                            @input="menu = false"
                            ></v-date-picker>
                        </v-menu>
                        <div style="margin-top:-10px" class="red-color">{{ errors.first("date") }}</div>
                        </div>
                        <div class="col-md-6">
                            <v-select
                            
                            v-model="editData.customer_type" 
                            class="mt-2"
                            v-validate="'required'" name="type" data-vv-as="type"
                   
                                :items="items"
                                append-icon="mdi-chevron-down"
                            >
                                <template v-slot:label>
                                <span class="add-text">Customer Type</span>
                                </template>
                         </v-select>
                         <div style="margin-top:-10px" class="red-color">{{ errors.first("type") }}</div>
                        </div>
                        <div class="col-md-6"   v-if="editData.customer_type =='CORPORATE'">
                            <v-text-field   v-model="editData.company"   class="mt-2" v-validate="'required'" name="company" data-vv-as="company" >
                           <template  v-slot:label>
                            <span class="add-text">Company</span>
                          </template>
                        </v-text-field>
                        <div style="margin-top:-10px" class="red-color">{{ errors.first("company") }}</div>

                        </div>
                        <div class="col-md-6"   v-if="editData.customer_type =='CORPORATE'">
                            <v-text-field v-model="editData.job_title"  v-validate="'required'" name="title" data-vv-as="title"    class="mt-2" >
                           <template  v-slot:label>
                            <span class="add-text">Job Title</span>
                          </template>
                        </v-text-field>
                        <div style="margin-top:-10px" class="red-color">{{ errors.first("title") }}</div>
                        </div>
                       
                        <div class="col-md-6">
                            <v-select
                            
                            class="mt-2"
                            v-model="editData.nationality"
                            v-validate="'required'" name="nationality" data-vv-as="nationality"
                                item-text="name"
                                item-value="code"
                                :items="countries"
                                append-icon="mdi-chevron-down"
                            >
                                <template v-slot:label>
                                <span class="add-text">Nationality</span>
                                </template>
                         </v-select>
                         <div style="margin-top:-10px" class="red-color">{{ errors.first("nationality") }}</div>
                        </div>
                        <div class="col-md-6">
                            <v-select
                            class="mt-2"
                            v-validate="'required'" name="gender" data-vv-as="gender"
                            v-model="editData.gender" 
                            :items='["MALE", "FEMALE", "OTHER"]'
                                append-icon="mdi-chevron-down"
                            >
                                <template v-slot:label>
                                <span class="add-text">Gender</span>
                                </template>
                         </v-select>
                         <div style="margin-top:-10px" class="red-color">{{ errors.first("gender") }}</div>
                        </div>
                        <div class="col-md-6">
                            <v-select
                            class="mt-2"
                            v-validate="'required'" name="lang" data-vv-as="Communication Language"
                            v-model="editData.sms_preference" 
                            :items="['ENGLISH','ARABIC']"
                                append-icon="mdi-chevron-down"
                            >
                                <template v-slot:label>
                                <span class="add-text">Communication Language</span>
                                </template>
                         </v-select>
                         <div style="margin-top:-10px" class="red-color">{{ errors.first("lang") }}</div>
                        </div>
                        <div class="col-md-6">
                            <div class="mt-n2">
                                <span class="add-text">Communication Platform</span>
                            </div>
                         <v-row class="mt-n6">
                                <v-col
                                    cols="12"
                                    sm="2"
                                    md="4"
                                >
                                    <v-checkbox
                                    v-model="editData.is_sms" 
                                    color="primary"
                                    >
                                    <template v-slot:label>
                                <span class="add-text">SMS</span>
                                </template>
                                </v-checkbox>
                                
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="4"
                                    md="4"
                                >
                                    <v-checkbox
                                    v-model="editData.is_email" 
                                    color="primary"
                                    >
                                    <template v-slot:label>
                                <span class="add-text">EMAIL</span>
                                </template>
                                </v-checkbox>
                                
                                </v-col>
                         </v-row>
                         <div v-if="plat" style="margin-top:-15px" class="red-color">You need to select one method</div>
                        </div>
                    </div>
                    </div>
                    <div class="row" >
                 <div class="col-md-3 offset-md-6">
                   <button
                  @click="dialog = false"
                    type="button"
                    class="align-self-end bl-cancel-btn w-100"
                    style="margin-top: 20px"
                  >
                    Cancel
                  </button>
                </div>
                <div class="col-md-3">
                   <button
                 @click="validatereq()"
                    type="button"
                    class="align-self-end bl-primary-btn w-100"
                    style="margin-top: 20px"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
        </div>
        </v-dialog>
    
  </div>
</template>

<script>
   // import moment from "moment";
    import service from '../../services/service';
    export default {
        data(){
            return{
                plat:false,
                date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                menu:false,
                countries:[],
                items: ["INDIVIDUAL", "RETAIL", "CORPORATE"],
                dialog:false,
                empty:false,
                orders:[],
                customer:null,
                editData:{
                    id:null,
                    name:'',
                    gender:null,
                    email:'',
                    nationality:null,
                    customer_type:'',
                    company:'',
                    job_title:'',
                    //mobile_number:null,
                    sms_preference:'',
                    // is_whatsapp:false, 
                     is_email:false,
                     is_sms:false,
                     date_day:'',
                     date_month:'',
                     date_year:''

                }
            }
        },
        mounted(){
            this.getCustomerData()
            this.getCountries()
       
        },
        methods:{
            getCountryname(code){
                for(var i =0; i < this.countries.length; i++){
                    if(this.countries[i].code == code){
                        return this.countries[i].name;
                    }
                }
            },
            validateState(ref) {
        if (
          this.veeFields[ref] &&
          (this.veeFields[ref].dirty || this.veeFields[ref].validated)
        ) {
          return !this.veeErrors.has(ref);
        }
        return null;
      },
            async getCountries(){
                this.countries = []
                let countries = await service._get('api/countries/')
                for(var i=0;i<countries.data.countries.length;i++){
                    var a = {
                        code:countries.data.countries[i][0],
                        name:countries.data.countries[i][1],
                    }
                    this.countries.push(a)
                }
            },
           editDataFill(){
            if(this.customer.date_year != null){
                this.date = new Date(this.customer.date_year+'-'+this.customer.date_month+'-'+this.customer.date_day).toISOString().substr(0, 10);
            }
            this.editData.id = this.customer.id
            this.editData.name = this.customer.name
            this.editData.gender = this.customer.gender
            this.editData.email = this.customer.email
            this.editData.nationality = this.customer.nationality
            this.editData.customer_type = this.customer.customer_type
            this.editData.company = this.customer.company
            this.editData.job_title = this.customer.job_title
            //this.editData.mobile_number = this.customer.mobile_number
            this.editData.sms_preference = this.customer.sms_preference
            this.editData.is_email = this.customer.is_email
            // this.editData.is_whatsapp = this.customer.is_whatsapp
             this.editData.is_sms = this.customer.is_sms
            this.dialog = true

           },
           validatereq(){
            this.plat = false

            this.$validator.validateAll().then((result) => {
          if (!result) {
            return;
          }
          
          if(!this.editData.is_sms && !this.editData.is_email){
            this.plat = true
          }else{
            this.saveEdit()
          }  
        });
           },
           async saveEdit(){
            var sp = this.date.split("-");

            this.editData.date_month = sp[1]
            this.editData.date_day = sp[2]
            this.editData.date_year = sp[0]
            console.log(this.editData);
                this.dialog = false


            console.log(this.editData.date_month);
            let result = await service._post('customer/edit-customer/',this.editData)
            this.editData = {
                    id:null,
                    name:'',
                    gender:null,
                    email:'',
                    nationality:null,
                    customer_type:'',
                    company:'',
                    job_title:'',
                    //mobile_number:null,
                    sms_preference:'',
                    // is_whatsapp:false, 
                     is_email:false,
                     is_sms:false,
                     date_day:'',
                     date_month:'',
                     date_year:''

                }
            if(result.status ==200){
                this.dialog = false
                window.location.reload();
               //this.getCustomerData();
            }else{
                this.dialog = false
                this.$vs.notification({
                    duration:'3000',
                    position:'top-right',
                    text: 'Try again',
                    border:"danger",
                    title: 'Profile update failed',
                })
            }
            console.log(result)
           },
            async getCustomerData(){
                let result = await service._get('api/customer-details/'+localStorage.getItem('bleach-tk')+'/');
          
                if(result.status ==200){
                    this.customer = result.data.customer_data
                    // let result2 = await service._get('customer/edit-customer/?customer_id='+this.customer.id);
                    // console.log(result2);

                }
            },

        }
    }
    </script>
  

<style lang="scss" scoped>
@media screen and (max-width: 500px) {
  .min-wid{
    min-width: auto !important;
    }
}
.pro-name{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 34px;
color: #707070;

}
.min-wid{
    min-width: 250px;
}
.v-date-picker-title__year{
color:#fff !important;
}
.v-date-picker-title__date > div{
color:#fff !important;

}
</style>