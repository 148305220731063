<template>
            <div>
                <div v-if="selectlan=='EN'" class="show-callus">
              <div class="about-cleaning">
                 Services Under Detailed Cleaning
               </div>
               <div class=" py-2" style="border-bottom:1px solid #C4C4C4;">
              <div class="d-flex pointer " @click="dc = !dc">
                 <div class="mobile-sub-link mobile-sub-header h-100 ma">
                 {{name}}
               </div>
               <div>
                 <img class="mr-1" :class="{ 'fa-arrow-down-open': dc }" src="../../assets/ar.png" alt="">
               </div>
              </div>
            </div>
            <div class="drop-service">
               <transition-expand>
              <div v-if="dc" >
                <ul class="drop-list">
                    <li v-if="'General Cleaning' != name" @click="$router.push('generalcleaning')">
                                  <a>
                                    General Cleaning
                                  </a>
                    </li>
                    <li v-if="'Deep Cleaning' != name" @click="$router.push('deepcleaning')">
                        <a>
                                    Deep Cleaning
                        </a>
                    </li>
                    <li v-if="'Storage Area Cleaning' != name" @click="$router.push('storagearea')">
                        <a>
                          Storage Area
                          </a>
                    </li>
                    <li v-if="'Window Cleaning' != name" @click="$router.push('windowcleaning')">
                      <a>
                        Window Cleaning</a>
                    </li>
                    <li v-if="'Outdoor Cleaning' != name" @click="$router.push('outdoorcleaning')">
                      <a>
                                    Outdoor Cleaning
                                  </a>
                                </li>
                                <li v-if="'Car Parking Shades Maintenance' != name" @click="$router.push('carparking')">
                                  <a>
                                    Car Parking Umbrella
                                  </a>
                                </li>
                                <li v-if="'Facade Cleaning' != name" @click="$router.push('facadecleaning')">
                                  <a >
                                    Facade Cleaning
                                  </a>
                                </li>
                              </ul>
              </div> 
            </transition-expand>
            </div>
            </div>

    <div v-if="selectlan=='ARABIC'" class="show-callus">
              <div class="about-cleaning">
                 Services Under Detailed Cleaning
               </div>
               <div class=" py-2" style="border-bottom:1px solid #C4C4C4;">
              <div class="d-flex pointer " @click="dc = !dc">
                 <div class="mobile-sub-link mobile-sub-header h-100 ma">
                 {{name}}
               </div>
               <div>
                 <img class="mr-1" :class="{ 'fa-arrow-down-open': dc }" src="../../assets/ar.png" alt="">
               </div>
              </div>
            </div>
            <div class="drop-service">
               <transition-expand>
              <div v-if="dc" >
                <ul class="drop-list">
                    <li v-if="'التنظيف العام ' != name" @click="$router.push('generalcleaning')">
                                  <a>
                                    التنظيف العام 
                                  </a>
                    </li>
                    <li v-if="'التنظيف العميق' != name" @click="$router.push('deepcleaning')">
                        <a>
                                    التنظيف العميق
                        </a>
                    </li>
                    <li v-if="'تنظيف منطقة التخزين' != name" @click="$router.push('storagearea')">
                        <a>
                          تنظيف منطقة التخزين
                          </a>
                    </li>
                    <li v-if="'تنظيف النوافذ' != name" @click="$router.push('windowcleaning')">
                      <a>
                        تنظيف النوافذ</a>
                    </li>
                    <li v-if="'تنظيف المساحة الخارجية' != name" @click="$router.push('outdoorcleaning')">
                      <a>
                                    تنظيف المساحة الخارجية
                                  </a>
                                </li>
                                <li v-if="'صيانة مظالت مواقف السيارات ' != name" @click="$router.push('carparking')">
                                  <a>
                                    صيانة مظالت مواقف السيارات 
                                  </a>
                                </li>
                                <li v-if="'تنظيف الواجهات' != name" @click="$router.push('facadecleaning')">
                                  <a >
                                    تنظيف الواجهات
                                  </a>
                                </li>
                              </ul>
              </div> 
            </transition-expand>
            </div>
            </div>

            </div>
</template>

<script>
export default {
    props:['name'],
    data(){
        return{
            dc:false,
              selectlan:localStorage.getItem('bleach-lang'),
        };
    }
}
</script>

<style>

</style>