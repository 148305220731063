<template>
  <div>
    <div>
      <section class="">
        <div class="bg-header-img-terms bg-header"></div>
      </section>
    </div>
    <div v-if="selectlan == 'EN'">
      <div class="bcontainer">
        <div class="py-15">
          <div class="page-head">
            TERMS<span class="w400"> & CONDITIONS</span>
          </div>
          <div class="service-content mt-5">
            These terms and conditions apply when you use the website which is
            owned and operated by Bleach International Company for Contracting
            and Cleaning of Buildings and Cities LLC (hereinafter referred to as
            “Bleach - Company - Us”), a leading company in the business of
            cleaning buildings and facilities, operating in the State of Kuwait
            and registered under the Commercial Register No. 362934 with a
            capital of 1,000 Kuwaiti Dinars (one thousand Kuwaiti dinars).
            <br /><br />
            Dear customer, your request for the Company's services requires you
            to read and agree to the general terms and conditions of use for you
            to become aware of the obligations that you must follow when using
            the site and/or requesting the services displayed on the home page
            of the website, therefore you must read it carefully and cautiously.
            <br /><br />
            The Company has the right to change the terms and conditions at any
            time at its discretion, thus we advise you to read them regularly
            from time to time to see any amendments that may occur in this
            regard. You are implied by the new terms and conditions, and if you
            do not agree to the terms and conditions, you must not use the site.
          </div>
          <div class="mt-10">
            <Collapsible class="example-collapsible" :isOpen="false">
              <div slot="trigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content"
                    style="
                      font-size: 16px;
                      color: #2b9fad !important ;
                      flex-grow: 1;
                    "
                  >
                    LEGAL CAPACITY TO USE
                  </div>
                  <div>
                    <img
                      class="mr-1 fa-arrow-down-open"
                      src="../../assets/ar.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div slot="closedTrigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content"
                    style="font-size: 16px; flex-grow: 1"
                  >
                    LEGAL CAPACITY TO USE
                  </div>
                  <div>
                    <img class="mr-1" src="../../assets/ar.png" alt="" />
                  </div>
                </div>
              </div>

              <div id="example2" class="pt-5">
                <p class="service-content">
                  1. Legal capacity of a natural person.
                  <br /><br />

                  Your registration through the site is an acknowledgment from
                  you that you have reached the legal age according to the laws
                  anي regulations of the State of Kuwait and that you have the
                  necessary legal capacity to accept the privacy policy, the
                  terms and conditions and use the site in accordance with them.
                  <br /><br />
                  2. Legal capacity of the legal person.
                  <br /><br />
                  Your registration through the site is an acknowledgment from
                  you that you are licensed and qualified by all the official
                  authorities in the State of Kuwait to practice your commercial
                  activities and that you are committed to respecting and
                  applying all of the laws and regulations issued by the
                  official authorities.
                </p>
              </div>
            </Collapsible>
            <Collapsible class="example-collapsible" :isOpen="false">
              <div slot="trigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content"
                    style="
                      font-size: 16px;
                      color: #2b9fad !important ;
                      flex-grow: 1;
                    "
                  >
                    RESPONSIBILITY FOR PROTECTING YOUR ACCOUNT
                  </div>
                  <div>
                    <img
                      class="mr-1 fa-arrow-down-open"
                      src="../../assets/ar.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div slot="closedTrigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content"
                    style="font-size: 16px; flex-grow: 1"
                  >
                    RESPONSIBILITY FOR PROTECTING YOUR ACCOUNT
                  </div>
                  <div>
                    <img class="mr-1" src="../../assets/ar.png" alt="" />
                  </div>
                </div>
              </div>

              <div id="example2" class="pt-5">
                <p class="service-content">
                  When you create an account to use the Website, the Website
                  will ask you to put your password, and then you are solely
                  responsible for maintaining the confidentiality of the
                  password and not allowing others to use it. In addition, you
                  are required to indemnify and discharge the company from any
                  loss it may incur or any third party which would result
                  someone else in using your account due to your failure of
                  exercising due diligence to protect your account.
                </p>
              </div>
            </Collapsible>
                        <Collapsible class="example-collapsible" :isOpen="false">
              <div slot="trigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content text-uppercase"
                    style="
                      font-size: 16px;
                      color: #2b9fad !important ;
                      flex-grow: 1;
                    "
                  >
                    Service Do’s & Don’t 
                  </div>
                  <div>
                    <img
                      class="mr-1 fa-arrow-down-open"
                      src="../../assets/ar.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div slot="closedTrigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content text-uppercase"
                    style="font-size: 16px; flex-grow: 1"
                  >
                    Service Do’s & Don’t 
                  </div>
                  <div>
                    <img class="mr-1" src="../../assets/ar.png" alt="" />
                  </div>
                </div>
              </div>

              <div id="example2" class="pt-5">
                <p class="service-content">
                  Exclusions from the General Cleaning: The following activities will not be included in the General Cleaning services.
                  <br /><br />
                  1. Removing paint residue
                  <br /><br />
                  2. Removing oil residue
                  <br /><br />
                  3. Cleaning stains and spots 
                  <br>
                  <br>
                </p>
                <p class="service-content">
                 Exclusions from the Deep Cleaning: The following activities will not be included in the Deep Cleaning services. 
                  <br /><br />
                  1. Cleaning of kitchen surfaces, floors, ceilings, walls and cabinets 
                  <br /><br />
                  2. Kitchen appliances cleaning 
                </p>
              </div>
            </Collapsible>
            <Collapsible class="example-collapsible" :isOpen="false">
              <div slot="trigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content"
                    style="
                      font-size: 16px;
                      color: #2b9fad !important ;
                      flex-grow: 1;
                    "
                  >
                    ACTIVITIES PROHIBITED ON THE SITE
                  </div>
                  <div>
                    <img
                      class="mr-1 fa-arrow-down-open"
                      src="../../assets/ar.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div slot="closedTrigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content"
                    style="font-size: 16px; flex-grow: 1"
                  >
                    ACTIVITIES PROHIBITED ON THE SITE
                  </div>
                  <div>
                    <img class="mr-1" src="../../assets/ar.png" alt="" />
                  </div>
                </div>
              </div>

              <div id="example2" class="pt-5">
                <p class="service-content">
                  Below is a list that includes the types of illegal and
                  prohibited conduct on the site, and the company reserves the
                  right to investigate or take any appropriate legal action
                  against any person or user that engages in any prohibited
                  activities recognized by the company’s discretion, including
                  those prohibited activities, but not limited are the
                  following: -
                  <br /><br />
                  1. Disseminate viruses or harmful computer code to the site or
                  to harm its users.
                  <br /><br />
                  2. Hacking the site or any similar act that leads to the
                  disruption of the site and/or the inability of the company
                  and/or users to access it and/or use it in the way it was
                  intended.
                  <br /><br />
                  3. Any act that the company deems, at its sole discretion,
                  exposes it or any of its users to any liability or harm of any
                  kind.

                  <br /><br />
                  4. The company has the right to terminate any user's access to
                  the site upon the occurrence of any violation of these terms
                  and conditions without objection from it
                </p>
              </div>
            </Collapsible>
            <Collapsible class="example-collapsible" :isOpen="false">
              <div slot="trigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content"
                    style="
                      font-size: 16px;
                      color: #2b9fad !important ;
                      flex-grow: 1;
                    "
                  >
                    WEBSITE’S RIGHTS AND INTELLECTUAL PROPERTY PROTECTION AND
                    PUBLISHING
                  </div>
                  <div>
                    <img
                      class="mr-1 fa-arrow-down-open"
                      src="../../assets/ar.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div slot="closedTrigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content"
                    style="font-size: 16px; flex-grow: 1"
                  >
                    WEBSITE’S RIGHTS AND INTELLECTUAL PROPERTY PROTECTION AND
                    PUBLISHING
                  </div>
                  <div>
                    <img class="mr-1" src="../../assets/ar.png" alt="" />
                  </div>
                </div>
              </div>

              <div id="example2" class="pt-5">
                <div class="service-content">
                  1. The Website contents of the concepts, design, and software
                  are all protected by intellectual property rights and
                  copyrights which are as per the international and domestic
                  laws, therefore you may not download parts of the materials,
                  content, and different parts of the Website or reuse them by
                  yourself, whether for personal or commercial purposes.
                  Moreover, any re-sending or publication of any copyrighted
                  materials regarding the company without obtaining the express
                  written consent thereof exposes you to legal liability.
                  <br /><br />
                  2. When you use the Website, you acknowledge and agree that
                  all copyrights, trademarks, and other intellectual property
                  rights are owned by the Company.
                  <br /><br />
                  3. In case you find any materials published or displayed on
                  the site infringe your property and copyright rights, you can
                  request the deletion of such materials by contacting customer
                  service and provided that you provide the following
                  information:
                  <br /><br />
                  <div class="pl-15">
                    a) Identification and an indication of the content that
                    enjoys the right of protection from publication and that has
                    been infringed by the site, please describe the work or the
                    place of that work and add a copy of it (example: an
                    electronic link).
                    <br /><br />
                    b) Providing us with your personal data (name, address,
                    phone number, and e-mail address).
                    <br /><br />
                    c) All data and documents supporting the unauthorized
                    complaint by the copyright owner, his agent, or the law
                    evidencing such infringement.
                  </div>
                  <br />
                  Therefore, please send all this information by e-mail to:
                  customercare@bleach-kw.com
                </div>
              </div>
            </Collapsible>
            <Collapsible class="example-collapsible" :isOpen="false">
              <div slot="trigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content"
                    style="
                      font-size: 16px;
                      color: #2b9fad !important ;
                      flex-grow: 1;
                    "
                  >
                    WARRANTIES AND DISCLAIMER
                  </div>
                  <div>
                    <img
                      class="mr-1 fa-arrow-down-open"
                      src="../../assets/ar.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div slot="closedTrigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content"
                    style="font-size: 16px; flex-grow: 1"
                  >
                    WARRANTIES AND DISCLAIMER
                  </div>
                  <div>
                    <img class="mr-1" src="../../assets/ar.png" alt="" />
                  </div>
                </div>
              </div>

              <div id="example2" class="pt-5">
                <p class="service-content">
                  Although we will take the necessary care to ensure the
                  correctness of the information and contents posted on the
                  Website, the Company does not assume any obligations or
                  warranties expressed, implied, statutory, or otherwise, for
                  the correctness or accuracy of such information, including but
                  not limited to the terms and conditions for the provision of
                  the offered services. Moreover, the company does not provide
                  any guarantees regarding the accuracy, continuity, usefulness,
                  efficiency, completeness, or validity of the information, in
                  addition; the company does not and cannot provide guarantees
                  against human or mechanical errors, omissions, delays,
                  interruptions or losses, this includes loss of data. The
                  Company does not and cannot provide guarantees that any of the
                  files available for downloading from the Site are not infected
                  with viruses of various types, or other codes that have
                  contaminating or destructive properties, and the Company does
                  not and cannot guarantee the continuity of any content posted
                  on the Website. However, the Company does not guarantee that
                  the functions or services provided on the Website will be
                  uninterrupted or error-free, or that defects on the Website
                  will be corrected. Errors may appear during the service
                  request process from time to time, they may occur in the
                  transmission and may lead to an interruption of the process of
                  terminating the service request or payment, etc. The Company
                  will do its best to try to solve any problems that may arise
                  as a result of these errors, and the user agrees that it’s the
                  Company’s absolute right to act and take the appropriate
                  decision in such cases
                </p>
              </div>
            </Collapsible>
            <Collapsible class="example-collapsible" :isOpen="false">
              <div slot="trigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content"
                    style="
                      font-size: 16px;
                      color: #2b9fad !important ;
                      flex-grow: 1;
                    "
                  >
                    WARRANTIES AND DISCLAIMER
                  </div>
                  <div>
                    <img
                      class="mr-1 fa-arrow-down-open"
                      src="../../assets/ar.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div slot="closedTrigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content"
                    style="font-size: 16px; flex-grow: 1"
                  >
                    JOINT LIABILITY LIMITS
                  </div>
                  <div>
                    <img class="mr-1" src="../../assets/ar.png" alt="" />
                  </div>
                </div>
              </div>

              <div id="example2" class="pt-5">
                <p class="service-content">
                  1. The Company or its users shall not bear any direct,
                  indirect, special, incidental, or consequential liability
                  arising from your use of the Website, or any other claim
                  related to your use of the Website.
                  <br /><br />
                  2. The Company shall not be liable for the delay or failure to
                  provide the service or otherwise to perform any obligation as
                  specified in these terms if the cause is wholly or partly
                  caused by events of force majeure, whether directly or
                  indirectly, or due to circumstances beyond its reasonable
                  control.
                  <br /><br />
                  3. If any provision of these terms is deemed by any competent
                  authority to be invalid or unenforceable in whole or in part,
                  this shall not affect the validity or enforceability of the
                  other terms.
                  <br /><br />
                  4. In no way can the Company's delay in exercising any powers
                  or powers granted to it under these conditions or a request
                  for compensation be a waiver by the company of any of the
                  foregoing.
                </p>
              </div>
            </Collapsible>
            <Collapsible class="example-collapsible" :isOpen="false">
              <div slot="trigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content"
                    style="
                      font-size: 16px;
                      color: #2b9fad !important ;
                      flex-grow: 1;
                    "
                  >
                    SERVICES AND PRICES
                  </div>
                  <div>
                    <img
                      class="mr-1 fa-arrow-down-open"
                      src="../../assets/ar.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div slot="closedTrigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content"
                    style="font-size: 16px; flex-grow: 1"
                  >
                    SERVICES AND PRICES
                  </div>
                  <div>
                    <img class="mr-1" src="../../assets/ar.png" alt="" />
                  </div>
                </div>
              </div>

              <div id="example2" class="pt-5">
                <p class="service-content">
                  Determining the service price requires you, our dear customer,
                  to provide us with accurate information about the type of
                  service required. You can use the packages available on the
                  website if they suit your demands. From the date of its
                  issuance, and when you agree to it, the price offer will be an
                  integral part of these terms and conditions, and in all cases,
                  you must pay the value of the business as soon as you agree to
                  the price offered or choose the package. of any of the
                  foregoing.
                </p>
              </div>
            </Collapsible>
            <Collapsible class="example-collapsible" :isOpen="false">
              <div slot="trigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content"
                    style="
                      font-size: 16px;
                      color: #2b9fad !important ;
                      flex-grow: 1;
                    "
                  >
                    AMENDMENTS REGARDING THE PRICE OF SERVICES AND PRODUCTS
                  </div>
                  <div>
                    <img
                      class="mr-1 fa-arrow-down-open"
                      src="../../assets/ar.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div slot="closedTrigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content"
                    style="font-size: 16px; flex-grow: 1"
                  >
                    AMENDMENTS REGARDING THE PRICE OF SERVICES AND PRODUCTS
                  </div>
                  <div>
                    <img class="mr-1" src="../../assets/ar.png" alt="" />
                  </div>
                </div>
              </div>

              <div id="example2" class="pt-5">
                <div class="service-content">
                  The Company has the right to amend the price of the services
                  provided on the Website at any time without needing to notify
                  you, and we are not responsible to you, our dear customer, for
                  changing or modifying the price of the service, except for the
                  price that was agreed upon in the price offer as long as it is
                  valid as we will explain later these terms and conditions.
                  <br /><br />
                  <b>Payment</b>
                  <div class="pl-15">
                    1. When paying through the site, the value of the required
                    services is paid using bank cards only, and the process of
                    debiting the bank card takes place at the time the service
                    request is received and after investigating the card
                    details.
                    <br /><br />
                    2. The customer knows that the payment through the site will
                    be through a third party, and the payment of the value of
                    the service is only considered by entering the Company’s
                    bank account for it
                  </div>
                </div>
              </div>
            </Collapsible>
            <Collapsible class="example-collapsible" :isOpen="false">
              <div slot="trigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content"
                    style="
                      font-size: 16px;
                      color: #2b9fad !important ;
                      flex-grow: 1;
                    "
                  >
                    SERVICE DELIVERY TIME
                  </div>
                  <div>
                    <img
                      class="mr-1 fa-arrow-down-open"
                      src="../../assets/ar.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div slot="closedTrigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content"
                    style="font-size: 16px; flex-grow: 1"
                  >
                    SERVICE DELIVERY TIME
                  </div>
                  <div>
                    <img class="mr-1" src="../../assets/ar.png" alt="" />
                  </div>
                </div>
              </div>

              <div id="example2" class="pt-5">
                <div class="service-content">
                  1. When the payment is deposited for the services specified in
                  the price offer or for one of the packages displayed on the
                  Website, the Company will offer several dates to provide the
                  services, whereby you can choose the most appropriate date and
                  time for your services. Moreover, the specified date will be
                  binding on you as it is binding on the Company and without
                  prejudice to the limits of joint liability. The Company is
                  committed to government decisions related to working hours in
                  open places during the summer to protect its labor team.
                  <br /><br />
                  2. On the specified time and date, the Company’s labor team
                  must meet up with the authorized person who has the authority
                  to give access to our labor team to the work site, and this
                  person should be the one who will be contacted by phone on the
                  mobile phone number registered with us when requesting the
                  service unless the customer gives the name and mobile number
                  of another person.
                </div>
              </div>
            </Collapsible>
            <Collapsible class="example-collapsible" :isOpen="false">
              <div slot="trigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content"
                    style="
                      font-size: 16px;
                      color: #2b9fad !important ;
                      flex-grow: 1;
                    "
                  >
                    WRONG ADDRESS
                  </div>
                  <div>
                    <img
                      class="mr-1 fa-arrow-down-open"
                      src="../../assets/ar.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div slot="closedTrigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content"
                    style="font-size: 16px; flex-grow: 1"
                  >
                    WRONG ADDRESS
                  </div>
                  <div>
                    <img class="mr-1" src="../../assets/ar.png" alt="" />
                  </div>
                </div>
              </div>

              <div id="example2" class="pt-5">
                <div class="service-content">
                  If you booked a visit at the wrong address (created the wrong
                  address or booked at the wrong place) and went to that
                  address, the company has the right to consider the visit
                  complete and the customer bears the full amount of the visit.
                </div>
              </div>
            </Collapsible>
            <Collapsible class="example-collapsible" :isOpen="false">
              <div slot="trigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content"
                    style="
                      font-size: 16px;
                      color: #2b9fad !important ;
                      flex-grow: 1;
                    "
                  >
                    AMENDMENT AND CANCELLATION POLICY
                  </div>
                  <div>
                    <img
                      class="mr-1 fa-arrow-down-open"
                      src="../../assets/ar.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div slot="closedTrigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content"
                    style="font-size: 16px; flex-grow: 1"
                  >
                    AMENDMENT AND CANCELLATION POLICY
                  </div>
                  <div>
                    <img class="mr-1" src="../../assets/ar.png" alt="" />
                  </div>
                </div>
              </div>

              <div id="example2" class="pt-5">
                <div class="service-content">
                  <b>1. AMENDMENT AND POSTPONEMENT</b>
                  Our dear customer, you can modify the date of providing the
                  service once for free, provided that the amendment is by
                  postponing the date of providing the service and not by having
                  the time or date earlier, the amendment must be requested at
                  least 48 hours before the specified date for providing the
                  service, and in case you wish to amend the date before a
                  period of fewer than 48 hours, the company has the right to
                  increase a percentage of 10% on the value of the requested
                  service without your objection, and you will have to pay for
                  this percentage before setting the new date for providing the
                  service.
                  <br /><br />
                  <b>2. CANCELLATION</b>
                  You have the right to cancel the service requested at least 48
                  hours before the date of its submission. However, if the
                  service requested was canceled for a period less the 48 hours,
                  the Company has the right to deduct a percentage of 20% from
                  the amount paid by yourself, and the rest of the amount will
                  be refunded in the same manner in which the payment was made.
                  <br /><br />
                  <div class="pl-15">
                    a) AMENDMENT OR CANCELLATION ON THE DAY SPECIFIED FOR
                    PROVIDING THE SERVICE
                  </div>
                  <br />
                  If the cancellation or amendment is requested on the day
                  specified for providing the service, this means that Company
                  will miss the opportunity to exploit the appointment for the
                  benefit of other customers, and the amounts paid by the
                  customer are considered the absolute right of the Company’s
                  consensual compensation without any obligation on the Company
                  toward proving that there are damages which incurred as a
                  result of the cancellation or amendment.
                </div>
              </div>
            </Collapsible>
            <Collapsible class="example-collapsible" :isOpen="false">
              <div slot="trigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content"
                    style="
                      font-size: 16px;
                      color: #2b9fad !important ;
                      flex-grow: 1;
                    "
                  >
                    WARRANTY AND GUARANTEE
                  </div>
                  <div>
                    <img
                      class="mr-1 fa-arrow-down-open"
                      src="../../assets/ar.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div slot="closedTrigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content"
                    style="font-size: 16px; flex-grow: 1"
                  >
                    WARRANTY AND GUARANTEE
                  </div>
                  <div>
                    <img class="mr-1" src="../../assets/ar.png" alt="" />
                  </div>
                </div>
              </div>

              <div id="example2" class="pt-5">
                <div class="service-content">
                  1. The Materials Used BLEACH® guarantees the quality of the
                  materials used in the cleaning work, by not defecting or
                  destroying them for the cleaning work. In all cases, our dear
                  customer, you must disclose any essential notes related to the
                  purposes of the cleaning work, if the manual for their use -
                  the catalog - states that certain materials should not be used
                  in the cleaning work, and the Company disclaims its
                  responsibility if the customer does not disclose that
                  essential information at the time of the request, or the time
                  it was provided.
                  <br /><br />
                  2. Cleaning Work BLEACH® always seeks to obtain the
                  satisfaction of its customers, and in this way, it provides a
                  professional cleaning service through a highly qualified team,
                  and due to the nature of the service provided by the cleaning
                  work, which cannot be guaranteed, the fact that Bleach has
                  completed the provision of the service is a presumption that
                  the work has been completed. It is the responsibility of the
                  customer to receive the work site and sign the site receipt
                  acknowledgment unless substantial comments are made from you
                  on the works, which are subject to Bleach’s assessment and
                  evaluation. Your signature on the receipt of the works is an
                  acknowledgment of the completion and implementation of the
                  work and the absence of notes on the executed works, without
                  prejudice to what will be presented later regarding the work
                  completion report.
                </div>
              </div>
            </Collapsible>
            <Collapsible class="example-collapsible" :isOpen="false">
              <div slot="trigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content"
                    style="
                      font-size: 16px;
                      color: #2b9fad !important ;
                      flex-grow: 1;
                    "
                  >
                    WORK SITE AND PROPERTY PROTECTION
                  </div>
                  <div>
                    <img
                      class="mr-1 fa-arrow-down-open"
                      src="../../assets/ar.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div slot="closedTrigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content"
                    style="font-size: 16px; flex-grow: 1"
                  >
                    WORK SITE AND PROPERTY PROTECTION
                  </div>
                  <div>
                    <img class="mr-1" src="../../assets/ar.png" alt="" />
                  </div>
                </div>
              </div>

              <div id="example2" class="pt-5">
                <div class="service-content">
                  1. On the date of providing the service, our dear customer is
                  obligated to remove and lift any expensive or breakable
                  items/devices or both from the work site that will not be
                  subject to cleaning work if they are removable and lifted, to
                  preserve them and not being exposed to accidents of breakage,
                  and our team has the right to request that before starting the
                  work.
                  <br /><br />
                  2. Our labor team has the full right to prepare a report upon
                  entering the work site to prove the presence of defective or
                  broken devices or objects, and the customer is obligated to
                  sign it before starting work, for his failure to cooperate in
                  removing it from the work site, as well as not being
                  responsible for any damage to property after the work team
                  leaves the site.
                  <br /><br />
                  3. Starting work is on the day specified for providing the
                  service. The Company’s labor team can contact you via messages
                  or phone calls to confirm the work location and arrival time.
                  <br /><br />
                  4. When our team begins cleaning work on the site, our dear
                  customer must work to facilitate the implementation of the
                  work through the following:
                  <div class="pl-15">
                    a) Not to include contractors or other workers to carry out
                    any other work inside the site at the time of carrying out
                    the cleaning work.
                    <br /><br />
                    b) Commitment to the areas and works specified in the price
                    offer or the package. It is not permissible to ask the
                    company’s affiliates to carry out any other works that have
                    not been agreed upon, unless the customer pays for them
                    before executing them, provided that the added works do not
                    conflict with the dates specified by Bleach with other
                    customers on the same day the work is executed.
                    <br /><br />
                    c) The customer may not request to stop the cleaning work
                    mentioned in the work plan after its start, and if the work
                    stops, the company will not be responsible for completing or
                    repeating the cleaning.
                    <br /><br />
                    d) Not to interfere during the cleaning work by the work
                    team, or to assign the Company’s work team any work outside
                    the scope of the required cleaning work, even if for a fee
                    outside the scope of its agreement with the Company, or to
                    agree with the Company’s work team on any external work for
                    the personal interest of the customer or any third party
                    outside working hours.
                  </div>
                  <br /><br />
                  In all cases, BLEACH® has the right to consider that the work
                  has been completed if the customer does not cooperate with our
                  team or violates the previous instructions.
                </div>
              </div>
            </Collapsible>
            <Collapsible class="example-collapsible" :isOpen="false">
              <div slot="trigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content"
                    style="
                      font-size: 16px;
                      color: #2b9fad !important ;
                      flex-grow: 1;
                    "
                  >
                    WORK COMPLETION REPORT
                  </div>
                  <div>
                    <img
                      class="mr-1 fa-arrow-down-open"
                      src="../../assets/ar.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div slot="closedTrigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content"
                    style="font-size: 16px; flex-grow: 1"
                  >
                    WORK COMPLETION REPORT
                  </div>
                  <div>
                    <img class="mr-1" src="../../assets/ar.png" alt="" />
                  </div>
                </div>
              </div>

              <div id="example2" class="pt-5">
                <div class="service-content">
                  After the Company’s team has finished providing the service at
                  the work site, a report will be prepared on the work executed.
                  Our dear customer we will ask you to sign a document
                  indicating that the work has been completed and received from
                  you. Your consent benefits business and property safety, and
                  in all cases, you agree that our team has the right to
                  photograph the work site for documentation and advertising
                  purposes.
                </div>
              </div>
            </Collapsible>
            <Collapsible class="example-collapsible" :isOpen="false">
              <div slot="trigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content"
                    style="
                      font-size: 16px;
                      color: #2b9fad !important ;
                      flex-grow: 1;
                    "
                  >
                    RIGHT TO CANCEL THE ACCOUNT
                  </div>
                  <div>
                    <img
                      class="mr-1 fa-arrow-down-open"
                      src="../../assets/ar.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div slot="closedTrigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content"
                    style="font-size: 16px; flex-grow: 1"
                  >
                    RIGHT TO CANCEL THE ACCOUNT
                  </div>
                  <div>
                    <img class="mr-1" src="../../assets/ar.png" alt="" />
                  </div>
                </div>
              </div>

              <div id="example2" class="pt-5">
                <div class="service-content">
                  BLEACH® has the absolute right to cancel your account and
                  prevent your use of its website without the need to give
                  reasons, and this will be necessary if the information and
                  data under which the registration was made are found to be
                  incorrect or when you use the website, or you violate these
                  terms and conditions.
                </div>
              </div>
            </Collapsible>
            <Collapsible class="example-collapsible" :isOpen="false">
              <div slot="trigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content"
                    style="
                      font-size: 16px;
                      color: #2b9fad !important ;
                      flex-grow: 1;
                    "
                  >
                    NOTICES AND COMMUNICATIONS
                  </div>
                  <div>
                    <img
                      class="mr-1 fa-arrow-down-open"
                      src="../../assets/ar.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div slot="closedTrigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content"
                    style="font-size: 16px; flex-grow: 1"
                  >
                    NOTICES AND COMMUNICATIONS
                  </div>
                  <div>
                    <img class="mr-1" src="../../assets/ar.png" alt="" />
                  </div>
                </div>
              </div>

              <div id="example2" class="pt-5">
                <div class="service-content">
                  The Company has the right to send notices under these Terms
                  and Conditions using e-mail, a general notice on the site, or
                  advertising or promotional messages to your e-mail.
                </div>
              </div>
            </Collapsible>
            <Collapsible class="example-collapsible" :isOpen="false">
              <div slot="trigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content"
                    style="
                      font-size: 16px;
                      color: #2b9fad !important ;
                      flex-grow: 1;
                    "
                  >
                    LAW REGULATING USE AND APPROVED LANGUAGE
                  </div>
                  <div>
                    <img
                      class="mr-1 fa-arrow-down-open"
                      src="../../assets/ar.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div slot="closedTrigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content"
                    style="font-size: 16px; flex-grow: 1"
                  >
                    LAW REGULATING USE AND APPROVED LANGUAGE
                  </div>
                  <div>
                    <img class="mr-1" src="../../assets/ar.png" alt="" />
                  </div>
                </div>
              </div>

              <div id="example2" class="pt-5">
                <div class="service-content">
                  1. These terms and conditions shall be governed and
                  interpreted as per the laws in force in the State of Kuwait
                  related to the present agreement, in particular Law No. 20 of
                  2014 regarding electronic transactions, and that any dispute
                  that may arise from it or the services provided by us must be
                  considered before the competent courts in the State of Kuwait.
                  If any provision of these Terms and Conditions is
                  unenforceable or legally binding, you will remain bound by all
                  other provisions and provisions.
                  <br /><br />
                  2. These terms and conditions have been written in both Arabic
                  and English, and in the event of some difference in meanings,
                  the text written in Arabic shall apply.
                </div>
              </div>
            </Collapsible>
          </div>
        </div>
      </div>
    </div>
    <div v-if="selectlan == 'ARABIC'" dir="rtl">
      <div class="bcontainer">
        <div class="py-15">
          <div class="page-head arabic-font">الشروط والأحكام</div>
          <div class="service-content mt-5 arabic-font">
            تطبق هذه الشروط والاحكام عند استخدامك للموقع الالكتروني
            www.bleach-kw.comوالذي تملكه وتديره شركة بليتش العالمية للمقاولات
            وتنظيف المباني والمدن ذ.م.م (و يشار اليها فيما بعد بـ " بليتش -
            الشركة – نحن") وهي شركة رائدة في أعمال تنظيف المباني والمنشآت، وتعمل
            في نطاق دولة الكويت ومقيدة بالسجل التجاري برقم 362934 برأس مال وقدره
            1000 د.ك (ألف دينار كويتي) .
            <br /><br />
            عملينا العزيز، طلبكم لخدمات الشركة يستوجب عليكم القراءة والموافقة
            على سياسة الشروط والأحكام العامة والخاصة بالاستخدام وذلك لتوعيتكم
            بالالتزامات الواجب عليكم اتباعها عند استخدامكم للموقع و/ أو طلب
            الخدمات المعروضة على الصفحة الرئيسية للموقع الالكتروني، لذا يجب
            عليكم قراءتها بتمعن واهتمام.
            <br /><br />
            يحق للشركة تغيير الشروط والأحكام في أي وقت حسب تقديرها الخاص
            وبإرادتها المنفردة، لذا ننصحكم بقراءتها بشكل منتظم من وقت الى آخر
            للاطلاع على أي تعديلات قد تطرأ في هذا الخصوص، وتدخل الشروط والأحكام
            المعدلة حيز النفاذ فور نشرها على الموقع ويعد استخدامكم للموقع بعد
            نشرها، موافقة ضمنية منكم على الشروط والأحكام الجديدة وفي حال عدم
            موافقتكم على الشروط والاحكام يتعين عليكم عدم استخدام الموقع.
          </div>
          <div class="mt-10">
            <Collapsible class="example-collapsible" :isOpen="false">
              <div slot="trigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content arabic-font"
                    style="
                      font-size: 16px;
                      color: #2b9fad !important ;
                      flex-grow: 1;
                    "
                  >
                    الأهلية القانونية للاستخدام
                  </div>
                  <div>
                    <img
                      class="mr-1 fa-arrow-down-open"
                      src="../../assets/ar.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div slot="closedTrigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content arabic-font"
                    style="font-size: 16px; flex-grow: 1"
                  >
                    الأهلية القانونية للاستخدام
                  </div>
                  <div>
                    <img class="mr-1" src="../../assets/ar.png" alt="" />
                  </div>
                </div>
              </div>

              <div id="example2" class="pt-5">
                <p class="service-content arabic-font">
                  1. الأهلية القانونية للشخص الطبيعي.
                  <br /><br />
                  إن تسجيلكم من خلال الموقع يعد إقرارا منكم بأنكم قد بلغتم السن
                  القانونية حسب القوانين المعمول بها في دولة الكويت وأنكم
                  تتمتعون بالأهلية القانونية اللازمة لقبول سياسة الخصوصية
                  والشروط والأحكام واستخدام الموقع وفقا لهم.
                  <br /><br />
                  2. الأهلية القانونية للشخص الاعتباري.
                  <br /><br />
                  إن تسجيلكم من خلال الموقع يعد إقرارا منكم بأنكم مرخصين ومؤهلين
                  من قبل جميع الجهات المعنية في دولة الكويت لمزاولة وممارسة
                  نشاطكم وأنكم ملتزمون باحترام وتطبيق جميع القوانين واللوائح
                  الخاصة والصادرة من الجهات الرسمية.
                </p>
              </div>
            </Collapsible>
            <Collapsible class="example-collapsible" :isOpen="false">
              <div slot="trigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content arabic-font"
                    style="
                      font-size: 16px;
                      color: #2b9fad !important ;
                      flex-grow: 1;
                    "
                  >
                    المسئولية عن حماية حسابكم
                  </div>
                  <div>
                    <img
                      class="mr-1 fa-arrow-down-open"
                      src="../../assets/ar.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div slot="closedTrigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content arabic-font"
                    style="font-size: 16px; flex-grow: 1"
                  >
                    المسئولية عن حماية حسابكم
                  </div>
                  <div>
                    <img class="mr-1" src="../../assets/ar.png" alt="" />
                  </div>
                </div>
              </div>

              <div id="example2" class="pt-5">
                <p class="service-content arabic-font">
                  عند إنشائكم لحساب لاستخدام الموقع، سوف يطلب منكم الموقع وضع
                  كلمة المرور الخاصة بكم، ومن ثم فإنكم وحدكم تتحملون المسئولية
                  الكاملة عن الحفاظ على سرية كلمة المرور وعدم السماح للآخرين
                  باستخدامها وبالإضافة إلى ذلك، فأنتم مطالبون بتعويض الشركة
                  وإبراء ذمتها من أي خسارة قد تتكبدها أو أي طرف آخر نتيجة
                  استخدام شخص آخر لحسابكم وذلك بسبب عدم إتباعكم للعناية الواجبة
                  لحماية حسابكم.
                </p>
              </div>
            </Collapsible>
            <Collapsible class="example-collapsible" :isOpen="false">
              <div slot="trigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content arabic-font"
                    style="
                      font-size: 16px;
                      color: #2b9fad !important ;
                      flex-grow: 1;
                    "
                  >
                  
                الأعمال المحظورة في نطاق عمل خدمتي التنظيف العام و التنظيف العميق 
                  </div>
                  <div>
                    <img
                      class="mr-1 fa-arrow-down-open"
                      src="../../assets/ar.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div slot="closedTrigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content arabic-font"
                    style="font-size: 16px; flex-grow: 1"
                  >
                    الأعمال المحظورة في نطاق عمل خدمتي التنظيف العام و التنظيف العميق 
              
                  </div>
                  <div>
                    <img class="mr-1" src="../../assets/ar.png" alt="" />
                  </div>
                </div>
              </div>

              <div id="example2" class="pt-5">
                <p class="service-conten arabic-font">
                  ما هو ليس مشمول بخدمة التنظيف العام: الأعمال الغير مشمولة هي كالآتي: 
                  <br /><br />
                  1. إزالة سوائل الأصباغ 
                  <br /><br />
                  2. إزالة الدهون اللزجة 
                  <br /><br />
                  3. إزالة الأصباغ والبقع 
                  <br>
                  <br>
                </p>
                 <p class="service-conten arabic-font">
                 ما هو ليس مشمول بخدمة التنظيف العميق: الأعمال الغير مشمولة هي كالآتي:  
                  <br /><br />
                  1. تنظيف أسطح المطابخ، أسقف المطبخ، أرضيات المطبخ، حوائط المطبخ وكبتات المطبخ  
                  <br /><br />
                  2. تنظيف مستلزمات وأجهزة المطبخ 
                  
                </p>
              </div>
            </Collapsible>
                   <Collapsible class="example-collapsible" :isOpen="false">
              <div slot="trigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content arabic-font"
                    style="
                      font-size: 16px;
                      color: #2b9fad !important ;
                      flex-grow: 1;
                    "
                  >
                    أنشطة يحظر القيام بها على الموقع
                  </div>
                  <div>
                    <img
                      class="mr-1 fa-arrow-down-open"
                      src="../../assets/ar.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div slot="closedTrigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content arabic-font"
                    style="font-size: 16px; flex-grow: 1"
                  >
                    أنشطة يحظر القيام بها على الموقع
                  </div>
                  <div>
                    <img class="mr-1" src="../../assets/ar.png" alt="" />
                  </div>
                </div>
              </div>

              <div id="example2" class="pt-5">
                <p class="service-conten arabic-font">
                  نبين فيما يلي قائمة تشمل أنواع التصرفات غير القانونية والمحظور
                  القيام بها على الموقع، وتحتفظ الشركة بحقها في التحقيق أو اتخاذ
                  أية إجراءات قانونية مناسبة بحق أي شخص أو مستخدم أو يشارك في أي
                  أنشطة محظورة حسب تقدير الشركة، وتشمل تلك الأنشطة المحظورة على
                  سبيل المثال لا الحصر التالي: -
                  <br /><br />
                  1. نشر فيروسات أو رموز حاسوب ضارة بالموقع أو للإضرار
                  بمستخدميه.
                  <br /><br />
                  2. اختراق الموقع أو أي فعل مشابه يؤدي الى تعطيل الموقع و/أو
                  عدم تمكن الشركة و/أو المستخدمين من الدخول عليه و/أو استخدامه
                  بالطريقة الذي أعد من أجله.
                  <br /><br />
                  3. أي فعل تراه الشركة حسب تقديرها الخاص انه يعرضها أو يعرض أي
                  من مستخدميها لأي مسئولية أو ضرر أياً كان نوعه.
                  <br /><br />
                  4.يحق للشركة، إنهاء وصول أي مستخدم إلى الموقع عند حدوث أي
                  مخالفة لهذه الشروط والأحكام دون اعتراض منه.
                </p>
              </div>
            </Collapsible>
            <Collapsible class="example-collapsible" :isOpen="false">
              <div slot="trigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content arabic-font"
                    style="
                      font-size: 16px;
                      color: #2b9fad !important ;
                      flex-grow: 1;
                    "
                  >
                    حقوق الموقع وحماية الملكية الفكرية والنش
                  </div>
                  <div>
                    <img
                      class="mr-1 fa-arrow-down-open"
                      src="../../assets/ar.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div slot="closedTrigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content arabic-font"
                    style="font-size: 16px; flex-grow: 1"
                  >
                    حقوق الموقع وحماية الملكية الفكرية والنش
                  </div>
                  <div>
                    <img class="mr-1" src="../../assets/ar.png" alt="" />
                  </div>
                </div>
              </div>

              <div id="example2" class="pt-5">
                <div class="service-content arabic-font">
                  1.تعتبر المحتويات الخاصة بأفكار وتصميم وبرمجيات الموقع محمية
                  بكافة حقوق الملكية الفكرية والطبع والنشر وذلك وفقا للقوانين
                  الدولية والمحلية في هذا الخصوص، ومن ثم فإنه لا يجوز لكم تحميل
                  أجزاء من المواد والمحتوى وأجزاء مختلفة من الموقع او اعادة
                  استخدامها من جانبكم سواء لاغراض شخصية او تجارية ، وإن أي إعادة
                  إرسال أو نشر لأي مواد محفوظة الحقوق للشركة دون الحصول على
                  الموافقة الخطية الصريحة منها يعرضكم للمسائلة القانونية.
                  <br /><br />
                  2.عند استخدامك للموقع فإنك تقر وتوافق على أن جميع حقوق النشر
                  والعلامات التجارية وسائر حقوق الملكية الفكرية وهي ملك للشركة
                  <br /><br />
                  3. في حال تبين لكم أن أي مواد منشورة أو معروضة على الموقع
                  تنتهك حقوق الملكية والنشر الخاصة بكم، يمكنكم طلب حذف هذه
                  المواد من خلال التواصل مع خدمة العملاء وبشرط تقديمكم المعلومات
                  التالية: -
                  <br /><br />
                  <div class="pr-15">
                    1.تحديد وبيان المحتوى الذي يتمتع بحق الحماية من النشر والذي
                    تم انتهاكه من قبل الموقع، يرجى وصف العمل وإضافة نسخة منه أو
                    مكان ذلك العمل (مثال: رابط إلكتروني).
                    <br /><br />
                    2. تزويدنا بالبيانات الشخصية الخاصة بكم (الاسم، والعنوان،
                    ورقم الهاتف، وعنوان البريد الإلكتروني).
                    <br /><br />
                    3. كافة البيانات والمستندات التي تؤيد الشكوى الغير مرخص بها
                    من قبل مالك حق النشر، أو وكيله أو القانون الدال على ذلك
                    الانتهاك.
                  </div>
                  <br />
                  وعليه يرجي إرسال كافة هذه المعلومات بالبريد الإلكتروني إلى::
                  customercare@bleach-kw.com
                </div>
              </div>
            </Collapsible>
            <Collapsible class="example-collapsible" :isOpen="false">
              <div slot="trigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content arabic-font"
                    style="
                      font-size: 16px;
                      color: #2b9fad !important ;
                      flex-grow: 1;
                    "
                  >
                    الضمانات وإخلاء المسؤولية
                  </div>
                  <div>
                    <img
                      class="mr-1 fa-arrow-down-open"
                      src="../../assets/ar.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div slot="closedTrigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content arabic-font"
                    style="font-size: 16px; flex-grow: 1"
                  >
                    الضمانات وإخلاء المسؤولية
                  </div>
                  <div>
                    <img class="mr-1" src="../../assets/ar.png" alt="" />
                  </div>
                </div>
              </div>

              <div id="example2" class="pt-5">
                <p class="service-content arabic-font">
                  على الرغم من أننا سنبذل العناية اللازمة للتأكد من صحة
                  المعلومات والمحتويات المنشورة على الموقع ، إلا أن الشركة لا
                  يقع على عاتقها أي التزامات أو ضمانات صريحة كانت أو ضمنية أو
                  قانونية أو غير ذلك بصحة أو دقة تلك المعلومات، بما في ذلك على
                  سبيل المثال لا الحصر الشروط والأحكام الخاصة بتوفير الخدمات
                  المعروضة، كما أن الشركة لا تقدم أي ضمانات خاصة بدقة المعلومات
                  واستمراريتها وفائدتها وكفاءتها واكتمالها أو صلاحيتها، بالإضافة
                  إلى ذلك، لا تقدم الشركة ولا تستطيع تقديم ضمانات مقابل الأخطاء
                  البشرية أو الآلية أو الحذف أو التأخير أو الانقطاعات أو
                  الخسائر، بما في ذلك فقدان البيانات، ولا تقدم الشركة ولا يمكنها
                  تقديم ضمانات أن أي من الملفات المتاحة للتحميل من على الموقع
                  غير مصابة بالفيروسات بأنواعها المختلفة، أو رموز أخرى لها خصائص
                  ملوثة أو مدمرة، ولا تضمن الشركة ولا يمكنها تقديم ضمان
                  استمرارية بقاء أي محتوى منشور على الموقع، مع ذلك لا تضمن
                  الشركة أن المهمات أو الخدمات المقدمة على الموقع لن تتعرض
                  للانقطاع أو أنها خالية من الأخطاء أو أن عيوب الموقع سيتم
                  تصحيحها. وقد تظهر الأخطاء أثناء عملية طلب الخدمة من وقت لآخر،
                  والأخطاء قد تحصل في الإرسال وقد تؤدي إلى انقطاع لعملية انهاء
                  طلب الخدمة او الدفع وخلافه، سوف تبذل الشركة قصارى جهدها
                  لمحاولة حل أية مشاكل قد تنشأ نتيجة لحصول هذه الأخطاء، ويوافق
                  المستخدم على أن تكون للشركة الحرية الكاملة للتصرف واتخاذ
                  القرار المناسب في مثل هذه الحالات.
                </p>
              </div>
            </Collapsible>
            <Collapsible class="example-collapsible" :isOpen="false">
              <div slot="trigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content arabic-font"
                    style="
                      font-size: 16px;
                      color: #2b9fad !important ;
                      flex-grow: 1;
                    "
                  >
                    حدود المسئولية المشتركة
                  </div>
                  <div>
                    <img
                      class="mr-1 fa-arrow-down-open"
                      src="../../assets/ar.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div slot="closedTrigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content arabic-font"
                    style="font-size: 16px; flex-grow: 1"
                  >
                    حدود المسئولية المشتركة
                  </div>
                  <div>
                    <img class="mr-1" src="../../assets/ar.png" alt="" />
                  </div>
                </div>
              </div>

              <div id="example2" class="pt-5">
                <p class="service-content arabic-font">
                  1. لا تتحمل الشركة أو مستخدميها أي مسئولية مباشرة، أو غير
                  مباشرة، أو خاصة، أو عرضية، أو تبعية تنشأ عن استخدامكم للموقع
                  أو أية مطالبة أخرى تتعلق باستخدامكم للموقع.
                  <br /><br />
                  2. لا تتحمل الشركة المسؤولية في التأخير أو الفشل في تقديم
                  الخدمة أو خلاف ذلك لأداء أي التزام كما هو محدد في هذه الشروط
                  إذا كان السبب كلياً أو جزئياً ناتجاً عن الأحداث القاهرة و/أو
                  بسبب قوة قاهره، سواء بشكل مباشر أو غير مباشر، أو بسبب ظروف
                  خارجه عن سيطرتها المعقولة.
                  <br /><br />
                  3. إذا تم اعتبار أي بند من هذه الشروط من قبل أي سلطة مختصة غير
                  صالح أو غير قابل للتطبيق كلياً أو جزئياً، فإن ذلك لا يؤثر على
                  صحة أو قابلية تنفيذ الشروط الأخرى.
                  <br /><br />
                  4.لا يمكن بأي حال من الأحوال اعتبار تأخير الشركة بممارسة اي
                  سلطات او صلاحيات ممنوحة لها بموجب هذه الشروط او طلب تعويضات
                  بمثابة تنازل من الشركة عن أياً مما سبق.
                </p>
              </div>
            </Collapsible>
            <Collapsible class="example-collapsible" :isOpen="false">
              <div slot="trigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content arabic-font"
                    style="
                      font-size: 16px;
                      color: #2b9fad !important ;
                      flex-grow: 1;
                    "
                  >
                    الخدمات والأسعار
                  </div>
                  <div>
                    <img
                      class="mr-1 fa-arrow-down-open"
                      src="../../assets/ar.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div slot="closedTrigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content arabic-font"
                    style="font-size: 16px; flex-grow: 1"
                  >
                    الخدمات والأسعار
                  </div>
                  <div>
                    <img class="mr-1" src="../../assets/ar.png" alt="" />
                  </div>
                </div>
              </div>

              <div id="example2" class="pt-5">
                <p class="service-content arabic-font">
                  أن تحديد سعر الخدمة يستوجب عليك عملينا العزيز تزودينا بمعلومات
                  دقيقة عن نوع الخدمة المطلوبة، ويمكنكم الاستعانة بالباقات
                  المتوفرة على الموقع اذا كانت تتناسب مع متطالبتكم، وفي حال
                  رغبتكم في خدمات اخرى خلاف المبينة بالباقات يرجى منكم افادتنا
                  بها وسنزودكم بعرض سعر بقيمة الخدمة صالح لمدة 14 يوم من تاريخ
                  اصداره، وفي حال موافقتكم عليه سيكون عرض السعر جزء لا يتجزأ من
                  هذه الشروط والأحكام، و في جميع الأحوال يستوجب عليك سداد قيمة
                  الأعمال فور موافقتكم على عرض السعر او اختيار الباقة.
                </p>
              </div>
            </Collapsible>
            <Collapsible class="example-collapsible" :isOpen="false">
              <div slot="trigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content arabic-font"
                    style="
                      font-size: 16px;
                      color: #2b9fad !important ;
                      flex-grow: 1;
                    "
                  >
                    تعديل أسعار الخدمات والمنتجات..
                  </div>
                  <div>
                    <img
                      class="mr-1 fa-arrow-down-open"
                      src="../../assets/ar.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div slot="closedTrigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content arabic-font"
                    style="font-size: 16px; flex-grow: 1"
                  >
                    تعديل أسعار الخدمات والمنتجات.
                  </div>
                  <div>
                    <img class="mr-1" src="../../assets/ar.png" alt="" />
                  </div>
                </div>
              </div>

              <div id="example2" class="pt-5">
                <div class="service-content arabic-font">
                  يحق للشركة تعديل اسعار الخدمات المقدمة منها على الموقع في اي
                  وقت دون حاجة لإشعاركم ونحن لسنا مسئولين تجاهك عملينا العزيز عن
                  تغيير او تعديل اسعار الخدمة وذلك عدا الأسعار التي تم الاتفاق
                  عليها بعرض السعر طالما كان سارياً على النحو الذي سنبينه لاحقاً
                  هذه الشروط والأحكام.
                  <br /><br />
                  <b>الدفع:</b>
                  <div class="pl-15">
                    عند الدفع عن طريق الموقع، يتم سداد قيمة الخدمات المطلوبة
                    باستخدام البطاقات المصرفية فقط، وتتم عملية الخصم من البطاقة
                    المصرفية في الوقت الذي يتم فيه تلقي طلب الخدمة وبعد التحقيق
                    في تفاصيل البطاقة. ويعلم العميل أن السداد عن طريق الموقع
                    سيكون من خلال طرف ثالث، ولا يعتد بسداد قيمة الخدمة إلا بدخول
                    مقابلها حساب الشركة البنكي.
                  </div>
                </div>
              </div>
            </Collapsible>
            <Collapsible class="example-collapsible" :isOpen="false">
              <div slot="trigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content arabic-font"
                    style="
                      font-size: 16px;
                      color: #2b9fad !important ;
                      flex-grow: 1;
                    "
                  >
                    ميعاد تقديم الخدمة
                  </div>
                  <div>
                    <img
                      class="mr-1 fa-arrow-down-open"
                      src="../../assets/ar.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div slot="closedTrigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content arabic-font"
                    style="font-size: 16px; flex-grow: 1"
                  >
                    ميعاد تقديم الخدمة
                  </div>
                  <div>
                    <img class="mr-1" src="../../assets/ar.png" alt="" />
                  </div>
                </div>
              </div>

              <div id="example2" class="pt-5">
                <div class="service-content arabic-font">
                  فور سدادكم لقيمة الأعمال المحددة بعرض السعر أو سداد قيمة أحد
                  الباقات المعروضة على الموقع الالكتروني، ستقوم بليتش بطرح عدة
                  تواريخ ومواعيد لتقديم الخدمة يمكنكم اختيار الأنسب منها ، سيكون
                  الميعاد والتاريخ المحدد ملزم لكم كما هو ملزم للشركة ودون
                  الإخلال بحدود المسئولية المشتركة، وبليتش ملتزمة بالقرارات
                  الحكومية المتعلقة بشأن ساعات العمل بالأماكن المكشوفة خلال فصل
                  الصيف حماية لفريق عملها وفي الميعاد المحدد يجب أن يكون في
                  استقبال فريق عمل الشركة شخص يملك سلطة الإذن بإدخال فريقينا إلى
                  موقع العمل، ويفضل أن يكون هذا الشخص هو من سيتم التواصل معه
                  هاتفياً على رقم الهاتف الجوال المسجل لدينا عند طلب تقديم
                  الخدمة ما لم يبدي العميل اسم ورقم جوال شخص أخر.
                </div>
              </div>
            </Collapsible>
            <Collapsible class="example-collapsible" :isOpen="false">
              <div slot="trigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content arabic-font"
                    style="
                      font-size: 16px;
                      color: #2b9fad !important ;
                      flex-grow: 1;
                    "
                  >
                    العنوان الخطأ
                  </div>
                  <div>
                    <img
                      class="mr-1 fa-arrow-down-open"
                      src="../../assets/ar.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div slot="closedTrigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content arabic-font"
                    style="font-size: 16px; flex-grow: 1"
                  >
                    العنوان الخطأ
                  </div>
                  <div>
                    <img class="mr-1" src="../../assets/ar.png" alt="" />
                  </div>
                </div>
              </div>

              <div id="example2" class="pt-5">
                <div class="service-content arabic-font">
                  إذا قمت بحجز زيارة على عنوان خاطئ (أنشأت عنوانًا خاطئًا ، حجزت
                  في مكان خاطئ) ، وتم التوجه إلى ذلك العنوان، فيحق للشركة اعتبار
                  الزيارة تامه ويتحمل العميل كامل مبلغ الزيارة
                </div>
              </div>
            </Collapsible>
            <Collapsible class="example-collapsible" :isOpen="false">
              <div slot="trigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content arabic-font"
                    style="
                      font-size: 16px;
                      color: #2b9fad !important ;
                      flex-grow: 1;
                    "
                  >
                    سياسة التعديل والإلغاء
                  </div>
                  <div>
                    <img
                      class="mr-1 fa-arrow-down-open"
                      src="../../assets/ar.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div slot="closedTrigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content arabic-font"
                    style="font-size: 16px; flex-grow: 1"
                  >
                    سياسة التعديل والإلغاء
                  </div>
                  <div>
                    <img class="mr-1" src="../../assets/ar.png" alt="" />
                  </div>
                </div>
              </div>

              <div id="example2" class="pt-5">
                <div class="service-content arabic-font">
                  <b>1- التعديل والتأجيل.</b>
                  يمكنك عملينا العزيز تعديل موعد تقديم الخدمة لمرة واحدة مجاناً
                  شريطة أن يكون التعديل بتأجيل موعد تقديم الخدمة وليس بتبكيره،
                  على ان يتم طلب تعديل قبل الموعد المحدد لتقديم الخدمة بـ 48
                  ساعة على الأقل، وفي حالة كان رغبتكم في التعديل قبل مدة تقل عن
                  الـ 48 ساعة المشار اليها فيحق للشركة زيادة نسبة قدرها 10 % على
                  قيمة الخدمة المطلوبة دون ثمة اعتراض منكم، وسيستوجب عليكم سداد
                  مقابل هذه النسبة قبل تحديد الموعد الجديد لتقديم الخدمة.
                  <br /><br />
                  <b>2- الإلغاء .</b>
                  يحق لكم الغاء طلب الخدمة قبل موعد تقديهما بـ48 ساعة على الأقل
                  وفي هذه الحالة سيتم رد كامل قيمة الخدمة المدفوعة منكم ويكون
                  الرد بالكيفية التي تم السداد بموجبها، أما في حال كان طلب
                  الالغاء في مدة أقل من ذلك فيحق للشركة خصم نسبة قدرها 20% من
                  قيمة الخدمة المدفوعة منكم، وسيتم رد باقي المبلغ بذات الكيفية
                  التي تم السداد بموجبها.
                  <br /><br />

                  <b> 3- التعديل أو الالغاء في اليوم المحدد لتقديم الخدمة .</b>
                  <br />
                  <br />
                  اذا تم طلب الالغاء او التعديل في اليوم المحدد لتقديم الخدمة
                  فهذا يعني تفويت الفرصة على بليتش في استغلال الموعد لصالح عملاء
                  اخرين وتكون المبالغ المسددة من العميل حقاً خالصاً لبليتش
                  تعويضاً رضائياً دون التزام على بليتش باثبات ثمة اضرار اصابتها
                  جراء الالغاء او التعديل .
                </div>
              </div>
            </Collapsible>
            <Collapsible class="example-collapsible" :isOpen="false">
              <div slot="trigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content arabic-font"
                    style="
                      font-size: 16px;
                      color: #2b9fad !important ;
                      flex-grow: 1;
                    "
                  >
                    الضمان والكفالة
                  </div>
                  <div>
                    <img
                      class="mr-1 fa-arrow-down-open"
                      src="../../assets/ar.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div slot="closedTrigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content arabic-font"
                    style="font-size: 16px; flex-grow: 1"
                  >
                    الضمان والكفالة
                  </div>
                  <div>
                    <img class="mr-1" src="../../assets/ar.png" alt="" />
                  </div>
                </div>
              </div>

              <div id="example2" class="pt-5">
                <div class="service-content arabic-font">
                  أ- المواد المستخدمة.
                  <br /><br />
                  تضمن بليتش جودة المواد المستخدمة منها في أعمال التنظيف، وذلك
                  بعدم تعييبها أو إتلافيها للأغراض الواقع عليها اعمال التنظيف،
                  وفي جميع الأحوال يجب عليك عملينا العزيز الإفصاح عن أي ملاحظات
                  جوهرية تتعلق بالأغراض الواقع عليها اعمال التنظيف فيما اذا كان
                  ينص دليل استخدامها – الكتالوج - على عدم استخدام مواد معينة في
                  أعمال تنظيفها، وتخلي الشركة مسئوليتها في حال لم يفصح العميل عن
                  تلك المعلومات الجوهرية وقت طلب الخدمة أو وقت تقديمها.
                  <br /><br />
                  ب- أعمال التنظيف.
                  <br /><br />
                  تسعى بليتش دائماً أن تنول رضاء عملائها، وفي سبيل ذلك تقوم
                  بتقديم خدمة التنظيف بحرفية ومن خلال فريق عمل ذو كفاءة عالية،
                  ونظراً لطبيعة الخدمة المقدمة من اعمال التنظيف والتي لا يمكن
                  كفالتها، فإن قيام بليتش بالانتهاء من تقديم الخدمة هي قرينة على
                  أن الأعمال قد تمت صحيحة ومراعية للأصول الفنية في تنفيذها ،
                  ويكون على عاتق العميل استلام موقع العمل والتوقيع على اقرار
                  استلام الموقع ما لم يتم ابداء ملاحظات جوهرية منكم على الأعمال
                  والتي تخضع لتقدير وتقييم بليتش. ويكون توقيعك على استلام
                  الاعمال اقرار بإتمام العمل وتنفيذه وبعدم وجود ملاحظات على
                  الأعمال المنفذه مع عدم الاخلال بما سيرد لاحقاً بشأن تقرير
                  إتمام الأعمال .
                </div>
              </div>
            </Collapsible>
            <Collapsible class="example-collapsible" :isOpen="false">
              <div slot="trigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content arabic-font"
                    style="
                      font-size: 16px;
                      color: #2b9fad !important ;
                      flex-grow: 1;
                    "
                  >
                    موقع العمل وحماية الممتلكات
                  </div>
                  <div>
                    <img
                      class="mr-1 fa-arrow-down-open"
                      src="../../assets/ar.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div slot="closedTrigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content arabic-font"
                    style="font-size: 16px; flex-grow: 1"
                  >
                    موقع العمل وحماية الممتلكات
                  </div>
                  <div>
                    <img class="mr-1" src="../../assets/ar.png" alt="" />
                  </div>
                </div>
              </div>

              <div id="example2" class="pt-5">
                <div class="service-content arabic-font">
                  في موعد تقديم الخدمة تلتزم عملينا العزيز بازالة و رفع أي
                  أغراض/ اجهزة ثمنية أو قابلة للكسر او كلاهما من موقع العمل
                  والتي لن تخضع لأعمال التنظيف إذا كانت قابله للإزالة والرفع
                  وذلك لغايات المحافظة عليها وعدم تعرضها لحوادث الكسر، ويحق
                  لفريق عملنا طلب ذلك قبل البدء في أعمال التنظيف.
                  <br /><br />
                  وفريق عملنا له كامل الحق في إعداد تقرير عند دخول موقع العمل
                  لاثبات وجود اجهزة او اغراض بها عيوب او كسر ويلتزم العميل
                  بالتوقيع عليه قبل بدء العمل، وفي حال عدم التوقيع ستقوم ادارة
                  الشركة بارسال بريد الكتروني للعميل بعدم مسئوليتها عن اية حوادث
                  تتعرض لها ممتلكات العميل ناتجة عن عدم تعاونه في رفعها من موقع
                  العمل، وكذلك عدم مسئوليتها عن اي ضرر للممتلكات بعد مغادرة فريق
                  العمل للموقع.
                  <br /><br />
                  بدء العمل في اليوم المحدد لتقديم الخدمة من الممكن ان يتواصل
                  معكم فريق عمل بليتش عبر الرسائل او الاتصال الهاتفي لتاكيد موقع
                  العمل وموعد الوصول .
                  <br /><br />
                  عند مباشرة فريق عملنا أعمال التنظيف بالموقع يجب عليك عملينا
                  العزيز العمل على تسهيل تنفيذ الاعمال وذلك عن طريق ما يلي:-
                  <br /><br />
                  1. - عدم إدخال مقاولين او عمال اخرين لتنفيذ أية اعمال اخرى
                  داخل الموقع وقت تنفيذ أعمال التنظيف.
                  <br /><br />
                  2.- الالتزام بالمساحات وبالأعمال المحددة بعرض السعر أو الباقة
                  ولا يجوز الطلب من تابعي الشركة تنفيذ أية اعمال اخرى لم يتم
                  الاتفاق عليها، ما لم يسدد العميل مقابلها قبل تنفيذيها بشرط ألا
                  تتعارض الأعمال المضافة مع المواعيد المحددة من بليتش مع عملاء
                  اخرين في ذات يوم تنفيذ العمل.
                  <br /><br />
                  3. - لا يجوز للعميل طلب إيقاف أعمال التنظيف الواردة في خطة
                  العمل بعد بدئها ، وفي حال توقف العمل لا تتحمل الشركة مسؤولية
                  إتمام التنظيف أو إعادته.
                  <br /><br />
                  4.عدم التدخل خلال قيام فريق العمل بأعمال التنظيف، او تكليف
                  فريق عمل الشركة بأية اعمال خارج نطاق أعمال التنظيف المطلوبة
                  حتى ولو بمقابل مادي خارج نطاق اتفاقه مع الشركة، او الاتفاق مع
                  فريق عمل الشركة على اية اعمال خارجية لمصلحة العميل الشخصية او
                  لأي طرف ثالث خارج اوقات العمل.

                  <br /><br />
                  ويحق لبليتش في جميع الأحوال اعتبار أن الأعمال قد تمت اذا لم
                  يتم التعاون مع فريق عملنا من قبل العميل او مخالفة التعليمات
                  السابقة.
                </div>
              </div>
            </Collapsible>
            <Collapsible class="example-collapsible" :isOpen="false">
              <div slot="trigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content arabic-font"
                    style="
                      font-size: 16px;
                      color: #2b9fad !important ;
                      flex-grow: 1;
                    "
                  >
                    تقرير إتمام العمل
                  </div>
                  <div>
                    <img
                      class="mr-1 fa-arrow-down-open"
                      src="../../assets/ar.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div slot="closedTrigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content arabic-font"
                    style="font-size: 16px; flex-grow: 1"
                  >
                    تقرير إتمام العمل
                  </div>
                  <div>
                    <img class="mr-1" src="../../assets/ar.png" alt="" />
                  </div>
                </div>
              </div>

              <div id="example2" class="pt-5">
                <div class="service-content arabic-font">
                  عقب انتهاء فريق عمل بليتش من تقديم الخدمة بموقع العمل، سيتم
                  إعداد تقرير عن الأعمال، سيطلب منك عملينا العزيز التوقيع عليه
                  بما يفيد بإنتهاء العمل واستلامه منكم وفي حال رفضكم التوقيع على
                  التقرير دون ابداء اسباب سيتم ارسال بريد الكتروني لكم بما يفيد
                  انتهاء العمل وتسليمه اليكم وبما يفيد موافقتكم الأعمال وسلامة
                  الممتلكات، وفي جميع الأحوال انتم توافقون على أحقية فريق علمنا
                  في التصوير الفوتوغرافي لموقع العمل لغايات التوثيق والدعاية.
                </div>
              </div>
            </Collapsible>
            <Collapsible class="example-collapsible" :isOpen="false">
              <div slot="trigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content arabic-font"
                    style="
                      font-size: 16px;
                      color: #2b9fad !important ;
                      flex-grow: 1;
                    "
                  >
                    حق الغاء الحساب
                  </div>
                  <div>
                    <img
                      class="mr-1 fa-arrow-down-open"
                      src="../../assets/ar.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div slot="closedTrigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content arabic-font"
                    style="font-size: 16px; flex-grow: 1"
                  >
                    حق الغاء الحساب
                  </div>
                  <div>
                    <img class="mr-1" src="../../assets/ar.png" alt="" />
                  </div>
                </div>
              </div>

              <div id="example2" class="pt-5">
                <div class="service-content arabic-font">
                  لبليتش الحق المطلق في الغاء حسابكم ومنع استخدامكم لموقعها
                  الالكتروني ودون حاجة لابداء اسباب، وسيكون ذلك ضرورياً اذا تبين
                  عدم صحة المعلومات والبيانات التي تم التسجيل بموجبها أو عند
                  استخدامكم غير المقبول للموقع ، أو مخالفتكم لهذه الشروط
                  والاحكام.
                </div>
              </div>
            </Collapsible>
            <Collapsible class="example-collapsible" :isOpen="false">
              <div slot="trigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content arabic-font"
                    style="
                      font-size: 16px;
                      color: #2b9fad !important ;
                      flex-grow: 1;
                    "
                  >
                    الإشعارات والمراسلات
                  </div>
                  <div>
                    <img
                      class="mr-1 fa-arrow-down-open"
                      src="../../assets/ar.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div slot="closedTrigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content arabic-font"
                    style="font-size: 16px; flex-grow: 1"
                  >
                    الإشعارات والمراسلات
                  </div>
                  <div>
                    <img class="mr-1" src="../../assets/ar.png" alt="" />
                  </div>
                </div>
              </div>

              <div id="example2" class="pt-5">
                <div class="service-content arabic-font">
                  يحق للشركة إرسال إشعارات بموجب هذه الشروط والأحكام باستخدام
                  البريد الإلكتروني أو إشعار عام على الموقع أو رسائل دعائية أو
                  ترويجية الى بريدكم الإلكتروني
                </div>
              </div>
            </Collapsible>
            <Collapsible class="example-collapsible" :isOpen="false">
              <div slot="trigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content arabic-font"
                    style="
                      font-size: 16px;
                      color: #2b9fad !important ;
                      flex-grow: 1;
                    "
                  >
                    القانون المُنظم للاستخدام واللغة المعتمدة
                  </div>
                  <div>
                    <img
                      class="mr-1 fa-arrow-down-open"
                      src="../../assets/ar.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div slot="closedTrigger">
                <div class="customTrigger d-flex">
                  <div
                    class="service-content arabic-font"
                    style="font-size: 16px; flex-grow: 1"
                  >
                    القانون المُنظم للاستخدام واللغة المعتمدة
                  </div>
                  <div>
                    <img class="mr-1" src="../../assets/ar.png" alt="" />
                  </div>
                </div>
              </div>

              <div id="example2" class="pt-5">
                <div class="service-content arabic-font">
                  تخضع هذه الشروط والأحكام وتُفسر وفقاً للقوانين السارية بدولة
                  الكويت ذات الصلة بالاتفاق الراهن وبشكل خاص القانون رقم 20 لسنة
                  2014 في شأن المعاملات الالكترونية، وأن أي نزاع قد ينشأ عنها أو
                  عن الخدمات المقدمة منا يجب أن ينظر أمام المحاكم المختصة في
                  دولة الكويت، وفي حال أصبح أي بند من هذه الشروط والأحكام غير
                  قابل للتطبيق أو غير ملزم قانوناً، فإنكم ستظلون ملزمين بجميع
                  الأحكام والبنود الأخرى.
                  <br /><br />
                  حررت هذه الشروط والأحكام باللغتين العربية ولانجليزية، وفي حال
                  وجود اختلاف بعض المعاني فيسري النص المحرر باللغة العربية.
                </div>
              </div>
            </Collapsible>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-collapsible-component/lib/vue-collapsible.css";
import Collapsible from "vue-collapsible-component";
//import Collapse from 'vue-collapse'
//import privacyexpand from '../../components/privacyexpand.vue'
//import pageheading from "../../components/pageheading.vue";
export default {
  components: { Collapsible },
  data() {
    return {
      btnselected: "",
      selectlan: localStorage.getItem("bleach-lang"),
    };
  },
};
</script>

<style scoped>
.Collapsible {
  margin-top: 10px !important;
  padding-bottom: 8px !important;
  border-bottom: 1px solid #e9ecef !important;
}
</style>