<template>
            <div>
                <div  class="show-callus" v-if="selectlan == 'EN'">
             <div class="about-cleaning">
                 Sectores Under Residential
               </div>
               <div class=" py-2 mb-3" style="border-bottom:1px solid #C4C4C4;">
              <div class="d-flex pointer " @click="dc = !dc">
                 <div class="mobile-sub-link mobile-sub-header h-100 ma">
                 {{name}}
               </div>
               <div>
                 <img class="mr-1" :class="{ 'fa-arrow-down-open': dc }" src="../../assets/ar.png" alt="">
               </div>
              </div>
            </div>
            <div class="drop-service">
               <transition-expand>
              <div v-if="dc" >
                <ul class="drop-list">
                    <li v-if="'Domestic Sector' != name" @click="$router.push('domesticsector')">
                                  <a>
                                    Domestic Sector
                                  </a>
                    </li>
                    <li v-if="'Baby Care' != name" @click="$router.push('babycare')">
                        <a>
                                    Baby Care
                        </a>
                    </li>
                    <li v-if="'Moving In/Out' != name" @click="$router.push('moving')">
                        <a>
                          Moving In/Out
                          </a>
                    </li>
                    <li v-if="'Special Events Care' != name" @click="$router.push('events')">
                      <a>
                        Special Events Care</a>
                    </li>
                </ul>
              </div> 
            </transition-expand>
            </div>
            </div>
              <div  class="show-callus" v-if="selectlan == 'ARABIC'">
              <div class="about-cleaning arabic-font">
                 Sectores Under Residential 
               </div>
               <div class=" py-2 mb-3" style="border-bottom:1px solid #C4C4C4;">
              <div class="d-flex pointer " @click="dc = !dc">
                 <div class="mobile-sub-link mobile-sub-header h-100 ma">
                 {{name}}
               </div>
               <div>
                 <img class="mr-1" :class="{ 'fa-arrow-down-open': dc }" src="../../assets/ar.png" alt="">
               </div>
              </div>
            </div>
            <div class="drop-service">
               <transition-expand>
              <div v-if="dc" >
                <ul class="drop-list">
                    <li v-if="'قطاع األعمال المنزلية' != name" @click="$router.push('domesticsector')">
                                  <a class="arabic-font">
                                    قطاع األعمال المنزلية
                                  </a>
                    </li>
                    <li v-if="'رعاية األطفال' != name" @click="$router.push('babycare')">
                        <a class="arabic-font">
                                    رعاية األطفال
                        </a>
                    </li>
                    <li v-if="'عمال انتقال المأجر من والى العين' != name" @click="$router.push('moving')">
                        <a class="arabic-font">
                          أعمال انتقال المأجر
                          </a>
                    </li>
                    <li v-if="'Special Events Care' != name" @click="$router.push('events')">
                      <a class="arabic-font">
                        رعاية المناسبات الخاصة</a>
                    </li>
                </ul>
              </div> 
            </transition-expand>
            </div>
            </div>
            </div>
</template>

<script>
export default {
    props:['name'],
    data(){
        return{
            dc:false,
            selectlan:localStorage.getItem('bleach-lang')
        };
    }
}
</script>

<style>

</style>