<template>
<div>
   <div>
      <section class=""  >
         <div class="bg-header-img-com bg-header  ">
         </div>
      </section>
  </div>
    <div v-if="selectlan == 'EN'">
     
         <div class="bcontainer">
      <div class="py-15">
        <div class="page-head">
          commercial
        </div>
        <div class="sub-content mt-2 text-left">

          BLEACH® has a rich history of providing a clean setup to corporate houses, Governates, and
stores & facilities, to operate in a healthy environment. Our experienced cleaners
understand clients’ needs and leave you with a healthy work setting where you spend most
of your everyday time.
        </div>
      </div>
      <div>
   

      </div>
    </div>
       <router-view/>
  </div>
    <div v-if="selectlan == 'ARABIC'" dir="rtl">
      
         <div class="bcontainer">
      <div class="py-15">
        <div class="page-head arabic-font" dir="rtl">
         خدمات نظافة <span class="w400">الشركات والمؤسسات </span>
        </div>
        <div class="sub-content mt-2 text-right arabic-font">
        لدى بليتش® تاريخ غني لإعداد وتوفير خدمات النظافة لمكاتب الشركات، ومباني المحافظات، ومرافق التخزين، والمرافق العمومية من أجل السماح لهم بالعمل في بيئة صحية، حيث يتفهم عمال النظافة ذوو الخبرة لدينا احتياجات العميل فيعملوا على إعداد بيئة عمل صحية حيث يقضي الموظفون معظم أوقات عملهم بشكل يومي.
        </div>
      </div>
      <div>
   

      </div>
    </div>
       <router-view/>
  </div>
</div>
</template>

<script>
//import pageheading from '../../components/pageheading.vue'
export default {
    components:{},
     data(){
        return{
            selectlan:localStorage.getItem('bleach-lang')
        }
    },
}
</script>

<style>

</style>