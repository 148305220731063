<template>
<div>
    <div v-if="selectlan =='EN'">
      <div class="gray-bg-2">
      <div class="bcontainer">
         <div class="row py-15">
             <div class="col-md-4 col-lg-3">
                 <ul class="service-ui show-head">

                    <li  @click="$router.push('domesticsector')"  >
                        <span class="dot-li"></span>
                        Domestic Sector
                    </li> 
                     <li class="service-ui-active">
                         <div class="dot-li-active"></div>
                       Baby Care
                    </li>
                    <li @click="$router.push('moving')" >
                         <span class="dot-li"></span>
                       Moving In/Out
                    </li>
                     <li @click="$router.push('events')" >
                        <span class="dot-li"></span>
                        Special Events Care
                    </li>
                     
                 </ul>
                 <resmenu :name="'Baby Care'"/>
                  <div class="mt-5">
                    <div class="recommended-head">
                        RECOMMENDED SERVICES
                    </div>
                    <div class="rec-item-name mt-3" @click="goto('/service/specialcare/mattresscleaning')">
                        <img src="../../assets/icons/recommend/matress.png" alt="" class="ml-2 mr-2"  > Mattress Cleaning
                    </div>
                    <div class="rec-item-name mt-3" @click="goto('/service/specialcare/carpetcleaning')">
                        <img src="../../assets/icons/recommend/carpet.png" alt="" class="ml-2 mr-2" >  Carpet Cleaning
                    </div>
                     <div class="rec-item-name mt-3" @click="goto('/service/pestcontrol/pestcontrol')">
                        <img src="../../assets/icons/recommend/inf.png" alt="" class="ml-2 mr-2">  Pest Control
                    </div>
                 </div>
             </div>
             <div class="col-md-8 col-lg-9">
                 <div class="cleaning-head show-head">Baby <span class="w400">Care</span>  </div>
                   <div class="sub-content mt-2">
                  BLEACH® provides the highest quality cleaning and sanitization services to protect your
newborns, infants, or grownups from being exposed to germs from their living environment. 
Our children-safe cleaning products are non-toxic in nature and best suited for cleaning your
child's room as well as disinfecting blocks and toys, and anything else you might need to
keep the baby safe, secure, and happy! <br><br>
BLEACH®’s staff are given special training (CIMS® & ISSA® Compliant) in hygiene precautions
with regard to infants and have an understanding of how a clean environment is as vital as
nourishing food for your kids.

                 </div>
                 <div class="bold-heading mt-10">
                     DID YOU KNOW ?
                 </div>
                 <div class="mt-3 sub-content">
                    Newborns are at a greater health risk of being infected by bacterial and viral infections. The
right care products used during this time determine the health of the baby for many years
to come.

                 </div>
                  <div class="bold-heading mt-10">
                    WHY DO YOU NEED PRO CLEANING FOR YOUR CHILD’S HEALTHY GROWTH?
                 </div>
               <div class="mt-5 sub-content">
                   Non-toxic chemicals and green cleaning methods grant safety, hygiene, and comfort to
newly born babies and everyone else. It requires less manpower and is accomplished in
short time duration. BLEACH® makes sure of contamination and germs-free living
environment for your child’s healthy growth.
                 </div>
             </div>
         </div>
      </div>
  </div>
 
</div>
 <div v-if="selectlan =='ARABIC'">
      <div class="gray-bg-2">
      <div class="bcontainer">
         <div class="row py-15">
             <div class="col-md-4 col-lg-3">
                 <ul class="service-ui-ar show-head">

                    <li  @click="$router.push('domesticsector')" class="arabic-font"  >
                        <span class="dot-li-ar"></span>
                        قطاع األعمال المنزلية 
                    </li> 
                     <li class="service-ui-active arabic-font">
                         <div class="dot-li-ar-active"></div>
                       رعاية األطفال
                    </li>
                    <li @click="$router.push('moving')" class="arabic-font" >
                         <span class="dot-li-ar"></span>
                         أعمال انتقال المأجر
                    </li>
                     <li class="arabic-font" @click="$router.push('events')" >
                        <span class="dot-li-ar"></span>
                        رعاية المناسبات الخاصة
                    </li>
                     
                 </ul>
                 <resmenu :name="'رعاية األطفال'"/>
             </div>
             <div class="col-md-8 col-lg-9">
                 <div class="cleaning-head show-head arabic-font">رعاية <span class="w400">األطفال</span>  </div>
                   <div class="sub-content mt-2 arabic-font">
                 
                 توفر بليتش® أعلى مستوى من خدمات النظافة والتعقيم لحماية المواليد الجدد، والرضع، والقصر من التعرض للجراثيم المحيطة ببيئتهم المعيشية. 
                 <br><br>
                 إن منتجاتنا للتنظيف آمنة عن تعرض الأطفال لها كونها هي غير سامة بطبيعتها، وهي الأنسب لتنظيف غرفة طفلك وكذلك تطهير المكعبات والألعاب، وأي شيء آخر قد تحتاجه للحفاظ على سلامة الطفل وأمانه وسعادته. 
                 <br><br>
                 توفر بليتش® لموظفيها تدريباً متخصصاً (متوافق مع المعايير الإدارية لصناعة التنظيف  CIMS® والرابطة الدولية للتوريدات الصحية ISSA®) في احتياطات النظافة فيما يتعلق بالرضع، كما أنهم يدركون مدى أهمية البيئة النظيفة لأطفالك كمثل الغذاء المغذي لهم. 

                 </div>
                 <div class="bold-heading mt-10 arabic-font">
                     هل تعلم؟
                 </div>
                 <div class="mt-3 sub-content arabic-font">
                    الأطفال حديثو الولادة أكثر عرضة للإصابة بالعدوى البكتيرية والفيروسية، ومنتجات الرعاية المناسبة المستخدمة خلال هذه الفترة من عمرهم تحدد صحة الطفل لسنوات عديدة قادمة. 
                 </div>
                  <div class="bold-heading mt-10 arabic-font">
                    لماذا تحتاج إلى تنظيف احترافي لنمو طفلك الصحي؟
                 </div>
               <div class="mt-5 sub-content arabic-font">
                  تضمن بليتش® بيئة معيشية خالية من التلوث والجراثيم لنمو صحي لطفلك عبر استخدام مواد كيميائية غير السامة وطرق تنظيف صديقة للبيئة تمنح السلامة والنظافة والراحة للأطفال حديثي الولادة وأي أشخاص آخرين، متطلبة قوة بشرية أقل، ويتم إنجاز أعمال التنظيف في فترة زمنية قصيرة.
                 </div>
             </div>
         </div>
      </div>
  </div>
  
</div>

</div>
</template>

<script>
import resmenu from '../../components/menu/resmenu.vue'
export default {
    components:{
        resmenu
    },
     data(){
        return{
            selectlan:localStorage.getItem('bleach-lang')
        }
    },
    methods:{
         goto(loc) {
         window.location = loc;
        },
    }
}
</script>

<style>

</style>