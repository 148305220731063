<template>
  <div>
      <div class="d-flex">
          <div class="d-flex mr-2">
              <div class="step-circle ma" :class="step>=1?'active':''">
                  1
              </div>
            <div class="step-line">
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; 
            </div>
          </div>
          <div class="d-flex mr-2" >
              <div class="step-circle ma" :class="step>=2?'active':''">
                  2
              </div>
            <div class="step-line">
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; 
            </div>
          </div>
          <div class="d-flex">
              <div class="step-circle ma" :class="step==3?'active':''">
                  3
              </div>

          </div>
        </div>
  </div>
</template>

<script>
export default {
    props:['step']

}
</script>

<style>
.step-circle{
    border: 1px solid #e9ecef;
    border-radius: 50%;
    margin: 0 auto;
    padding-top: 11px;
    text-align: center;
    height: 41px;
    width: 41px;
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 18px;
text-align: center;
color: #6D6D6E;
}
.step-circle.active{
    color: #2B9FAD !important;
    background: #E0F6F9 !important;
border: 1px solid #2B9FAD !important;
}
</style>