<template>
  <div>
    <div class="gray-bg-2">
      <div class="bcontainer">
        <div class="pt-5 pb-15">
          <div class="page-head">Blog</div>
          <div class="row mt-10">
            <div class="col-md-6">
              <div class="mpr-5" @click="goto('blog-4')">
                <img
                  src="../../assets/images/blog/bleach-digital.png"
                  class="blog-img w-100 pointer"
                  alt=""
                />
              </div>
              <div class="mpr-5 mt-8">
                <div class="px-3">
                  <div class="blog-date text-uppercase">
                    Business <span class="w400">| NOVEMBER 02 2022 </span>
                  </div>
                  <div
                    class="sub-heading mt-3 pointer text uppercase"
                    @click="goto('blog-4')"
                  >
                    BLEACH® Goes Digital
                  </div>
                  <div class="service-content mt-2" style="line-height: 21px">
                    Established in the year 2013, BLEACH® is all set to unwind a
                    completely new cleaning experience for customers. The
                    best-in-class residential & commercial cleaning company in
                    Kuwait has been working rigorously to offer something
                    extravagant to its customer with a smooth customer journey
                    and improved services.
                  </div>
                  <div class="d-flex mt-5">
                    <div>
                      <v-icon class="mt-1" large color="">
                        mdi-account-circle
                      </v-icon>
                    </div>
                    <div class="pl-2 service-content pt-2">
                      <b>Bleach Newsdesk</b><br />
                    </div>
                  </div>
                  <div class="bleach-link mt-5" @click="goto('blog-1')">
                    Read more
                    <img
                      class="ml-5"
                      src="../../assets/icons/blue-arrow.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mpr-5" @click="goto('blog-1')">
                <img
                  src="../../assets/images/blog/traing.png"
                  class="blog-img w-100 pointer"
                  alt=""
                />
              </div>
              <div class="mpr-5 mt-8">
                <div class="px-3">
                  <div class="blog-date text-uppercase">
                    Business <span class="w400">| MARCH 12 2021 </span>
                  </div>
                  <div class="sub-heading mt-3 pointer" @click="goto('blog-1')">
                    TRAINING & DEVELOPMENT DURING COVID ERA
                  </div>
                  <div class="service-content mt-2" style="line-height: 21px">
                    Cleaning services have moved into the limelight due to the
                    COVID-19 pandemic accelerates and people are looking at
                    CLEANING FOR HEALTH rather than cleaning for appearance.
                  </div>
                  <div class="d-flex mt-5">
                    <div>
                      <v-icon class="mt-1" large color="">
                        mdi-account-circle
                      </v-icon>
                    </div>
                    <div class="pl-2 service-content pt-2">
                      <b>Bleach Newsdesk</b><br />
                    </div>
                  </div>
                  <div class="bleach-link mt-5" @click="goto('blog-1')">
                    Read more
                    <img
                      class="ml-5"
                      src="../../assets/icons/blue-arrow.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 mt-15">
              <div class="mpr-5" @click="goto('blog-3')">
                <img
                  src="../../assets/images/blog/tech.png"
                  class="blog-img w-100 pointer"
                  alt=""
                />
              </div>
              <div class="mpr-5 mt-8">
                <div class="blog-date mt-2 text-uppercase">
                  Business <span class="w400">| October 10 2022</span>
                </div>
                <div class="sub-heading pointer mt-3" @click="goto('blog-3')">
                  4 WAYS CLEANING SERVICES CAN ADAPT TO THE COVID-19 PANDEMIC
                </div>
                <div class="service-content mt-2" style="line-height: 21px">
                  As restrictions were at full force at the onset of the
                  COVID-19 pandemic, business owners had no choice but to
                  quickly adapt to the new reality of online sales and cashless
                  transactions.
                </div>
                <div class="d-flex mt-5">
                  <div>
                    <v-icon class="mt-1" large color="">
                      mdi-account-circle
                    </v-icon>
                  </div>
                  <div class="pl-2 service-content pt-2">
                    <b>Bleach Newsdesk</b><br />
                  </div>
                </div>
                <div class="bleach-link mt-5" @click="goto('blog-3')">
                  Read more
                  <img
                    class="ml-5"
                    src="../../assets/icons/blue-arrow.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6 mt-15">
              <div class="mpl-5" @click="goto('blog-2')">
                <img
                  src="../../assets/images/blog/safety.png"
                  class="blog-img w-100 pointer"
                  alt=""
                />
              </div>
              <div class="mpl-5 mt-8">
                <div class="blog-date mt-2 text-uppercase">
                  Safety <span class="w400">| MARCH 12 2021 </span>
                </div>
                <div class="sub-heading mt-3 pointer" @click="goto('blog-2')">
                  SAFETY DURING COVID
                </div>
                <div class="service-content mt-2" style="line-height: 21px">
                  One could still remember the day the first case of Covid-19
                  was recorded in Kuwait. Luckily, we were prepared and ready!
                  <br />
                  PPE has always been a main pillar in any cleaning service we
                  provide, long before the COVID-19 pandemic. Our main goal was
                  to ensure the safety of our employees against, but not limited
                  to, skin burns, eye injuries, inhaling hazardous fumes or
                  dust. However, the benefits are not only in the favor of the
                  employee. PPE ensured that the area is clean and germ-free,
                  guaranteeing our customers a premium and hygienic experience.
                </div>
                <div class="d-flex mt-5">
                  <div>
                    <v-icon class="mt-1" large color="">
                      mdi-account-circle
                    </v-icon>
                  </div>
                  <div class="pl-2 service-content pt-2">
                    <b>Bleach Newsdesk</b><br />
                  </div>
                </div>
                <div class="bleach-link mt-5" @click="goto('blog-2')">
                  Read more
                  <img
                    class="ml-5"
                    src="../../assets/icons/blue-arrow.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goto(loc) {
      window.location = loc;
    },
  },
};
</script>

<style>
</style>