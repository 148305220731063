<template>
  <div class="pb-15 mt-10">
    <div class="">
      <div class="d-flex">
        <div class="my-account pointer" @click="gotoMain('home')">Home</div>
        <div class="my-account ml-3 ma mr-3">
          <svg
            width="6"
            height="9"
            viewBox="0 0 6 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.3297 0.999986L4.65942 4.32971L1.3297 7.65944"
              stroke="#8A8A8A"
              stroke-width="1.17724"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div @click="gotoMain('my/orders')" class="my-account pointer">
          My Orders
        </div>
        <div class="my-account ml-3 ma mr-3">
          <svg
            width="6"
            height="9"
            viewBox="0 0 6 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.3297 0.999986L4.65942 4.32971L1.3297 7.65944"
              stroke="#8A8A8A"
              stroke-width="1.17724"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div
          v-if="orderData != null"
          class="my-account pointer"
          style="font-weight: 600"
        >
          <!-- {{ $route.params.id }} -->
          {{ orderData.order_data.order_no }}
        </div>
      </div>
      <div v-if="orderData != null">
        <div class="order-box px-4 px-sm-10 mt-6">
          <div class="row">
            <div class="col-md-8">
              <div class="order-details-head">
                Order Details &nbsp;
                <span class="quation-text" @click="openQuation()"
                  >View Quotation</span
                >
              </div>
              <div class="order-stl-num">
                {{ orderData.order_data.order_no }}
              </div>
              <div class="row no-gutters">
                <div class="col-md-6 mt-2">
                  <div class="row no-gutters mb-3">
                    <div class="col-4 col-md-5 service-content">Booking ID</div>
                    <div
                      class="col-8 col-md-7 service-content"
                      style="color: #535355"
                    >
                      - {{ orderData.booking_id }}
                    </div>
                  </div>
                  <div class="row no-gutters mb-3">
                    <div class="col-4 col-md-5 service-content">Status</div>
                    <div
                      class="col-8 col-md-7 service-content"
                      style="color: #535355"
                    >
                      - {{ getStatus(orderData.order_data.order_status) }}
                    </div>
                  </div>
                  <div class="row no-gutters mb-3">
                    <div class="col-4 col-md-5 service-content">Plan</div>
                    <div
                      class="col-8 col-md-7 service-content"
                      style="color: #535355"
                    >
                      - {{ orderData.policy }}
                    </div>
                  </div>

                  <div class="row no-gutters mb-3">
                    <div class="col-4 col-md-5 service-content">
                      No of visit
                    </div>
                    <div
                      class="col-8 col-md-7 service-content"
                      style="color: #535355"
                    >
                      - {{ orderData.total_visits }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 no-gutters mt-2">
                  <div class="row no-gutters mb-3">
                    <div class="col-4 col-md-5 service-content">Start Date</div>
                    <div
                      class="col-8 col-md-7 service-content"
                      style="color: #535355"
                    >
                      - {{ orderData.start_date }}
                    </div>
                  </div>
                  <!-- <div class="row no-gutters mb-3">
                    <div class="col-6 service-content">Visit Time</div>
                    <div class="col-6 service-content" style="color: #535355">
                      - {{ orderData.start_time }}
                    </div>
                  </div> -->
                  <div class="row no-gutters mb-3">
                    <div class="col-4 col-md-5 service-content">End Date</div>
                    <div
                      class="col-8 col-md-7 service-content"
                      style="color: #535355"
                    >
                      - {{ orderData.end_date }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="status-card">
                <div class="d-flex">
                  <div class="service-content ma ml-0">Completed</div>
                  <div class="complete-nubmer">
                    {{ orderData.completed_visits }}/<span
                      style="font-size: 18px"
                      >{{ orderData.total_visits }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="status-card">
                <div class="d-flex">
                  <div class="service-content ma ml-0">Previous Visit</div>
                  <div class="complete-nubmer" style="line-height: 18px">
                    <span style="font-size: 18px">{{
                      orderData.previous_visit.split(" ")[0]
                    }}</span>
                    <br />
                    <span class="service-content"
                      >{{ orderData.previous_visit.split(" ")[1] }}
                      {{ orderData.previous_visit.split(" ")[2] }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="status-card">
                <div class="d-flex">
                  <div class="service-content ma ml-0">Upcoming Visit</div>
                  <div class="complete-nubmer" style="line-height: 18px">
                    <span style="font-size: 18px">{{
                      orderData.upcoming_visit.split(" ")[0]
                    }}</span>
                    <br />
                    <span class="service-content"
                      >{{ orderData.upcoming_visit.split(" ")[1] }}
                      {{ orderData.upcoming_visit.split(" ")[2] }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="order-details-head mx-10 mt-5 mb-4">Service Details</div>
        <Collapsible
          v-for="(item, index) in orderSection"
          :key="index"
          class="example-collapsible mb-5"
          :isOpen="false"
        >
          <div slot="trigger" class="expand-header">
            <div class="customTrigger d-flex">
              <div
                class="service-content"
                style="font-size: 16px; flex-grow: 1"
              >
                {{ item.service_type.name }}
              </div>
              <div>
                <img
                  class="mr-1 fa-arrow-down-open"
                  src="../../assets/ar.png"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div slot="closedTrigger" class="expand-header">
            <div class="customTrigger d-flex">
              <div
                class="service-content"
                style="font-size: 16px; flex-grow: 1"
              >
                {{ item.service_type.name }}
              </div>
              <div>
                <img class="mr-1" src="../../assets/ar.png" alt="" />
              </div>
            </div>
          </div>

          <div id="example2" class="expand-body">
            <div
              class="row  pt-4"
              v-for="(section, index) in item.evaluationsection_book"
              :key="index"
            >
              <div class="col-md-7 " style="padding-bottom:30px!important">
                <div
                  class="row service-content pl-3 "
                  style="font-size: 16px"
                >
                  Section {{ index + 1 }}
                </div>
                <div class="row">
                  <div class="col-md-6 mt_pd" v-if="section.size != null">
                    <div class="row no-gutters w-100 mt_pd">
                      <div class="col-6 service-content">Size</div>
                      <div class="col-6 service-content" style="color: #535355">
                        - &nbsp; {{ section.size }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mt_pd" v-if="section.age != null">
                    <div class="row no-gutters w-100 mt_pd">
                      <div class="col-6 service-content">Age</div>
                      <div class="col-6 service-content" style="color: #535355">
                        - &nbsp; {{ section.size }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mt_pd" v-if="section.floor != null">
                    <div class="row no-gutters w-100 mt_pd " >
                      <div class="col-6 service-content">Floor</div>
                      <div class="col-6 service-content" style="color: #535355">
                        - &nbsp; {{ section.floor }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mt_pd" v-if="section.age_of_stain != null">
                    <div class="row no-gutters w-100 mt_pd">
                      <div class="col-6 service-content">Age of stain</div>
                      <div class="col-6 service-content" style="color: #535355">
                        - &nbsp; {{ section.age_of_stain }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mt_pd" v-if="section.apartment != null">
                    <div class="row no-gutters w-100 mt_pd">
                      <div class="col-6 service-content">Apartment</div>
                      <div class="col-6 service-content" style="color: #535355">
                        - &nbsp; {{ section.apartment }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mt_pd" v-if="section.category != null">
                    <div class="row no-gutters w-100 mt_pd">
                      <div class="col-6 text-cap service-content">category</div>
                      <div class="col-6 service-content" style="color: #535355">
                        - &nbsp; {{ section.category }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mt_pd" v-if="section.cause_of_stain != null">
                    <div class="row no-gutters w-100 mt_pd">
                      <div class="col-6 text-cap service-content">
                        cause of stain
                      </div>
                      <div class="col-6 service-content" style="color: #535355">
                        - &nbsp; {{ section.cause_of_stain }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mt_pd" v-if="section.ceiling_type != null">
                    <div class="row no-gutters w-100 mt_pd">
                      <div class="col-6 text-cap service-content">
                        ceiling type
                      </div>
                      <div class="col-6 service-content" style="color: #535355">
                        - &nbsp; {{ section.ceiling_type }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mt_pd" v-if="section.colour != null">
                    <div class="row no-gutters w-100 mt_pd">
                      <div class="col-6 text-cap service-content">colour</div>
                      <div class="col-6 service-content" style="color: #535355">
                        - &nbsp; {{ section.colour }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mt_pd" v-if="section.dirt_level != null">
                    <div class="row no-gutters w-100 mt_pd">
                      <div class="col-6 text-cap service-content">
                        dirt level
                      </div>
                      <div class="col-6 service-content" style="color: #535355">
                        - &nbsp; {{ section.dirt_level }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mt_pd" v-if="section.floor_type != null">
                    <div class="row no-gutters w-100 mt_pd">
                      <div class="col-6 text-cap service-content">
                        floor type
                      </div>
                      <div class="col-6 service-content" style="color: #535355">
                        - &nbsp; {{ section.floor_type }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mt_pd" v-if="section.hall_size != null">
                    <div class="row no-gutters w-100 mt_pd">
                      <div class="col-6 text-cap service-content">
                        Hall size
                      </div>
                      <div class="col-6 service-content" style="color: #535355">
                        - &nbsp; {{ section.hall_size }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mt_pd" v-if="section.material != null">
                    <div class="row no-gutters w-100 mt_pd">
                      <div class="col-6 text-cap service-content">material</div>
                      <div class="col-6 service-content" style="color: #535355">
                        - &nbsp; {{ section.material }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mt_pd" v-if="section.quantity != null">
                    <div class="row no-gutters w-100 mt_pd">
                      <div class="col-6 text-cap service-content">quantity</div>
                      <div class="col-6 service-content" style="color: #535355">
                        - &nbsp; {{ section.quantity }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mt_pd" v-if="section.room != null">
                    <div class="row no-gutters w-100 mt_pd">
                      <div class="col-6 text-cap service-content">room</div>
                      <div class="col-6 service-content" style="color: #535355">
                        - &nbsp; {{ section.room }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mt_pd" v-if="section.upholstery_type != null">
                    <div class="row no-gutters w-100 mt_pd">
                      <div class="col-6 text-cap service-content">
                        upholstery type
                      </div>
                      <div class="col-6 service-content" style="color: #535355">
                        - &nbsp; {{ section.upholstery_type }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mt_pd" v-if="section.wall_type != null">
                    <div class="row no-gutters w-100 mt_pd">
                      <div class="col-6 text-cap service-content">
                        wall type
                      </div>
                      <div class="col-6 service-content" style="color: #535355">
                        - &nbsp; {{ section.wall_type }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mt_pd" v-if="section.window_side != null">
                    <div class="row no-gutters w-100 mt_pd">
                      <div class="col-6 text-cap service-content">
                        window side
                      </div>
                      <div class="col-6 service-content" style="color: #535355">
                        - &nbsp; {{ section.window_side }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mt_pd" v-if="section.section_cost != null">
                    <div class="row no-gutters w-100 mt_pd">
                      <div class="col-6 text-cap service-content">
                        section cost
                      </div>
                      <div class="col-6 service-content" style="color: #535355">
                        - &nbsp; {{ section.section_cost }} KD
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div
                v-if="index + 1 < item.evaluationsection_book.length"
                class="col-md-12 mt_pd "
              >
                <hr class="mt_pd" />
              </div>
            </div>
          </div>
        </Collapsible>

        <div class="mx-sm-10 mx-3">
          <div class="order-details-head mt-5 mb-4">Payment Details</div>
          <div class="row">
            <div class="col-md-8">
              <div class="row no-gutters">
                <div class="col-md-6 mt-2">
                  <div class="row no-gutters mb-3">
                    <div class="col-6 service-content">Payment Policy</div>
                    <div class="col-6 service-content" style="color: #535355">
                      - &nbsp; {{ evaluation_info.payment_method }}
                    </div>
                  </div>
                  <div class="row no-gutters mb-3">
                    <div class="col-6 service-content">Paid</div>
                    <div class="col-6 service-content" style="color: #535355">
                      - &nbsp; {{ orderData.order_data.amount_paid }} KD
                    </div>
                  </div>
                  <div class="row no-gutters mb-3">
                    <div class="col-6 service-content">Balance</div>
                    <div class="col-6 service-content" style="color: #535355">
                      - &nbsp; {{ orderData.order_data.remining_amount }} KD
                    </div>
                  </div>
                  <div v-if="orderData.payment_type" class="row no-gutters mb-3">
                    <div class="col-6 service-content">Payment Type</div>
                    <div class="col-6 service-content" style="color: #535355">
                      - &nbsp; {{ orderData.payment_type }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mt-2">
                  <div
                  v-if="evaluation_info.estimated_cost != 0"
                  class="row no-gutters mb-3"
                >
                  <div class="col-6 service-content">Gross Amount</div>
                  <div class="col-6 service-content" style="color: #535355">
                    - &nbsp; {{ evaluation_info.estimated_cost }} KD
                  </div>
                </div>

                  <div  v-if="evaluation_info.discount != 0" class="row no-gutters mb-3">
                    <div class="col-6 service-content">Total Amount</div>
                    <div class="col-6 service-content" style="color: #535355">
                      - &nbsp; {{ orderData.net_amount +  orderData.discount}} KD
                    </div>
                  </div>
                  <div
                    v-if="orderData.discount != 0"
                    class="row no-gutters mb-3"
                  >
                    <div class="col-6 service-content">Discount</div>
                    <div class="col-6 service-content" style="color: #535355">
                      - &nbsp; {{ orderData.discount }} KD
                    </div>
                  </div>
                  
                  <div
                    v-if="evaluation_info.fine_amount != 0"
                    class="row no-gutters mb-3"
                  >
                    <div class="col-6 service-content">Fine</div>
                    <div class="col-6 service-content" style="color: #535355">
                      - &nbsp; {{ evaluation_info.fine_amount }} KD
                    </div>
                  </div>

                  <div
                    v-if="evaluation_info.writeback_amount != 0"
                    class="row no-gutters mb-3"
                  >
                    <div class="col-6 service-content">Write Off</div>
                    <div class="col-6 service-content" style="color: #535355">
                      - &nbsp; {{ evaluation_info.writeback_amount }} KD
                    </div>
                  </div>
                  <div
                    v-if="evaluation_info.promocode_amount != 0"
                    class="row no-gutters mb-3"
                  >
                    <div class="col-6 service-content">Promocode Amount</div>
                    <div class="col-6 service-content" style="color: #535355">
                      - &nbsp; {{ evaluation_info.promocode_amount }} KD
                    </div>
                  </div>
                  <div
                  class="row no-gutters mb-3"
                >
                  <div class="col-6 service-content">Net Amount</div>
                  <div class="col-6 service-content" style="color: #535355">
                    - &nbsp; {{ orderData.net_amount }} KD
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import service from "../../services/service";
import "vue-collapsible-component/lib/vue-collapsible.css";
import Collapsible from "vue-collapsible-component";
export default {
  components: {
    Collapsible,
  },
  created() {
    this.getOrderData();
  },
  data() {
    return {
      orderData: null,
      orderSection: [],
      evaluation_info: null,
    };
  },
  methods: {
    getStatus(status) {
      if (status == "APPROVED_BY_CLIENT") return "Approved";
      else if (status == "ORDER_IN_PROGRESS") return "In Progress";
      else if (status == "CANCEL_IN_PROGRESS") return "Cancel In Progress";
      else if (status == "ORDER_CANCELLED") return "Cancelled";
      else if (status == "ORDER_CLOSED") return "Closed";
    },
    gotoMain(loc) {
      window.location = window.location.href.split("/")[0] + "/" + loc;
    },
    openQuation() {
      window.open(
        service.api + "customer/quatation/" + this.orderData.quotationURL
      );
    },
    async getOrderData() {
      let result = await service._get(
        "api/customer-order-details/" + this.$route.params.id
      );
      if (result.status == 200) {
        this.orderData = result.data.data;
        this.orderSection = this.orderData.evaluation_details;
        this.evaluation_info = this.orderData.evaluation_info[0];
      }
      console.log(result);
    },
  },
};
</script>

<style>
.mt_pd{
    padding-top:0px!important;
    margin-top:0px!important;
    padding-bottom:0px!important;
    margin-bottom:0px!important
}
.expand-body {
  padding: 7px 44px;
}
.expand-header {
  background: #f1f3f5;
  padding: 7px 44px;
}
.order-box {
  background: #f1f3f5;
  border: 1px solid #c4c4c4;
  padding: 35px 35px;
}
.order-details-head {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 38px;
  text-transform: capitalize;
  color: #535355;
}
.status-card {
  background: #fdfeff;
  border: 1px solid #c4c4c4;
  padding: 12px 28px 9px 31px;
  margin-bottom: 13px;
  margin-left: 20px;
}
.complete-nubmer {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 42px;

  text-align: center;
  text-transform: capitalize;
  color: #8a8a8a;
}
.quation-text {
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;

  color: #357aff;
}
.order-stl-num {
  font-style: normal;
  font-weight: 600;
  font-size: 18.8546px;
  line-height: 28px;
  color: #8a8a8a;
}
.text-cap {
  text-transform: capitalize;
}


@media screen and (max-width: 500px) {
  .status-card {
    background: #fdfeff;
    border: 1px solid #c4c4c4;
    padding: 12px 28px 9px 31px;
    margin-bottom: 13px;
    margin-left: 0px !important;
  }
  .expand-body {
  padding: 7px 20px;
}
}
</style>