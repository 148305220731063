
<template>
  <div>
  <div>
      <section class=""  >
         <div class="bg-header-img-residential bg-header  ">
         </div>
      </section>
  </div>
    <div v-if="selectlan=='EN'">
     
         <div class="bcontainer">
      <div class="py-15">
        <div class="page-head">
          Residential
           
        </div>
        <div class="sub-content  mt-2 text-left">
          We at BLEACH<sup>®</sup> care for our customer’s needs just like you care for your residence. Our
highly skilled cleaners not only clean your residence but also restore its true essence for you
to relish family time and moments in a healthy environment.
        </div>
      </div>
      <div>
   

      </div>
    </div>
       <router-view/>
  </div>
  <div v-if="selectlan=='ARABIC'" dir="rtl">
    
         <div class="bcontainer">
      <div class="py-15">
        <div class="page-head arabic-font" dir="rtl">
          خدمات نظافة
           <span class="w400">العقارات السكنية</span>
        </div>
        <div class="sub-content mt-2 text-right arabic-font">
         كما تهتم أنت بمحل إقامتك، فنحن في بليتش® نهتم باحتياجات عملائنا، فعمال التنظيف ذوو المهارات العالية لا يقومون بتنظيف منزلك فحسب، بل يعيدون اليه جوهره الحقيقي لتستمتع أنت بأوقات ولحظات عائلية ممتعة في بيئة صحية. 
        </div>
      </div>
      <div>
   

      </div>
    </div>
       <router-view/>
  </div>
  </div>
</template>

<script>
//import pageheading from '../../components/pageheading.vue'
export default {
    components:{},
    data(){
        return{
            selectlan:localStorage.getItem('bleach-lang')
        }
    },

}
</script>

<style>

</style>