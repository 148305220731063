<template>
  <div>
    <div id="id_address" class="gray-bg-2" v-if="!eval_succes">
      <div class="bcontainer">
        <div class="pt-10 pb-15">
          <stepper :step="3" />
          <div class="sub-heading mt-5" style="text-transform: none">
            Special Instructions
          </div>
          <div class="service-content">
            We love to hear from you. Please add additional details for our Evaluator to take care of.
          </div>
          <div class="row mt-8">
            <div class="col-md-12">
              <div style="color: #8a8a8a" class="service-content">Note</div>
              <textarea
              v-model="note"
                name=""
                class="mt-2"
                id="note"
                cols="30"
                rows="10"
              ></textarea>
              <!-- <div class="d-flex mt-2">
               <div>
                  <label class="checkcontainer submenu-items" style="margin-top:3px;padding-left:10px">
                  <input v-model="evaluation.is_assistance_needed" @click="changeAssistant()"  type="checkbox" name="kitch"  >
                  <span class="checkmark"></span>
                </label>
               </div>
                <div class="ml-4">
                  <span class="service-content f-16">
                    Need assistant from us after booking
                  </span>
                  <br>
                  <span class="service-content">
                    Our customer care executive will call you after your booking for any help related to booking
readjustment, rescheduling, or for support to fill in the instructions for the Evaluator.
                  </span>
                </div>
              </div> -->
            </div>
             <v-alert
             v-if="errorFlag"
             class="mt-5"
      dense
      text
      type="error"
    >
     <span class="red-color"> Something went wrong try again</span>
    </v-alert>
            <div class="col-md-2 col-lg-2 offset-md-10">
              <div class="pb-6 mt-7 h-100 d-flex flex-column">
                <button
                  type="button"
                  class="align-self-end bl-secondary-btn w-100"
                  style="margin-top: auto"
                  @click="backToAdd()"
                >
                  Back
                </button>
                <button
                v-if="!loader"
                 @click="bookEvalution()"
                  type="button"
                  class="align-self-end bl-primary-btn w-100"
                  style="margin-top: 20px"
                >
                  Next
                </button>
                <div   style="margin-top: 20px" class="text-center" v-if="loader">
                   <v-progress-circular
                  
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="id_address" class="gray-bg-2" v-else>
      <div class="bcontainer">
        <div class="py-15">
            <div class="text-center success-head">
                Congratulations! You Have Successfully Booked an Evaluator Visit.
            </div>
            <div class="mpx-20 mt-10">
                <div class="mpx-20">
                    <div style="background:white">
                        <div class="el-success-head">
                            Booking Summery
                        </div>
                        <div class="pa-6">
                            <div class="d-flex">
                                <div class="service-content full-flex text-right pr-10" style="line-height:25px">
                                Customer Name
                                <br>
                                    Booking ID
                                    <br> 
                                    Visit Day
                                    <br>
                                     Visit Time
                                    <br> 
                                    <!-- Visit Address -->
                                </div>
                                <div class="service-content" style="line-height:25px">
                                :
                                <br>
                                    :
                                    <br>
                                    :
                                    <br>
                                    :
                                    <br>
                                    <!-- : -->
                                </div>
                                <div class="service-content full-flex pl-10" v-if="$store.state.customer != null" style="line-height:25px">
                                {{$store.state.customer.name}}
                                <br>
                                    {{eval_data.booking_id}}
                                    <br>
                                    {{eval_data.visit_date_time.split(' ')[0]}}
                                    <br>
                                      {{eval_data.visit_date_time.split(' ')[1]}} {{eval_data.visit_date_time.split(' ')[2]}} 
                                    <br>
                                  
                                        <!-- {{eval_data.address.apartment}},
                                        {{eval_data.address.floor}},
                                        {{eval_data.address.building}},
                                        {{eval_data.address.block}},
                                        {{eval_data.address.street}},
                                        {{eval_data.address.avenue}},
                                        {{eval_data.address.area.name}},
                                        {{eval_data.address.governorate.name}} -->
                                </div>
                            </div>
                            <div class="d-flex" >
                                <div class="service-content full-flex text-right pr-10" style="width:55%;line-height:25px">
                                   
                                    Visit Address
                                </div>
                                <div class="service-content" style="line-height:25px">
                                    :
                                   
                                </div>
                                <div class="service-content full-flex pl-10" style="line-height:25px;width:50%;">
                                    
                                  
                                        {{eval_data.address.apartment}},
                                        {{eval_data.address.floor}},
                                        {{eval_data.address.building}},
                                        {{eval_data.address.block}},
                                        {{eval_data.address.street}},
                                        {{eval_data.address.avenue}},
                                        {{eval_data.address.area.name}},
                                        {{eval_data.address.governorate.name}}
                                </div>
                            </div>
                            <div class="text-center success-in mt-10">
                                Instruction for evaluator
                            </div>
                            <div class="service-content text-center mpx-20">
                               {{eval_data.evaluation_note}}
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center mt-10">
                <svg width="22" height="30"  viewBox="0 0 22 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22 30H0V27H22V30ZM11 24L1.57143 15L3.78714 12.885L9.42857 18.255V0H12.5714V18.255L18.2129 12.885L20.4286 15L11 24Z" fill="#2B9FAD">
   
</path>
</svg>

            </div>
            <div class="text-center">
                 <button
                 @click="downloadPdf()"
                  type="button"
                  class="align-self-end bl-primary-btn "
                  style="margin-top: 10px"
                >
                  Download Recipt
                </button>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import service from "../../services/service";
import { mapState } from "vuex";
import stepper from "../../components/stepper.vue";
export default {
  components: {
    stepper,
  },
  mounted(){
     if(this.evaluationStep != 3){
        this.$router.push('timeslots')
      }
       var element = document.getElementById("id_address");
      var top = element.offsetTop;
      window.scrollTo(0, top-60);
  },
  computed: {
      ...mapState({
      evaluationStep: state => state.evaluationStep,
      evaluation: state => state.evaluation,
    })
  },
  data() {
    return {
         errorFlag:false,
      loader:false,
      checked: false,
      note:'',
      eval_succes:false,
      eval_data:null
    };
  },
  methods: {
    changeAssistant(){
      this.$store.commit("set_eval_assistn");
    },
    backToAdd(){
       this.$store.commit("set_evalutionStep", 2);
      this.$router.push('address')
    },
    async downloadPdf(){
      // let result = await service._get('customer/customer-evaluation-booking/download/'+this.eval_data.booking_id);
      // console.log(result);
      window.location.href = service.api+'customer/customer-evaluation-booking/download/'+this.eval_data.booking_id
    },
    async bookEvalution(){
      this.$store.commit("set_eval_note", this.note);
      this.loader = true
      this.errorFlag = false
      let result = await service._post('api/evaluation-booking-submit/',this.evaluation);
      this.loader = false

      console.log(result)
      if(result.status == 200){
        if(result.data.success){
          this.$store.commit("clearEvalution");
          this.$store.commit("set_evalutionStep", 1);
          this.eval_succes = true
          this.eval_data = result.data
          var element = document.getElementById("id_address");
      var top = element.offsetTop;
      window.scrollTo(0, top-60);

        }
      }else{
      this.errorFlag = true

      }

    }
  },
};
</script>
<style lang="scss"></style>
