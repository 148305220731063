<template>
  <div>

  </div>
</template>

<script>
export default {
mounted(){
    this.$store.commit("set_cart", true);
    this.$router.push('/')
}
}
</script>

<style>

</style>