<template>
  <div>
      <div v-if="selectlan == 'EN'" class="gray-bg-2">
        <div class="bcontainer">
            <div class="row py-15">
                <div class="col-md-4 col-lg-3">
                    <ul class="service-ui show-head">
                        <li  class="service-ui-active"  >
                        <div class="dot-li-active"></div>
                            General Questions
                        </li> 
                        <li @click="$router.push('service')" >
                            <span class="dot-li"></span>
                            Service Procedure
                        </li>
                        <li @click="$router.push('technical')" >
                            <span class="dot-li"></span>
                            Technical Questions
                        </li>
                    </ul>
                     <div  class="show-callus px-5">
               <div class=" py-2 mb-3" style="border-bottom:1px solid #C4C4C4;">
              <div class="d-flex pointer " @click="dc = !dc">
                 <div class="mobile-sub-link mobile-sub-header h-100 ma">
                 General Questions
               </div>
               <div>
                 <img class="mr-1" :class="{ 'fa-arrow-down-open': dc }" src="../../assets/ar.png" alt="">
               </div>
              </div>
            </div>
            <div class="drop-service">
               <transition-expand>
              <div v-if="dc" >
                <ul class="drop-list">

                    <li  @click="$router.push('service')">
                        <a>
                                    Service Procedure
                        </a>
                    </li>
                    <li  @click="$router.push('technical')">
                        <a>
                          Technical Questions
                          </a>
                    </li>

                </ul>
              </div> 
            </transition-expand>
        </div>
</div>
                </div>
                 <div class="col-md-8 col-lg-9">
                    <div class="px-5">
                         <expanadcard v-for="(item,index) in qs" :key="index" :qstn="item.qstn" :ans="item.answer" />

                    </div>
                   

                 </div>
            </div>
        </div>
      </div>
        <div v-if="selectlan == 'ARABIC'" class="gray-bg-2">
        <div class="bcontainer">
            <div class="row py-15">
                <div class="col-md-4 col-lg-3">
                    <ul class="service-ui-ar show-head">
                        <li  class="service-ui-active arabic-font"  >
                        <div class="dot-li-ar-active "></div>
                            الأسئلة العامة
                        </li> 
                        <li @click="$router.push('service')"  class="arabic-font">
                            <span class="dot-li-ar"></span>
                            أسئلة إجراءات الخدمة
                        </li>
                        <li @click="$router.push('technical')" class="arabic-font" >
                            <span class="dot-li-ar"></span>
                            الأسئلة الفنية
                        </li>
                    </ul>
                     <div  class="show-callus px-5">
               <div class=" py-2 mb-3" style="border-bottom:1px solid #C4C4C4;">
              <div class="d-flex pointer " @click="dc = !dc">
                 <div class="mobile-sub-link mobile-sub-header h-100 ma">
                الأسئلة العامة
               </div>
               <div>
                 <img class="mr-1" :class="{ 'fa-arrow-down-open': dc }" src="../../assets/ar.png" alt="">
               </div>
              </div>
            </div>
            <div class="drop-service">
               <transition-expand>
              <div v-if="dc" >
                <ul class="drop-list">

                    <li   @click="$router.push('service')">
                        <a class="arabic-font">
                                    أسئلة إجراءات الخدمة
                        </a>
                    </li>
                    <li  @click="$router.push('technical')">
                        <a class="arabic-font">
                          الأسئلة الفنية
                          </a>
                    </li>

                </ul>
              </div> 
            </transition-expand>
        </div>
</div>
                </div>
                 <div class="col-md-8 col-lg-9">
                    <div class="px-5">
                         <expanadcard v-for="(item,index) in ArQs" :key="index" :qstn="item.qstn" :ans="item.answer" />

                    </div>
                   

                 </div>
            </div>
        </div>
      </div>
  </div>
</template>

<script>
    import expanadcard from '../../components/expanadcard.vue'
export default {
    components:{
        expanadcard
    },
    data(){
        return{
            selectlan: localStorage.getItem("bleach-lang"),
            dc:false,
            qs:[
                {
    
    qstn:'What are monthly subscriptions?',
    answer:'Our customizable Monthly Subscription Service allows you to avail your preferred cleaning service without the need for repetitive booking. Our cleaning team saves a slot for you on your requested date(s) every month and performs the cleaning procedures.'
},
{
    
    qstn:'Do I need to be present with the cleaners while cleaning?',
    answer:'No! Your assets are safe in the hands of our professional cleaners. Plus, we provide insurance coverage to clients’ assets, safeguarding them from any mishap and accidental damage at the time of our service visit.'
},
{
    qstn:'How are the prices of cleaning services determined?',
    answer:'We offer customized cleaning services since every space has unique needs. The prices are determined based on the coverage area and material used to clean the space. Our Inspection team surveys the area upon appointment and gives you a quotation based on those factors.'
},
{
    qstn:'What if my asset is damaged during a cleaning visit?',
    answer:'We at BLEACH® have professionally trained cleaners. We also offer insurance coverage for any mishap or damage during our service visit.'
},
{
    qstn:'Do you provide service for sanitizing kids’ rooms and their toys?',
    answer:'Yes. BLEACH® pays special attention when it comes to a child’s safety. We use only all-natural, non-toxic (Green Seal/EPA/MOH certified) products. Our special range of cleaning materials reduces the health risks and hazards of conventional cleaning products to children'
},
{
    qstn:'What is the nationality of cleaners?',
    answer:'All cleaners in the company are of the Indian, Nepali, and Filipino nationalities.'
},
{
    qstn:'Do you have a laundry service?',
    answer:'We apologize, we do not have a laundry service within the company services.'
},
{
    qstn:'Do you have a female cleaning team?',
    answer:'Yes, available for simple cleaning contracts, such as inhabited apartments, bedrooms, and bathrooms.'
},
{
    qstn:'Do you sanitize cars?',
    answer:'Yes, we do.'
},
{
    qstn:'I have an allergy or asthma, do sanitization materialshave an odor?',
    answer:'No, they are odorless.'
},
{
    qstn:'Can I request a delay to the service visit or reschedule it?',
    answer:'Yes. Customers may request a service visit delay with NO extra charge, under the condition of not rescheduling the visit to an earlier appointment.'
},
{
    qstn:'Is service visit rescheduling chargeable?',
    answer:'Visit delay requests MUST be done at least 48 hours before the original visit date/time otherwise an extra charge of 10% from the order original invoice will be mandated for payment to reschedule such a delay.'
},
{
    qstn:'Will I get a refund if I cancel the service visit?',
    answer:'Customers may request a service visit cancellation at least 24 hours before the service visit date/time where they may get a full refund of their order price through the same method by which the payment was processed. If the service visit cancellation is requested less than 24 hours prior to the service date/time, an extra charge of 20% from the order original invoice will be mandated to pay to get the refund of the invoice.'
}
            ],
            ArQs:[
                {
                    qstn:'ما الاشتراكات الشهرية؟',
                    answer:'تتيح لك خدمة الاشتراك الشهري القابلة للتخصيص الاستفادة من خدمة التنظيف المناسبة لك دون الحاجة إلى الحجز المتكرر. يحتفظ فريق التنظيف بسجل خاص بك يتضمن الموعد (المواعيد) المطلوبة كل شهر وينفّذ إجراءات التنظيف تبعاً لذلك.'
                },
                {
                    qstn:'هل أحتاج إلى التواجد مع عمال النظافة أثناء التنظيف؟',
                    answer:'لا! نضمن لك بقاء ممتلكاتك آمنة في أيدي عمال النظافة المحترفين. ونقدم تغطية تأمينية لأصول العملاء، ونحميهم من وقوع أي حوادث أو أضرار عرضية أثناء تقديم خدماتنا لهم. '
                },
                {
                    qstn:'كيف تحدد أسعار خدمات التنظيف؟',
                    answer:'نحن نقدم خدمات تنظيف مخصصة لأن كل مسكن يتطلب عمليات تنظيف تلائمه. وتحدد الأسعار بناءً على مساحة العمل المطلوبة والمواد المستخدمة لتنظيف المسكن. يقوم فريق التفتيش بمسح المنطقة بعد تحديد موعد ويقدم لك عرض أسعار بناءً على تلك العوامل.'
                },
                {
                    qstn:'ماذا لو تضررت أصولي أثناء زيارة فريق التنظيف؟',
                    answer:'لدينا في بليتش® منظفين مدربين بشكل احترافي. نقدم أيضًا تغطية تأمينية لأي حادث أو ضرر أثناء زيارة عملائنا.'
                },
                {
                    qstn:'هل تقدم خدمة تعقيم غرف الأطفال وألعابهم؟',
                    answer:'نعم. تولي شركة بليتش® اهتمامًا خاصًا عندما يتعلق الأمر بسلامة الطفل. نستخدم فقط منتجات طبيعية وغير سامة (منظمة غرين سيل/معتمدة من وكالة حماية البيئة/وزارة الصحة). تقلل مجموعتنا الخاصة من مواد التنظيف من المخاطر الصحية ومخاطر منتجات التنظيف التقليدية على الأطفال.'
                },
                {
                    qstn:'ما جنسية عمال النظافة؟',
                    answer:'جميع عمال النظافة في الشركة من الجنسيات الهندية والنيبالية والفلبينية.'
                },
                {
                    qstn:'هل لديك خدمة غسيل الملابس؟',
                    answer:'نعتذر، ليس لدينا خدمة غسيل ملابس ضمن خدمات الشركة.'
                },
                {
                    qstn:'هل لديك فريق تنظيف نسائي؟',
                    answer:'نعم، متاح لعقود التنظيف البسيط، مثل الشقق المأهولة وغرف النوم والحمامات.'
                },
                {
                    qstn:'هل لديكم خدمة تعقيم السيارات؟',
                    answer:'نعم. لدينا.'
                },
                {
                    qstn:'لدي حساسية أو ربو، هل مواد التعقيم المستخدمة لها رائحة؟',
                    answer:'لا، بل عديمة الرائحة.'
                },
                {
                    qstn:'هل يمكنني طلب تأجيل موعد الخدمة أو إعادة جدولتها؟',
                    answer:'نعم. يجوز للعملاء طلب تأجيل موعد الخدمة دون أي رسوم إضافية، شرط عدم إعادة جدولة الزيارة إلى موعد سابق.'
                },
                {
                    qstn:'هل إعادة جدولة زيارة الخدمة تتطلب دفع رسوم؟',
                    answer:'يجب إجراء طلبات تأجيل الزيارة قبل 48 ساعة على الأقل من موعد/وقت الزيارة الأصلي وإلا ستفرض رسوم إضافية بنسبة 10 ٪ من فاتورة الطلب الأصلية تدفع لإعادة جدولة هذا التأجيل.'
                },
                {
                    qstn:'هل أسترد أموالي إذا ألغيت الخدمة؟',
                    answer:'يجوز للعملاء طلب إلغاء الخدمة قبل 24 ساعة على الأقل من موعد/وقت زيارة الخدمة حيث يمكنهم استرداد كامل المبلغ من خلال طريقة معالجة الدفع نفسها. وإذا طلب العميل إلغاء موعد الخدمة قبل أقل من 24 ساعة من تاريخ/وقت الخدمة، تُفرض رسوم إضافية بنسبة 20 ٪ من الفاتورة الأصلية تدفع عند استرداد الفاتورة.'
                }
            ]
        }
    }
}
</script>

<style>

</style>