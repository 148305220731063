<template>
  <div>
      <div>
      <section class=""  >
         <div class="bg-header-img-career bg-header  ">
         </div>
      </section>
  </div>
    <div v-if="selectlan == 'EN'">
    <div class="bcontainer">
      <div class="py-15">
          <div class="page-head ">
                  CAREERS
              </div>
        <div class="sub-content">
            Let’s grow together! We're building a culture at BLEACH® where talents (like you) can do
their best work. You've come to the right place if you're ready to grow your career and help
 an organization grow better.
        </div>
        <div class="current-open-btn mt-15" @click="gotoLink()">
sEE CURRENT oPENINGS
        </div>
      </div>
    </div>
    <div class="gray-bg-2">
        <div class="bcontainer">
            <div class="pt-5 pb-15">
                <div class="sub-heading mt-5"> WHAT IS <span class="w400">BLEACH </span></div>
                <div class="sub-content mt-2">
                    BLEACH® is a leading cleaning services provider to offices and residents in Kuwait.
Thousands of customers across the country are using our services for cleaner and healthier
surroundings. 
                </div>
                <div class="exp-with mt-10 pointer" @click="goto('about-us')">
                    eXPLORE MORE ABOUT US
                </div>
                <div class="row">
                    <div class="col-lg-6 col-md-12 mt-10">
                        <div class="career-card h-100">
                            <div class="d-flex">
                                <div class="ma">
                                     <div class="" >
                                        <img src="../../assets/icons/career/1.png"   alt="" />
                                    </div>
                                </div>
                                <div class="ml-5">
                                    <div class="card-head-career">
                                        BEST PLACE TO WORK
                                    </div>
                                    <div class="service-content mt-2">
                                        BLEACH® commenced operations in 2013 and we take pride in offering a comfortable work
environment to our employees ever since. Our staff has been with us for a long time and
that speaks volumes about how much they love working with us, for us, and in the healthy set-up BLEACH® offers.

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 mt-10">
                        <div class="career-card h-100">
                            <div class="d-flex">
                                <div class="ma">
                                     <div class="" >
                                         <img src="../../assets/icons/career/2.png"   alt="" />
                                    </div>
                                </div>
                                <div class="ml-5">
                                    <div class="card-head-career">
                                        REMOTE WORK CULTURE
                                    </div>
                                    <div class="service-content mt-2">
                                        BLEACH® trusts its employees and believes in offering a flexible working atmosphere. Our
employees not only work from the office but from across the globe. Our remote team takes
care of our key operations and is allowed to operate at flexible work hours.


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 mt-10">
                        <div class="career-card h-100">
                            <div class="d-flex">
                                <div class="ma">
                                     <div class="" >
                                         <img src="../../assets/icons/career/3.png"   alt="" />
                                    </div>
                                </div>
                                <div class="ml-5">
                                    <div class="card-head-career">
                                        OPEN DOOR POLICY
                                    </div>
                                    <div class="service-content mt-2">
                                      We believe in welcoming suggestions and feedback from all our employees to be a better
 set up at every step. Everyone at BLEACH® has access to the company’s management and is
 free to contribute to the organization’s growth with their valuable inputs


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                     <div class="col-lg-6 col-md-12 mt-10">
                        <div class="career-card h-100">
                            <div class="d-flex">
                                <div class="ma">
                                     <div class="" >
                                          <img src="../../assets/icons/career/4.png"   alt="" />
                                    </div>
                                </div>
                                <div class="ml-5">
                                    <div class="card-head-career">
                                        EXPANSION SPREE
                                    </div>
                                    <div class="service-content mt-2">
                                     BLEACH® is growing exponentially and aims to be even bigger in the near times. Our growth
is such that even the pandemic couldn’t tarnish our projects. We grow together with our
employees and make things happen.

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                     <div class="col-lg-6 col-md-12 mt-10">
                        <div class="career-card h-100">
                            <div class="d-flex">
                                <div class="ma">
                                     <div class="" >
                                          <img src="../../assets/icons/career/5.png"   alt="" />
                                    </div>
                                </div>
                                <div class="ml-5">
                                    <div class="card-head-career">
                                        GENDER EQUALITY

                                    </div>
                                    <div class="service-content mt-2">
                                    We strongly support gender equality in the workplace. Our staff comprises both women and
men. One of the major benefits of gender equality in the workplace is a more cohesive and productive workforce which is made possible through cooperation in daily operation at
BLEACH® by both genders

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 mt-10">
                        <div class="career-card h-100">
                            <div class="d-flex">
                                <div class="ma">
                                     <div class="" >
                                         <img src="../../assets/icons/career/6.png"   alt="" />
                                    </div>
                                </div>
                                <div class="ml-5">
                                    <div class="card-head-career">
                                        LEARN MORE WITH US


                                    </div>
                                    <div class="service-content mt-2">
                                   BLEACH® gives opportunities to its employees to sharpen their skills and learn new things at
work. We assist employees to take up initiatives at work, do wonders and make mistakes for
a fruitful association.

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 mt-10">
                        <div class="career-card h-100">
                            <div class="d-flex">
                                <div class="ma">
                                     <div class="" >
                                         <img src="../../assets/icons/career/7.png"   alt="" />
                                    </div>
                                </div>
                                <div class="ml-5">
                                    <div class="card-head-career">
                                        EASY TO APPROACH MANAGEMENT
                                    </div>
                                    <div class="service-content mt-2">
                                We strongly support gender equality in the workplace. Our staff comprises both women and
men. One of the major benefits of gender equality in the workplace is a more cohesive and 

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                     <div class="col-lg-6 col-md-12 mt-10">
                        <div class="career-card h-100">
                            <div class="d-flex">
                                <div class="ma">
                                     <div class="" >
                                         <img src="../../assets/icons/career/8.png"   alt="" />
                                    </div>
                                </div>
                                <div class="ml-5">
                                    <div class="card-head-career">
                                      WORK WITH THE BEST
                                    </div>
                                    <div class="service-content mt-2">
                                When you work at BLEACH®, you get the opportunity to work with the best talents who are
responsible for making BLEACH® a synonym for cleanliness in Kuwait. We hire people who
are best at behavior, work, skills, and professionalism.


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
        </div>
    </div>
    </div>
    <div class="bcontainer">
        <div class="py-15 mpx-15">
            <div class="mpx-10">
                <div class="emp-say-head">
                KNOW WHAT OUR EMPLOYEES SAY
            </div>
            <div class="row mt-9">
                <div class="col-md-12 col-lg-6" >
                    <div class="emp-review-card h-100">
                        <div class="emp-review-card-first">
                            <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.56 29.32C6.08 29.32 4.04 28.56 2.44 27.04C0.92 25.44 0.16 23.4 0.16 20.92C0.16 17.8 1.44 14.4 4 10.72C6.64 6.96 10.12 3.64 14.44 0.759996L16.24 2.55999C14.56 4.32 13.24 5.96 12.28 7.48C11.32 8.99999 10.84 10.24 10.84 11.2C10.84 11.76 11 12.28 11.32 12.76C11.72 13.24 12.28 13.76 13 14.32C14.12 15.2 15 16.12 15.64 17.08C16.28 18.04 16.6 19.32 16.6 20.92C16.6 23.4 15.84 25.44 14.32 27.04C12.8 28.56 10.88 29.32 8.56 29.32ZM31.84 29.32C29.36 29.32 27.32 28.56 25.72 27.04C24.2 25.44 23.44 23.4 23.44 20.92C23.44 17.8 24.72 14.4 27.28 10.72C29.92 6.96 33.4 3.64 37.72 0.759996L39.52 2.55999C37.84 4.32 36.52 5.96 35.56 7.48C34.6 8.99999 34.12 10.24 34.12 11.2C34.12 11.76 34.28 12.28 34.6 12.76C35 13.24 35.56 13.76 36.28 14.32C37.4 15.2 38.28 16.12 38.92 17.08C39.56 18.04 39.88 19.32 39.88 20.92C39.88 23.4 39.12 25.44 37.6 27.04C36.08 28.56 34.16 29.32 31.84 29.32Z" fill="#6D6D6E"/>
                            </svg>
                            <div class="service-content mt-6">
                           Working at BLEACH®is amazing. The friendly work culture here encourages teamwork to
reach our company goal which is customer satisfaction.
                            </div>

                        </div>
                        <div class="emp-review-card-footer">
                            <div class="d-flex">
                                <div>
                                    <img src="../../assets/images/career/1.png" alt="">
                                </div>
                                <div class="emp-name full-flex ma ml-5">
                                    Ahmed Abdou <br>
                                    <span class="service-content">Sales Executive </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="col-md-12 col-lg-6" >
                    <div class="emp-review-card h-100   ">
                        <div class="emp-review-card-first">
                            <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.56 29.32C6.08 29.32 4.04 28.56 2.44 27.04C0.92 25.44 0.16 23.4 0.16 20.92C0.16 17.8 1.44 14.4 4 10.72C6.64 6.96 10.12 3.64 14.44 0.759996L16.24 2.55999C14.56 4.32 13.24 5.96 12.28 7.48C11.32 8.99999 10.84 10.24 10.84 11.2C10.84 11.76 11 12.28 11.32 12.76C11.72 13.24 12.28 13.76 13 14.32C14.12 15.2 15 16.12 15.64 17.08C16.28 18.04 16.6 19.32 16.6 20.92C16.6 23.4 15.84 25.44 14.32 27.04C12.8 28.56 10.88 29.32 8.56 29.32ZM31.84 29.32C29.36 29.32 27.32 28.56 25.72 27.04C24.2 25.44 23.44 23.4 23.44 20.92C23.44 17.8 24.72 14.4 27.28 10.72C29.92 6.96 33.4 3.64 37.72 0.759996L39.52 2.55999C37.84 4.32 36.52 5.96 35.56 7.48C34.6 8.99999 34.12 10.24 34.12 11.2C34.12 11.76 34.28 12.28 34.6 12.76C35 13.24 35.56 13.76 36.28 14.32C37.4 15.2 38.28 16.12 38.92 17.08C39.56 18.04 39.88 19.32 39.88 20.92C39.88 23.4 39.12 25.44 37.6 27.04C36.08 28.56 34.16 29.32 31.84 29.32Z" fill="#6D6D6E"/>
                            </svg>
                            <div class="service-content mt-6">
                             Working in a team spirit is a fun thing at BLEACH®. It makes you feel comfortable at work
where all departments merge so that there are no obstacles to progress and success.
                            </div>

                        </div>
                        <div class="emp-review-card-footer">
                            <div class="d-flex">
                                <div>
                                    <img src="../../assets/images/career/2.png" alt="">
                                </div>
                                <div class="emp-name full-flex ma ml-5">
                                    Abdulhamid Sarrag <br>
                                    <span class="service-content">Team Leader </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-6" >
                    <div class="emp-review-card h-100   ">
                        <div class="emp-review-card-first">
                            <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.56 29.32C6.08 29.32 4.04 28.56 2.44 27.04C0.92 25.44 0.16 23.4 0.16 20.92C0.16 17.8 1.44 14.4 4 10.72C6.64 6.96 10.12 3.64 14.44 0.759996L16.24 2.55999C14.56 4.32 13.24 5.96 12.28 7.48C11.32 8.99999 10.84 10.24 10.84 11.2C10.84 11.76 11 12.28 11.32 12.76C11.72 13.24 12.28 13.76 13 14.32C14.12 15.2 15 16.12 15.64 17.08C16.28 18.04 16.6 19.32 16.6 20.92C16.6 23.4 15.84 25.44 14.32 27.04C12.8 28.56 10.88 29.32 8.56 29.32ZM31.84 29.32C29.36 29.32 27.32 28.56 25.72 27.04C24.2 25.44 23.44 23.4 23.44 20.92C23.44 17.8 24.72 14.4 27.28 10.72C29.92 6.96 33.4 3.64 37.72 0.759996L39.52 2.55999C37.84 4.32 36.52 5.96 35.56 7.48C34.6 8.99999 34.12 10.24 34.12 11.2C34.12 11.76 34.28 12.28 34.6 12.76C35 13.24 35.56 13.76 36.28 14.32C37.4 15.2 38.28 16.12 38.92 17.08C39.56 18.04 39.88 19.32 39.88 20.92C39.88 23.4 39.12 25.44 37.6 27.04C36.08 28.56 34.16 29.32 31.84 29.32Z" fill="#6D6D6E"/>
                            </svg>
                            <div class="service-content mt-6">
                               I would say that I’m grateful to be working at BLEACH® for so long. I have learned so many
things here and enhanced my skills. We at BLEACH® get the opportunity to improve
ourselves daily, be more responsible and work with greater efforts to achieve goals.
                            </div>

                        </div>
                        <div class="emp-review-card-footer">
                            <div class="d-flex">
                                <div>
                                    <img src="../../assets/images/career/3.png" alt="">
                                </div>
                                <div class="emp-name full-flex ma ml-5">
                                    Rowena Miguel <br>
                                    <span class="service-content">Bleach Staff </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-6" >
                    <div class="emp-review-card h-100   ">
                        <div class="emp-review-card-first">
                            <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.56 29.32C6.08 29.32 4.04 28.56 2.44 27.04C0.92 25.44 0.16 23.4 0.16 20.92C0.16 17.8 1.44 14.4 4 10.72C6.64 6.96 10.12 3.64 14.44 0.759996L16.24 2.55999C14.56 4.32 13.24 5.96 12.28 7.48C11.32 8.99999 10.84 10.24 10.84 11.2C10.84 11.76 11 12.28 11.32 12.76C11.72 13.24 12.28 13.76 13 14.32C14.12 15.2 15 16.12 15.64 17.08C16.28 18.04 16.6 19.32 16.6 20.92C16.6 23.4 15.84 25.44 14.32 27.04C12.8 28.56 10.88 29.32 8.56 29.32ZM31.84 29.32C29.36 29.32 27.32 28.56 25.72 27.04C24.2 25.44 23.44 23.4 23.44 20.92C23.44 17.8 24.72 14.4 27.28 10.72C29.92 6.96 33.4 3.64 37.72 0.759996L39.52 2.55999C37.84 4.32 36.52 5.96 35.56 7.48C34.6 8.99999 34.12 10.24 34.12 11.2C34.12 11.76 34.28 12.28 34.6 12.76C35 13.24 35.56 13.76 36.28 14.32C37.4 15.2 38.28 16.12 38.92 17.08C39.56 18.04 39.88 19.32 39.88 20.92C39.88 23.4 39.12 25.44 37.6 27.04C36.08 28.56 34.16 29.32 31.84 29.32Z" fill="#6D6D6E"/>
                            </svg>
                            <div class="service-content mt-6">
                               BLEACH® has been great! It has been 8 years since I started working here and it has been
an amazing journey ever since. I got so many opportunities here to grow and to learn.
                            </div>

                        </div>
                        <div class="emp-review-card-footer">
                            <div class="d-flex">
                                <div>
                                    <img src="../../assets/images/career/4.png" alt="">
                                </div>
                                <div class="emp-name full-flex ma ml-5">
                                    Sheik Musthaffa <br>
                                    <span class="service-content">Operation Officer </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
 
  </div>
  <div v-if="selectlan == 'ARABIC'" dir="rtl">
    <div class="bcontainer">
      <div class="py-15">
          <div class="page-head  arabic-font">
                  الوظائف
              </div>
        <div class="sub-content arabic-font">
          لنكبر معاً. نحن نبني ثقافة في بليتش® حيث يمكن للمواهب المميزة (مثل موهبتك) تقديم أفضل ما لديها. لقد أتيت إلى المكان المناسب إذا كنت مستعدًا لتطوير حياتك المهنية ومساعدة المؤسسة على النمو بشكل أفضل.
        </div>
        <div class="current-open-btn mt-15 arabic-font"  @click="gotoLink()">
sEE CURRENT oPENINGS
        </div>
      </div>
    </div>
    <div class="gray-bg-2">
        <div class="bcontainer">
            <div class="pt-5 pb-15">
                <div class="sub-heading mt-5 arabic-font"> من نحن</div>
                <div class="sub-content mt-2 arabic-font">
                   عدّ بليتش® مزودًا رائدًا لخدمات التنظيف للمكاتب وأماكن الإقامة في الكويت. يستخدم الآلاف من العملاء في جميع أنحاء البلاد خدماتنا من أجل بيئة أكثر نظافة وصحة. 
                </div>
                <div class="exp-with mt-10 pointer arabic-font" @click="goto('about-us')">
                    استكشف المزيد عن شركتنا
                </div>
                <div class="row">
                    <div class="col-lg-6 col-md-12 mt-10">
                        <div class="career-card h-100">
                            <div class="d-flex">
                                <div class="ma">
                                     <div class="" >
                                        <img src="../../assets/icons/career/1.png"   alt="" />
                                    </div>
                                </div>
                                <div class="mr-5">
                                    <div class="card-head-career arabic-font" >
                                        أفضل مكان للعمل 
                                    </div>
                                    <div class="service-content arabic-font mt-2">
                                        بدأت بليتش® عملياتها التشغيلية في عام 2013 ونفخر بأننا نجحنا في توفير بيئة عمل مريحة لموظفينا منذ ذلك الحين. يعمل موظفونا هنا منذ فترة طويلة ويتحدثون عن مدى حبهم للعمل معنا ومن أجلنا، وفي بيئة العمل السليمة التي توفرها بليتش®. 

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 mt-10">
                        <div class="career-card h-100">
                            <div class="d-flex">
                                <div class="ma">
                                     <div class="" >
                                         <img src="../../assets/icons/career/2.png"   alt="" />
                                    </div>
                                </div>
                                <div class="mr-5">
                                    <div class="card-head-career arabic-font" >
                                        ثقافة العمل عن بعد
                                    </div>
                                    <div class="service-content arabic-font mt-2">
                                        تثق بليتش® بموظفيها وتؤمن بضرورة توفير جو عمل مرن. لا يعمل موظفونا الإداريين من مقر مكتبنا في الكويت وحسب بل من جميع أنحاء العالم. يتابع فريقنا الذي يعمل عن بعد عملياتنا الرئيسية ويُسمح له بالعمل وفق ساعات عمل مرنة.


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 mt-10">
                        <div class="career-card h-100">
                            <div class="d-flex">
                                <div class="ma">
                                     <div class="" >
                                         <img src="../../assets/icons/career/3.png"   alt="" />
                                    </div>
                                </div>
                                <div class="mr-5">
                                    <div class="card-head-career arabic-font">
                                        سياسة الباب المفتوح 
                                    </div>
                                    <div class="service-content arabic-font mt-2">
                                      إننا نؤمن بأهمية الترحيب بالاقتراحات والملاحظات الواردة من جميع موظفينا لنتمكن من توفير بيئة عمل أفضل في كل خطوة. يتمتع الجميع في بليتش® بإمكانية الوصول إلى إدارة الشركة ولديهم الحرية في المساهمة في نمو المؤسسة من خلال ملاحظاتهم القيّمة

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                     <div class="col-lg-6 col-md-12 mt-10">
                        <div class="career-card h-100">
                            <div class="d-flex">
                                <div class="ma">
                                     <div class="" >
                                          <img src="../../assets/icons/career/4.png"   alt="" />
                                    </div>
                                </div>
                                <div class="mr-5">
                                    <div class="card-head-career arabic-font">
                                        مرحلة التوسّع 
                                    </div>
                                    <div class="service-content arabic-font mt-2">
                                     تنمو بليتش® بشكل أسي وتسعى إلى أن تعمل على صعيد أكبر في القريب العاجل. إننا ننمو بمعدل كبير لدرجة أنه حتى الوباء لم يتمكن من عرقلة مشاريعنا. ننمو بفضل جهود موظفينا ولا نعرف المستحيل. 

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                     <div class="col-lg-6 col-md-12 mt-10">
                        <div class="career-card h-100">
                            <div class="d-flex">
                                <div class="ma">
                                     <div class="" >
                                          <img src="../../assets/icons/career/5.png"   alt="" />
                                    </div>
                                </div>
                                <div class="mr-5">
                                    <div class="card-head-career arabic-font">
                                        المساواة بين الجنسين 

                                    </div>
                                    <div class="service-content arabic-font mt-2">
                                 إننا ندعم بقوة المساواة بين الجنسين في مكان العمل. يضم كادر موظفينا النساء والرجال على حدّ سواء. ولعلّ أهم الفوائد الجليّة للمساواة بين الجنسين في مكان العمل تتمثل في وجود قوة عاملة أكثر تماسكًا وإنتاجية والتي أصبحت ممكنة من خلال التعاون في العمليات اليومية في بليتش® من قبل كلا الجنسين. 

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 mt-10">
                        <div class="career-card h-100">
                            <div class="d-flex">
                                <div class="ma">
                                     <div class="" >
                                         <img src="../../assets/icons/career/6.png"   alt="" />
                                    </div>
                                </div>
                                <div class="mr-5">
                                    <div class="card-head-career arabic-font">
                                        تعلّم المزيد بصحبتنا 
                                    </div>
                                    <div class="service-content arabic-font mt-2">
                                  تمنح بليتش® موظفيها فرصًا لشحذ مهاراتهم وتعلم أشياء جديدة في العمل. نحن نشجع الموظفين على اتخاذ مبادرات في العمل، وطرح الأسئلة والتعلم من الأخطاء من أجل شراكةٍ مثمرة. 

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 mt-10">
                        <div class="career-card h-100">
                            <div class="d-flex">
                                <div class="ma">
                                     <div class="" >
                                         <img src="../../assets/icons/career/7.png"   alt="" />
                                    </div>
                                </div>
                                <div class="mr-5">
                                    <div class="card-head-career arabic-font">
                                        سهولة التواصل مع الإدارة 
                                    </div>
                                    <div class="service-content arabic-font mt-2">
                              يمكن للجميع الوصول بسهولة إلى إدارة بليتش® . يمكن للموظفين المشاركة بحرية مع إدارة الشركة لمناقشة قضاياهم وأفكارهم وآرائهم والكثير غير ذلك في أي وقت. موظفونا ليسوا مجرد فريق عملٍ بل هم عائلتنا! 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                     <div class="col-lg-6 col-md-12 mt-10">
                        <div class="career-card h-100">
                            <div class="d-flex">
                                <div class="ma">
                                     <div class="" >
                                         <img src="../../assets/icons/career/8.png"   alt="" />
                                    </div>
                                </div>
                                <div class="mr-5">
                                    <div class="card-head-career arabic-font">
                                      العمل مع الأفضل 
                                    </div>
                                    <div class="service-content arabic-font mt-2">
                                        عندما تعمل في بليتش®، ستحصل على فرصة للعمل مع أفضل المواهب المسؤولة عن جعل بليتش® مرادفًا لمفهوم النظافة في الكويت. إننا نوظف الأشخاص الأفضل من حيث السلوك والعمل والمهارات والمهنية. 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
        </div>
    </div>
    </div>
    <div class="bcontainer">
        <div class="py-15 mpx-15">
            <div class="mpx-10">
                <div class="emp-say-head arabic-font">
               اطلع على ما يقوله موظفونا 
            </div>
            <div class="row mt-9">
                <div class="col-md-12 col-lg-6" >
                    <div class="emp-review-card h-100">
                        <div class="emp-review-card-first">
                            <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.56 29.32C6.08 29.32 4.04 28.56 2.44 27.04C0.92 25.44 0.16 23.4 0.16 20.92C0.16 17.8 1.44 14.4 4 10.72C6.64 6.96 10.12 3.64 14.44 0.759996L16.24 2.55999C14.56 4.32 13.24 5.96 12.28 7.48C11.32 8.99999 10.84 10.24 10.84 11.2C10.84 11.76 11 12.28 11.32 12.76C11.72 13.24 12.28 13.76 13 14.32C14.12 15.2 15 16.12 15.64 17.08C16.28 18.04 16.6 19.32 16.6 20.92C16.6 23.4 15.84 25.44 14.32 27.04C12.8 28.56 10.88 29.32 8.56 29.32ZM31.84 29.32C29.36 29.32 27.32 28.56 25.72 27.04C24.2 25.44 23.44 23.4 23.44 20.92C23.44 17.8 24.72 14.4 27.28 10.72C29.92 6.96 33.4 3.64 37.72 0.759996L39.52 2.55999C37.84 4.32 36.52 5.96 35.56 7.48C34.6 8.99999 34.12 10.24 34.12 11.2C34.12 11.76 34.28 12.28 34.6 12.76C35 13.24 35.56 13.76 36.28 14.32C37.4 15.2 38.28 16.12 38.92 17.08C39.56 18.04 39.88 19.32 39.88 20.92C39.88 23.4 39.12 25.44 37.6 27.04C36.08 28.56 34.16 29.32 31.84 29.32Z" fill="#6D6D6E"/>
                            </svg>
                            <div class="service-content mt-6">
                          العمل في بليتش® مذهل. تشجع ثقافة العمل الودية هنا نجاح العمل الجماعي للوصول إلى هدف شركتنا الأهم وهو رضا العملاء
                            </div>

                        </div>
                        <div class="emp-review-card-footer">
                            <div class="d-flex">
                                <div>
                                    <img src="../../assets/images/career/1.png" alt="">
                                </div>
                                <div class="emp-name full-flex ma mr-5">
                                    أحمد عبد <br>
                                    <span class="service-content">رئيس فريق المبيعات و خدمة العملاء </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="col-md-12 col-lg-6" >
                    <div class="emp-review-card h-100   ">
                        <div class="emp-review-card-first">
                            <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.56 29.32C6.08 29.32 4.04 28.56 2.44 27.04C0.92 25.44 0.16 23.4 0.16 20.92C0.16 17.8 1.44 14.4 4 10.72C6.64 6.96 10.12 3.64 14.44 0.759996L16.24 2.55999C14.56 4.32 13.24 5.96 12.28 7.48C11.32 8.99999 10.84 10.24 10.84 11.2C10.84 11.76 11 12.28 11.32 12.76C11.72 13.24 12.28 13.76 13 14.32C14.12 15.2 15 16.12 15.64 17.08C16.28 18.04 16.6 19.32 16.6 20.92C16.6 23.4 15.84 25.44 14.32 27.04C12.8 28.56 10.88 29.32 8.56 29.32ZM31.84 29.32C29.36 29.32 27.32 28.56 25.72 27.04C24.2 25.44 23.44 23.4 23.44 20.92C23.44 17.8 24.72 14.4 27.28 10.72C29.92 6.96 33.4 3.64 37.72 0.759996L39.52 2.55999C37.84 4.32 36.52 5.96 35.56 7.48C34.6 8.99999 34.12 10.24 34.12 11.2C34.12 11.76 34.28 12.28 34.6 12.76C35 13.24 35.56 13.76 36.28 14.32C37.4 15.2 38.28 16.12 38.92 17.08C39.56 18.04 39.88 19.32 39.88 20.92C39.88 23.4 39.12 25.44 37.6 27.04C36.08 28.56 34.16 29.32 31.84 29.32Z" fill="#6D6D6E"/>
                            </svg>
                            <div class="service-content mt-6 arabic-font">
                             إن العمل بروح الفريق رائع في بليتش®. إنه يمنحك شعوراً بالراحة أثناء العمل حيث تندمج جميع الأقسام ولا توجد عقبات أمام التقدم والنجاح
                            </div>

                        </div>
                        <div class="emp-review-card-footer">
                            <div class="d-flex">
                                <div>
                                    <img src="../../assets/images/career/2.png" alt="">
                                </div>
                                <div class="emp-name full-flex ma mr-5 arabic-font">
                                    عبد الحميد سراج <br>
                                    <span class="service-content arabic-font">قائد الفريق </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-6" >
                    <div class="emp-review-card h-100   ">
                        <div class="emp-review-card-first">
                            <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.56 29.32C6.08 29.32 4.04 28.56 2.44 27.04C0.92 25.44 0.16 23.4 0.16 20.92C0.16 17.8 1.44 14.4 4 10.72C6.64 6.96 10.12 3.64 14.44 0.759996L16.24 2.55999C14.56 4.32 13.24 5.96 12.28 7.48C11.32 8.99999 10.84 10.24 10.84 11.2C10.84 11.76 11 12.28 11.32 12.76C11.72 13.24 12.28 13.76 13 14.32C14.12 15.2 15 16.12 15.64 17.08C16.28 18.04 16.6 19.32 16.6 20.92C16.6 23.4 15.84 25.44 14.32 27.04C12.8 28.56 10.88 29.32 8.56 29.32ZM31.84 29.32C29.36 29.32 27.32 28.56 25.72 27.04C24.2 25.44 23.44 23.4 23.44 20.92C23.44 17.8 24.72 14.4 27.28 10.72C29.92 6.96 33.4 3.64 37.72 0.759996L39.52 2.55999C37.84 4.32 36.52 5.96 35.56 7.48C34.6 8.99999 34.12 10.24 34.12 11.2C34.12 11.76 34.28 12.28 34.6 12.76C35 13.24 35.56 13.76 36.28 14.32C37.4 15.2 38.28 16.12 38.92 17.08C39.56 18.04 39.88 19.32 39.88 20.92C39.88 23.4 39.12 25.44 37.6 27.04C36.08 28.56 34.16 29.32 31.84 29.32Z" fill="#6D6D6E"/>
                            </svg>
                            <div class="service-content mt-6 arabic-font">
                              أود أن أقول إنني ممتنة للعمل في بليتش® لفترة طويلة. لقد تعلمت الكثير من الأشياء هنا وعززت مهاراتي. نحن في بليتش® نحظى بفرصة تحسين مهاراتنا يوميًا، وأن نكون أكثر مسؤولية وأن نعمل بجهود أكبر لتحقيق الأهداف المرجوة
                            </div>

                        </div>
                        <div class="emp-review-card-footer">
                            <div class="d-flex">
                                <div>
                                    <img src="../../assets/images/career/3.png" alt="">
                                </div>
                                <div class="emp-name arabic-font full-flex ma mr-5">
                                    روينا ميغيل  <br>
                                    <span class="service-content arabic-font">Bleach Staff </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-6" >
                    <div class="emp-review-card h-100   ">
                        <div class="emp-review-card-first">
                            <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.56 29.32C6.08 29.32 4.04 28.56 2.44 27.04C0.92 25.44 0.16 23.4 0.16 20.92C0.16 17.8 1.44 14.4 4 10.72C6.64 6.96 10.12 3.64 14.44 0.759996L16.24 2.55999C14.56 4.32 13.24 5.96 12.28 7.48C11.32 8.99999 10.84 10.24 10.84 11.2C10.84 11.76 11 12.28 11.32 12.76C11.72 13.24 12.28 13.76 13 14.32C14.12 15.2 15 16.12 15.64 17.08C16.28 18.04 16.6 19.32 16.6 20.92C16.6 23.4 15.84 25.44 14.32 27.04C12.8 28.56 10.88 29.32 8.56 29.32ZM31.84 29.32C29.36 29.32 27.32 28.56 25.72 27.04C24.2 25.44 23.44 23.4 23.44 20.92C23.44 17.8 24.72 14.4 27.28 10.72C29.92 6.96 33.4 3.64 37.72 0.759996L39.52 2.55999C37.84 4.32 36.52 5.96 35.56 7.48C34.6 8.99999 34.12 10.24 34.12 11.2C34.12 11.76 34.28 12.28 34.6 12.76C35 13.24 35.56 13.76 36.28 14.32C37.4 15.2 38.28 16.12 38.92 17.08C39.56 18.04 39.88 19.32 39.88 20.92C39.88 23.4 39.12 25.44 37.6 27.04C36.08 28.56 34.16 29.32 31.84 29.32Z" fill="#6D6D6E"/>
                            </svg>
                            <div class="service-content arabic-font mt-6">
                               العمل مع بليتش® رائع! لقد مرت 8 سنوات منذ أن بدأت العمل هنا وكانت رحلة مذهلة منذ ذلك الحين. لقد مُنحت هنا العديد من الفرص للنمو والتعلم
                            </div>

                        </div>
                        <div class="emp-review-card-footer">
                            <div class="d-flex">
                                <div>
                                    <img src="../../assets/images/career/4.png" alt="">
                                </div>
                                <div class="emp-name arabic-font full-flex ma mr-5">
                                    شيخ مصطفى <br>
                                    <span class="service-content arabic-font">مسؤول العمليات </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
 
  </div>
  </div>
</template>

<script>
//import pageheading from "../../components/pageheading.vue";
export default {
  components: {  },
  data(){
      return{
          btnselected:'',
          selectlan:localStorage.getItem('bleach-lang'),
      };
  },
  methods:{
     goto(loc) {
      window.location = loc;
    },
    gotoLink(){
        window.location = 'https://bleachkw.bamboohr.com/jobs/'
    }
  }
};
</script>

<style lang="scss">

.emp-review-card-footer{
    background: #E0F6F9;
border-radius: 0px 0px 20px 20px;
padding: 16px 32px 15px 45px;
}
.emp-review-card-first{
    background: #F9F9F9;
    padding: 35px 32px 20px 45px;
    border-radius: 20px 20px 0px 0px ;
    min-height: 235px;
}
.emp-review-card{
    margin-bottom: 70px;
}
.emp-say-head{
    font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 22px;
line-height: 33px;
text-transform: uppercase;
color: #2B9FAD;
}
.career-card{
    padding: 25px 31px;
    background: #fff;
}

.card-head-career{
    font-family: 'Poppins';
    font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 30px;
text-transform: capitalize;
color: #6D6D6E;

}
.vertical {
  padding-top: 10px;
  border-left: 1px solid #5d5d5d;
  height: 80%;
}
.active-btn-form{
background: #E0F6F9 !important;
color: #2B9FAD !important;
}
.exp-with{
    font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 22px;
text-transform: capitalize;
color: #2B9FAD;

}
</style>
