<template>
  <div>
    <div class="gray-bg-2">
      <div class="bcontainer">
        <br /><br />
        <img
          src="../../assets/images/blog/saf.png"
          class="blog-img w-100"
          alt=""
        />
        <div class="blog-date mt-10 text-uppercase">
          Safety <span class="w400">| MARCH 12 2021 </span>
        </div>
        <div class="sub-heading mt-3">SAFETY DURING COVID</div>
        <div class="service-content mt-2">
          One could still remember the day the first case of Covid-19 was
          recorded in Kuwait. Luckily, we were prepared and ready! PPE has
          always been a main pillar in any cleaning service we provide, long
          before the COVID-19 pandemic. Our main goal was to ensure the safety
          of our employees against, but not limited to, skin burns, eye
          injuries, inhaling hazardous fumes or dust. However, the benefits are
          not only in the favor of the employee. PPE ensured that the area is
          clean and germ-free, guaranteeing our customers a premium and hygienic
          experience.
          <br />
          <br />
          Our Learning & Development team rapidly joined virtual global
          conventions, hoping to learn more about the pandemic and the optimum
          ways to fight against it. Acquiring information from the world’s
          greatest institutions such as WHO, CDC, ISSA and more, our Learning &
          Development team embarked on massive training sessions to all our
          employees, regardless if they were field or office employees
          <br /><br />
          <b><i> The Lockdown!</i> </b>
          <br />
          <br />

          When the lockdown started, Bleach’s concerns regarding our employees
          have been boiling up to the surface. We had to do something, to secure
          our employees basic requirements of food and medicine. We have managed
          to put a plan in action, to purchase and store ration of our entire
          accommodation residents, which would last for more than two months.
          <br /><br />
          During those three weeks, Bleach has learned the true meaning of TEAM
          WORK.
          <br /><br />
          <b><i>COVID-19…The Aftermath!</i></b>
          <br /><br />
          For the last year, the whole world has been suffering greatly. The
          concept of cleaning and sterilization has evolved and has now become a
          necessity to ensure general safety. Having the spotlight on those, now
          essential, services has multiplied our responsibilities at BLEACH.
          <br /><br />
          We had to ensure the safety of our employees, who were risking their
          health, every day on the field, making sure our customers’ premises
          are clean and sanitized. Our focus on PPE has magnified to minimize
          any risk of infection. For over a year now, our staff temperature has
          been recorded, on a daily-basis.
          <br /><br />
          Isolation & quarantine plans for our returning staff, from abroad,
          were immediately put in action, to ensure the safety of the rest of
          the employees and our customers. Random & periodic PCR tests have been
          carried out, as a precautionary measure. On top of all that our
          esteemed supervisors, have been ensuring that all of our accommodation
          premises is cleaned and sanitized regularly.
          <br /><br />
          <b><i>VACCINATION…The answer!</i></b>
          <br /><br />
          Now after the shock has gradually faded, spreading awareness for the
          vaccination is our new responsibility at BLEACH. All of our employees
          are impatiently waiting for the vaccinations. Already, many of our
          employees have received the first doses, while others are expecting it
          soon.
        </div>
        <div class="d-flex mt-8 pb-10">
          <div>
            <v-icon class="mt-1" large color=""> mdi-account-circle </v-icon>
          </div>
          <div class="pl-2 service-content mt-3">
            <b>Bleach Newsdesk</b><br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.blog-img {
  border-radius: 10px;
}
</style>