<template>
 <div>
    <div v-if="selectlan =='EN'">

    <div >
       <carousel
      autoplayTimeout="5000"
        @changed="changed"
        items="1"
        :slideTransition="'fadeIn'"
        :loop="false"
        :dots="false"
        :nav="false"
        :autoplay="true"
      >
      <!-- <div class="main-slider-item-6">
          <div class="bcontainer">
            <div class="slider-inner">
              <h1 class="slider-main-text ">
                Book Online  <br />To Get <br />
                <span class="w500"> 15% Discount</span>
              </h1>
              <div class="slider-text ">
               Now you can book our cleaning services online.
              </div>
              <button @click="dia = true" class="book-btn-white btn-trans">Book Now</button>
            </div>
          </div>
        </div> -->
         <div class="main-slider-item-4">
          <div class="bcontainer">
            <div class="slider-inner">
              <h1 class="slider-main-text ">
                Trusted Cleaning <br />Partner <br />
                <span class="w500"> Since 2013</span>
              </h1>
              <div class="slider-text ">
                Certified Cleaning Services for Corporate Houses, <br> Stores,
                Governates & More
              </div>
              <button @click="dia = true" class="book-btn-white btn-trans">Book Now</button>
            </div>
          </div>
        </div>
         <div class="main-slider-item-3">
          <div class="bcontainer">
            <div class="slider-inner">
              <h1 class="slider-main-text">
                Precision. <br />Perfection. <br />
                <span class="w500"
                  >Bleach<sup style="font-size: 40px">®</sup></span
                >
              </h1>
              <div class="slider-text">
                It's more than clean. It's an experience!
              </div>
              <button @click="dia = true" class="book-btn-white btn-trans ">Book Now</button>
            </div>
          </div>
        </div>
       
       <div class="main-slider-item-1">
          <div class="bcontainer">
            <div class="slider-inner">
              <h1 class="slider-main-text animate__animated animate__fadeInUp">
                Your Cleaning <br />Search <br />
                <span class="w500">Stops Here</span>
              </h1>
              <div
                class="slider-text m-mt-20 animate__animated animate__fadeInUp animate__delay-08s"
              >
                Expert Cleaning Solutions Making Spaces Germ & Dirt-free
              </div>
              <button @click="dia = true"
                class="book-btn-white btn-trans  m-mt-20 animate__animated animate__fadeInUp animate__delay-1s"
              >
                Book Now
              </button>
            </div>
          </div>
        </div>
       
       
      <div class="main-slider-item-2">
          <div class="bcontainer">
            <div class="slider-inner">
              <h1 class="slider-main-text">
                Environment First <br />Cleaning <br />
                <span class="w500">Concept</span>
              </h1>
              <div class="slider-text">
                Our Carefully Chosen Products Keep Environmental Safety in Check
              </div>
               <button @click="dia = true" class="book-btn-white btn-trans">View Our Prices</button> 
            </div>
          </div>
        </div>
      </carousel>
    </div>
    <section class="bcontainer">
        <div class="bp-40">
          
        <div class="text-center section-heading " data-aos="fade-up"  data-aos-duration="1000"  > 
          WHAT <span class="w500" > WE DO</span>
        </div>
        <div class="my-10 about-section" data-aos="fade-up"  data-aos-duration="1000">
         BLEACH® is a technology-driven cleaning company that offers high-end services for the most discerning clients. We deliver exceptional value through a 3-tier cleaning model, featuring uncompromising VIP services, flexible scheduling, and an uber-easy booking experience. 
        </div>
        <div class="py-2" >
          <div class="row my-10" data-aos="fade-up"  data-aos-duration="1000"> 
            <div class="col-lg-3 col-md-6 mt-10" @click="gotoDc()">
              <div class="service-card">
                  <div class="service-card-circle">
                    <img
                    
                      src="../../assets/icons/services/dc.png"
                      alt=""
                    />
                  </div>
                  <div class="cleaning-card-name text-center">
                    DETAILED CLEANING
                  </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 mt-10" @click="gotosp()">
              <div class="service-card">
                  <div class="service-card-circle">
                    <img
                    style="margin-top:-5px"
                       src="../../assets/icons/services/sp.png"
                      alt=""
                    />
                  </div>
                  <div class="cleaning-card-name text-center">
                    SPECIAL CARE
                  </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 mt-10" @click="gotoKit()">
              <div class="service-card">
                  <div class="service-card-circle">
                    <img

                      src="../../assets/icons/services/kc.png"
                      alt=""
                    />
                  </div>
                  <div class="cleaning-card-name text-center">
                    KITCHEN CLEANING
                  </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 mt-10" @click="gotoIc()">
              <div class="service-card">
                  <div class="service-card-circle">
                    <img
                    
                      src="../../assets/icons/services/ic.png"
                      alt=""
                    />
                  </div>
                  <div class="cleaning-card-name text-center">
                     PEST CONTROL
                  </div>
                </div>
            </div>
          </div>
        </div>
        <!-- <div
          class="text-center mt-5 pt-15" data-aos="fade-up"  data-aos-duration="1000"
        >
          <span class="highlight-text"> Clean, Protect and Freshen-up Your Premises </span> 
          <button class="book-btn ml-3 ma" @click="goto('service/detailedcleaning')">
            View Our Prices
          </button>
        </div> -->
        <div class="py-2">
          <div class="row" data-aos="fade-up"  data-aos-duration="1000">
            <div class="col-md-6">
              <div class="book-eval-card" @click="gotoMain('evaluation')">
                
                <div class="book-eval-card-text" style="padding-top:50px">
                  <img
                  width="98"
                            src="../../assets/images/evalhome.png"
                            class="text-center"
                            alt=""
                            />
                            <br>
                  <div class="mt-4">
                    Book your Free Evaluation
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 m-mt-10">
              <div class="book-eval-card2" @click="dia = true">
                <div class="book-eval-card-text2" style="padding-top:50px">
                  <img
                  width="98"
                            src="../../assets/images/pricehome.png"
                            class="text-center"
                            alt=""
                            />
                            <br>
                            <div class="mt-4">
                              View Our price
                            </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="gray-bg" id="SPECIALITY">
        <div class="bcontainer">
            <div class="bp-40">
                <div class="text-center section-heading" data-aos="fade-up"  data-aos-duration="1000">
                WHO <span class="w500"> WE SERVE</span>
            </div>
                <div class="mt-10 about-section" data-aos="fade-up"  data-aos-duration="1000">
                We pride ourselves on providing professional and quality cleaning services to residential and commercial clients. Our wide range of services allows you to customize a plan that fits your needs – Daily, Weekly, Monthly, or Event Clean-up. Subscribe from our full range of services. 
                </div>
                <div class="row" data-aos="fade-up"  data-aos-duration="1000">
                  <div class="col-md-6 mt-5">
                    <div class="card-div h-100" style="cursor: pointer;" @click="goto('residential')">
                        <div class="who-serve-card-img who-serve-card-res text-center">
                            <img
                            src="../../assets/images/res.png"
                            class="text-center"
                            alt=""
                            />
                            <div class="who-text mt-3">Residential</div>
                        </div>
                        <div class="who-card-contents ">
                            Custom solutions for domestic cleaning needs, ranging from Sanitization and Upholstery Cleaning to Facade Cleaning. 
                            <br /><br>
                        </div>
                        <div class="who-gray"><br><br></div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-5">
                    <div class="card-div  h-100" style="cursor: pointer;" @click="goto('commercial')">
                        <div class="who-serve-card-img who-serve-card-com text-center">
                            <img
                            src="../../assets/images/com.png"
                            class="text-center"
                            alt=""
                            />
                            <div class="who-text mt-3">Commercial</div>
                        </div>
                        <div class="who-card-contents">
                        Extensive commercial cleaning services to the highest standards for Corporate Houses, Educational Facilities, Retail, Industrial requirements & more. 
                            <br>
                        </div>
                         <div class="who-gray"><br><br></div>
                    </div>
                  </div>
                </div>
                <div v-if="false" class="dflex-card mt-10" data-aos="fade-up"  data-aos-duration="1000">
                    <div class="card-div card-div-1 h-100" style="cursor: pointer;" @click="goto('residential')">
                        <div class="who-serve-card-img who-serve-card-res text-center">
                            <img
                            src="../../assets/images/res.png"
                            class="text-center"
                            alt=""
                            />
                            <div class="who-text mt-3">Residential</div>
                        </div>
                        <div class="who-card-contents ">
                            Custom solutions for domestic cleaning needs, ranging from Sanitization and Upholstery Cleaning to Facade Cleaning. 
                            <br /><br>
                        </div>
                        <div class="who-gray"><br><br></div>
                    </div>
                    <div class="card-div card-div-2 h-100" style="cursor: pointer;" @click="goto('commercial')">
                        <div class="who-serve-card-img who-serve-card-com text-center">
                            <img
                            src="../../assets/images/com.png"
                            class="text-center"
                            alt=""
                            />
                            <div class="who-text mt-3">Commercial</div>
                        </div>
                        <div class="who-card-contents">
                        Extensive commercial cleaning services to the highest standards for Corporate Houses, Educational Facilities, Retail, Industrial requirements & more. 
                            <br><br>
                        </div>
                         <div class="who-gray"><br><br></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section >
        <div class="bcontainer" >
        <div class="bp-40">
          <div class="text-center section-heading"  data-aos="fade-up"  data-aos-duration="1000">
            WHAT <span class="w500"> MAKES US DIFFERENT</span>
          </div>
          <div class="my-10 about-section" data-aos="fade-up"  data-aos-duration="1000">
            We follow the mantra of acknowledging customers’ needs first. Our globally-certified cleaning methods; professionally-trained cleaners ensuring services of international standards; and digitally-powered easy service booking experience depict our culture. 
          </div>
          <div >
       <div class="mt-15 show-diff">
          <div class="row" data-aos="fade-up"  data-aos-duration="1000">
          <div class="col-md-4 mt-5 col-lg-2">
            <div class="text-center diff-card" @click="changeDiff(0)">
              <div class="diff-icons" :class="activeDiff.id==differece[0].id?'img-active-border':''">
                <img src="../../assets/images/makediff/ad.png"   alt="" />
              </div>
              <div class="diff-heading" :class="activeDiff.id==differece[0].id?'active-text':''">Advanced Customer Care</div>
            </div>
          </div>
          <div class="col-md-4 mt-5 col-lg-2">
            <div class="text-center diff-card" @click="changeDiff(1)">
              <div class="diff-icons" :class="activeDiff.id==differece[1].id?'img-active-border':''" >
                  <img src="../../assets/images/makediff/well.png" alt="" />
              </div>
              <div class="diff-heading" :class="activeDiff.id==differece[1].id?'active-text':''">Well-Trained Professionals </div>
            </div>
          </div>
          <div class="col-md-4 mt-5 col-lg-2">
            <div class="text-center diff-card" @click="changeDiff(2)">
              <div class="diff-icons" :class="activeDiff.id==differece[2].id?'img-active-border':''">
                  <img src="../../assets/images/makediff/eco.png"  alt="" />
              </div>
              <div class="diff-heading" :class="activeDiff.id==differece[2].id?'active-text':''">Eco-Friendly Services  </div>
            </div>
          </div>
          <div class="col-md-4 mt-5 col-lg-2">
            <div class="text-center diff-card" @click="changeDiff(3)">
              <div class="diff-icons" :class="activeDiff.id==differece[3].id?'img-active-border':''">
                <img src="../../assets/images/makediff/ch.png"  alt="" />
              </div>
              <div class="diff-heading"  :class="activeDiff.id==differece[3].id?'active-text':''">Child-Friendly Products  </div>
            </div>
          </div>
          <div class="col-md-4 mt-5 col-lg-2">
            <div class="text-center diff-card" @click="changeDiff(4)">
              <div class="diff-icons" :class="activeDiff.id==differece[4].id?'img-active-border':''" >
                <img src="../../assets/images/makediff/pet.png" alt="" />
              </div>
              <div class="diff-heading"  :class="activeDiff.id==differece[4].id?'active-text':''">Pet-Friendly Methods </div>
            </div>
          </div>
          <div class="col-md-4 mt-5 col-lg-2">
            <div class="text-center diff-card" @click="changeDiff(5)">
              <div class="diff-icons" :class="activeDiff.id==differece[5].id?'img-active-border':''" >
                <img src="../../assets/images/makediff/in.png" alt="" />
              </div>
              <div class="diff-heading"  :class="activeDiff.id==differece[5].id?'active-text':''">Insurance <br>  Coverage</div>
            </div>
          </div>

        </div>
       </div>
       <div class="mt-15 diff-side" data-aos="fade-up"  data-aos-duration="1000">
          <carousel
          :loop='true'
          v-if="width != null"
          items="1" 
            :autoplay="isCar()"
            :dots="false"
            :nav="false"
           
          >
           <template  ref="prev"  slot="prev" >
                  <div style="left:-2%" class="arrow-div">
                <v-icon large>
                mdi-chevron-left
              </v-icon>
               </div> 
               </template>
                 <template  ref="next" slot="next"> 
             <div style="right:-2%" class="arrow-div">
                <v-icon large>
                mdi-chevron-right
              </v-icon>
               </div> 
            </template>
          <div class="text-center diff-card" @click="changeDiff(0)">
               <div class="diff-icons img-active-border" >
                <img src="../../assets/images/makediff/ad.png"   alt="" />
              </div>
              <div class="diff-heading active-text">Advanced Customer Care</div>
               <div class="bmt-30 ">
                  <div class="about-section" data-aos="fade-up"  data-aos-duration="1000"> 
                    <span style="color: #2B9FAD;" class="show-head">{{activeDiff.title}}: </span>{{differece[0].content}}
                    </div>
                </div>
            </div>
             <div class="text-center diff-card" @click="changeDiff(1)">
               <div class="diff-icons img-active-border">
                  <img src="../../assets/images/makediff/well.png" alt="" />
              </div>
              <div class="diff-heading active-text" >Well-Trained Professionals </div>
               <div class="bmt-30 ">
                  <div class="about-section" data-aos="fade-up"  data-aos-duration="1000"> 
                    <span style="color: #2B9FAD;" class="show-head">{{activeDiff.title}}: </span>{{differece[1].content}}
                    </div>
                </div>
            </div>
            <div class="text-center diff-card" @click="changeDiff(2)">
              <div class="diff-icons img-active-border" >
                  <img src="../../assets/images/makediff/eco.png"  alt="" />
              </div>
              <div class="diff-heading active-text" >Eco-Friendly Services  </div>
               <div class="bmt-30 ">
                  <div class="about-section" data-aos="fade-up"  data-aos-duration="1000"> 
                    <span style="color: #2B9FAD;" class="show-head">{{activeDiff.title}}: </span>{{differece[2].content}}
                    </div>
                </div>
            </div>
            <div class="text-center diff-card" @click="changeDiff(3)">
              <div class="diff-icons img-active-border">
                <img src="../../assets/images/makediff/ch.png"  alt="" />
              </div>
              <div class="diff-heading active-text"  >Child-Friendly Products  </div>
               <div class="bmt-30 ">
                  <div class="about-section" data-aos="fade-up"  data-aos-duration="1000"> 
                    <span style="color: #2B9FAD;" class="show-head">{{activeDiff.title}}: </span>{{differece[3].content}}
                    </div>
                </div>
            </div>
             <div class="text-center diff-card" @click="changeDiff(4)">
              <div class="diff-icons img-active-border"  >
                <img src="../../assets/images/makediff/pet.png" alt="" />
              </div>
              <div class="diff-heading active-text"  >Pet-Friendly Methods </div>
               <div class="bmt-30 ">
                  <div class="about-section" data-aos="fade-up"  data-aos-duration="1000"> 
                    <span style="color: #2B9FAD;" class="show-head">{{activeDiff.title}}: </span>{{differece[4].content}}
                    </div>
                </div>
            </div>
            <div class="text-center diff-card" @click="changeDiff(5)">
              <div class="diff-icons img-active-border" >
                <img src="../../assets/images/makediff/in.png" alt="" />
              </div>
              <div class="diff-heading active-text" >Insurance Coverage</div>
               <div class="bmt-30 ">
                  <div class="about-section" data-aos="fade-up"  data-aos-duration="1000"> 
                    <span style="color: #2B9FAD;" class="show-head">{{activeDiff.title}}: </span>{{differece[5].content}}
                    </div>
                </div>
            </div>
          </carousel>
       </div>
        <div class="bmt-30 show-diff">
          <div class="about-section" style="min-height: 125px;" data-aos="fade-up"  data-aos-duration="1000"> 
           <span style="color: #2B9FAD;" class="show-head">{{activeDiff.title}}: </span>{{activeDiff.content}}
        </div>
      </div>
      </div>
        </div>
      </div>
    </section>
    <section class="gray-bg">
        <div class="bcontainer">
             <div class="bp-40">
                 <div class="text-center section-heading" data-aos="fade-up"  data-aos-duration="1000">CORPORATE  <span class="w500"> FEEDBACK</span></div>
                 <div dawho-serve-card-comta-aos="fade-up" class="mt-5"  data-aos-duration="1000">
                   <carousel :responsive="{0:{items:1,nav:false},769:{items:2,nav:false}}"  :autoplay="false"  :loop="true" :dots="false" :nav="false" >
                  <div class="feedback-card">
                              <div class="feedback-card-top cop-feedback">
                                <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8.56 29.32C6.08 29.32 4.04 28.56 2.44 27.04C0.92 25.44 0.16 23.4 0.16 20.92C0.16 17.8 1.44 14.4 4 10.72C6.64 6.96 10.12 3.64 14.44 0.759996L16.24 2.55999C14.56 4.32 13.24 5.96 12.28 7.48C11.32 8.99999 10.84 10.24 10.84 11.2C10.84 11.76 11 12.28 11.32 12.76C11.72 13.24 12.28 13.76 13 14.32C14.12 15.2 15 16.12 15.64 17.08C16.28 18.04 16.6 19.32 16.6 20.92C16.6 23.4 15.84 25.44 14.32 27.04C12.8 28.56 10.88 29.32 8.56 29.32ZM31.84 29.32C29.36 29.32 27.32 28.56 25.72 27.04C24.2 25.44 23.44 23.4 23.44 20.92C23.44 17.8 24.72 14.4 27.28 10.72C29.92 6.96 33.4 3.64 37.72 0.759996L39.52 2.55999C37.84 4.32 36.52 5.96 35.56 7.48C34.6 8.99999 34.12 10.24 34.12 11.2C34.12 11.76 34.28 12.28 34.6 12.76C35 13.24 35.56 13.76 36.28 14.32C37.4 15.2 38.28 16.12 38.92 17.08C39.56 18.04 39.88 19.32 39.88 20.92C39.88 23.4 39.12 25.44 37.6 27.04C36.08 28.56 34.16 29.32 31.84 29.32Z" fill="#6D6D6E"/>
                              </svg>
                              <div class="service-content mt-5">
                                We always felt safe having your team clean and sterilize our premises during covid times. The operation team and the sales team are well organized in arranging the schedule for the works. The schedule is properly followed by the staff and they handle the works very professionally with proper protection for themselves and for us .The service provided by your team is always satisfactory and we have very minimal feedback as complaints or issues. 
                              </div>
                              </div>
                              <div class="feedback-card-bottom">
                               <div class="d-flex">
                                <div class="full-flex ma">
                                    <div class="emp-name">
                                      Saravana Sundaram
                                    </div>
                                    <div class="service-content">
                                      Facilities Manager
                                    </div>
                                </div>
                                <div>
                                  <img src="../../assets/images/feedback/tabco.png" alt="">
                                </div>
                               </div>
                              </div>
                          </div>
                      <div class="feedback-card">
                              <div class="feedback-card-top cop-feedback">
                                <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8.56 29.32C6.08 29.32 4.04 28.56 2.44 27.04C0.92 25.44 0.16 23.4 0.16 20.92C0.16 17.8 1.44 14.4 4 10.72C6.64 6.96 10.12 3.64 14.44 0.759996L16.24 2.55999C14.56 4.32 13.24 5.96 12.28 7.48C11.32 8.99999 10.84 10.24 10.84 11.2C10.84 11.76 11 12.28 11.32 12.76C11.72 13.24 12.28 13.76 13 14.32C14.12 15.2 15 16.12 15.64 17.08C16.28 18.04 16.6 19.32 16.6 20.92C16.6 23.4 15.84 25.44 14.32 27.04C12.8 28.56 10.88 29.32 8.56 29.32ZM31.84 29.32C29.36 29.32 27.32 28.56 25.72 27.04C24.2 25.44 23.44 23.4 23.44 20.92C23.44 17.8 24.72 14.4 27.28 10.72C29.92 6.96 33.4 3.64 37.72 0.759996L39.52 2.55999C37.84 4.32 36.52 5.96 35.56 7.48C34.6 8.99999 34.12 10.24 34.12 11.2C34.12 11.76 34.28 12.28 34.6 12.76C35 13.24 35.56 13.76 36.28 14.32C37.4 15.2 38.28 16.12 38.92 17.08C39.56 18.04 39.88 19.32 39.88 20.92C39.88 23.4 39.12 25.44 37.6 27.04C36.08 28.56 34.16 29.32 31.84 29.32Z" fill="#6D6D6E"/>
                              </svg>
                              <div class="service-content mt-5">
                                We commissioned BLEACH Cleaning Service just before Toby's Estate Coffee Roaster opened its doors to the public for the first time and loved their quality of work so much that we recommissioned them over and over again. I would like to commend BLEACH management and its staff on the level of service and exceptional professionalism that your employees exert while performing work inside the shop and outside each and every time we hired BLEACH to perform these ser-vices. We have found BLEACH to be very detailed oriented and feel the quality of work to be exceptional. We would definitely use BLEACH in all of our future locations going forward.
                              </div>
                              </div>
                              <div class="feedback-card-bottom">
                               <div class="d-flex">
                                <div class="full-flex ma">
                                    <div class="emp-name">
                                      Tareq A. Alshaikh
                                    </div>
                                    <div class="service-content">
                                      Managing Partner
                                    </div>
                                </div>
                                <div>
                                  <img src="../../assets/images/feedback/toby.png" alt="">
                                </div>
                               </div>
                              </div>
                          </div>
                      <div class="feedback-card">
                              <div class="feedback-card-top cop-feedback">
                                <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8.56 29.32C6.08 29.32 4.04 28.56 2.44 27.04C0.92 25.44 0.16 23.4 0.16 20.92C0.16 17.8 1.44 14.4 4 10.72C6.64 6.96 10.12 3.64 14.44 0.759996L16.24 2.55999C14.56 4.32 13.24 5.96 12.28 7.48C11.32 8.99999 10.84 10.24 10.84 11.2C10.84 11.76 11 12.28 11.32 12.76C11.72 13.24 12.28 13.76 13 14.32C14.12 15.2 15 16.12 15.64 17.08C16.28 18.04 16.6 19.32 16.6 20.92C16.6 23.4 15.84 25.44 14.32 27.04C12.8 28.56 10.88 29.32 8.56 29.32ZM31.84 29.32C29.36 29.32 27.32 28.56 25.72 27.04C24.2 25.44 23.44 23.4 23.44 20.92C23.44 17.8 24.72 14.4 27.28 10.72C29.92 6.96 33.4 3.64 37.72 0.759996L39.52 2.55999C37.84 4.32 36.52 5.96 35.56 7.48C34.6 8.99999 34.12 10.24 34.12 11.2C34.12 11.76 34.28 12.28 34.6 12.76C35 13.24 35.56 13.76 36.28 14.32C37.4 15.2 38.28 16.12 38.92 17.08C39.56 18.04 39.88 19.32 39.88 20.92C39.88 23.4 39.12 25.44 37.6 27.04C36.08 28.56 34.16 29.32 31.84 29.32Z" fill="#6D6D6E"/>
                              </svg>
                              <div class="service-content mt-5">
                                BLEACH is the best cleaning company I dealt with. As I tried couple of companies to clean my clinic before No one was as precise and punctual as them. They cleaned the clinic professionally and efficiently and were honest with me from the beginning on what they can offer and what was not possible to be done.  Would definitely use their services again and recommend it to everyone I know.  
                              </div>
                              </div>
                              <div class="feedback-card-bottom">
                               <div class="d-flex">
                                <div class="full-flex ma">
                                    <div class="emp-name">
                                      Dr. Muneera Al Mansour
                                    </div>
                                    <div class="service-content">
                                       Chief Executive Officer
                                    </div>
                                </div>
                                <div>
                                  <img src="../../assets/images/feedback/crytstal.png" alt="">
                                </div>
                               </div>
                              </div>
                          </div>
                          <div class="feedback-card">
                              <div class="feedback-card-top cop-feedback">
                                <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8.56 29.32C6.08 29.32 4.04 28.56 2.44 27.04C0.92 25.44 0.16 23.4 0.16 20.92C0.16 17.8 1.44 14.4 4 10.72C6.64 6.96 10.12 3.64 14.44 0.759996L16.24 2.55999C14.56 4.32 13.24 5.96 12.28 7.48C11.32 8.99999 10.84 10.24 10.84 11.2C10.84 11.76 11 12.28 11.32 12.76C11.72 13.24 12.28 13.76 13 14.32C14.12 15.2 15 16.12 15.64 17.08C16.28 18.04 16.6 19.32 16.6 20.92C16.6 23.4 15.84 25.44 14.32 27.04C12.8 28.56 10.88 29.32 8.56 29.32ZM31.84 29.32C29.36 29.32 27.32 28.56 25.72 27.04C24.2 25.44 23.44 23.4 23.44 20.92C23.44 17.8 24.72 14.4 27.28 10.72C29.92 6.96 33.4 3.64 37.72 0.759996L39.52 2.55999C37.84 4.32 36.52 5.96 35.56 7.48C34.6 8.99999 34.12 10.24 34.12 11.2C34.12 11.76 34.28 12.28 34.6 12.76C35 13.24 35.56 13.76 36.28 14.32C37.4 15.2 38.28 16.12 38.92 17.08C39.56 18.04 39.88 19.32 39.88 20.92C39.88 23.4 39.12 25.44 37.6 27.04C36.08 28.56 34.16 29.32 31.84 29.32Z" fill="#6D6D6E"/>
                              </svg>
                              <div class="service-content mt-5">
                                Working with BLEACH was worth every penny. Staff were extremely careful. Professional and efficient. The shops were so clean and fresh. Truly a pleasure to work with. Thank you  
                              </div>
                              </div>
                              <div class="feedback-card-bottom">
                               <div class="d-flex">
                                <div class="full-flex ma">
                                    <div class="emp-name">
                                      Bibi Hayat  
                                    </div>
                                    <div class="service-content">
                                      Owner
                                    </div>
                                </div>
                                <div>
                                  <img  src="../../assets/images/feedback/nov.png" alt="">
                                </div>
                               </div>
                              </div>
                          </div>
                           <div class="feedback-card">
                              <div class="feedback-card-top cop-feedback">
                                <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8.56 29.32C6.08 29.32 4.04 28.56 2.44 27.04C0.92 25.44 0.16 23.4 0.16 20.92C0.16 17.8 1.44 14.4 4 10.72C6.64 6.96 10.12 3.64 14.44 0.759996L16.24 2.55999C14.56 4.32 13.24 5.96 12.28 7.48C11.32 8.99999 10.84 10.24 10.84 11.2C10.84 11.76 11 12.28 11.32 12.76C11.72 13.24 12.28 13.76 13 14.32C14.12 15.2 15 16.12 15.64 17.08C16.28 18.04 16.6 19.32 16.6 20.92C16.6 23.4 15.84 25.44 14.32 27.04C12.8 28.56 10.88 29.32 8.56 29.32ZM31.84 29.32C29.36 29.32 27.32 28.56 25.72 27.04C24.2 25.44 23.44 23.4 23.44 20.92C23.44 17.8 24.72 14.4 27.28 10.72C29.92 6.96 33.4 3.64 37.72 0.759996L39.52 2.55999C37.84 4.32 36.52 5.96 35.56 7.48C34.6 8.99999 34.12 10.24 34.12 11.2C34.12 11.76 34.28 12.28 34.6 12.76C35 13.24 35.56 13.76 36.28 14.32C37.4 15.2 38.28 16.12 38.92 17.08C39.56 18.04 39.88 19.32 39.88 20.92C39.88 23.4 39.12 25.44 37.6 27.04C36.08 28.56 34.16 29.32 31.84 29.32Z" fill="#6D6D6E"/>
                              </svg>
                              <div class="service-content mt-5">
                                Working with BLEACH has been a pleasure! Their staff is very professional, timely and most importantly, their level of service is impeccable. They do a great job providing deep clean services to our locations and we are happy to be a regular customer of theirs!
                              </div>
                              </div>
                              <div class="feedback-card-bottom">
                               <div class="d-flex">
                                <div class="full-flex ma">
                                    <div class="emp-name">
                                      KHALED JAFAR   
                                    </div>
                                    <div class="service-content">
                                      Owner
                                    </div>
                                </div>
                                <div>
                                  <img src="../../assets/images/feedback/orange.png" alt="">
                                </div>
                               </div>
                              </div>
                          </div>
                          <div class="feedback-card">
                              <div class="feedback-card-top cop-feedback">
                                <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8.56 29.32C6.08 29.32 4.04 28.56 2.44 27.04C0.92 25.44 0.16 23.4 0.16 20.92C0.16 17.8 1.44 14.4 4 10.72C6.64 6.96 10.12 3.64 14.44 0.759996L16.24 2.55999C14.56 4.32 13.24 5.96 12.28 7.48C11.32 8.99999 10.84 10.24 10.84 11.2C10.84 11.76 11 12.28 11.32 12.76C11.72 13.24 12.28 13.76 13 14.32C14.12 15.2 15 16.12 15.64 17.08C16.28 18.04 16.6 19.32 16.6 20.92C16.6 23.4 15.84 25.44 14.32 27.04C12.8 28.56 10.88 29.32 8.56 29.32ZM31.84 29.32C29.36 29.32 27.32 28.56 25.72 27.04C24.2 25.44 23.44 23.4 23.44 20.92C23.44 17.8 24.72 14.4 27.28 10.72C29.92 6.96 33.4 3.64 37.72 0.759996L39.52 2.55999C37.84 4.32 36.52 5.96 35.56 7.48C34.6 8.99999 34.12 10.24 34.12 11.2C34.12 11.76 34.28 12.28 34.6 12.76C35 13.24 35.56 13.76 36.28 14.32C37.4 15.2 38.28 16.12 38.92 17.08C39.56 18.04 39.88 19.32 39.88 20.92C39.88 23.4 39.12 25.44 37.6 27.04C36.08 28.56 34.16 29.32 31.84 29.32Z" fill="#6D6D6E"/>
                              </svg>
                              <div class="service-content mt-5">
                                Thanks to your company and the excellent service provided by your delightful employees,

My soft opening went off without a hitch. All the staff provided service with a smile. I would love to recommend your company to anyone who needs a catering service in the future. Please feel free to add my endorsement to any promotional materials you have in the future. 
                              </div>
                              </div>
                              <div class="feedback-card-bottom">
                               <div class="d-flex">
                                <div class="full-flex ma">
                                    <div class="emp-name">
                                     Amani Al Ahmad
                                    </div>
                                    <div class="service-content">
                                      Owner
                                    </div>
                                </div>
                                <div>
                                  <img src="../../assets/images/feedback/coffee.png" alt="">
                                </div>
                               </div>
                              </div>
                          </div>
                   </carousel>
                 </div>
              <div class="text-center mt-5 section-heading" data-aos="fade-up"  data-aos-duration="1000">CUSTOMER  <span class="w500"> FEEDBACK</span></div>
                   <div data-aos="fade-up" class="mt-5"  data-aos-duration="1000">
                    <carousel :responsive="{0:{items:1,nav:false},769:{items:2,nav:false}}"  :autoplay="false"  :loop="true" :dots="false" :nav="false" >
                  <div class="feedback-card">
                              <div class="feedback-card-top cus-feedback">
                                <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8.56 29.32C6.08 29.32 4.04 28.56 2.44 27.04C0.92 25.44 0.16 23.4 0.16 20.92C0.16 17.8 1.44 14.4 4 10.72C6.64 6.96 10.12 3.64 14.44 0.759996L16.24 2.55999C14.56 4.32 13.24 5.96 12.28 7.48C11.32 8.99999 10.84 10.24 10.84 11.2C10.84 11.76 11 12.28 11.32 12.76C11.72 13.24 12.28 13.76 13 14.32C14.12 15.2 15 16.12 15.64 17.08C16.28 18.04 16.6 19.32 16.6 20.92C16.6 23.4 15.84 25.44 14.32 27.04C12.8 28.56 10.88 29.32 8.56 29.32ZM31.84 29.32C29.36 29.32 27.32 28.56 25.72 27.04C24.2 25.44 23.44 23.4 23.44 20.92C23.44 17.8 24.72 14.4 27.28 10.72C29.92 6.96 33.4 3.64 37.72 0.759996L39.52 2.55999C37.84 4.32 36.52 5.96 35.56 7.48C34.6 8.99999 34.12 10.24 34.12 11.2C34.12 11.76 34.28 12.28 34.6 12.76C35 13.24 35.56 13.76 36.28 14.32C37.4 15.2 38.28 16.12 38.92 17.08C39.56 18.04 39.88 19.32 39.88 20.92C39.88 23.4 39.12 25.44 37.6 27.04C36.08 28.56 34.16 29.32 31.84 29.32Z" fill="#6D6D6E"/>
                              </svg>
                              <div class="service-content mt-5">
                                I would like to thank your company for a job well done! The detailed cleaning and care to ensure a superb job is amazing. We were very pleased with Lilly and the rest of the crew, very friendly and considerate. Maybe I can sign a contract for your services once or twice a month in the future.
                              </div>
                              </div>
                              <div class="feedback-card-bottom">
                               <div class="d-flex">
                                <div class="full-flex ma">
                                    <div class="emp-name">
                                      SAMA AL ENEZI
                                    </div>
                                    <!-- <div class="service-content">
                                      Sample text
                                    </div> -->
                                </div>
                              
                               </div>
                              </div>
                          </div>
                          <div class="feedback-card">
                              <div class="feedback-card-top cus-feedback">
                                <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8.56 29.32C6.08 29.32 4.04 28.56 2.44 27.04C0.92 25.44 0.16 23.4 0.16 20.92C0.16 17.8 1.44 14.4 4 10.72C6.64 6.96 10.12 3.64 14.44 0.759996L16.24 2.55999C14.56 4.32 13.24 5.96 12.28 7.48C11.32 8.99999 10.84 10.24 10.84 11.2C10.84 11.76 11 12.28 11.32 12.76C11.72 13.24 12.28 13.76 13 14.32C14.12 15.2 15 16.12 15.64 17.08C16.28 18.04 16.6 19.32 16.6 20.92C16.6 23.4 15.84 25.44 14.32 27.04C12.8 28.56 10.88 29.32 8.56 29.32ZM31.84 29.32C29.36 29.32 27.32 28.56 25.72 27.04C24.2 25.44 23.44 23.4 23.44 20.92C23.44 17.8 24.72 14.4 27.28 10.72C29.92 6.96 33.4 3.64 37.72 0.759996L39.52 2.55999C37.84 4.32 36.52 5.96 35.56 7.48C34.6 8.99999 34.12 10.24 34.12 11.2C34.12 11.76 34.28 12.28 34.6 12.76C35 13.24 35.56 13.76 36.28 14.32C37.4 15.2 38.28 16.12 38.92 17.08C39.56 18.04 39.88 19.32 39.88 20.92C39.88 23.4 39.12 25.44 37.6 27.04C36.08 28.56 34.16 29.32 31.84 29.32Z" fill="#6D6D6E"/>
                              </svg>
                              <div class="service-content mt-5">
                                When I first contacted BLEACH House Keeping Co. I was really impressed with the quality of their work, their dedication to work, their efforts, their friendly staff, and how they will do anything just to make you feel satisfied, I dealt with them in cleaning my house, my workplace and recommended them to my family and all were satisfied with them, thank you to the BLEACH Co. team. 
                              </div>
                              </div>
                              <div class="feedback-card-bottom">
                               <div class="d-flex">
                                <div class="full-flex ma">
                                    <div class="emp-name">
                                      Mariam Al Jarallah
                                    </div>
                                    <!-- <div class="service-content">
                                      Sample text
                                    </div> -->
                                </div>
                             
                               </div>
                              </div>
                          </div>
                           <div class="feedback-card">
                              <div class="feedback-card-top cus-feedback">
                                <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8.56 29.32C6.08 29.32 4.04 28.56 2.44 27.04C0.92 25.44 0.16 23.4 0.16 20.92C0.16 17.8 1.44 14.4 4 10.72C6.64 6.96 10.12 3.64 14.44 0.759996L16.24 2.55999C14.56 4.32 13.24 5.96 12.28 7.48C11.32 8.99999 10.84 10.24 10.84 11.2C10.84 11.76 11 12.28 11.32 12.76C11.72 13.24 12.28 13.76 13 14.32C14.12 15.2 15 16.12 15.64 17.08C16.28 18.04 16.6 19.32 16.6 20.92C16.6 23.4 15.84 25.44 14.32 27.04C12.8 28.56 10.88 29.32 8.56 29.32ZM31.84 29.32C29.36 29.32 27.32 28.56 25.72 27.04C24.2 25.44 23.44 23.4 23.44 20.92C23.44 17.8 24.72 14.4 27.28 10.72C29.92 6.96 33.4 3.64 37.72 0.759996L39.52 2.55999C37.84 4.32 36.52 5.96 35.56 7.48C34.6 8.99999 34.12 10.24 34.12 11.2C34.12 11.76 34.28 12.28 34.6 12.76C35 13.24 35.56 13.76 36.28 14.32C37.4 15.2 38.28 16.12 38.92 17.08C39.56 18.04 39.88 19.32 39.88 20.92C39.88 23.4 39.12 25.44 37.6 27.04C36.08 28.56 34.16 29.32 31.84 29.32Z" fill="#6D6D6E"/>
                              </svg>
                              <div class="service-content mt-5">
                                The Bleach staff arrived on time and worked a full 9 hour shift. They were very professional, friendly and hard working. All of the health safety precautions were taken into account. I was very impressed with the quality of work and the precise detailing. They even removed hard rust stains from the floor and managed to deep clean the upholstery. I recommend using Bleach and no other
                              </div>
                              </div>
                              <div class="feedback-card-bottom">
                               <div class="d-flex">
                                <div class="full-flex ma">
                                    <div class="emp-name">
                                      Dina Al Baijan
                                    </div>
                                    <!-- <div class="service-content">
                                      Sample text
                                    </div> -->
                                </div>
                             
                               </div>
                              </div>
                          </div>
                         
                   </carousel>
                   </div>
             </div>

        </div>
    </section>
    <section class="bcontainer">
        <div class="bp-40">
            <div class="text-center section-heading" data-aos="fade-up"  data-aos-duration="1000">
          OUR <span class="w500">CERTIFICATIONS</span>
        </div>
         <div class="mt-10 about-section"
            style="padding-left: 20%; padding-right: 20%" data-aos="fade-up"  data-aos-duration="1000"
          >
           BLEACH® pioneers in the movement towards greener cleaning products and services. We are certified by global organizations like ISSA, and ISO.
          </div>
         <div >
 <div class="py-10 text-center" data-aos="fade-up"  data-aos-duration="1000" >
            <img src="../../assets/images/certificate/1.1.png" class="mx-1" alt="">
            <!-- <img src="../../assets/images/certificate/1.2.png" class="mx-1" alt="">
            <img src="../../assets/images/certificate/1.3.png" class="mx-1" alt=""> -->
            <img src="../../assets/images/certificate/1.4.png" class="mx-1" alt="">

          </div>
         </div>
          <!-- <div class="py-10  diff-side" data-aos="fade-up"  data-aos-duration="1000" v-if="false">
              <carousel
          :loop='true'
          items="1" 
            :autoplay="true"
            :dots="false"
            :nav="false"
           
          >
            <template  ref="prev"  slot="prev" >
                  <div style="left:-2%" class="arrow-div">
                <v-icon large>
                mdi-chevron-left
              </v-icon>
               </div> 
               </template>
                 <template  ref="next" slot="next"> 
             <div style="right:-2%" class="arrow-div">
                <v-icon large>
                mdi-chevron-right
              </v-icon>
               </div> 
            </template> -
            <div class="text-center">
              <img src="../../assets/images/certificate/1.1.png" class="ma" style="width:auto !important;" alt="">
            </div>
            
            <img src="../../assets/images/certificate/1.2.png"  class="ma" style="width:auto !important;" alt="">
            <img src="../../assets/images/certificate/1.3.png"  class="ma" style="width:auto !important;" alt="">
            <img src="../../assets/images/certificate/1.4.png"  class="ma" style="width:auto !important;"  alt="">
              </carousel>
          </div> -->

          <div class="bmt-30 section-heading text-center" data-aos="fade-up"  data-aos-duration="1000">
              WE <span class="w400">Work with the best partners</span>
          </div>
          <div class="mt-5 px-5" data-aos="fade-up"  data-aos-duration="1000">
              <carousel items="1"  :autoplay="true"  :loop="true" :dots="false" :nav="false">
               <template  ref="prev"  slot="prev" >
                  <div style="left:-2%" class="arrow-div show-head">
                <v-icon large>
                mdi-chevron-left
              </v-icon>
               </div> 
               </template>
              <div class="row">
            <div class="col-lg-2 col-md-4 col-sm-3 col-3  mt-4 text-center">
              <img src="../../assets/images/clients/1.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-3 col-3  mt-4 text-center">
              <img src="../../assets/images/clients/2.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
              <img src="../../assets/images/clients/3.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
              <img src="../../assets/images/clients/4.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
              <img src="../../assets/images/clients/5.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
              <img src="../../assets/images/clients/6.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
              <img src="../../assets/images/clients/7.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
              <img src="../../assets/images/clients/8.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
              <img src="../../assets/images/clients/9.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
              <img src="../../assets/images/clients/10.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
              <img src="../../assets/images/clients/11.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
              <img src="../../assets/images/clients/12.png" alt="" class="img-shadow">
            </div>
          </div>
            <div class="row">
            <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
              <img src="../../assets/images/clients/13.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
              <img src="../../assets/images/clients/14.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
              <img src="../../assets/images/clients/15.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
              <img src="../../assets/images/clients/16.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
              <img src="../../assets/images/clients/17.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
              <img src="../../assets/images/clients/18.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
              <img src="../../assets/images/clients/19.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
              <img src="../../assets/images/clients/20.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
              <img src="../../assets/images/clients/21.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
              <img src="../../assets/images/clients/22.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
              <img src="../../assets/images/clients/23.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
              <img src="../../assets/images/clients/24.png" alt="" class="img-shadow">
            </div>
          </div>
            
           <template  ref="next" slot="next"> 
             <div style="right:-2%" class="arrow-div show-head">
                <v-icon large>
                mdi-chevron-right
              </v-icon>
               </div> 
            </template>
              </carousel>
            
            </div>
      </div>
    </section>
  </div>
  <arabichome v-if="selectlan =='ARABIC'" @openservice="opendial" />
   <v-dialog
   scrollable
        transition="dialog-bottom-transition"
        max-width="1122"
         v-model="dia"
         
      >
      <div class="mp48 h-100" style="background:#fff;position: relative;" v-if="selectlan =='EN'">
        <div class="cart-close">
          <v-icon class="pointer" @click="dia = false">
            mdi-close
          </v-icon>
        </div>
        <div class="text-center section-heading" >
                Our Services  <span class="w400">Category</span>
            </div>
            <div class="service-content text-center py-7 show-full">
         BLEACH® is a technology-driven cleaning company that offers high-end services for the most discerning clients. We deliver exceptional value through its 3-tier cleaning model, featuring uncompromising VIP services, flexible scheduling, and an uber-easy booking experience. 

            </div>

             <div class="pb-15" >
          <div class="row " > 
            <div class="col-lg-3 col-md-6 col-6 mt-10" @click="gotoDc()">
              <div class="service-card">
                  <div class="service-card-circle">
                    <img
                    
                      src="../../assets/icons/services/dc.png"
                      alt=""
                    />
                  </div>
                  <div class="cleaning-card-name text-center show-full">
                    DETAILED CLEANING
                  </div>
                  <div class="cleaning-card-name text-center diff-side">
                    DETAILED <br> CLEANING
                  </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-6 mt-10" @click="gotosp()">
              <div class="service-card">
                  <div class="service-card-circle">
                    <img
                    style="margin-top:-5px"
                       src="../../assets/icons/services/sp.png"
                      alt=""
                    />
                  </div>
                  <div class="cleaning-card-name text-center show-full"  >
                    SPECIAL CARE
                  </div>
                  <div class="cleaning-card-name text-center diff-side"  >
                    SPECIAL <br> CARE
                  </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-6 mt-10" @click="gotoKit()">
              <div class="service-card">
                  <div class="service-card-circle">
                    <img

                      src="../../assets/icons/services/kc.png"
                      alt=""
                    />
                  </div>
                  <div class="cleaning-card-name text-center show-full">
                    KITCHEN CLEANING
                  </div>
                  <div class="cleaning-card-name text-center diff-side">
                    KITCHEN <br> CLEANING
                  </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-6 mt-10" @click="gotoIc()">
              <div class="service-card">
                  <div class="service-card-circle">
                    <img
                    
                      src="../../assets/icons/services/ic.png"
                      alt=""
                    />
                  </div>
                  <div class="cleaning-card-name text-center show-full">
                     PEST CONTROL
                  </div>
                   <div class="cleaning-card-name text-center diff-side">
                     PEST <br> CONTROL
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>  
      <div class="mp48 h-100" style="background:#fff;position: relative;" v-if="selectlan =='ARABIC'">
        <div class="cart-close">
          <v-icon class="pointer" @click="dia = false">
            mdi-close
          </v-icon>
        </div>
        <div class="text-center section-heading" >
                Our Services  <span class="w400">Category</span>
            </div>
            <div class="service-content text-center py-7 show-full">

              إن بليتش® شركة تنظيف تعتمد على التكنولوجيا تقدم خدمات راقية عالية الجودة للعملاء الأكثر تميزًا. نقدم لعملائنا قيمة استثنائية من خلال نموذج التنظيف ثلاثي المستويات، الذي يتميز بأداء فائق الجودة لعملائنا من كبار الشخصيات، والمواعيد المرنة، وتجربة حجز سهلة توفر خيارات رائعة
            </div>

             <div class="pb-15" >
          <div class="row " dir="rtl" > 
            <div class="col-lg-3 col-md-6 col-6 mt-10" @click="gotoDc()">
              <div class="service-card">
                  <div class="service-card-circle">
                    <img
                    
                      src="../../assets/icons/services/dc.png"
                      alt=""
                    />
                  </div>
                  <div class="cleaning-card-name text-center show-full">
                   التنظيف الدقيق
                  </div>
                  <div class="cleaning-card-name text-center diff-side">
                    الدقيق <br> التنظيف
                  </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-6 mt-10" @click="gotosp()">
              <div class="service-card">
                  <div class="service-card-circle">
                    <img
                    style="margin-top:-5px"
                       src="../../assets/icons/services/sp.png"
                      alt=""
                    />
                  </div>
                  <div class="cleaning-card-name text-center show-full"  >
                   الرعاية الخاصة 
                  </div>
                  <div class="cleaning-card-name text-center diff-side"  >
                    الخاصة <br> الرعاية
                  </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-6 mt-10" @click="gotoKit()">
              <div class="service-card">
                  <div class="service-card-circle">
                    <img

                      src="../../assets/icons/services/kc.png"
                      alt=""
                    />
                  </div>
                  <div class="cleaning-card-name text-center show-full">
                   تنظيف المطبخ 
                  </div>
                  <div class="cleaning-card-name text-center diff-side">
                    المطبخ <br> تنظيف 
                  </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-6 mt-10" @click="gotoIc()">
              <div class="service-card">
                  <div class="service-card-circle">
                    <img
                    
                      src="../../assets/icons/services/ic.png"
                      alt=""
                    />
                  </div>
                  <div class="cleaning-card-name text-center show-full">
                    مكافحة انتشار الأمراض 
                  </div>
                   <div class="cleaning-card-name text-center diff-side">
                     الأمراض <br> مكافحة انتشار
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>  
      </v-dialog>
 </div>
</template>

<script>
import arabichome from './arabichome.vue'
import carousel from "../../components/carousel.vue";
import $ from "jquery";
import { mapState } from "vuex";
export default {
  mounted() {

    if(localStorage.getItem('bleach-sp')){
      localStorage.removeItem('bleach-sp')
      this.$nextTick(()=> window.document.getElementById("SPECIALITY").scrollIntoView());
    }
    this.activeDiff = this.differece[0];
    this.activeDiffAr = this.differeceAr[0];
    this.width = screen.width;
    this.dia = this.dialog_box
    //this.dia = true

     this.$store.commit("set_cart", false);
      // $(".main-slide").addClass("slide-animation")
      $(".slider-main-text")
        .addClass("animate__animated animate__fadeInUp")
        .one(
          "webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend",
          function () {
            $(".slider-main-text").removeClass(
              "animate__animated animate__fadeInUp"
            );
          }
        );

      $(".slider-text")
        .addClass("animate__animated animate__fadeInUp animate__delay-08s")
        .one(
          "webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend",
          function () {
            $(".slider-text").removeClass(
              "animate__animated animate__fadeInUp animate__delay-08s"
            );
          }
        );
      $(".btn-trans")
        .addClass("animate__animated animate__fadeInUp animate__delay-1s")
        .one(
          "webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend",
          function () {
            $(".btn-trans").removeClass(
              "animate__animated animate__fadeInUp animate__delay-1s"
            );
          }
        );
  },
  computed: {
      ...mapState({
      dialog_box: state => state.dialog_box,
     
    })
  },
  components: { carousel,arabichome },
  data() {
    return {
      dia:false,
      width:null,
      selectlan: localStorage.getItem("bleach-lang"),
      activeDiff: {},
      activeDiffAr: {},
      differece: [
        {
          id: 1,
          title: "Advanced Customer Care ",
          content:
            "Our customer care service combines the best technology, training, and service to provide an excellent experience. With a team of dedicated professionals operating 24x7, every customer is addressed personally. Our dedicated personal helpdesk agent ensures the smooth execution of their project. Connect with us anytime on WhatsApp. ",
        },
        {
          id: 2,
          title: "Well-Trained Professionals ",
          content:
            "We take pride in calling our cleaners ‘Professionals’. The applied training methods to our crew members at BLEACH® are compliant with ISSA®’s CIMS™ — the world's highest recognized certification in the industry of cleaning and hospitality. Their annual training curriculum covers hands-on training on team management, time management, advanced cleaning and infection control applications, risk management, and an overview of the latest equipment, materials, and supplies. ",
        },
        {
          id: 3,
          title: "Eco-Friendly Services ",
          content:
            "BLEACH® takes pride in its cleaning abilities and turns that over to the hands of its clients by helping to keep the planet clean. We implement efficient cleaning practices to reduce waste and chemical use, use non-toxic, environmentally friendly products, and promote a healthy environment. ",
        },
        {
          id: 4,
          title: "Child-Friendly Products ",
          content:
            "In order to avoid the health risks and hazards of conventional cleaning products to children, we use only all-natural, non-toxic cleaning products. Our cleaning chemicals (Green Seal/EPA certified) are free of harmful chemicals which could poison, irritate or harm your child. Our cleaning chemicals are free from BLEACH, phthalates, phosphates, petroleum solvents & triclosan (we only use it in special cases and after the client’s approval). ",
        },
        {
          id: 5,
          title: "Pet-Friendly Methods ",
          content:
            "BLEACH® loves pets as much as you do. We adhere to best cleaning practices at home or facility. Our cleaning products are largely pet-friendly, containing minimal or no ammonia or BLEACH since they are toxic to them. Our products are safe around and do not irritate them with allergies or sensitivities.   ",
        },
        {
          id: 6,
          title: "Insurance Coverage for Customers & Staff ",
          content:
            "At BLEACH®, we value our customers and staff equally. We provide insurance coverage to clients’ assets, safeguarding them from any mishap and accidental damages at the time of our service visit.",
        },
      ],
      differeceAr: [
        {
          id: 1,
          title: "رعاية العمالء المتقدمة",
          content:
            "تجمع خدمة رعاية العمالء لدينا بين أفضل التقنيات والتدريبات والخدمات لمنح العميل تجربةممتازة. بوجود فريق من المحترفين المتخصصين الذين يعملون على مدار الساعة طوال أياما معاملةا ضابط خدمة العمالء األسبوع، نمنح كل عميل من عمالئن خاصة في شركتنا بليتش.. يضمنّي وقت عبر الواتساب.التنفيذ السلس للخدمة التي يطلبها عميلنا بكل إخالص. تواصل معنا في ",
        },
        {
          id: 2,
          title: "محترفون مدربون تدريباً عاليا",
          content:
            "نفخر بإطالق اسم " +
            "المحترفين" +
            " على عمال النظافة لدينا. تتوافق أساليب التدريب المطبقة على أفرادطاقمنا في بليتشR مع معايير CIMS s®'ISSA - ™وهي أعلى الشهادة معترف بها في العالمفي مجال التنظيف والضيافة. يغطي منهج التدريب السنوي التدريب العملي على إدارة الفريق و إدارةالوقت والتطبيقات المتقدمة للتنظيف ومكافحة انتشار األمراض وإدارة المخاطر، ويطلعون علىأحدث المعدات والمواد واإلمدادات ضمن هذا المجال. ",
        },
        {
          id: 3,
          title: "خدمات صديقة للبيئة",
          content:
            "تفخر بليتشR بما لديها من قدرات على التنظيف تمنحها لعمالئها مع ضمان الحفاظ على نظافةالكوكب. إننا نطبق ممارسات تنظيف فعالة للح ّد من النفايات واستخدام المواد الكيميائية، واستخدامالمنتجات غير سامة وصديقة البيئة، وتعزيز البيئة الصحية.",
        },
        {
          id: 4,
          title: "منتجات صديقة للطفولة",
          content:
            "من أجل تجنب المخاطر الصحية ومخاطر منتجات التنظيف التقليدية على األطفال، فإننا نستخدمفقط منتجات التنظيف الطبيعية غير السامة. مواد التنظيف الكيميائية المعتمدة لدينا )معتمدة منمنظمة غرين سيل/ وكالة حماية البيئة( خالية من المواد الكيميائية الضارة التي يمكن أن تسمم الطفلأو تسبب له التهيج أو األذى. مواد التنظيف الكيميائية المعتمدة لدينا خالية من المبيضات والفثاالتوالفوسفات ومذيبات البترول والتريكلوسان )نستخدمها فقط في حاالت خاصة وبعد موافقة العميل(.",
        },
        {
          id: 5,
          title: "وسائل صديقة للحيوانات األليفة",
          content:
            "تحب بليتشR الحيوانات األليفةوتسعى لضمان سالمتها مثلكم تماما. ونحن ملتزمون باعتماد أفضل اممارسات التنظيف في المنزل أو المنشأة. منتجات التنظيف المعتمدة لدينا مناسبة إلى ح ّد كبيرللحيوانات األليفة، وتحتوي على الح ّد األدنى أو خالية من األمونيا أو المبيض ألنها قد تسبب التسممبسبب المواد المثيرة لالهتياج أو الحساسية.لهم. منتجاتنا آمنة للمحيط وال تسبب تهيجا",
        },
        {
          id: 6,
          title: "التغطية التأمينية للعمالء والموظفين",
          content:
            "نحن نقدر عمالءنا وموظفينا بالقد نفسه في شركة بليتش R. نقدم تغطية تأمينية ألصول العمالء،ونحميهم من وقوع أي حوادث أو أضرار عرضية أثناء زيارتنا لتقديم خدماتنا لهم.كما أن جميع الفنيين العاملين لدى بليتش مشمولون بالتأمين على الحياة. باإلضافة إلى ذلك، نحتفظبسجل خدمة لتحسين جودة خدماتنا لمنحك رضا بنسبة 100 ٪ في الزيارة التالية. هذا يعني أنه معبليتشR ال توجد مخاطر على عمالئنا الذين يضمنون دائ اما أفضل الخدمات.",
        },
      ],
    };
  },
  methods: {
opendial(){
  this.dia = true
},
    isCar(){
      //console.log(this.width)
      if(this.width != null){
        if(this.width<768){
          return true
        }
        return false
      }
      return false
    },
    changeItm(event) {
      //console.log(event)
      this.activeDiff = this.differece[event.item.index];
    },
    gotoDc(){
      localStorage.setItem('bleach-dc',true)
      this.gotoMain('service/detailedcleaning')
    },
    gotosp(){
      localStorage.setItem('bleach-dc',true)
      this.gotoMain('service/specialcare')
    },
    gotoKit(){
      localStorage.setItem('bleach-dc',true)
      this.gotoMain('service/kitchencleaning')
    },
     gotoIc(){
      localStorage.setItem('bleach-dc',true)
      this.gotoMain('service/pestcontrol')
    },
    gotoMain(loc) {
      window.location = window.location.href.split("/")[0] + "/" + loc;
    },
    goto(loc) {
      window.location = loc;
    },
    // changeItm2(event){
    //     //console.log(event)
    // },
    changeDiff(id) {
      this.activeDiff = this.differece[id];
      this.activeDiffAr = this.differeceAr[id];
    },
    changeIn(){
      ////console.log("changeIn");
  
    },
    changed() {
      ////console.log(e)
      if(screen.width > 991){
        $(".main-slide").css("background-size","100% 100%")
      }
      
      $(".slider-main-text")
        .addClass("animate__animated animate__fadeInUp")
        .one(
          "webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend",
          function () {
            $(".slider-main-text").removeClass(
              "animate__animated animate__fadeInUp"
            );
          }
        );
      $(".slider-text")
        .addClass("animate__animated animate__fadeInUp animate__delay-08s")
        .one(
          "webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend",
          function () {
            $(".slider-text").removeClass(
              "animate__animated animate__fadeInUp animate__delay-08s"
            );
          }
        );
      $(".btn-trans")
        .addClass("animate__animated animate__fadeInUp animate__delay-1s")
        .one(
          "webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend",
          function () {
            $(".btn-trans").removeClass(
              "animate__animated animate__fadeInUp animate__delay-1s"
            );
          }
        );
        if(screen.width > 991){
                $(".main-slide").addClass("slide-animation")
         }

    },
  },
};
</script>

<style>
/* .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 0px !important;
    z-index: 999 !important;
    position: absolute !important; 
    bottom: 100px !important;
    margin-left: auto !important;
margin-right: auto !important;
left: 0 !important;
right: 0 !important;
text-align: center !important;
}
.owl-theme .owl-dots .owl-dot span {
background:#fff;
}
.owl-dot.active span{
      width: 10px !important;6000
    background: #2B9FAD !important
} */
</style>
// 6000screen.width > 991