<template>
<div>
    <div v-if="selectlan == 'EN'">
      <div class="gray-bg-2">
      <div class="bcontainer">
         <div class="row py-15">
             <div class="col-md-4 col-lg-3">
                 <ul class="service-ui show-head">

                     <li class="service-ui-active">
                         <div class="dot-li-active"></div>
                       Industrial Sector
                    </li>
                    <li @click="$router.push('corporate')" >
                         <span class="dot-li"></span>
                        Corporate Offices
                    </li>
                    <li @click="$router.push('education')" >
                         <span class="dot-li"></span>
                        Educational Facilities
                    </li>
                     <li @click="$router.push('health')" >
                         <span class="dot-li"></span>
                        Health Care Sector
                    </li>
                    <li @click="$router.push('retail')" >
                         <span class="dot-li"></span>
                        Retail Sector
                    </li>
                    <li @click="$router.push('sports')" >
                         <span class="dot-li"></span>
                        Sports & Fitness
                    </li>
                    <li @click="$router.push('foods')" >
                         <span class="dot-li"></span>
                        Foods & Beverages
                    </li>
                     <li @click="$router.push('hotels')" >
                         <span class="dot-li"></span>
                        Hotels & Luxury
                    </li>
                 </ul>
                 <commenu :name="'INDUSTRIAL SECTOR MAINTENANCE'"/>
                 <div class="mt-5">
                    <div class="recommended-head">
                        RECOMMENDED SERVICES
                    </div>
                    <div class="rec-item-name mt-3"  @click="goto('/service/detailedcleaning/deepcleaning')">
                        <img src="../../assets/icons/recommend/deep.png" alt="" class="ml-2 mr-2">  Deep Cleaning
                    </div>
                    <div class="rec-item-name mt-3" @click="goto('/service/detailedcleaning/generalcleaning')">
                        <img src="../../assets/icons/recommend/general.png" alt="" class="ml-2 mr-2">  General Cleaning
                    </div>
                    <div class="rec-item-name mt-3" @click="goto('/service/pestcontrol/pestcontrol')">
                        <img src="../../assets/icons/recommend/inf.png" alt="" class="ml-2 mr-2">  Pest Control
                    </div>
                 </div>
             </div>
             <div class="col-md-8 col-lg-9">
                 <div class="cleaning-head show-head">INDUSTRIAL SECTOR <span class="w400">MAINTENANCE</span> </div>
                   <div class="sub-content mt-2">
                   Maintaining a clean and safe workspace is the core of our Industrial Cleaning Services. We
offer commercial cleaning services to manufacturing, warehousing, and production facilities.
Our extensive range of services can be customized to meet your cleaning demands. Some of
our industrial cleaning services include, but not limited to:
                    <div class="row mt-5">
                    <div class="col-md-6">
                        <ul class="content-ui">
                            <li>Common Areas</li>
                            <li>Entrance Areas</li>
                            <li>Public Areas</li>
                            <li>Floors</li>
                            <li>Restrooms</li>
                            <li>Surfaces</li>
                            <li>Office cubicles</li>
                            <li>Conference rooms</li>
                        </ul>
                    </div>
                    <div class="col-md-6">
                         <ul class="content-ui">
                            <li>Labs</li>
                            <li>Locker rooms</li>
                            <li>Loading docks</li>
                            <li>High-level vacuuming and dusting</li>
                            <li>Warehouses</li>
                            <li>Cafeterias</li>
                            <li>Storage areas</li>
                            <li>Industrial equipment</li>
                        </ul>
                    </div>
                    </div>

                 </div>
                 <div class="bold-heading mt-10">
                     DID YOU KNOW ?
                 </div>
                 <div class="mt-3 sub-content">
                    In the fast-paced world of manufacturing and warehousing, productivity is critical. Poorly
maintained facilities can lead to wasted material, lost time, higher turnover, and lower
morale.

                 </div>
                  <div class="bold-heading mt-10">
                     BENEFITS OF A CLEAN INDUSTRIAL SETUP
                 </div>
                 <div class="mt-5 sub-content">
                     A thorough cleaning of industrial equipment reduces wear and tear, removes any harmful
residues, and keeps machines in good working order for regular use.
                 </div>
             </div>
         </div>
      </div>
  </div>
  <!-- <div class="bcontainer">
      <div class="py-15">
          <div class="recommended">
          RECOMMENDED SERVICES
      </div>
      <div class="mt-5">
          <div class="row">
              <div class="col-lg-3 col-md-4 rec-div mt-5">
                  <div class="d-flex">
                      <div   class="diff-icons-2">
                          <img src="../../assets/icons/rec.png" alt="">
                      </div>
                      <div class="rec-text">
                          General Cleaning
                          
                      </div>
                  </div>
              </div>
               <div class="col-lg-3 col-md-4 rec-div mt-5">
                  <div class="d-flex">
                      <div   class="diff-icons-2">
                          <img src="../../assets/icons/rec.png" alt="">
                      </div>
                      <div class="rec-text">
                          General Cleaning
                          
                      </div>
                  </div>
              </div>
               <div class="col-lg-3 col-md-4 rec-div mt-5">
                  <div class="d-flex">
                      <div   class="diff-icons-2">
                          <img src="../../assets/icons/rec.png" alt="">
                      </div>
                      <div class="rec-text">
                          General Cleaning
                          
                      </div>
                  </div>
              </div>
          </div>
      </div>
      </div>
  </div> -->
</div>
 <div v-if="selectlan == 'ARABIC'">
      <div class="gray-bg-2">
      <div class="bcontainer">
         <div class="row py-15">
             <div class="col-md-4 col-lg-3">
                 <ul class="service-ui-ar show-head">

                     <li class="service-ui-active arabic-font">
                         <div class="dot-li-ar-active"></div>
                       صيانة القطاع الصناعي 
                    </li>
                    <li class="arabic-font" @click="$router.push('corporate')" >
                         <span class="dot-li-ar"></span>
                        مقرات ومكاتب الشركات والمؤسسات
                    </li>
                    <li class="arabic-font" @click="$router.push('education')" >
                         <span class="dot-li-ar"></span>
                        صيانة المرافق التربوية والتعليمية 
                    </li>
                     <li class="arabic-font" @click="$router.push('health')" >
                         <span class="dot-li-ar"></span>
                       الإهتمام بقطاع الرعاية الصحية 
                    </li>
                    <li class="arabic-font" @click="$router.push('retail')" >
                         <span class="dot-li-ar"></span>
                        خدمات النظافة لقطاع البيع بالتجزئة
                    </li>
                    <li class="arabic-font" @click="$router.push('sports')" >
                         <span class="dot-li-ar"></span>
                       رياضة ولياقة بدنية
                    </li>
                    <li class="arabic-font" @click="$router.push('foods')" >
                         <span class="dot-li-ar"></span>
                        صيانة مرافق التغذية والمرطبات 
                    </li>
                     <li class="arabic-font" @click="$router.push('hotels')" >
                         <span class="dot-li-ar"></span>
                       قطاع الفنادق والرفاهية
                    </li>
                 </ul>
                 <commenu :name="'صيانة القطاع الصناعي'"/>
             </div>
             <div class="col-md-8 col-lg-9">
                 <div class="cleaning-head show-head arabic-font">صيانة القطاع <span class="w400">الصناعي</span> </div>
                   <div class="sub-content mt-2 arabic-font">
                    الحفاظ على مكان عمل نظيف وآمن هو جوهر خدمات التنظيف الصناعية التي نقدمها، فنحن نقدم خدمات التنظيف التجارية لمرافق التصنيع والتخزين والإنتاج، كما يمكن لنا تخصيص مجموعتنا الواسعة من الخدمات لتلبية متطلبات التنظيف الخاصة بكم، وتشمل خدمات التنظيف الصناعي لدينا، على سبيل المثال لا الحصر خدمات التنظيف للمواقع التالية:
                    <div class="row mt-5">
                    <div class="col-md-6">
                        <ul class="content-ui pr-5">
                            <li class="arabic-font">    المساحات المشتركة </li>
                            <li class="arabic-font">    مساحات الاستقبال والدخول </li>
                            <li class="arabic-font">المساحات العامة </li>
                            <li class="arabic-font">     أدوار المباني </li>
                            <li class="arabic-font"> المراحيض</li>
                            <li class="arabic-font">    الأسطح </li>
                            <li class="arabic-font">     مقصورات المكاتب </li>
                            <li class="arabic-font">      غرف الإجتماعات </li>
                           








                        </ul>
                    </div>
                    <div class="col-md-6">
                         <ul class="content-ui">
                             
                            <li class="arabic-font">      المعامل </li>
                            <li class="arabic-font">      غرف الخزائن المغلقة </li>
                            <li class="arabic-font">          أرصفة تحميل </li>
                            <li class="arabic-font">              عمليات عالية المستوى للكنس بمكانس كهربائية وإزالة الأتربة  </li>
                            <li class="arabic-font">                  المستودعات </li>
                            <li class="arabic-font"> المقاصف </li>
                            <li class="arabic-font"> مواقع التخزين </li>
                            <li class="arabic-font">     المعدات الصناعية </li>
                        </ul>
                    </div>
                    </div>

                 </div>
                 <div class="bold-heading mt-10 arabic-font">
                     هل تعلم؟
                 </div>
                 <div class="mt-3 sub-content arabic-font">
                     الإنتاجية أمر بالغ الأهمية في عالم التصنيع والمستودعات سريع الخطى، فيمكن أن تؤدي المرافق التي لا يتم صيانتها بشكل جيد إلى إهدار المواد، وضياع الوقت، وزيادة معدل تدوير العمال، وانخفاض الروح المعنوية. 
                 </div>
                  <div class="bold-heading mt-10 arabic-font">
                     فوائد اإلعداد النظيف للمواقع صناعية
                 </div>
                 <div class="mt-5 sub-content arabic-font">
                    يقلل التنظيف الشامل للمعدات الصناعية من الإنهاك والتشقق، ويزيل أي بقايا ضارة، ويحافظ على المعدات في حالة عمل جيدة للاستخدام المنتظم. 
                 </div>
             </div>
         </div>
      </div>
  </div>
  
</div>

</div>
</template>

<script>
import commenu from '../../components/menu/commenu.vue'
export default {
    components:{
        commenu
    },
     data(){
        return{
            selectlan:localStorage.getItem('bleach-lang')
        }
    },
    methods:{
         goto(loc) {
         window.location = loc;
        },
    }
}
</script>

<style>

</style>