<template>
 <div>
  <div>
      <section class=""  >
         <div class="bg-header-img-contact bg-header  ">
         </div>
      </section>
  </div>
    <div  v-if="selectlan == 'EN'">

    <div class="bcontainer">
      <div class="py-15">
        <div class="page-head">
          Contact <span class="w500">US</span>
        </div>
        <div class="sub-content ">
          Thank you for your interest in BLEACH® cleaning services. You have safely landed on our ‘Connection Junction’. Here, our professionally trained and prompt customer service team gladly assists you on your journey to a cleaner and healthier surrounding with us.We at BLEACH® take pride in being one of the most comprehensive cleaning services providers in Kuwait. You can Call and WhatsApp us or email us at customercare@bleach-kw.comfor a quick resolution to all your questions and concerns by our 24/7 customer care personnel.
        </div>
        <div >
         
          <div class="gray-bg-2 contact-form mt-10">
            <div class="text-center form-heading">BUSINESS INQUIRIES</div>
            <div class="sub-content text-center mt-5 mpx-15">
              We are just a few steps away to assist you with all your business queries. Simply fill in your details,and our Sales Team will connect with you in 24 hours.
            </div>
             <v-alert
             v-if="error"
             class="mt-5"
      dense
      text
      type="error"
    >
      Something went wrong submission failed
    </v-alert>
             <v-alert
             v-if="success"
             class="mt-5"
      dense
      text
      type="success"
    >
      your enquiry has been submitted successfully
    </v-alert>
            <div class="row mt-5" v-if="!success">
              <div class="col-md-6 mt-5 text-center">
                <input type="text" v-validate="'required'" name="name" data-vv-as="First name" placeholder="First Name" class="contact-fields" v-model="fname" >
                 <span class="red-color">{{ errors.first('name') }}</span>
              </div>
              <div class="col-md-6 mt-5 text-center">
                <input type="text" placeholder="Last Name" v-validate="'required'" name="lname" data-vv-as="Last name" class="contact-fields" v-model="lname">
                 <span class="red-color">{{ errors.first('lname') }}</span>

              </div>

              <div class="col-md-6 mt-5 text-center">
                <input type="email" placeholder="Email" v-validate="'required|email'" name="mail" data-vv-as="Email"  class="contact-fields" v-model="mail.customer_email">
                 <span class="red-color">{{ errors.first('mail') }}</span>

              </div>
              <div class="col-md-6 mt-5 text-center">
                <input type="number" placeholder="Phone No." class="contact-fields" v-validate="'required'" name="phone" data-vv-as="Phone" v-model="mail.customer_contact">
                 <span class="red-color">{{ errors.first('phone') }}</span>

              </div>
              <div class="col-md-6 mt-5 text-center">
                <input type="text" v-validate="'required'" name="org" data-vv-as="Organization" placeholder="Organization" class="contact-fields" v-model="mail.customer_organization" >
                 <span class="red-color">{{ errors.first('org') }}</span>
              </div>
              <div class="col-md-6 mt-5 text-center">
                 <v-select
                 v-model="mail.customer_inquiry_type"
                 v-validate="'required'" name="type" data-vv-as="Inquiry Type " 
                 height="60"
                 class='elevation-0'
          :items="items"
          label="Inquiry Type"
          solo
        ></v-select>
         <span class="red-color">{{ errors.first('type') }}</span>

              </div>
               <div class="col-md-12  text-center">
                <textarea  placeholder="Message" rows="5" class="contact-fields" v-validate="'required'" name="msg" data-vv-as="Message" v-model="mail.customer_message"></textarea>
                 <span class="red-color">{{ errors.first('msg') }}</span>

              </div>
              <div class="col-md-12 mt-10 text-center">
                 <button class="btn-sub" @click="onSubmit()"><span v-if="!loader">Submit</span>  <v-progress-circular
                 v-else
      indeterminate
      color="#ffffff"
    ></v-progress-circular></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
   <div  v-if="selectlan == 'ARABIC'" dir="rtl">
    
    <div class="bcontainer">
      <div class="py-15">
        <div class="page-head arabic-font">
          اتصل <span class="w500">بنا</span>
        </div>
        <div class="sub-content arabic-font">
          نشكرك على اهتمامك بخدمات التنظيف التي تقدمها شركة بليتش®. ويسرنا أنّك نجحت في الوصول إلى "رابط الاتصال" بنا. ستجد هنا فريقاً مدرباً ومحترفاً في خدمة العملاء يقدم خدماته لك آنياً ويساعدك بكل ترحابٍ بضمان العيش في مكان أكثر نظافة وصحة.
<br>
ونفخر في شركة بليتش® بأننا من بين أفضل مزودي خدمات التنظيف الشاملة في الكويت.
<br>
يمكنك الاتصال بنا عبر الواتساب أو مراسلتنا عبر البريد الإلكتروني على العنوان <a style="text-decoration:none !important;" href="mailto:customercare@bleachw.com">customercare@bleachw.com</a> للحصول على جواب سريع بشأن جميع تساؤلاتك ومخاوفك من قبل موظفي خدمة العملاء على مدار 24 ساعة يوميًا/7 أيام أسبوعيًا.
        </div>
        <div >
         
          <div class="gray-bg-2 contact-form mt-10">
            <div class="text-center form-heading arabic-font">استفسارات للخدمات التجارية</div>
            <div class="sub-content text-center mt-5 mpx-15 arabic-font">
              نحن على أهبة الاستعداد لمساعدتك في الإجابة على جميع استفساراتك. ما عليك سوى ملء البيانات، وسيتواصل معك فريق المبيعات خلال 24 ساعة.
            </div>
             <v-alert
             v-if="error"
             class="mt-5"
      dense
      text
      type="error"
    >
      Something went wrong submission failed
    </v-alert>
             <v-alert
             v-if="success"
             class="mt-5"
      dense
      text
      type="success"
    >
      your enquiry has been submitted successfully
    </v-alert>
            <div class="row mt-5" v-if="!success">
              <div class="col-md-6 mt-5 text-center">
                <input type="text" v-validate="'required'" name="name" data-vv-as="First name" placeholder="First Name" class="contact-fields arabic-font" v-model="fname" >
                 <span class="red-color">{{ errors.first('name') }}</span>
              </div>
              <div class="col-md-6 mt-5 text-center">
                <input type="text" placeholder="Last Name" v-validate="'required'" name="lname" data-vv-as="Last name" class="contact-fields arabic-font" v-model="lname">
                 <span class="red-color">{{ errors.first('lname') }}</span>

              </div>

              <div class="col-md-6 mt-5 text-center">
                <input type="email" placeholder="Email" v-validate="'required|email'" name="mail" data-vv-as="Email"  class="contact-fields arabic-font" v-model="mail.customer_email">
                 <span class="red-color">{{ errors.first('mail') }}</span>

              </div>
              <div class="col-md-6 mt-5 text-center">
                <input type="number" placeholder="Phone No." class="contact-fields arabic-font" v-validate="'required'" name="phone" data-vv-as="Phone" v-model="mail.customer_contact">
                 <span class="red-color">{{ errors.first('phone') }}</span>

              </div>
              <div class="col-md-6 mt-5 text-center">
                <input type="text" v-validate="'required'" name="org" data-vv-as="Organization" placeholder="Organization" class="contact-fields arabic-font" v-model="mail.customer_organization" >
                 <span class="red-color">{{ errors.first('org') }}</span>
              </div>
              <div class="col-md-6 mt-5 text-center">
                 <v-select
                 v-validate="'required'" name="type" data-vv-as="Inquiry Type " 
                 height="60"
                 class='elevation-0 arabic-font'
          :items="items"
          label="Inquiry Type"
          solo
          v-model="mail.customer_inquiry_type"
        ></v-select>
         <span class="red-color">{{ errors.first('type') }}</span>

              </div>
               <div class="col-md-12  text-center">
                <textarea  placeholder="Message" rows="5" class="contact-fields arabic-font" v-validate="'required'" name="msg" data-vv-as="Message" v-model="mail.customer_message"></textarea>
                 <span class="red-color">{{ errors.first('msg') }}</span>

              </div>
              <div class="col-md-12 mt-10 text-center">
                 <button class="btn-sub arabic-font" @click="onSubmit()"><span v-if="!loader">Submit</span>  <v-progress-circular
                 v-else
      indeterminate
      color="#ffffff"
    ></v-progress-circular></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 </div>
</template>

<script>
import service from '../../services/service'
//import pageheading from "../../components/pageheading.vue";
export default {
  components: {  },
  data(){
      return{
        selectlan:localStorage.getItem('bleach-lang'),
        items:['Career Seekers','Investor Relations','Media','Partners','Request for Service'],
        success:false,
        error:false,
        lname:'',
        fname:'',
        loader:false,
          btnselected:'',
          mail:{
            customer_name:'', customer_contact:'', customer_email:'', customer_message:'',customer_organization:'', customer_inquiry_type:''
          }
      };
  },
  methods:{
    async sentMailReq(){
      this.mail.customer_name = this.fname+' '+this.lname
      this.loader = true
      let result = await service._post('api/website-inquiry-mail/',this.mail);
      if(result.status == 200){

        this.success = true
        this.mail = {
            customer_name:'', customer_contact:'', customer_email:'', customer_message:'',customer_organization:'', customer_inquiry_type:''
          }
          this.lname=''
          this.fname=''
      }else{
        this.error = true
      }
      this.loader = false
      //console.log(result)
    },
          onSubmit() {
      this.$validator.validateAll().then(result => {
        //console.log(result)
        if (!result) {
          return;
        }
        this.sentMailReq()
      });
    },
        validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
  }
};
</script>

<style lang="scss">
.v-label {
        font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    text-transform: lowercase !important;
    color: rgba(138, 138, 138, 0.5) !important;
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
        color: rgba(138, 138, 138, 1) !important;

}
.theme--light.v-select .v-select__selections {
   color: rgba(138, 138, 138, .5) !important;
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot{
  box-shadow: none !important;
}
.red-color{
  color: red;
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
}
.vertical {
  padding-top: 10px;
  border-left: 1px solid #5d5d5d;
  height: 80%;
}
.active-btn-form{
background: #E0F6F9 !important;
color: #2B9FAD !important;
}
</style>
