<template>
            <div>
                <div  class="show-callus" v-if="selectlan == 'EN'">
              <div class="about-cleaning">
                 Sectores Under Commercial
               </div>
               <div class=" py-2 mb-3" style="border-bottom:1px solid #C4C4C4;">
              <div class="d-flex pointer " @click="dc = !dc">
                 <div class="mobile-sub-link mobile-sub-header h-100 ma">
                 {{name}}
               </div>
               <div>
                 <img class="mr-1" :class="{ 'fa-arrow-down-open': dc }" src="../../assets/ar.png" alt="">
               </div>
              </div>
            </div>
            <div class="drop-service">
               <transition-expand>
              <div v-if="dc" >
                <ul class="drop-list">
                    <li v-if="'INDUSTRIAL SECTOR MAINTENANCE' != name" @click="$router.push('industrial')">
                                  <a>
                                    Industrial Sector
                                  </a>
                    </li>
                    <li v-if="'Corporate Offices' != name" @click="$router.push('corporate')">
                        <a>
                                    Corporate Offices
                        </a>
                    </li>
                    <li v-if="'EDUCATIONAL FACILITIES MAINTENANCE' != name" @click="$router.push('education')">
                        <a>
                          Educational Facilities
                          </a>
                    </li>
                    <li v-if="'HEALTH SECTOR CARE' != name" @click="$router.push('health')">
                      <a>
                        Health Care Sector</a>
                    </li>
                     <li v-if="'RETAIL SECTOR CLEANING' != name" @click="$router.push('retail')">
                      <a>
                        Retail Sector</a>
                    </li>
                    <li v-if="'SPORTS & FITNESS FACILITIES MAINTENANCE' != name" @click="$router.push('sports')">
                      <a>
                        Sports & Fitness</a>
                    </li>
                    <li v-if="'FOOD & BEVERAGES FACILITY MAINTENANCE' != name" @click="$router.push('foods')">
                      <a>
                        Foods & Beverages</a>
                    </li>
                    <li v-if="'HOTEL & LUXURY SECTOR' != name" @click="$router.push('hotels')">
                      <a>
                        Hotels & Luxury</a>
                    </li>
                </ul>
              </div> 
            </transition-expand>
            </div>
            </div>
              <div  class="show-callus" v-if="selectlan == 'ARABIC'">
              <div class="about-cleaning">
                 Sectores Under Commercial
               </div>
               <div class=" py-2 mb-3" style="border-bottom:1px solid #C4C4C4;">
              <div class="d-flex pointer " @click="dc = !dc">
                 <div class="mobile-sub-link mobile-sub-header h-100 ma">
                 {{name}}
               </div>
               <div>
                 <img class="mr-1" :class="{ 'fa-arrow-down-open': dc }" src="../../assets/ar.png" alt="">
               </div>
              </div>
            </div>
            <div class="drop-service">
               <transition-expand>
              <div v-if="dc" >
                <ul class="drop-list">
                    <li v-if="'صيانة القطاع الصناعي' != name" @click="$router.push('industrial')">
                                  <a>
                                    صيانة القطاع الصناعي
                                  </a>
                    </li>
                    <li v-if="'مقرات ومكاتب الشركات والمؤسسات' != name" @click="$router.push('corporate')">
                        <a>
                                    مقرات ومكاتب الشركات والمؤسسات
                        </a>
                    </li>
                    <li v-if="'صيانة المرافق التربوية والتعليمية' != name" @click="$router.push('education')">
                        <a>
                          صيانة المرافق التربوية والتعليمية
                          </a>
                    </li>
                    <li v-if="'اإلهتمام بقطاع الرعاية الصحية ' != name" @click="$router.push('health')">
                      <a>
                        الإهتمام بقطاع الرعاية الصحية </a>
                    </li>
                     <li v-if="'خدمات النظافة لقطاع البيع بالتجزئة' != name" @click="$router.push('retail')">
                      <a>
                        خدمات النظافة لقطاع البيع بالتجزئة</a>
                    </li>
                    <li v-if="'صيانة مرافق ممارسة األلعاب الرياضية والبدنية ' != name" @click="$router.push('sports')">
                      <a>
                        صيانة مرافق ممارسة األلعاب الرياضية والبدنية </a>
                    </li>
                    <li v-if="'صيانة مرافق التغذية والمرطبات' != name" @click="$router.push('foods')">
                      <a>
                        صيانة مرافق التغذية والمرطبات</a>
                    </li>
                    <li v-if="'قطاع الفنادق والرفاهية' != name" @click="$router.push('hotels')">
                      <a>
                        قطاع الفنادق والرفاهية </a>
                    </li>
                </ul>
              </div> 
            </transition-expand>
            </div>
            </div>
            </div>
</template>

<script>
export default {
    props:['name'],
    data(){
        return{
            dc:false,
            selectlan:localStorage.getItem('bleach-lang')
        };
    }
}
</script>

<style>

</style>