<template>
 <div>
  <div>
      <section class=""  >
         <div class="bg-header-img-dc bg-header  ">
         </div>
      </section>
  </div>
    <div v-if="selectlan =='EN'">
    
         <div class="bcontainer">
      <div class="pmy-15">
        <div class="page-head">
                DETAILED  <span class="w400">CLEANING</span>
              </div>
        <div class="sub-content mt-2 text-left">
          BLEACH® goes ‘microscopic’ when it comes to cleaning your domestic and commercial space
– every inch matters to us. We offer a highly advanced alternative to traditional cleaning
methods that focuses on consistency and not just convenience. <span v-if="!dc" style="color:#2B9FAD" @click="dc = true">Read more</span>

  <transition-expand>
<div v-if="dc">
  <br><br>
  Covid times have made us understand the importance of home and office hygiene more
than ever. BLEACH® provides you with expert detailed cleaning services by a team of
experienced cleaners that are guaranteed to leave your place immaculately clean. Our
cleaning methods and an array of specialized tools & equipment promise a world-class
service with all-natural solutions that are safe for your family and the environment.
<br><br>
A look at our key Detailed Cleaning Services:
<br><br>
<ul class="content-ui">
  <li> Our General Cleaning service takes care of overall sanitary maintenance.</li>
  <li> Full Deep Cleaning removes all sticky residue, stains, and stubborn grime
from every crack and crevice.</li>
<li>
  Storage Cleaning will leave your storage space racks and aisles spotless from
dry dust or dirt
</li>
<li>
  Facade and Window Cleaning services require seasonal maintenance due to
yearly weather conditions, which is why we clean them to keep off microbes and
bacteria from getting indoors
</li>
<li>
  Outdoor areas with our Car Parking Shades and Outdoor Cleaning are a
continuous cycle to the facade but they additionally assist in reaching out into
cleaning aisles, fences, and more
</li>

</ul>
<div v-if="width<768">
  <span style="color:#2B9FAD" v-if="dc" @click="dc = false">Read less</span>
</div>
</div>

  </transition-expand>
        </div>
      </div>
      <div>
   

      </div>
    </div>
    <div id="id_sec">
       <router-view/>

    </div>
  </div>
   <div v-if="selectlan=='ARABIC'">
      
         <div class="bcontainer">
      <div class="pmy-15">
         <div class="page-head arabic-font" dir="rtl">
                التنظيف  <span class="w400">الدقيق</span>
              </div>
        <div class="sub-content mt-2 arabic-font" dir="rtl">
          ترتقي بليتش إلى مستوى الدقة المجهرية عندما يتعلق الأمر بتنظيف منزلك ومكان عملك – نعتني بكل بوصة باهتمام فائق. نقدم بديلاً متقدمًا للغاية عن طرق التنظيف التقليدية التي تركز على التناسق وليس فقط الراحة.
          <span v-if="!dc" style="color:#2B9FAD" @click="dc = true">المزيد</span>
  <transition-expand>
<div v-if="dc">
<br><br>
لقد جعلنا وباء كوفيد نفهم أهمية النظافة المنزلية والمكتبية أكثر من أي وقت مضى. توفر لك بليتش® خدمات تنظيف دقيقة على يد فريق من عمال النظافة المتمرسين الذين يضمنون لك جعل منزلك ومكان عملك نظيفًا تمامًا. إن طرق التنظيف المعتمدة لدينا إلى جانب مجموعة الأدوات والمعدات المتخصصة تضمن لك خدمة عالمية المستوى مع حلول طبيعية بالكامل وآمنة لعائلتك وللبيئة. 
<br><br>
نظرة على خدمات التنظيف الدقيق الرئيسية لدينا:
<br><br>
<ul class="content-ui pr-5 ">
  <li class="arabic-font"> تهتم خدمة التنظيف العامة لدينا بالصيانة الصحية الشاملة.</li>
  <li class="arabic-font"> التنظيف العميق الكامل يزيل كافة البقايا اللزجة والبقع والأوساخ العنيدة من كل فتحة وشقّ.</li>
<li class="arabic-font"> ويحرص تنظيف الخزائن على جعل رفوف مساحة التخزين والممرات نظيفة من الغبار الجاف أو الأوساخ</li>
<li class="arabic-font">
 تتطلب خدمات تنظيف الواجهات والنوافذ صيانة موسمية بسبب الظروف الجوية السنوية، ولذلك
نقوم بتنظيفها بعناية لمنع دخول الميكروبات والبكتيريا إلى الداخل.
</li>
<li class="arabic-font">
 تحتاج المناطق الخارجية المزودة بمظلات لركن السيارات والمحيط الخارجي إلى تنظيف دوري مستمر يضمن نظافة الواجهات ويساعد أيضًا نظافة الممرات والأسوار وما إلى ذلك.
</li>

</ul>
<div v-if="width<768">
  <span style="color:#2B9FAD" v-if="dc" @click="dc = false">المختصر</span>
</div>
</div>

  </transition-expand>
        </div>
      </div>
      <div>
   

      </div>
    </div>
     <div id="id_sec">
       <router-view dir="rtl" />
       </div>
  </div>
 </div>
</template>

<script>
//import pageheading from '../../../components/pageheading.vue'
export default {
  mounted() {
    
    this.width = screen.width;
    if(this.width<768){
       
          this.dc = false
    }else{   
      if(localStorage.getItem('bleach-dc')){
        localStorage.removeItem('bleach-dc')
      }else{
        this.$nextTick(()=> window.document.getElementById("id_sec").scrollIntoView());
      }
    }
  },
    components:{},
     data(){
        return{
          dc:true,
          width:null,
            selectlan:localStorage.getItem('bleach-lang')
        }
    },
    methods:{
       isCar(){
      if(this.width != null){
        if(this.width<768){
          return true
        }
        return false
      }
      return false
    },
    }

}
</script>

<style>

</style>