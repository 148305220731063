<template>
  <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.648926 10.2021C0.648926 7.49634 1.72379 4.9014 3.63705 2.98813C5.55032 1.07486 8.14527 0 10.851 0C13.5568 0 16.1518 1.07486 18.065 2.98813C19.9783 4.9014 21.0531 7.49634 21.0531 10.2021C21.0531 12.9079 19.9783 15.5028 18.065 17.4161C16.1518 19.3294 13.5568 20.4042 10.851 20.4042C8.14527 20.4042 5.55032 19.3294 3.63705 17.4161C1.72379 15.5028 0.648926 12.9079 0.648926 10.2021H0.648926ZM10.2688 14.5686L16.1425 7.22581L15.0815 6.377L10.073 12.6357L6.52534 9.67976L5.65476 10.7245L10.2688 14.57V14.5686Z" fill="#2B9FAD"/>
</svg>
</template>

<script>
export default {

}
</script>

<style>

</style>