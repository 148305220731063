<template>
  <div v-if="selectlan == 'ARABIC'">
    <div>
      <carousel
        @changed="changed"
        items="1"
        :slideTransition="'fadeUp'"
        :autoplayTimeout="7000"
        :autoplay="true"
        :loop="true"
        :dots="false"
        :nav="false"
      >
        <!-- <div class="main-slider-item-6">
          <div class="bcontainer">
            <div class="slider-inner">
              <div class="row">
                <div class="col-md-5 text-right">
                  <h1 class="slider-main-text arabic-hero">
                    احجز اونلاين <br />وأخذ <br />

                    <span class="w500"> خصم %15 </span>
                  </h1>

                  <div class="slider-text arabic-font">
                   الحين تقدر تحجز خدمة التنظيف اونلاين وتاخذ خصم 15% بقيمة تصل ل 75 دينار
                  </div>

                  <button
                    @click="openServiceDialog()"
                    class="book-btn-white btn-trans arabic-font"
                  >
                    حجز الحين
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> -->
           <div class="main-slider-item-4">
          <div class="bcontainer">
            <div class="slider-inner">
              <div class="row">
                <div class="col-md-5 text-right">
                  <h1 class="slider-main-text arabic-hero">
                    شريك تنظيف<br />موثوق به <br />

                    <span class="w500"> منذ عام 2013 </span>
                  </h1>

                  <div class="slider-text arabic-font">
                    خدمات التنظيف معتمدة لمكاتب الشركات والمتاجر والمديريات
                    والمزيد
                  </div>

                  <button
                    @click="openServiceDialog()"
                    class="book-btn-white btn-trans arabic-font"
                  >
                    حجز الحين
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="main-slider-item-3">
          <div class="bcontainer">
            <div class="slider-inner">
              <div class="row">
                <div class="col-md-5 text-right">
                  <h1 class="slider-main-text arabic-hero">
                    الدقة<br />التفاصيل<br />

                    <span class="w500">
                      بليتش<sup style="font-size: 40px">®</sup>.
                    </span>
                  </h1>

                  <div class="slider-text arabic-font">
                    يتعدى الأمر أن يكون من مجرد تنظيف عادية، إنها تجربة لا تنسى
                  </div>

                  <button
                    @click="openServiceDialog()"
                    class="book-btn-white btn-trans arabic-font"
                  >
                    حجز الحين
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="main-slider-item-1">
          <div class="bcontainer">
            <div class="slider-inner">
              <div class="row">
                <div class="col-md-5 text-right">
                  <h1
                    class="slider-main-text animate__animated animate__fadeInUp arabic-hero"
                  >
                    هنا ينتهي <br />البحث عن <br />

                    <span class="w500">أعمال النظافة</span>
                  </h1>

                  <div
                    class="slider-text m-mt-20 animate__animated animate__fadeInUp animate__delay-08s arabic-font"
                  >
                    حلول أخصائيي التنظيف تجعل المكان خالٍ من الجراثيم والأوساخ
                  </div>

                  <button
                    @click="openServiceDialog()"
                    class="book-btn-white btn-trans arabic-font m-mt-20 animate__animated animate__fadeInUp animate__delay-1s"
                  >
      حجز الحين
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="main-slider-item-2">
          <div class="bcontainer">
            <div class="slider-inner">
              <div class="row">
                <div class="col-md-5 text-right">
                  <h1 class="slider-main-text arabic-hero">
                    مفهوم التنظيف <br />الذي يراعي <br />

                    <span class="w500">البيئة أولاً </span>
                  </h1>

                  <div class="slider-text arabic-font">
                    تحرص منتجاتنا المختارة بعناية على ضمان سلامة البيئة
                  </div>

                  <button
                    @click="openServiceDialog()"
                    class="book-btn-white btn-trans arabic-font"
                  >
                    حجز الحين
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </carousel>
    </div>
    <section class="bcontainer">
      <div class="bp-40" dir="rtl">
        <div
          class="text-center section-heading arabic-font"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          خدماتنا
        </div>
        <div
          class="my-10 about-section arabic-font"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          إن بليتش® شركة تنظيف تعتمد على التكنولوجيا تقدم خدمات راقية عالية
          الجودة للعملاء الأكثر تميزًا. نقدم لعملائنا قيمة استثنائية من خلال
          نموذج التنظيف ثلاثي المستويات، الذي يتميز بأداء فائق الجودة لعملائنا
          من كبار الشخصيات، والمواعيد المرنة، وتجربة حجز سهلة توفر خيارات رائعة
        </div>
        <div class="py-2">
          <div class="row my-10" data-aos="fade-up" data-aos-duration="1000">
            <div
              class="col-lg-3 col-md-6 mt-10"
              @click="goto('service/detailedcleaning')"
            >
              <div class="service-card">
                <div class="service-card-circle">
                  <img src="../../assets/icons/services/dc.png" alt="" />
                </div>
                <div class="cleaning-card-name text-center arabic-font">
                  التنظيف الدقيق
                </div>
              </div>
            </div>
            <div
              class="col-lg-3 col-md-6 mt-10"
              @click="goto('service/specialcare')"
            >
              <div class="service-card">
                <div class="service-card-circle">
                  <img
                    style="margin-top: -5px"
                    src="../../assets/icons/services/sp.png"
                    alt=""
                  />
                </div>
                <div class="cleaning-card-name text-center arabic-font">
                  الرعاية الخاصة
                </div>
              </div>
            </div>
            <div
              class="col-lg-3 col-md-6 mt-10"
              @click="goto('service/kitchencleaning')"
            >
              <div class="service-card">
                <div class="service-card-circle">
                  <img src="../../assets/icons/services/kc.png" alt="" />
                </div>
                <div class="cleaning-card-name text-center arabic-font">
                  تنظيف المطبخ
                </div>
              </div>
            </div>
            <div
              class="col-lg-3 col-md-6 mt-10"
              @click="goto('service/pestcontrol')"
            >
              <div class="service-card">
                <div class="service-card-circle">
                  <img src="../../assets/icons/services/ic.png" alt="" />
                </div>
                <div class="cleaning-card-name text-center arabic-font">
                  مكافحة الحشرات 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="py-2">
          <div class="row" data-aos="fade-up"  data-aos-duration="1000">
            <div class="col-md-6">
              <div class="book-eval-card" @click="gotoMain('evaluation')">
                
                <div class="book-eval-card-text" style="padding-top:50px">
                  <img
                  width="98"
                            src="../../assets/images/evalhome.png"
                            class="text-center"
                            alt=""
                            />
                            <br>
                  <div class="mt-4">
                    احجز موعد المعاينة المجانية
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 m-mt-10">
              <div class="book-eval-card2"  @click="openServiceDialog()">
                <div class="book-eval-card-text2" style="padding-top:50px">
                  <img
                  width="98"
                            src="../../assets/images/pricehome.png"
                            class="text-center"
                            alt=""
                            />
                            <br>
                            <div class="mt-4">
                              قائمة الأسعار
                            </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div
          dir="rtl"
          class="text-center mt-5 pt-15"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <span class="highlight-text arabic-font">
            احرص على تنظيف وحماية وتجديد الأجواء في مكان عملك
          </span>
          <button
            class="book-btn ml-3 ma arabic-font"
            @click="goto('service/detailedcleaning')"
          >
            راجع أسعارنا
          </button>
        </div> -->
      </div>
    </section>
    <section class="gray-bg" dir="rtl" id="SPECIALITY">
      <div class="bcontainer">
        <div class="bp-40">
          <div class="text-center section-heading arabic-font">عملاؤنا</div>
          <div class="mt-10 about-section arabic-font">
            نفخر بتقديم أفضل خدمات تنظيف الاحترافية لعملائنا في المنازل وأماكن
            العمل. تتيح لك مجموعتنا الواسعة من الخدمات تخصيص خطة تناسب احتياجاتك
            – تنظيف أسبوعي أو شهري أو التنظيف بعد المناسبات. اشترك لتصلك نشرة
            بمجموعة خدماتنا الكاملة.
          </div>
          <div class="row" data-aos="fade-up"  data-aos-duration="1000">
            <div class="col-md-6 mt-5">
              <div
              class="card-div  h-100"
              style="cursor: pointer"
              @click="goto('residential')"
            >
              <div class="who-serve-card-img who-serve-card-res text-center">
                <img
                
                  src="../../assets/images/res.png"
                  class="text-center"
                  alt=""
                />
                <div class="who-text mt-3 arabic-font">تنظيف الشقق السكنية</div>
              </div>
              <div class="who-card-contents arabic-font">
                نتبع شعار تقدير واحترام احتياجات العملاء أولاً. ونعكس ثقافتنا من
                خلال اعتماد طرق التنظيف الأحدث عالميًا؛ وعمال النظافة المدربين
                مهنيًا على تقديم خدمات بمعايير دولية؛ وسهولة حجز الخدمة رقميًا.
                <br />
              </div>
              <div class="who-gray"><br /><br /></div>
            </div>
            </div>
            <div class="col-md-6 mt-5">
              <div 
              class="card-div  h-100"
              style="cursor: pointer"
              @click="goto('commercial')"
            >
              <div class="who-serve-card-img who-serve-card-com text-center">
                <img
               
                  src="../../assets/images/com.png"
                  class="text-center"
                  alt=""
                />
                <div class="who-text mt-3 arabic-font">تنظيف أماكن العمل</div>
              </div>
              <div class="who-card-contents arabic-font">
                خدمات تنظيف أماكن عمل واسعة النطاق وفق أعلى المعايير تشمل تنظيف
                مقرات الشركات والمرافق التعليمية والمتاجر والمرافق الصناعية
                وغيرها.
                <br /><br>
              </div>
              <div class="who-gray"><br /><br /></div>
            </div>
            </div>
          </div>
         
        </div>
      </div>
    </section>
    <section>
      <div class="bcontainer">
        <div class="bp-40">
          <div
            dir="rtl"
            class="text-center section-heading arabic-font"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            ما الذي<span class="w500"> يميزنا؟</span>
          </div>
          <div
            dir="rtl"
            class="my-10 about-section arabic-font"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            نتبع شعار تقدير واحترام احتياجات العملاء أولاً. ونعكس ثقافتنا من
            خلال اعتماد طرق التنظيف الأحدث عالميًا؛ وعمال النظافة المدربين
            مهنيًا على تقديم خدمات بمعايير دولية؛ وسهولة حجز الخدمة رقميًا.
          </div>
          <div>
            <div class="mt-15 show-diff" dir="rtl">
              <div class="row" data-aos="fade-up" data-aos-duration="1000">
                <div class="col-md-4 mt-5 col-lg-2">
                  <div class="text-center diff-card" @click="changeDiff(0)">
                    <div
                      class="diff-icons"
                      :class="
                        activeDiff.id == differece[0].id
                          ? 'img-active-border'
                          : ''
                      "
                    >
                      <img src="../../assets/images/makediff/ad.png" alt="" />
                    </div>
                    <div
                      class="diff-heading arabic-font"
                      :class="
                        activeDiff.id == differece[0].id ? 'active-text' : ''
                      "
                    >
                      رعاية العملاء متقدمة 
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mt-5 col-lg-2">
                  <div class="text-center diff-card" @click="changeDiff(1)">
                    <div
                      class="diff-icons"
                      :class="
                        activeDiff.id == differece[1].id
                          ? 'img-active-border'
                          : ''
                      "
                    >
                      <img src="../../assets/images/makediff/well.png" alt="" />
                    </div>
                    <div
                      class="diff-heading arabic-font"
                      :class="
                        activeDiff.id == differece[1].id ? 'active-text' : ''
                      "
                    >
                      محترفون مدربون تدريباً عاليا
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mt-5 col-lg-2">
                  <div class="text-center diff-card" @click="changeDiff(2)">
                    <div
                      class="diff-icons"
                      :class="
                        activeDiff.id == differece[2].id
                          ? 'img-active-border'
                          : ''
                      "
                    >
                      <img src="../../assets/images/makediff/eco.png" alt="" />
                    </div>
                    <div
                      class="diff-heading arabic-font"
                      :class="
                        activeDiff.id == differece[2].id ? 'active-text' : ''
                      "
                    >
                      خدمات صديقة للبيئة
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mt-5 col-lg-2">
                  <div class="text-center diff-card" @click="changeDiff(3)">
                    <div
                      class="diff-icons"
                      :class="
                        activeDiff.id == differece[3].id
                          ? 'img-active-border'
                          : ''
                      "
                    >
                      <img src="../../assets/images/makediff/ch.png" alt="" />
                    </div>
                    <div
                      class="diff-heading arabic-font"
                      :class="
                        activeDiff.id == differece[3].id ? 'active-text' : ''
                      "
                    >
                      منتجات صديقة للطفولة
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mt-5 col-lg-2">
                  <div class="text-center diff-card" @click="changeDiff(4)">
                    <div
                      class="diff-icons"
                      :class="
                        activeDiff.id == differece[4].id
                          ? 'img-active-border'
                          : ''
                      "
                    >
                      <img src="../../assets/images/makediff/pet.png" alt="" />
                    </div>
                    <div
                      class="diff-heading arabic-font"
                      :class="
                        activeDiff.id == differece[4].id ? 'active-text' : ''
                      "
                    >
                      وسائل صديقة للحيوانات الأليفة 
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mt-5 col-lg-2">
                  <div class="text-center diff-card" @click="changeDiff(5)">
                    <div
                      class="diff-icons"
                      :class="
                        activeDiff.id == differece[5].id
                          ? 'img-active-border'
                          : ''
                      "
                    >
                      <img src="../../assets/images/makediff/in.png" alt="" />
                    </div>
                    <div
                      class="diff-heading arabic-font"
                      :class="
                        activeDiff.id == differece[5].id ? 'active-text' : ''
                      "
                    >
                      التغطية التأمينية للعملاء والموظفين 
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bmt-30 show-diff" dir="rtl">
              <div
                class="about-section arabic-font"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <span style="color: #2b9fad" class="show-head"
                  >{{ activeDiff.title }}: </span
                >{{ activeDiff.content }}
              </div>
            </div>
            <div
              class="mt-15 diff-side"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <carousel
                :loop="true"
                v-if="width != null"
                items="1"
                :autoplay="isCar()"
                :dots="false"
                :nav="false"
              >
                <template ref="prev" slot="prev">
                  <div style="left: -2%" class="arrow-div">
                    <v-icon large> mdi-chevron-left </v-icon>
                  </div>
                </template>
                <template ref="next" slot="next">
                  <div style="right: -2%" class="arrow-div">
                    <v-icon large> mdi-chevron-right </v-icon>
                  </div>
                </template>
                <div class="text-center diff-card" @click="changeDiff(0)">
                  <div class="diff-icons img-active-border">
                    <img src="../../assets/images/makediff/ad.png" alt="" />
                  </div>
                  <div class="diff-heading active-text arabic-font">
                    رعاية العمالء المتقدمة
                  </div>
                  <div class="bmt-30">
                    <div
                      class="about-section arabic-font"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      {{ differece[0].content }}
                    </div>
                  </div>
                </div>
                <div class="text-center diff-card" @click="changeDiff(1)">
                  <div class="diff-icons img-active-border">
                    <img src="../../assets/images/makediff/well.png" alt="" />
                  </div>
                  <div class="diff-heading active-text arabic-font">
                    محترفون مدربون تدريباً عاليا
                  </div>
                  <div class="bmt-30">
                    <div
                      class="about-section arabic-font"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      {{ differece[1].content }}
                    </div>
                  </div>
                </div>
                <div class="text-center diff-card" @click="changeDiff(2)">
                  <div class="diff-icons img-active-border">
                    <img src="../../assets/images/makediff/eco.png" alt="" />
                  </div>
                  <div class="diff-heading active-text arabic-font">
                    خدمات صديقة للبيئة
                  </div>
                  <div class="bmt-30">
                    <div
                      class="about-section arabic-font"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      {{ differece[2].content }}
                    </div>
                  </div>
                </div>
                <div class="text-center diff-card" @click="changeDiff(3)">
                  <div class="diff-icons img-active-border">
                    <img src="../../assets/images/makediff/ch.png" alt="" />
                  </div>
                  <div class="diff-heading active-text arabic-font">
                    منتجات صديقة للطفولة
                  </div>
                  <div class="bmt-30">
                    <div
                      class="about-section arabic-font"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      {{ differece[3].content }}
                    </div>
                  </div>
                </div>
                <div class="text-center diff-card" @click="changeDiff(4)">
                  <div class="diff-icons img-active-border">
                    <img src="../../assets/images/makediff/pet.png" alt="" />
                  </div>
                  <div class="diff-heading active-text arabic-font">
                    وسائل صديقة للحيوانات األليفة
                  </div>
                  <div class="bmt-30">
                    <div
                      class="about-section arabic-font"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      {{ differece[4].content }}
                    </div>
                  </div>
                </div>
                <div class="text-center diff-card" @click="changeDiff(5)">
                  <div class="diff-icons img-active-border">
                    <img src="../../assets/images/makediff/in.png" alt="" />
                  </div>
                  <div class="diff-heading active-text arabic-font">
                    التغطية التأمينية للعمالء والموظفين
                  </div>
                  <div class="bmt-30">
                    <div
                      class="about-section arabic-font"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      {{ differece[5].content }}
                    </div>
                  </div>
                </div>
              </carousel>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="gray-bg">
      <div class="bcontainer">
        <div class="bp-40">
          <div
            class="text-center section-heading arabic-font"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            ما تقوله الشركات عنا
          </div>
          <div data-aos="fade-up" class="mt-5" data-aos-duration="1000">
            <carousel
              :responsive="{
                0: { items: 1, nav: false },
                769: { items: 2, nav: false },
              }"
              :autoplay="false"
              :loop="true"
              :dots="false"
              :nav="false"
            >
              <div class="feedback-card" dir="rtl">
                <div class="feedback-card-top cop-feedback">
                  <svg
                    width="40"
                    height="30"
                    viewBox="0 0 40 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.56 29.32C6.08 29.32 4.04 28.56 2.44 27.04C0.92 25.44 0.16 23.4 0.16 20.92C0.16 17.8 1.44 14.4 4 10.72C6.64 6.96 10.12 3.64 14.44 0.759996L16.24 2.55999C14.56 4.32 13.24 5.96 12.28 7.48C11.32 8.99999 10.84 10.24 10.84 11.2C10.84 11.76 11 12.28 11.32 12.76C11.72 13.24 12.28 13.76 13 14.32C14.12 15.2 15 16.12 15.64 17.08C16.28 18.04 16.6 19.32 16.6 20.92C16.6 23.4 15.84 25.44 14.32 27.04C12.8 28.56 10.88 29.32 8.56 29.32ZM31.84 29.32C29.36 29.32 27.32 28.56 25.72 27.04C24.2 25.44 23.44 23.4 23.44 20.92C23.44 17.8 24.72 14.4 27.28 10.72C29.92 6.96 33.4 3.64 37.72 0.759996L39.52 2.55999C37.84 4.32 36.52 5.96 35.56 7.48C34.6 8.99999 34.12 10.24 34.12 11.2C34.12 11.76 34.28 12.28 34.6 12.76C35 13.24 35.56 13.76 36.28 14.32C37.4 15.2 38.28 16.12 38.92 17.08C39.56 18.04 39.88 19.32 39.88 20.92C39.88 23.4 39.12 25.44 37.6 27.04C36.08 28.56 34.16 29.32 31.84 29.32Z"
                      fill="#6D6D6E"
                    />
                  </svg>
                  <div class="service-content arabic-font mt-5">
                    شعرنا بأمان دائم ومستمر بوجود طاقم بليتش للقيام بتعقيم
                    منشئات الشركة المختلفة أثناء أزمة كورونا. فريق بليتش
                    للعمليات وفريق المبيعات مقتدرين ومتمكنين ب تجهيز جداول
                    الزيارات لمواقعنا المختلفة. يسير طاقم المنظفين لبليتش وفق
                    الجدول الزمني و الوقت المحدد لديهم، ومحترفين في القيام
                    بعملهم بلبس واستخدام مستلزمات الوقاية المطلوبة أثناء العمل.
                    الخدمات القدمة من فريقكم متميزة دائما. مالحظاتنا وشكاوينا
                    على أعمال . الخدمة محدودة جدا
                  </div>
                </div>
                <div class="feedback-card-bottom">
                  <div class="d-flex">
                    <div class="full-flex ma">
                      <div class="emp-name arabic-font">سارافانا سندارام</div>
                      <div class="service-content arabic-font">
                        مدير المرافق
                      </div>
                    </div>
                    <div>
                      <img
                        src="../../assets/images/feedback/tabco.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="feedback-card" dir="rtl">
                <div class="feedback-card-top cop-feedback">
                  <svg
                    width="40"
                    height="30"
                    viewBox="0 0 40 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.56 29.32C6.08 29.32 4.04 28.56 2.44 27.04C0.92 25.44 0.16 23.4 0.16 20.92C0.16 17.8 1.44 14.4 4 10.72C6.64 6.96 10.12 3.64 14.44 0.759996L16.24 2.55999C14.56 4.32 13.24 5.96 12.28 7.48C11.32 8.99999 10.84 10.24 10.84 11.2C10.84 11.76 11 12.28 11.32 12.76C11.72 13.24 12.28 13.76 13 14.32C14.12 15.2 15 16.12 15.64 17.08C16.28 18.04 16.6 19.32 16.6 20.92C16.6 23.4 15.84 25.44 14.32 27.04C12.8 28.56 10.88 29.32 8.56 29.32ZM31.84 29.32C29.36 29.32 27.32 28.56 25.72 27.04C24.2 25.44 23.44 23.4 23.44 20.92C23.44 17.8 24.72 14.4 27.28 10.72C29.92 6.96 33.4 3.64 37.72 0.759996L39.52 2.55999C37.84 4.32 36.52 5.96 35.56 7.48C34.6 8.99999 34.12 10.24 34.12 11.2C34.12 11.76 34.28 12.28 34.6 12.76C35 13.24 35.56 13.76 36.28 14.32C37.4 15.2 38.28 16.12 38.92 17.08C39.56 18.04 39.88 19.32 39.88 20.92C39.88 23.4 39.12 25.44 37.6 27.04C36.08 28.56 34.16 29.32 31.84 29.32Z"
                      fill="#6D6D6E"
                    />
                  </svg>
                  <div class="service-content mt-5 arabic-font">
                    قبل افتتاح المقهى رسميا للجمهور طلبنا بليتش ألول مرة، أحببنا
                    مستوى وجودة العمل الذي قدموه وأصبحنا نطلبهم مرة تلو األخرى،
                    وأود أن أثني على إدارة بليتش وموظفيها على مستوى الخدمة
                    والمهنية العالية التي يتمتعون بها داخل أو ،خارج المحل عند كل
                    زيارة يقومون بها، الحظنا أن بليتش تهتم بالتفاصيل الدقيقة
                    وتقدم جودة خدمة استثنائية تشعر بها مستقبال عند افتتاح أي
                    موقع أو فرع جديد بالتأكيد سنكلف بليتش بتولي المهمة
                  </div>
                </div>
                <div class="feedback-card-bottom">
                  <div class="d-flex">
                    <div class="full-flex ma">
                      <div class="emp-name arabic-font">طارق الشيخ</div>
                      <div class="service-content arabic-font">شريك اداري</div>
                    </div>
                    <div>
                      <img src="../../assets/images/feedback/toby.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="feedback-card" dir="rtl">
                <div class="feedback-card-top cop-feedback">
                  <svg
                    width="40"
                    height="30"
                    viewBox="0 0 40 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.56 29.32C6.08 29.32 4.04 28.56 2.44 27.04C0.92 25.44 0.16 23.4 0.16 20.92C0.16 17.8 1.44 14.4 4 10.72C6.64 6.96 10.12 3.64 14.44 0.759996L16.24 2.55999C14.56 4.32 13.24 5.96 12.28 7.48C11.32 8.99999 10.84 10.24 10.84 11.2C10.84 11.76 11 12.28 11.32 12.76C11.72 13.24 12.28 13.76 13 14.32C14.12 15.2 15 16.12 15.64 17.08C16.28 18.04 16.6 19.32 16.6 20.92C16.6 23.4 15.84 25.44 14.32 27.04C12.8 28.56 10.88 29.32 8.56 29.32ZM31.84 29.32C29.36 29.32 27.32 28.56 25.72 27.04C24.2 25.44 23.44 23.4 23.44 20.92C23.44 17.8 24.72 14.4 27.28 10.72C29.92 6.96 33.4 3.64 37.72 0.759996L39.52 2.55999C37.84 4.32 36.52 5.96 35.56 7.48C34.6 8.99999 34.12 10.24 34.12 11.2C34.12 11.76 34.28 12.28 34.6 12.76C35 13.24 35.56 13.76 36.28 14.32C37.4 15.2 38.28 16.12 38.92 17.08C39.56 18.04 39.88 19.32 39.88 20.92C39.88 23.4 39.12 25.44 37.6 27.04C36.08 28.56 34.16 29.32 31.84 29.32Z"
                      fill="#6D6D6E"
                    />
                  </svg>
                  <div class="service-content mt-5 arabic-font">
                    من افضل الشركات التي تعاملت معها لتنظيف مركز كريستال لطب
                    األسنان .التعامل معهم BLEACH شركة التنظيف مريح جدا حيث من
                    حيث الدقة في العمل و االلتزام بالمواعيد و الصراحة من حيث
                    المبدأ حيث يلتزمون بما يمكن عمله .و تقديم النصيحة بما ال
                    يمكن تنظيفه من قبلهم بالتأكيد سوف استمر بالتعامل معهم و أنصح
                    الجميع بتجربتهم د/ منيرة المنصور المدير التنفيذي لمركز
                    كريستال لطب األسنان
                  </div>
                </div>
                <div class="feedback-card-bottom">
                  <div class="d-flex">
                    <div class="full-flex ma">
                      <div class="emp-name arabic-font">د. منيرة المنصور</div>
                      <div class="service-content arabic-font">
                        الرئيس التنفيذى
                      </div>
                    </div>
                    <div>
                      <img
                        src="../../assets/images/feedback/crytstal.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="feedback-card" dir="rtl">
                <div class="feedback-card-top cop-feedback">
                  <svg
                    width="40"
                    height="30"
                    viewBox="0 0 40 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.56 29.32C6.08 29.32 4.04 28.56 2.44 27.04C0.92 25.44 0.16 23.4 0.16 20.92C0.16 17.8 1.44 14.4 4 10.72C6.64 6.96 10.12 3.64 14.44 0.759996L16.24 2.55999C14.56 4.32 13.24 5.96 12.28 7.48C11.32 8.99999 10.84 10.24 10.84 11.2C10.84 11.76 11 12.28 11.32 12.76C11.72 13.24 12.28 13.76 13 14.32C14.12 15.2 15 16.12 15.64 17.08C16.28 18.04 16.6 19.32 16.6 20.92C16.6 23.4 15.84 25.44 14.32 27.04C12.8 28.56 10.88 29.32 8.56 29.32ZM31.84 29.32C29.36 29.32 27.32 28.56 25.72 27.04C24.2 25.44 23.44 23.4 23.44 20.92C23.44 17.8 24.72 14.4 27.28 10.72C29.92 6.96 33.4 3.64 37.72 0.759996L39.52 2.55999C37.84 4.32 36.52 5.96 35.56 7.48C34.6 8.99999 34.12 10.24 34.12 11.2C34.12 11.76 34.28 12.28 34.6 12.76C35 13.24 35.56 13.76 36.28 14.32C37.4 15.2 38.28 16.12 38.92 17.08C39.56 18.04 39.88 19.32 39.88 20.92C39.88 23.4 39.12 25.44 37.6 27.04C36.08 28.56 34.16 29.32 31.84 29.32Z"
                      fill="#6D6D6E"
                    />
                  </svg>
                  <div class="service-content mt-5 arabic-font">
                    التعامل مع بليتش استحق كل مبلغ دفع، الموظفين كانوا في غاية
                    االحترافية والشفافية، محالتنا كانت في غاية النظافة ُسررت
                    بالتعامل مع بليتش .والنقاء، فعا
                  </div>
                </div>
                <div class="feedback-card-bottom">
                  <div class="d-flex">
                    <div class="full-flex ma">
                      <div class="emp-name arabic-font">بيبي حيات</div>
                      <div class="service-content arabic-font">صاحبة</div>
                    </div>
                    <div>
                      <img src="../../assets/images/feedback/nov.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="feedback-card" dir="rtl">
                <div class="feedback-card-top cop-feedback">
                  <svg
                    width="40"
                    height="30"
                    viewBox="0 0 40 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.56 29.32C6.08 29.32 4.04 28.56 2.44 27.04C0.92 25.44 0.16 23.4 0.16 20.92C0.16 17.8 1.44 14.4 4 10.72C6.64 6.96 10.12 3.64 14.44 0.759996L16.24 2.55999C14.56 4.32 13.24 5.96 12.28 7.48C11.32 8.99999 10.84 10.24 10.84 11.2C10.84 11.76 11 12.28 11.32 12.76C11.72 13.24 12.28 13.76 13 14.32C14.12 15.2 15 16.12 15.64 17.08C16.28 18.04 16.6 19.32 16.6 20.92C16.6 23.4 15.84 25.44 14.32 27.04C12.8 28.56 10.88 29.32 8.56 29.32ZM31.84 29.32C29.36 29.32 27.32 28.56 25.72 27.04C24.2 25.44 23.44 23.4 23.44 20.92C23.44 17.8 24.72 14.4 27.28 10.72C29.92 6.96 33.4 3.64 37.72 0.759996L39.52 2.55999C37.84 4.32 36.52 5.96 35.56 7.48C34.6 8.99999 34.12 10.24 34.12 11.2C34.12 11.76 34.28 12.28 34.6 12.76C35 13.24 35.56 13.76 36.28 14.32C37.4 15.2 38.28 16.12 38.92 17.08C39.56 18.04 39.88 19.32 39.88 20.92C39.88 23.4 39.12 25.44 37.6 27.04C36.08 28.56 34.16 29.32 31.84 29.32Z"
                      fill="#6D6D6E"
                    />
                  </svg>
                  <div class="service-content mt-5 arabic-font">
                    ليتش هو شرف لنا! طاقمهم محترف جدا، يحترم الوقت واألهم متقنين
                    بعملهم. العمل مع جدا متميّزين بإتمام العمل على أكمل وجهة في
                    خدمات التنظيف العميق التي يقدمونها لمواقعنا المختلقة. ونحن
                    سعيدون بأن نكون أحد عمالئهم!
                  </div>
                </div>
                <div class="feedback-card-bottom">
                  <div class="d-flex">
                    <div class="full-flex ma">
                      <div class="emp-name arabic-font">خالد جعفر</div>
                      <div class="service-content arabic-font">صاحبة</div>
                    </div>
                    <div>
                      <img
                        src="../../assets/images/feedback/orange.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="feedback-card" dir="rtl">
                <div class="feedback-card-top cop-feedback">
                  <svg
                    width="40"
                    height="30"
                    viewBox="0 0 40 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.56 29.32C6.08 29.32 4.04 28.56 2.44 27.04C0.92 25.44 0.16 23.4 0.16 20.92C0.16 17.8 1.44 14.4 4 10.72C6.64 6.96 10.12 3.64 14.44 0.759996L16.24 2.55999C14.56 4.32 13.24 5.96 12.28 7.48C11.32 8.99999 10.84 10.24 10.84 11.2C10.84 11.76 11 12.28 11.32 12.76C11.72 13.24 12.28 13.76 13 14.32C14.12 15.2 15 16.12 15.64 17.08C16.28 18.04 16.6 19.32 16.6 20.92C16.6 23.4 15.84 25.44 14.32 27.04C12.8 28.56 10.88 29.32 8.56 29.32ZM31.84 29.32C29.36 29.32 27.32 28.56 25.72 27.04C24.2 25.44 23.44 23.4 23.44 20.92C23.44 17.8 24.72 14.4 27.28 10.72C29.92 6.96 33.4 3.64 37.72 0.759996L39.52 2.55999C37.84 4.32 36.52 5.96 35.56 7.48C34.6 8.99999 34.12 10.24 34.12 11.2C34.12 11.76 34.28 12.28 34.6 12.76C35 13.24 35.56 13.76 36.28 14.32C37.4 15.2 38.28 16.12 38.92 17.08C39.56 18.04 39.88 19.32 39.88 20.92C39.88 23.4 39.12 25.44 37.6 27.04C36.08 28.56 34.16 29.32 31.84 29.32Z"
                      fill="#6D6D6E"
                    />
                  </svg>
                  <div class="service-content mt-5 arabic-font">
                    لشركتكم وللخدمة الممتازة التي قدمها موظفيكم شكرا المحترمين.
                    حفل االفتتاح انطلق بدون أي أخطاء. كل الموظفين كانو يقدمون
                    الخدمة وهم مبتسمين. سأكون سعيدة بدعوة كل من يبحث عن خدمات
                    تنظيف أن يستخدم خدمتكم بالمستقبل. أرجوكم استخدموا هذا
                    اإلقرار مني لشركتكم بأي أعمال ترويجية بالمستقبل.
                  </div>
                </div>
                <div class="feedback-card-bottom">
                  <div class="d-flex">
                    <div class="full-flex ma">
                      <div class="emp-name arabic-font">أماني األحمد</div>
                      <div class="service-content arabic-font">صاحبة</div>
                    </div>
                    <div>
                      <img
                        src="../../assets/images/feedback/coffee.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </carousel>
          </div>
          <div
            class="text-center mt-5 section-heading arabic-font"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
           ما يقوله العملاء عنا
          </div>
          <div data-aos="fade-up" class="mt-5" data-aos-duration="1000">
            <carousel
              :responsive="{
                0: { items: 1, nav: false },
                769: { items: 2, nav: false },
              }"
              :autoplay="false"
              :loop="true"
              :dots="false"
              :nav="false"
            >
              <div class="feedback-card" dir="rtl">
                <div class="feedback-card-top cus-feedback">
                  <svg
                    width="40"
                    height="30"
                    viewBox="0 0 40 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.56 29.32C6.08 29.32 4.04 28.56 2.44 27.04C0.92 25.44 0.16 23.4 0.16 20.92C0.16 17.8 1.44 14.4 4 10.72C6.64 6.96 10.12 3.64 14.44 0.759996L16.24 2.55999C14.56 4.32 13.24 5.96 12.28 7.48C11.32 8.99999 10.84 10.24 10.84 11.2C10.84 11.76 11 12.28 11.32 12.76C11.72 13.24 12.28 13.76 13 14.32C14.12 15.2 15 16.12 15.64 17.08C16.28 18.04 16.6 19.32 16.6 20.92C16.6 23.4 15.84 25.44 14.32 27.04C12.8 28.56 10.88 29.32 8.56 29.32ZM31.84 29.32C29.36 29.32 27.32 28.56 25.72 27.04C24.2 25.44 23.44 23.4 23.44 20.92C23.44 17.8 24.72 14.4 27.28 10.72C29.92 6.96 33.4 3.64 37.72 0.759996L39.52 2.55999C37.84 4.32 36.52 5.96 35.56 7.48C34.6 8.99999 34.12 10.24 34.12 11.2C34.12 11.76 34.28 12.28 34.6 12.76C35 13.24 35.56 13.76 36.28 14.32C37.4 15.2 38.28 16.12 38.92 17.08C39.56 18.04 39.88 19.32 39.88 20.92C39.88 23.4 39.12 25.44 37.6 27.04C36.08 28.56 34.16 29.32 31.84 29.32Z"
                      fill="#6D6D6E"
                    />
                  </svg>
                  <div class="service-content mt-5 arabic-font">
                    خدمة أكثر من ممتازة والستاف تعامله راقي ويشتغلون بضمير. شركة
                    تحترم الوقت ملتزمين بتعليمات الصحية من لبس الماسكات
                    والقلڤزات ... الخ حبيت حركة البخور آخر شي وريحته وايد حلوه😍
                    بالمختصر المفيد تجربه راح تتكرر ولحد يتردد بالتعامل معاهم👍🏼
                  </div>
                </div>
                <div class="feedback-card-bottom">
                  <div class="d-flex">
                    <div class="full-flex ma">
                      <div class="emp-name arabic-font">دلال الشطي</div>
                      <!-- <div class="service-content">
                                      Sample text
                                    </div> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="feedback-card" dir="rtl">
                <div class="feedback-card-top cus-feedback">
                  <svg
                    width="40"
                    height="30"
                    viewBox="0 0 40 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.56 29.32C6.08 29.32 4.04 28.56 2.44 27.04C0.92 25.44 0.16 23.4 0.16 20.92C0.16 17.8 1.44 14.4 4 10.72C6.64 6.96 10.12 3.64 14.44 0.759996L16.24 2.55999C14.56 4.32 13.24 5.96 12.28 7.48C11.32 8.99999 10.84 10.24 10.84 11.2C10.84 11.76 11 12.28 11.32 12.76C11.72 13.24 12.28 13.76 13 14.32C14.12 15.2 15 16.12 15.64 17.08C16.28 18.04 16.6 19.32 16.6 20.92C16.6 23.4 15.84 25.44 14.32 27.04C12.8 28.56 10.88 29.32 8.56 29.32ZM31.84 29.32C29.36 29.32 27.32 28.56 25.72 27.04C24.2 25.44 23.44 23.4 23.44 20.92C23.44 17.8 24.72 14.4 27.28 10.72C29.92 6.96 33.4 3.64 37.72 0.759996L39.52 2.55999C37.84 4.32 36.52 5.96 35.56 7.48C34.6 8.99999 34.12 10.24 34.12 11.2C34.12 11.76 34.28 12.28 34.6 12.76C35 13.24 35.56 13.76 36.28 14.32C37.4 15.2 38.28 16.12 38.92 17.08C39.56 18.04 39.88 19.32 39.88 20.92C39.88 23.4 39.12 25.44 37.6 27.04C36.08 28.56 34.16 29.32 31.84 29.32Z"
                      fill="#6D6D6E"
                    />
                  </svg>
                  <div class="service-content mt-5 arabic-font">
                    بليتش من اول الشركات اللي عرفتها للتنظيف في الكويت، اول
                    تعامل معاهم كان يمكن قبل ٥ سنين و الان تعاملت معاهم و لم
                    يتغير المستوى ولا الجودة بس ازدادوا بالاحترافية و مستوى
                    الخدمات المقدمة، الله يوفقهم ويبارك لهم
                  </div>
                </div>
                <div class="feedback-card-bottom">
                  <div class="d-flex">
                    <div class="full-flex ma">
                      <div class="emp-name arabic-font">فرح الصالح</div>
                      <!-- <div class="service-content">
                                      Sample text
                                    </div> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="feedback-card" dir="rtl">
                <div class="feedback-card-top cus-feedback">
                  <svg
                    width="40"
                    height="30"
                    viewBox="0 0 40 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.56 29.32C6.08 29.32 4.04 28.56 2.44 27.04C0.92 25.44 0.16 23.4 0.16 20.92C0.16 17.8 1.44 14.4 4 10.72C6.64 6.96 10.12 3.64 14.44 0.759996L16.24 2.55999C14.56 4.32 13.24 5.96 12.28 7.48C11.32 8.99999 10.84 10.24 10.84 11.2C10.84 11.76 11 12.28 11.32 12.76C11.72 13.24 12.28 13.76 13 14.32C14.12 15.2 15 16.12 15.64 17.08C16.28 18.04 16.6 19.32 16.6 20.92C16.6 23.4 15.84 25.44 14.32 27.04C12.8 28.56 10.88 29.32 8.56 29.32ZM31.84 29.32C29.36 29.32 27.32 28.56 25.72 27.04C24.2 25.44 23.44 23.4 23.44 20.92C23.44 17.8 24.72 14.4 27.28 10.72C29.92 6.96 33.4 3.64 37.72 0.759996L39.52 2.55999C37.84 4.32 36.52 5.96 35.56 7.48C34.6 8.99999 34.12 10.24 34.12 11.2C34.12 11.76 34.28 12.28 34.6 12.76C35 13.24 35.56 13.76 36.28 14.32C37.4 15.2 38.28 16.12 38.92 17.08C39.56 18.04 39.88 19.32 39.88 20.92C39.88 23.4 39.12 25.44 37.6 27.04C36.08 28.56 34.16 29.32 31.84 29.32Z"
                      fill="#6D6D6E"
                    />
                  </svg>
                  <div class="service-content mt-5 arabic-font">
                    افضل شركة تنظيف ، شغلهم مرتب و بروفيشنال ، يستحقون التجربة
                    👍
                  </div>
                </div>
                <div class="feedback-card-bottom">
                  <div class="d-flex">
                    <div class="full-flex ma">
                      <div class="emp-name arabic-font">رقية الفضيل</div>
                      <!-- <div class="service-content">
                                      Sample text
                                    </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </carousel>
          </div>
        </div>
      </div>
    </section>

    <section class="bcontainer">
      <div class="bp-40">
        <div
          dir="rtl"
          class="text-center section-heading arabic-font"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          شهاداتنا
        </div>
        <div
          class="mt-10 about-section arabic-font"
          style="padding-left: 20%; padding-right: 20%"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          شركة بليتش® رائدة في سعيها نحو العمل بمنتجات وخدمات تنظيف أكثر مراعاة
          للبيئة. لذلك نحن معتمدون من قبل المنظمات العالمية مثل ISSA و ISO.
        </div>
        <div >
          <div
            class="py-10 text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img
              src="../../assets/images/certificate/1.1.png"
              class="mx-1"
              alt=""
            />
            <!-- <img
              src="../../assets/images/certificate/1.2.png"
              class="mx-1"
              alt=""
            />
            <img
              src="../../assets/images/certificate/1.3.png"
              class="mx-1"
              alt=""
            /> -->
            <img
              src="../../assets/images/certificate/1.4.png"
              class="mx-1"
              alt=""
            />
          </div>
        </div>
        <!-- <div
          class="py-10 diff-side"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <carousel
            :loop="true"
            items="1"
            :autoplay="true"
            :dots="false"
            :nav="false"
          >
            <template  ref="prev"  slot="prev" >
                  <div style="left:-2%" class="arrow-div">
                <v-icon large>
                mdi-chevron-left
              </v-icon>
               </div> 
               </template>
                 <template  ref="next" slot="next"> 
             <div style="right:-2%" class="arrow-div">
                <v-icon large>
                mdi-chevron-right
              </v-icon>
               </div> 
            </template> 
            <div class="text-center">
              <img
                src="../../assets/images/certificate/1.1.png"
                class="ma"
                style="width: auto !important"
                alt=""
              />
            </div>

            <img
              src="../../assets/images/certificate/1.2.png"
              class="ma"
              style="width: auto !important"
              alt=""
            />
            <img
              src="../../assets/images/certificate/1.3.png"
              class="ma"
              style="width: auto !important"
              alt=""
            />
            <img
              src="../../assets/images/certificate/1.4.png"
              class="ma"
              style="width: auto !important"
              alt=""
            />
          </carousel>
        </div> -->

        <div
          class="bmt-30 section-heading text-center arabic-font"
          data-aos="fade-up"
          data-aos-duration="1000"
          dir="rtl"
        >
          نعمل <span class="w400"> مع الأفضل</span>
        </div>
        <div class="mt-5 px-5" data-aos="fade-up" data-aos-duration="1000">
          <carousel
            items="1"
            :autoplay="true"
            :loop="true"
            :dots="false"
            :nav="false"
          >
            <template ref="prev" slot="prev">
              <div style="left: -2%" class="arrow-div show-head">
                <v-icon large> mdi-chevron-left </v-icon>
              </div>
            </template>
            <div class="row">
              <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
                <img
                  src="../../assets/images/clients/1.png"
                  alt=""
                  class="img-shadow"
                />
              </div>
              <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
                <img
                  src="../../assets/images/clients/2.png"
                  alt=""
                  class="img-shadow"
                />
              </div>
              <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
                <img
                  src="../../assets/images/clients/3.png"
                  alt=""
                  class="img-shadow"
                />
              </div>
              <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
                <img
                  src="../../assets/images/clients/4.png"
                  alt=""
                  class="img-shadow"
                />
              </div>
              <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
                <img
                  src="../../assets/images/clients/5.png"
                  alt=""
                  class="img-shadow"
                />
              </div>
              <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
                <img
                  src="../../assets/images/clients/6.png"
                  alt=""
                  class="img-shadow"
                />
              </div>
              <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
                <img
                  src="../../assets/images/clients/7.png"
                  alt=""
                  class="img-shadow"
                />
              </div>
              <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
                <img
                  src="../../assets/images/clients/8.png"
                  alt=""
                  class="img-shadow"
                />
              </div>
              <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
                <img
                  src="../../assets/images/clients/9.png"
                  alt=""
                  class="img-shadow"
                />
              </div>
              <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
                <img
                  src="../../assets/images/clients/10.png"
                  alt=""
                  class="img-shadow"
                />
              </div>
              <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
                <img
                  src="../../assets/images/clients/11.png"
                  alt=""
                  class="img-shadow"
                />
              </div>
              <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
                <img
                  src="../../assets/images/clients/12.png"
                  alt=""
                  class="img-shadow"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
                <img
                  src="../../assets/images/clients/13.png"
                  alt=""
                  class="img-shadow"
                />
              </div>
              <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
                <img
                  src="../../assets/images/clients/14.png"
                  alt=""
                  class="img-shadow"
                />
              </div>
              <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
                <img
                  src="../../assets/images/clients/15.png"
                  alt=""
                  class="img-shadow"
                />
              </div>
              <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
                <img
                  src="../../assets/images/clients/16.png"
                  alt=""
                  class="img-shadow"
                />
              </div>
              <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
                <img
                  src="../../assets/images/clients/17.png"
                  alt=""
                  class="img-shadow"
                />
              </div>
              <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
                <img
                  src="../../assets/images/clients/18.png"
                  alt=""
                  class="img-shadow"
                />
              </div>
              <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
                <img
                  src="../../assets/images/clients/19.png"
                  alt=""
                  class="img-shadow"
                />
              </div>
              <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
                <img
                  src="../../assets/images/clients/20.png"
                  alt=""
                  class="img-shadow"
                />
              </div>
              <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
                <img
                  src="../../assets/images/clients/21.png"
                  alt=""
                  class="img-shadow"
                />
              </div>
              <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
                <img
                  src="../../assets/images/clients/22.png"
                  alt=""
                  class="img-shadow"
                />
              </div>
              <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
                <img
                  src="../../assets/images/clients/23.png"
                  alt=""
                  class="img-shadow"
                />
              </div>
              <div class="col-lg-2 col-md-4 col-sm-3 col-3 mt-4 text-center">
                <img
                  src="../../assets/images/clients/24.png"
                  alt=""
                  class="img-shadow"
                />
              </div>
            </div>

            <template ref="next" slot="next">
              <div style="right: -2%" class="arrow-div show-head">
                <v-icon large> mdi-chevron-right </v-icon>
              </div>
            </template>
          </carousel>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import carousel from "../../components/carousel.vue";
import $ from "jquery";
export default {
  mounted() {
    this.activeDiff = this.differece[0];
    this.width = screen.width;
  },
  components: { carousel },
  data() {
    return {
      width: null,
      selectlan: localStorage.getItem("bleach-lang"),
      activeDiff: {},
      activeDiffAr: {},

      differece: [
        {
          id: 1,
          title: "رعاية العملاء متقدمة ",
          content:
            "تجمع خدمة رعاية العملاء لدينا بين أفضل التقنيات والتدريبات والخدمات لمنح العميل تجربة ممتازة. بوجود فريق من المحترفين المتخصصين الذين يعملون على مدار الساعة طوال أيام الأسبوع، نمنح كل عميل من عملائنا معاملةً خاصة في شركتنا بليتش.. يضمن ضابط خدمة العملاء التنفيذ السلس للخدمة التي يطلبها عميلنا بكل إخلاص. تواصل معنا في أيّ وقت عبر الواتساب. ",
        },
        {
          id: 2,
          title: "محترفون مدربون تدريباً عاليا",
          content:
            'نفخر بإطلاق اسم "المحترفين" على عمال النظافة لدينا. تتوافق أساليب التدريب المطبقة على أفراد طاقمنا في بليتش® مع معايير ISSA®s CIMS™ - وهي أعلى الشهادة معترف بها في العالم في مجال التنظيف والضيافة. يغطي منهج التدريب السنوي التدريب العملي على إدارة الفريق وإدارة الوقت والتطبيقات المتقدمة للتنظيف ومكافحة انتشار الأمراض وإدارة المخاطر، ويطلعون على أحدث المعدات والمواد والإمدادات ضمن هذا المجال.',
        },

        {
          id: 3,
          title: "خدمات صديقة للبيئة",
          content:
            "تفخر بليتش® بما لديها من قدرات على التنظيف تمنحها لعملائها مع ضمان الحفاظ على نظافة الكوكب. إننا نطبق ممارسات تنظيف فعالة للحدّ من النفايات واستخدام المواد الكيميائية، واستخدام المنتجات غير سامة وصديقة البيئة، وتعزيز البيئة الصحية.",
        },
        {
          id: 4,
          title: "منتجات صديقة للطفل ",
          content:
            "من أجل تجنب المخاطر الصحية ومخاطر منتجات التنظيف التقليدية على الأطفال، فإننا نستخدم فقط منتجات التنظيف الطبيعية غير السامة. مواد التنظيف الكيميائية المعتمدة لدينا (معتمدة من منظمة غرين سيل/ وكالة حماية البيئة) خالية من المواد الكيميائية الضارة التي يمكن أن تسمم الطفل أو تسبب له التهيج أو الأذى. مواد التنظيف الكيميائية المعتمدة لدينا خالية من المبيضات والفثالات والفوسفات ومذيبات البترول والتريكلوسان (نستخدمها فقط في حالات خاصة وبعد موافقة العميل).  ",
        },
        {
          id: 5,
          title: "وسائل صديقة للحيوانات الأليفة ",
          content:
            "تحب بليتش® الحيوانات الأليفة وتسعى لضمان سلامتها مثلكم تماماً. ونحن ملتزمون باعتماد أفضل ممارسات التنظيف في المنزل أو المنشأة. منتجات التنظيف المعتمدة لدينا مناسبة إلى حدّ كبير للحيوانات الأليفة، وتحتوي على الحدّ الأدنى أو خالية من الأمونيا أو المبيض لأنها قد تسبب التسمم لهم. منتجاتنا آمنة للمحيط ولا تسبب تهيجاً بسبب المواد المثيرة للاهتياج أو الحساسية.",
        },
        {
          id: 6,
          title: "التغطية التأمينية للعملاء والموظفين ",
          content:
            "نحن نقدر عملاءنا وموظفينا بالقد نفسه في شركة بليتش ®. نقدم تغطية تأمينية لأصول العملاء، ونحميهم من وقوع أي حوادث أو أضرار عرضية أثناء زيارتنا لتقديم خدماتنا لهم. كما أن جميع الفنيين العاملين لدى بليتش مشمولون بالتأمين على الحياة. بالإضافة إلى ذلك، نحتفظ بسجل خدمة لتحسين جودة خدماتنا لمنحك رضا بنسبة 100 ٪ في الزيارة التالية. هذا يعني أنه مع بليتش® لا توجد مخاطر على عملائنا الذين يضمنون دائمًا أفضل الخدمات.  ",
        },
      ],
    };
  },
  methods: {
    openServiceDialog: function () {
      this.$emit("openservice");
    },
    isCar() {
      console.log(this.width);
      if (this.width != null) {
        if (this.width < 768) {
          return true;
        }
        return false;
      }
      return false;
    },
    changeItm(event) {
      this.activeDiff = this.differece[event.item.index];
    },
    goto(loc) {
      window.location = loc;
    },
    changeItm2(event) {
      console.log(event);
    },
    changeDiff(id) {
      this.activeDiff = this.differece[id];
      this.activeDiffAr = this.differeceAr[id];
    },
    changed() {
      $(".slider-main-text")
        .addClass("animate__animated animate__fadeInUp")
        .one(
          "webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend",
          function () {
            $(".slider-main-text").removeClass(
              "animate__animated animate__fadeInUp"
            );
          }
        );
      $(".slider-text")
        .addClass("animate__animated animate__fadeInUp animate__delay-08s")
        .one(
          "webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend",
          function () {
            $(".slider-text").removeClass(
              "animate__animated animate__fadeInUp animate__delay-08s"
            );
          }
        );
      $(".btn-trans")
        .addClass("animate__animated animate__fadeInUp animate__delay-1s")
        .one(
          "webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend",
          function () {
            $(".btn-trans").removeClass(
              "animate__animated animate__fadeInUp animate__delay-1s"
            );
          }
        );
    },
  },
};
</script>

<style></style>
