<template>
  <div>
    <div v-if="selectlan == 'EN'" class="gray-bg-2">
      <div class="bcontainer">
        <div class="pmy-15">
          <div class="row">
            <div class="col-lg-3 col-md-4">
              <ul class="service-ui show-head">
                <li @click="$router.push('kitchencleaning')">
                  <span class="dot-li"></span>
                  Kitchen Cleaning
                </li>
                <li class="service-ui-active">
                  <div class="dot-li-active"></div>
                  Kitchen Appliances
                </li>
              </ul>
              <div class="show-callus">
                <div class="about-cleaning">
                  Services Under Kitchen Cleaning
                </div>
                <div class="py-2" style="border-bottom: 1px solid #c4c4c4">
                  <div class="d-flex pointer" @click="dc = !dc">
                    <div class="mobile-sub-link mobile-sub-header h-100 ma">
                      Kitchen Appliances
                    </div>
                    <div>
                      <img
                        class="mr-1"
                        :class="{ 'fa-arrow-down-open': dc }"
                        src="../../../assets/ar.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class="drop-service">
                  <transition-expand>
                    <div v-if="dc">
                      <ul class="drop-list">
                        <li @click="$router.push('kitchencleaning')">
                          <a> Kitchen Cleaning </a>
                        </li>
                      </ul>
                    </div>
                  </transition-expand>
                </div>
              </div>
            </div>
            <div class="col-lg-9 col-md-8">
              <div class="cleaning-head show-head">
                Kitchen <span class="w400">Appliances</span>
              </div>
              <div class="row mt-1">
                <div class="col-lg-12 col-md-12 about-cleaning">
                  Our high-tech machinery delivers quality cleaning of
                  stainless-steel appliances, stoves, hood vents, window ledges,
                  dishwasher, fridge, microwave, and more
                </div>

                <div class="text-center py-20" v-if="loading">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
                <div v-else>
                  <div class="d-flex mt-5">
                    <div class="serive-sub-section ma">Cooking Range</div>
                    <hr
                      class="service-title-line"
                      style="border-top: 1px solid #c4c4c4"
                    />
                  </div>
                  <div class="row">
                    <div
                      class="mt-5 col-6 col-lg-3 col-md-6 text-center"
                      v-for="item in cook"
                      :key="item.id"
                    >
                      <div class="price-card-new h-100">
                        <div
                          class="price-card-size"
                          v-if="item.name != 'Cooking Range'"
                        >
                          {{ item.category
                          }}<span v-if="item.category == null"> Common</span>
                        </div>
                        <div
                          class="price-card-size"
                          v-if="item.name == 'Cooking Range'"
                        >
                          {{ item.category_updated }}
                        </div>

                        <div class="price-card-inside">
                          <div>KD</div>
                          <div class="price-card-price">{{ item.price }}</div>
                        </div>
                        <div
                          class="price-card-text"
                          v-if="item.name == 'Cooking Range'"
                        >
                          {{ item.category_updated_minimum }} -
                          {{ item.category_updated_maximum }} <br />
                          Burners
                        </div>

                        <div class="mt-2 price-card-text w400">
                          {{ item.name }}
                        </div>

                        <div
                          class="price-card-btn mt-4"
                          @click="selectItem(item)"
                        >
                          Add to cart
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div class="d-flex mt-5">
                    <div class="serive-sub-section ma">
                      Refrigerator & Freezer
                    </div>
                    <hr
                      class="service-title-line"
                      style="border-top: 1px solid #c4c4c4"
                    />
                  </div>
                  <div class="row">
                    <div
                      class="mt-5 col-6 col-lg-3 col-md-6 text-center"
                      v-for="item in ref"
                      :key="item.id"
                    >
                      <div class="price-card-new h-100">
                        <div
                          class="price-card-size"
                          v-if="item.name != 'Cooking Range'"
                        >
                          {{ item.category
                          }}<span v-if="item.category == null"> Common</span>
                        </div>
                        <div
                          class="price-card-size"
                          v-if="item.name == 'Cooking Range'"
                        >
                          {{ item.category_updated }}
                        </div>

                        <div class="price-card-inside">
                          <div>KD</div>
                          <div class="price-card-price">{{ item.price }}</div>
                        </div>
                        <div
                          class="price-card-text"
                          v-if="item.name == 'Cooking Range'"
                        >
                          {{ item.category_updated_minimum }} -
                          {{ item.category_updated_maximum }} <br />
                          Burners
                        </div>

                        <div class="mt-2 price-card-text w400">
                          {{ item.name }}
                        </div>

                        <div
                          class="price-card-btn mt-4"
                          @click="selectItem(item)"
                        >
                          Add to cart
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>

                  <div class="d-flex mt-5">
                    <div class="serive-sub-section ma">Other Appliances</div>
                    <hr
                      class="service-title-line"
                      style="border-top: 1px solid #c4c4c4"
                    />
                  </div>
                  <div class="row">
                    <div
                      class="mt-5 col-6 col-lg-3 col-md-6 text-center"
                      v-for="item in other"
                      :key="item.id"
                    >
                      <div class="price-card-new h-100">
                        <div
                          class="price-card-size"
                          v-if="item.name != 'Cooking Range'"
                        >
                          {{ item.category
                          }}<span v-if="item.category == null"> Common</span>
                        </div>
                        <div
                          class="price-card-size"
                          v-if="item.name == 'Cooking Range'"
                        >
                          {{ item.category_updated }}
                        </div>

                        <div class="price-card-inside">
                          <div>KD</div>
                          <div class="price-card-price">{{ item.price }}</div>
                        </div>
                        <div
                          class="price-card-text"
                          v-if="item.name == 'Cooking Range'"
                        >
                          {{ item.category_updated_minimum }} -
                          {{ item.category_updated_maximum }} <br />
                          Burners
                        </div>

                        <div class="mt-2 price-card-text w400">
                          {{ item.name }}
                        </div>

                        <div
                          class="price-card-btn mt-4"
                          @click="selectItem(item)"
                        >
                          Add to cart
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="selectlan == 'ARABIC'" class="gray-bg-2">
      <div class="bcontainer">
        <div class="pmy-15">
          <div class="row">
            <div class="col-lg-3 col-md-4">
              <ul class="service-ui-ar show-head">
                <li
                  @click="$router.push('kitchencleaning')"
                  class="arabic-font"
                >
                  <span class="dot-li-ar"></span>
                  تنظيف المطبخ
                </li>
                <li class="service-ui-active arabic-font">
                  <div class="dot-li-ar-active"></div>
                  أجهزة المطبخ
                </li>
              </ul>
              <div class="show-callus">
                <div class="about-cleaning arabic-font">
                  Services Under تنظيف المطبخ
                </div>
                <div class="py-2" style="border-bottom: 1px solid #c4c4c4">
                  <div class="d-flex pointer" @click="dc = !dc">
                    <div
                      class="
                        mobile-sub-link mobile-sub-header
                        h-100
                        ma
                        arabic-font
                      "
                    >
                      أجهزة المطبخ
                    </div>
                    <div>
                      <img
                        class="mr-1"
                        :class="{ 'fa-arrow-down-open': dc }"
                        src="../../../assets/ar.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class="drop-service">
                  <transition-expand>
                    <div v-if="dc">
                      <ul class="drop-list">
                        <li @click="$router.push('kitchencleaning')">
                          <a class="arabic-font"> تنظيف المطبخ </a>
                        </li>
                      </ul>
                    </div>
                  </transition-expand>
                </div>
              </div>
            </div>
            <div class="col-lg-9 col-md-8">
              <div class="cleaning-head show-head arabic-font">
                أجهزة <span class="w400">المطبخ</span>
              </div>
              <div class="row mt-1">
                <div class="col-lg-12 col-md-12 about-cleaning arabic-font">
                  توفر آلاتنا عالية التقنية تنظيفًا عالي الجودة للأجهزة المصنوعة
                  من الفولاذ المقاوم للصدأ والمواقد وفتحات التهوية وحواف النوافذ
                  وغسالة الأطباق والثلاجة والميكروويف وما إلى ذلك.
                </div>

                <div class="text-center py-20" v-if="loading">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
                <div v-else>
                  <div class="d-flex mt-5">
                    <div class="serive-sub-section-ar ma arabic-font">
                      الفرن ومحتوياته
                    </div>
                    <hr
                      class="service-title-line"
                      style="border-top: 1px solid #c4c4c4"
                    />
                  </div>
                  <div class="row">
                    <div
                      class="mt-5 col-6 col-lg-3 col-md-6 text-center"
                      v-for="item in cook"
                      :key="item.id"
                    >
                      <div class="price-card-new h-100">
                        <div
                          class="price-card-size"
                          v-if="item.name != 'Cooking Range'"
                        >
                          {{ item.category
                          }}<span v-if="item.category == null"> Common</span>
                        </div>
                        <div
                          class="price-card-size arabic-font"
                          v-if="item.name == 'Cooking Range'"
                        >
                          {{ getArSize(item.category_updated) }}
                        </div>

                        <div class="price-card-inside">
                          <div class="price-card-price">{{ item.price }}</div>
                          <div>د.ك.</div>
                        </div>
                        <div
                          class="price-card-text arabic-font"
                          v-if="item.name == 'Cooking Range'"
                        >
                          {{ item.category_updated_minimum }} -
                          {{ item.category_updated_maximum }} <br />
                          طباخ الغاز
                        </div>

                        <div class="mt-2 price-card-text w400 arabic-font">
                          الفرن ومحتوياته
                        </div>

                        <div
                          class="price-card-btn mt-4"
                          @click="selectItem(item)"
                        >
                          Add to cart
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div class="d-flex mt-5">
                    <div class="serive-sub-section-ar ma arabic-font">
                      الثلاجات و البرادات
                    </div>
                    <hr
                      class="service-title-line"
                      style="border-top: 1px solid #c4c4c4"
                    />
                  </div>
                  <div class="row">
                    <div
                      class="mt-5 col-6 col-lg-3 col-md-6 text-center"
                      v-for="item in ref"
                      :key="item.id"
                    >
                      <div class="price-card-new h-100">
                        <div
                          class="price-card-size arabic-font"
                          v-if="item.name != 'Cooking Range'"
                        >
                          {{ getArSize(item.category)
                          }}<span v-if="item.category == null"> Common</span>
                        </div>

                        <div class="price-card-inside">
                          <div class="price-card-price">{{ item.price }}</div>
                          <div>د.ك.</div>
                        </div>
                        <div
                          class="price-card-text"
                          v-if="item.name == 'Cooking Range'"
                        >
                          {{ item.category_updated_minimum }} -
                          {{ item.category_updated_maximum }} <br />
                          Burners
                        </div>

                        <div class="mt-2 price-card-text w400 arabic-font">
                          الثلاجات و البرادات
                        </div>

                        <div class="price-card-btn mt-4">Add to cart</div>
                        <br />
                      </div>
                    </div>
                  </div>

                  <div class="d-flex mt-5">
                    <div class="serive-sub-section-ar ma arabic-font">
                      أجهزة أخرى
                    </div>
                    <hr
                      class="service-title-line"
                      style="border-top: 1px solid #c4c4c4"
                    />
                  </div>
                  <div class="row">
                    <div
                      class="mt-5 col-6 col-lg-3 col-md-6 text-center"
                      v-for="item in other"
                      :key="item.id"
                    >
                      <div class="price-card-new h-100">
                        <div
                          class="price-card-size arabic-font"
                          v-if="item.name != 'Cooking Range'"
                        >
                          {{ item.category
                          }}<span v-if="item.category == null"> شائع</span>
                        </div>
                        <div
                          class="price-card-size"
                          v-if="item.name == 'Cooking Range'"
                        >
                          {{ item.category_updated }}
                        </div>

                        <div class="price-card-inside">
                          <div class="price-card-price">{{ item.price }}</div>
                          <div>د.ك.</div>
                        </div>
                        <div
                          class="price-card-text"
                          v-if="item.name == 'Cooking Range'"
                        >
                          {{ item.category_updated_minimum }} -
                          {{ item.category_updated_maximum }} <br />
                          Burners
                        </div>

                        <div
                          class="mt-2 price-card-text w400 arabic-font"
                          v-if="item.name == 'Fryers'"
                        >
                          قلايات
                        </div>
                        <div
                          class="mt-2 price-card-text w400 arabic-font"
                          v-else-if="item.name == 'Grillers'"
                        >
                          شوايات
                        </div>
                        <div
                          class="mt-2 price-card-text w400 arabic-font"
                          v-else-if="item.name == 'Exhaust Filters'"
                        >
                          شفاطات
                        </div>
                        <div
                          class="mt-2 price-card-text w400 arabic-font"
                          v-else-if="item.name == 'Microwave Oven'"
                        >
                          الفرن و الميكرويف
                        </div>

                        <div class="mt-2 price-card-text w400" v-else>
                          {{ item.name }}
                        </div>

                        <div class="price-card-btn mt-4">Add to cart</div>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <cart-reset-dialog :dialog="reset_dialog" @resetcart="is_reset" />
  </div>
</template>

<script>
import addedtocart from "../../../components/addedtocart.vue";
//import Utils from '../../../utils/utils';
import service from "../../../services/service";
export default {
  data() {
    return {
      reset_dialog: false,
      dc: false,
      service_price_ranges: [],
      selectlan: localStorage.getItem("bleach-lang"),
      loading: false,
      ref: [],
      cook: [],
      other: [],
      service_data: {
        section_name: "",
        addon_name: "",
        addon_category: "",
        addon_size: "",
        addon_price: "",
        addon_productivity: "",
        service_type: "",
      },
      productivity_id: "",
    };
  },
  mounted() {
    this.getProductivity();
  },
  methods: {
    is_reset(flag) {
      if (flag) {
        this.reset_dialog = false;
        this.addtocart();
      } else {
        this.reset_dialog = false;
      }
    },
    async selectItem(item) {
      console.log(item);
      this.service_data.service_type = item.service_type.id;
      this.productivity_id = item.id;
      this.service_data.addon_name = item.name;
      this.service_data.section_name = item.name;
      this.service_data.addon_category = item.category;
      this.service_data.addon_size = item.size;
      this.service_data.addon_price = item.price;
      this.service_data.addon_productivity = item.productivity;
      if (this.$store.state.isCartScheduled) {
        this.reset_dialog = true;
      } else {
        let result = await service._post(
          "customer/cart/" + localStorage.getItem("bleach-tk") + "/",
          {
            action: "add_service",
            service_data: this.service_data,
            productivity_id: this.productivity_id,
          }
        );
        if (result.status == 200) {
          this.$vs.notification({
            duration: "5000",
            icon: "<i class='bx bxs-cart-add' style='color:#2b9fad;margin-top:4px' ></i>",
            color: "#EDF9FA",
            position: "top-right",
            border: "#2b9fad",
            content: addedtocart,
          });
        } else {
          this.$vs.notification({
            border: "danger",
            duration: "5000",
            position: "top-right",
            title: "Add to cart failed",
            text: "Please try again.If fails multiple times then please relogin",
          });
        }
        console.log(result);
      }
    },
    async cartUpdation() {
      let result = await service._get(
        "customer/cart/" + localStorage.getItem("bleach-tk") + "/"
      );
      if (result.status == 200) {
        this.$store.commit("changeCart", result.data.cart_items_count);
      }
    },
    async addtocart() {
      if (localStorage.getItem("bleach-tk")) {
        let result = await service._post(
          "customer/cart/" + localStorage.getItem("bleach-tk") + "/",
          {
            action: "add_service",
            service_data: this.service_data,
            productivity_id: this.productivity_id,
          }
        );
        console.log(result);
      } else {
        this.$store.commit("set_logindialog", true);
      }
      //if(result.status ==200){}
    },
    getArSize(name) {
      let n = name.toLowerCase();
      if (n == "x-small") {
        return "حجم صغير ١";
      } else if (n == "one side") {
        return "جانب فردي";
      } else if (n == "x-large") {
        return "حجم كبير ١";
      } else if (n == "2x-large") {
        return "٢ حجم كبير";
      } else if (n == "2x-small") {
        return "٢ حجم صغير";
      } else if (n == "3x-large") {
        return "٣ حجم كبير";
      } else if (n == "4x-large") {
        return "٤ حجم كبير";
      } else if (n == "cribe") {
        return "سرير صغير";
      } else if (n == "single bed") {
        return "سرير فردي";
      } else if (n == "queen bed") {
        return "سرير كوين";
      } else if (n == "king bed") {
        return "سرير ملكي";
      } else if (n == "single side") {
        return "جانب فردي";
      } else if (n == "two side") {
        return "جانبين - مزودج";
      } else if (n == "small") {
        return "صغير";
      } else if (n == "medium") {
        return "متوسط";
      } else if (n == "large") {
        return "كبير";
      } else if (n == "small set") {
        return "مجموعة صغيرة";
      } else if (n == "medium set") {
        return "مجموعة متوسطة";
      } else if (n == "large set") {
        return "مجموعة كبيرة";
      } else {
        return name;
      }
    },
    async getProductivity() {
      this.loading = true;
      let result = await service._get(
        "api/service-add-ons/Kitchen%20Cleaning/"
      );
      this.loading = false;
      this.service_price_ranges = result.data.service_add_ons;
      for (var i = 0; i < this.service_price_ranges.length; i++) {
        if (this.service_price_ranges[i].name == "Refrigerator & Freezer") {
          this.ref.push(this.service_price_ranges[i]);
        } else if (this.service_price_ranges[i].name == "Cooking Range") {
          this.cook.push(this.service_price_ranges[i]);
        } else {
          this.other.push(this.service_price_ranges[i]);
        }
      }
      // console.log(result)
    },
  },
};
</script>

<style></style>
