<template>
<div>
    <div v-if="selectlan=='EN'" class="gray-bg-2">
    <div class="bcontainer">
      <div class="pmy-15">
        <div class="row">
          <div class="col-lg-3 col-md-4">
            <ul class="service-ui show-head">
              <li  @click="$router.push('generalcleaning')">
                 <span class="dot-li"></span>
                General Cleaning
              </li>
              <li @click="$router.push('deepcleaning')" >
                <span class="dot-li"></span>
                Deep Cleaning
              </li>
              <li @click="$router.push('storagearea')">
               <span class="dot-li"></span>
                Storage Area
              </li>
              <li @click="$router.push('windowcleaning')" >
                 <span class="dot-li"></span>
                Window Cleaning
              </li>
              <li @click="$router.push('outdoorcleaning')">
                <span class="dot-li"></span>
                Outdoor Cleaning
              </li>
               <li @click="$router.push('carparking')" >
                 <span class="dot-li"></span>
                Car Parking 
              </li>
               <li class="service-ui-active" >
                <div class="dot-li-active"></div>
                Facade Cleaning 
              </li>
            </ul>
            <detailedcleanmenu :name="'Facade Cleaning'" />
          </div>
          <div class="col-lg-9 col-md-8">
            <div class="cleaning-head show-head">Facade <span class="w400">Cleaning </span> </div>
            <div class="row mt-1">
              <div class="col-lg-12 col-md-12 about-cleaning">
              Our Façade Cleaning service involves in-depth cleaning the outside wall/glass of a building
or any structure to remove dust, dirt, grime, pollution, and impurities.
              </div>

            <div class="text-center py-20" v-if="loading">
               <v-progress-circular
      indeterminate
      color="primary"
    ></v-progress-circular>
            </div>
            <div v-else>
               <div class="d-flex mt-5">
                 <div class="serive-sub-section ma">
                   New Facade
                 </div>
                   <hr class="service-title-line" style="border-top: 1px solid #C4C4C4;">
                 </div>
                <div class="row" >
                <div
                 class="mt-5 col-lg-3 col-md-6 col-6 text-center "
                  v-for="item in newfacade " :key="item.id"
                >
                  <div class="price-card-new">
                    <div class="price-card-size">{{item.name}}</div>
                    <div class="price-card-inside">
                      <div>KD</div>
                      <div class="price-card-price">{{item.price}}</div>
                    </div>
                    <div class="price-card-text">{{item.minimum_area}} - {{item.maximum_area}} <br> SQM</div>
                    <div class="mt-2 price-card-text w400" v-if="item.is_highprice_facade">New Facade</div>
                    <div class="mt-2 price-card-text w400" v-if="!item.is_highprice_facade">Old Facade</div>
                    <!-- <div class="price-card-btn mt-4   ">Add to cart
                    </div> -->
                    <br>
                  </div>
                </div>
               
              </div>
               <div class="d-flex mt-5">
                 <div class="serive-sub-section ma">
                   Old Facade
                 </div>
                   <hr class="service-title-line" style="border-top: 1px solid #C4C4C4;">
                 </div>
                <div class="row" >
                <div
                 class="mt-5 col-lg-3 col-md-6 col-6 text-center "
                  v-for="item in oldfacade" :key="item.id"
                >
                  <div class="price-card-new">
                    <div class="price-card-size">{{item.name}}</div>
                    <div class="price-card-inside">
                      <div>KD</div>
                      <div class="price-card-price">{{item.price}}</div>
                    </div>
                    <div class="price-card-text">{{item.minimum_area}} - {{item.maximum_area}} <br> SQM</div>
                    <div class="mt-2 price-card-text w400" v-if="item.is_highprice_facade">New Facade</div>
                    <div class="mt-2 price-card-text w400" v-if="!item.is_highprice_facade">Old Facade</div>
                    <!-- <div class="price-card-btn mt-4   ">Add to cart
                    </div> -->
                    <br>
                  </div>
                </div>
               
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
   <div v-if="selectlan=='ARABIC'" class="gray-bg-2">
    <div class="bcontainer">
      <div class="pmy-15">
        <div class="row">
          <div class="col-lg-3 col-md-4">
            <ul class="service-ui-ar show-head">
              <li  @click="$router.push('generalcleaning')" class="arabic-font">
                 <span class="dot-li-ar"></span>
                التنظيف العام
              </li>
              <li @click="$router.push('deepcleaning')" class="arabic-font" >
                <span class="dot-li-ar"></span>
                التنظيف العميق 
              </li>
              <li @click="$router.push('storagearea')" class="arabic-font">
               <span class="dot-li-ar"></span>
                تنظيف منطقة التخزين
              </li>
              <li @click="$router.push('windowcleaning')" class="arabic-font">
                 <span class="dot-li-ar"></span>
                تنظيف النوافذ
              </li>
              <li @click="$router.push('outdoorcleaning')" class="arabic-font">
                <span class="dot-li-ar"></span>
                تنظيف المساحة الخارجية
              </li>
               <li @click="$router.push('carparking')" class="arabic-font">
                 <span class="dot-li-ar"></span>
                صيانة مظالت مواقف السيارات
              </li>
               <li class="service-ui-active arabic-font" >
                <div class="dot-li-ar-active"></div>
                تنظيف الواجهات
              </li>
            </ul>
            <detailedcleanmenu :name="'تنظيف الواجهات'" />
          </div>
          <div class="col-lg-9 col-md-8">
            <div class="cleaning-head show-head arabic-font">تنظيف <span class="w400">الواجهات </span> </div>
            <div class="row mt-1">
              <div class="col-lg-12 col-md-12 about-cleaning arabic-font">
             تتضمن خدمة تنظيف الواجهة لدينا تنظيفًا دقيقاً للجدار أو الزجاج الخارجي للمبنى أو أي هيكل لإزالة الغبار والأوساخ والتلوث والشوائب.
              </div>

            <div class="text-center py-20" v-if="loading">
               <v-progress-circular
      indeterminate
      color="primary"
    ></v-progress-circular>
            </div>
               <div v-else>
               <div class="d-flex mt-5">
                 <div class="serive-sub-section-ar ma arabic-font">
                   واجهة مبنى جديدة
                 </div>
                   <hr class="service-title-line" style="border-top: 1px solid #C4C4C4;">
                 </div>
                <div class="row" >
                <div
                 class="mt-5 col-lg-3 col-md-6 col-6 text-center "
                  v-for="item in newfacade " :key="item.id"
                >
                  <div class="price-card-new">
                    <div class="price-card-size arabic-font" >{{getArSize(item.name)}}</div>
                    <div class="price-card-inside">
                     
                      <div class="price-card-price">{{item.price}}</div>
                       <div>د.ك.</div>
                    </div>
                    <div class="price-card-text">{{item.minimum_area}} - {{item.maximum_area}} <br> متر مربع</div>
                    <div class="mt-2 price-card-text w400 arabic-font" v-if="item.is_highprice_facade">واجهة مبنى جديدة</div>
                    <div class="mt-2 price-card-text w400" v-if="!item.is_highprice_facade">Old Facade</div>
                    <!-- <div class="price-card-btn mt-4   ">Add to cart
                    </div> -->
                    <br>
                  </div>
                </div>
               
              </div>
               <div class="d-flex mt-5">
                 <div class="serive-sub-section-ar ma arabic-font">
                   واجهة مبنى قائمة
                 </div>
                   <hr class="service-title-line" style="border-top: 1px solid #C4C4C4;">
                 </div>
                <div class="row" >
                <div
                 class="mt-5 col-lg-3 col-md-6 col-6 text-center "
                  v-for="item in oldfacade" :key="item.id"
                >
                  <div class="price-card-new">
                    <div class="price-card-size arabic-font">{{getArSize(item.name)}}</div>
                    <div class="price-card-inside">
                     
                      <div class="price-card-price">{{item.price}}</div>
                       <div>د.ك.</div>
                    </div>
                    <div class="price-card-text">{{item.minimum_area}} - {{item.maximum_area}} <br> متر مربع</div>
                    <div class="mt-2 price-card-text w400" v-if="item.is_highprice_facade">New Facade</div>
                    <div class="mt-2 price-card-text w400 arabic-font" v-if="!item.is_highprice_facade">واجهة مبنى قائمة</div>
                    <!-- <div class="price-card-btn mt-4   ">Add to cart
                    </div> -->
                    <br>
                  </div>
                </div>
               
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
</template>

<script>
import Service from '../../../services/service'
import detailedcleanmenu from '../../../components/menu/detailedcleanmenu.vue'

export default {
  components:{
    detailedcleanmenu
  },
  data(){
    return{
      loading:false,
      service_price_ranges:[],
      selectlan:localStorage.getItem('bleach-lang'),
      newfacade:[],
      oldfacade:[]

    };
  },
  mounted(){
    this.getProductivity()
  },
  methods:{
    getArSize(name){
     let n = name.toLowerCase();
     if(n == 'x-small'){
     return 'حجم صغير ١';
     }else if(n == 'x-large'){
      return 'حجم كبير ١';
     }else if(n=='2x-large'){
       return '٢ حجم كبير'
     }else if(n=='2x-small'){
       return '٢ حجم صغير';
     }else if(n=='3x-large'){
       return '٣ حجم كبير';
     }else if(n=='4x-large'){
       return '٤ حجم كبير';
     }else if(n=='cribe'){
       return 'سرير صغير';
     }else if(n=='single bed'){
       return 'سرير فردي'
     }else if(n=='single side'){
       return 'جانب فردي';
     }else if(n=='two side'){
       return 'جانبين - مزودج';
     }else if (n=='small'){
       return 'صغير'
     }else if(n=='medium'){
       return 'متوسط';
     }else if(n=='large'){
       return 'كبير';
     }
     else{
       return name
     }
   },
   async getProductivity(){
     this.loading = true
     let result = await Service._get('api/service-price-ranges/Facade%20Cleaning/')
     this.loading = false
     this.service_price_ranges = result.data.service_price_ranges
     for(var i=0;i<this.service_price_ranges.length;i++){
         if(this.service_price_ranges[i].is_highprice_facade){
           this.newfacade.push(this.service_price_ranges[i])
         }else{
           this.oldfacade.push(this.service_price_ranges[i])
         }
     }
     //console.log(result)
   }
  }
};
</script>

<style></style>
