<template>
<div>
    <div v-if="selectlan=='EN'">
      <div class="gray-bg-2">
      <div class="bcontainer">
         <div class="row py-15">
             <div class="col-md-4 col-lg-3">
                 <ul class="service-ui show-head">

                     <li @click="$router.push('industrial')">
                         <span class="dot-li"></span> 
                       Industrial Sector
                    </li>
                    <li class="service-ui-active" >
                        <div class="dot-li-active"></div>
                        Corporate Offices
                    </li>
                    <li @click="$router.push('education')" >
                         <span class="dot-li"></span>
                        Educational Facilities
                    </li>
                     <li @click="$router.push('health')" >
                         <span class="dot-li"></span>
                        Health Care Sector
                    </li>
                    <li @click="$router.push('retail')" >
                         <span class="dot-li"></span>
                        Retail Sector
                    </li>
                    <li @click="$router.push('sports')" >
                         <span class="dot-li"></span>
                        Sports & Fitness
                    </li>
                    <li @click="$router.push('foods')" >
                         <span class="dot-li"></span>
                        Foods & Beverages
                    </li>
                     <li @click="$router.push('hotels')" >
                         <span class="dot-li"></span>
                        Hotels & Luxury
                    </li>
                 </ul>
                 <commenu :name="'Corporate Offices'"/>
                   <div class="mt-5">
                    <div class="recommended-head">
                        RECOMMENDED SERVICES
                    </div>
                   <div class="rec-item-name mt-3"  @click="goto('/service/detailedcleaning/deepcleaning')">
                        <img src="../../assets/icons/recommend/deep.png" alt="" class="ml-2 mr-2">  Deep Cleaning
                    </div>
                    <div class="rec-item-name mt-3" @click="goto('/service/detailedcleaning/generalcleaning')">
                        <img src="../../assets/icons/recommend/general.png" alt="" class="ml-2 mr-2">  General Cleaning
                    </div>
                    <div class="rec-item-name mt-3" @click="goto('/service/pestcontrol/pestcontrol')">
                        <img src="../../assets/icons/recommend/inf.png" alt="" class="ml-2 mr-2">  Pest Control
                    </div>
                 </div>
             </div>
             <div class="col-md-8 col-lg-9">
                 <div class="cleaning-head show-head">Corporate <span class="w400"> Offices</span></div>
                   <div class="sub-content mt-2">
                       A clean workplace is important in creating a positive brand image and reflects favorably on
                        the organization among clients. Additionally, a clean office makes employees feel healthier,
                        and more productive and reduces the rate of absenteeism. BLEACH® offers customized
                        cleaning packages for office areas with set cleaning frequency and time – from the reception
                        area, offices, meeting rooms, and washrooms to kitchens.

                 </div>
                 <div class="bold-heading mt-10">
                     DID YOU KNOW ?
                 </div>
                 <div class="mt-3 sub-content">
                  A 2015 report by the American Medical Association states 15 million people call in sick every
year and this leads to a loss of revenue due to the loss of productivity. Sick building
contributes to 7.7 sick days per employee per year. These sick days cost employers $225.8
Billion per year. <br><br>
A clean environment has a relaxing effect on the senses of the visitor. Employees working in
a cleaner environment work in harmony, hence making them more productive and creative.

                 </div>
                  <div class="bold-heading mt-10">
                     WHY A CLEAN CORPORATE WORKPLACE IS IMPORTANT
                 </div>
                 <div class="mt-5 sub-content">
                     We at BLEACH® believe ‘a healthy work culture comes from a healthy workplace’!
Additionally, it:
 <ul class="content-ui mt-3">
                            <li>Leaves a great impression on clients </li>
                            <li>Increases employees’ productivity</li>
                            <li>Makes employees happy</li>
                            <li>Safeguards employees’ health</li>
                            <li>Reduces hazards</li>
                            <li>Smoothens office operations</li>
                           
                        </ul>

                 </div>
             </div>
         </div>
      </div>
  </div>

</div>
   <div v-if="selectlan=='ARABIC'">
      <div class="gray-bg-2">
      <div class="bcontainer">
         <div class="row py-15">
             <div class="col-md-4 col-lg-3">
                 <ul class="service-ui-ar show-head">

                     <li class="arabic-font" @click="$router.push('industrial')">
                         <span class="dot-li-ar"></span> 
                       صيانة القطاع الصناعي 
                    </li>
                    <li class="service-ui-active arabic-font" >
                        <div class="dot-li-ar-active"></div>
                        مقرات ومكاتب الشركات والمؤسسات
                    </li>
                    <li class="arabic-font" @click="$router.push('education')" >
                         <span class="dot-li-ar"></span>
                        صيانة المرافق التربوية والتعليمية 
                    </li>
                     <li class="arabic-font" @click="$router.push('health')" >
                         <span class="dot-li-ar"></span>
                        الإهتمام بقطاع الرعاية الصحية 
                    </li>
                    <li class="arabic-font" @click="$router.push('retail')" >
                         <span class="dot-li-ar"></span>
                        خدمات النظافة لقطاع البيع بالتجزئة
                    </li>
                    <li class="arabic-font" @click="$router.push('sports')" >
                         <span class="dot-li-ar"></span>
                        رياضة ولياقة بدنية
                    </li>
                    <li class="arabic-font" @click="$router.push('foods')" >
                         <span class="dot-li-ar"></span>
                       صيانة مرافق التغذية والمرطبات 
                    </li>
                     <li  class="arabic-font" @click="$router.push('hotels')" >
                         <span class="dot-li-ar"></span>
                        قطاع الفنادق والرفاهية
                    </li>
                 </ul>
                 <commenu :name="'مقرات ومكاتب الشركات والمؤسسات'"/>
             </div>
             <div class="col-md-8 col-lg-9">
                 <div class="cleaning-head show-head arabic-font">مقرات ومكاتب <span class="w400">  الشركات والمؤسسات</span></div>
                   <div class="sub-content mt-2 arabic-font">
                      تعد نظافة مكان العمل أمراً اساسياً لإنشاء صورة إيجابية للعلامة التجارية بحيث ينعكس هذا الأمر بشكل إيجابي على المؤسسة بين عملاءها. وإضافة إلى ذلك، فإن المكتب النظيف يجعل الموظفين يشعرون بأنهم أكثر صحة وإنتاجية ويقلل من معدل التغيب عن العمل.  تقدم بليتش® عروض تنظيف مخصصة لمساحات المكاتب مع تكرار التنظيف في الوقت المحدد - من مساحات الاستقبال والمكاتب وغرف الاجتماعات، وصولاً لغرف المراحيض والمطابخ. 

                 </div>
                 <div class="bold-heading mt-10 arabic-font">
                     هل تعلم؟
                 </div>
                 <div class="mt-3 sub-content arabic-font">
                 ذكر تقرير صادر عن الجمعية الطبية الامريكية عام 2015 أن خمسة عشر مليون فرد يتقدمون بطلب إعفاء طبي عن العمل كل عام، ما يؤدي الى خسائر مادية بسبب عدم الإنتاجية. علماً بأن المباني ذات الأعراض المرضية تساهم في أن يصل عدد الأيام المرضية التي يحصل عليها الموظف الى 7.7 يوم في العام، والتي تكلف أصحاب الأعمال 225.8 مليار دولار امريكي سنوياً. 

  <br><br>

للبيئة النظيفة تأثير مريح على حواس الزائر. يجتهد الموظفون الذين تحيط بهم بيئة أنظف للعمل في وئام، مما يجعلهم أكثر إنتاجية وإبداع.

                 </div>
                  <div class="bold-heading mt-10 arabic-font">
                     أهمية نظافة مقر العمل المؤسسي
                 </div>
                 <div class="mt-5 sub-content arabic-font">
                     نحن في بليتش® نؤمن بأن "ثقافة العمل الصحي تأتي من وراء مقر عمل صحي"! وذلك إضافة الى: 
 <ul class="content-ui mt-3 pr-5">
                            <li class="arabic-font">ترك إنطباع جيد لدى العمالء. </li>
                            <li class="arabic-font">يزيد من إنتاجية الموظفين. </li>
                            <li class="arabic-font">يجعل الموظفين سعداء.</li>
                            <li class="arabic-font">يحافظ على صحة الموظفين.</li>
                            <li class="arabic-font">يقلل من المخاطر</li>
                            <li class="arabic-font">تيسر من إجراءات العمل في المكتب .</li>
                           
                        </ul>

                 </div>
             </div>
         </div>
      </div>
  </div>

</div>

</div>
</template>

<script>
import commenu from '../../components/menu/commenu.vue'
export default {
    components:{
        commenu
    },
  data(){
        return{
            selectlan:localStorage.getItem('bleach-lang')
        }
    },
     methods:{
         goto(loc) {
         window.location = loc;
        },
     }
    
}
</script>

<style>

</style>