<template>
   <div class="pb-15">
         
         <div class="bcontainer">
            <div class="d-flex">
                 <div class="my-account pointer" @click="gotoMain('home')">
                    Home
                </div>
                <div class="my-account ml-3 ma mr-3 ">
                    <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.3297 0.999986L4.65942 4.32971L1.3297 7.65944" stroke="#8A8A8A" stroke-width="1.17724" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                 <div class="my-account pointer" style="font-weight:600">
                {{$route.name}}
                </div>
             </div>
             <div class="d-flex mt-6" v-if="customer != null">
                 <div>
                    <v-avatar size="50" color="#6D6D6E">
                        <span class="white--text text-h5"> {{customer.name.charAt(0)}}</span>
                    </v-avatar>
                 </div>
                 <div class="user-name ma ml-2">
                     {{customer.name}}
                 </div>
             </div>
             <div>
                <router-view/>
             </div>
         </div>
   </div>
</template>

<script>
    import service from '../../services/service';
    export default {
        data(){
            return{
                empty:false,
                orders:[],
                customer:null
            }
        },
        created(){
            this.getCustomerData()
            this.getOrders()
        },
        methods:{
            gotoMain(loc) {
      window.location = window.location.href.split("/")[0] + "/" + loc;
    },
            getAddressDatas(item){
             
                if(item.governorate != undefined) return item.governorate.name+','+item.area.name;
            },
            async getCustomerData(){
                let result = await service._get('api/customer-details/'+localStorage.getItem('bleach-tk')+'/');
          
                if(result.status ==200){
                    this.customer = result.data.customer_data
                }
            },
            async getOrders(){
                let result = await service._get('api/customer-orders/'+localStorage.getItem('bleach-tk')+'/');
                if(result.status == 200){
                  
                    this.orders = result.data.data
                }
              
            }
        }
    }
    </script>
    
    <style>
    
    </style>