<template>
  <div>
    <div>
      <section class="">
        <div class="bg-header-img-kitchcleaning bg-header"></div>
      </section>
    </div>
    <div v-if="selectlan == 'EN'">
      <div class="bcontainer">
        <div class="pmy-15">
          <div class="page-head">
            KITCHEN <span class="w400">CLEANING</span>
          </div>
          <div class="sub-content mt-2 text-left">
            For your kitchen maintenance needs, you can rely only on Experts.
            The kitchen is one of the most unclean places in your home or
            office, which can be a breeding ground for pathogens and unwanted
            bacteria. Cleaning this sensitive area is crucial, and we have the
            right solution for all your Kitchen Maintenance needs, no matter how
            large or small. clean as per international standards.<span
              v-if="!dc"
              style="color: #2b9fad"
              @click="dc = true"
              >Read more</span
            >
            <transition-expand>
              <div v-if="dc">
                <br />
                You know the importance of making sure that your commercial
                kitchen meets health code standards at all times and our
                cleaning professionals understand the specific requirements to
                avoid any issues with your local health department. BLEACH®
                offers comprehensive kitchen cleaning services to help keep your
                kitchen and appliances fresh, ready to use, and
                <br /><br />
                We take care of every surface in your kitchen — countertops,
                cabinets, walls, appliances— with our dishwasher-safe cleaning
                products and professional techniques. Our professional team of
                cleaning technicians is highly experienced in addressing
                stubborn grime and tough stains in every corner of your kitchen.
                They are equipped with cleaning solutions and specialized tools
                and machines that are sure to keep your kitchen squeaky clean.
                Periodically deep cleaning your kitchen not only helps save
                energy consumption but also improves the performance of
                electronic appliances, like ovens, air conditioners, and
                refrigerators, and decreases the risk of respiratory and skin
                diseases.
                <div v-if="width < 768">
                  <span style="color: #2b9fad" v-if="dc" @click="dc = false"
                    >Read less</span
                  >
                </div>
              </div>
            </transition-expand>
          </div>
        </div>
        <div></div>
      </div>
      <div id="id_sec">
        <router-view />
      </div>
    </div>
    <div v-if="selectlan == 'ARABIC'">
      <div class="bcontainer">
        <div class="pmy-15">
          <div class="page-head arabic-font" dir="rtl">
            تنظيف <span class="w400">المطبخ</span>
          </div>
          <div class="sub-content mt-2 arabic-font" dir="rtl">
            لتلبية احتياجات صيانة مطبخك، يمكنك الاعتماد بكل ثقة على خبرائنا. يجب
            أن يكون المطبخ من أكثر الأماكن نظافة في منزلك أو مكتبك، لأنه وسط
            مناسب لانتشار مسببات الأمراض والبكتيريا المؤذية. وبالتالي فإن تنظيف
            هذه المكان أمر بالغ الأهمية، لذلك وفرنا لك الحل المناسب لتلبية
            احتياجات صيانة المطبخ بغض النظر عن حجمه أو مساحته.
            <span v-if="!dc" style="color: #2b9fad" @click="dc = true"
              >المزيد
            </span>
            <transition-expand>
              <div v-if="dc">
                <br /><br />

                وتدرك جيداً أهمية التأكد من أن مطبخك في مكان العمل يلبي معايير
                قانون الصحة في جميع الأوقات ويدرك أخصائيو التنظيف هذه المعايير
                أيضاً ويطبقونها لتجنب أي مشاكل مع قسم الصحة المحلي. توفر بليتش®
                خدمات تنظيف شاملة للمطبخ للمساعدة في الحفاظ على المطبخ والأجهزة
                الموجودة فيه نظيفة وجاهزة للاستخدام وفقًا للمعايير الدولية.
                <br /><br />
                إننا نهتم بنظافة كل سطح في مطبخك — أسطح الطاولات والخزائن
                والجدران والأجهزة - مع استخدام منتجات التنظيف الآمنة لغسالة
                الأطباق والأجهزة الاحترافية. يتمتع فريقنا المحترف من فنيي
                التنظيف بخبرة عالية في معالجة الأوساخ العنيدة والبقع الصعبة في
                كل ركن من أركان مطبخك. وهم مجهزون بحلول التنظيف والأدوات والآلات
                المتخصصة التي من المؤكد أنها تحافظ على مطبخك نظيفًا. لا يساعد
                التنظيف العميق لمطبخك بشكل دوري على توفير استهلاك الطاقة فحسب،
                بل يحسن أيضًا أداء الأجهزة الإلكترونية، مثل الأفران ومكيفات
                الهواء والثلاجات، ويقلل من خطر الإصابة بأمراض الجهاز التنفسي
                والجلد.
                <div v-if="width < 768">
                  <span style="color: #2b9fad" v-if="dc" @click="dc = false"
                    >المختصر</span
                  >
                </div>
              </div>
            </transition-expand>
          </div>
        </div>
      </div>
      <div id="id_sec">
        <router-view dir="rtl" />
      </div>
    </div>
  </div>
</template>

<script>
//import pageheading from '../../../components/pageheading.vue'
export default {
  mounted() {
    this.width = screen.width;
    if (this.width < 768) {
      this.dc = false;
    } else {
      if (localStorage.getItem("bleach-dc")) {
        localStorage.removeItem("bleach-dc");
      } else {
        this.$nextTick(() =>
          window.document.getElementById("id_sec").scrollIntoView()
        );
      }
    }
  },
  components: {},
  data() {
    return {
      dc: true,
      width: null,
      selectlan: localStorage.getItem("bleach-lang"),
    };
  },
  methods: {
    isCar() {
      if (this.width != null) {
        if (this.width < 768) {
          return true;
        }
        return false;
      }
      return false;
    },
  },
};
</script>
<style>
</style>