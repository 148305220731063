<template>
  <div>
    <div class="gray-bg-2">
      <div class="bcontainer">
        <div class="py-10">
          <stepper :step="1" />
          <div class="sub-heading mt-5" style="text-transform: none">
            Select Time Slot
          </div>
          <div class="service-content">
           We visit only at your preferred time and date.
          </div>
          <div class="row mt-8">
            <div class="col-lg-4 col-md-6">
              <v-date-picker
                @input="dateChange()"
                full-width
                header-color="transparent"
                color="primary"
                v-model="picker"
                :min="today"
              ></v-date-picker>
            </div>
            <div class="col-lg-6 col-md-6 mmt-20">
              <div class="mpx-6 h-100">
                <div class="row " v-if="loaded">
                  <div
                    class="col-lg-6 col-md-12 col-6"
                    v-for="(item, index) in time_slots"
                    :key="index"
                  >
                    <div class="mpx-5">
                      <div
                        v-if="isAvailableSlot(index)"
                        class="slot-box h-100"
                        @click="addSlot(item.start_time, item.end_time, index)"
                        :class="selected_slots == index ? 'active' : ''"
                      >
                        {{ item.start_time }} - {{ item.end_time }}
                      </div>
                      <div
                        v-if="!isAvailableSlot(index)"
                        class="slot-box inactive-slot h-100"
                      >
                        {{ item.start_time }} - {{ item.end_time }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center  h-100" style="min-height:396px;position: relative;" v-else>
                  <div style="position: absolute;top:45%;left:45%">
                    <v-progress-circular
                  class="ma"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-2">
              <div class="pb-6 h-100 d-flex flex-column">
                <button
                @click="goto('/')"
                  type="button"
                  class="align-self-end bl-secondary-btn w-100"
                  style="margin-top: auto"
                  
                >
                  Back
                </button>
     
                  <v-tooltip bottom color="primary" v-if=" selected_slots ==null">
      <template v-slot:activator="{ on, attrs }">
         <button
          v-bind="attrs"
          v-on="on"
                  type="button"
                  class="align-self-end bl-primary-btn w-100"
                  style="margin-top: 20px"
                  
                >
                  Next
                </button>
      </template>
      <span>Please select  time slot</span>
    </v-tooltip>
               <button
               v-else
                  type="button"
                  class="align-self-end bl-primary-btn w-100"
                  style="margin-top: 20px"
                  @click="checkLoged"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog  persistent v-model="dialog" width="500">
      <div class="pa-6" style="background: #fff">
        <div class="d-flex">
          <div style="flex-grow: 1">
            <svg
              width="140"
              height="35"
              viewBox="0 0 140 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M124.8 7.08008H131V33.9965H124.8V23.5815H117.027V33.9965H110.827V7.08008H117.027V17.3816H124.8V7.08008Z"
                fill="#289BAC"
              />
              <path
                d="M20.0361 26.1145C20.0361 30.8448 17.1535 34.181 10.5709 34.181H0V7.04248H10.1787C15.5516 7.04248 18.4341 10.029 18.4341 14.5229C18.4341 16.0729 17.8671 18.3222 16.1234 19.7162C19.0012 20.959 20.0361 23.6337 20.0361 26.1145ZM6.79525 13.6393V17.3629H10.2118C11.7428 17.3629 12.4517 16.5502 12.4517 15.5011C12.4517 14.452 11.7381 13.6393 10.2118 13.6393H6.79525ZM13.9118 25.8783C13.9118 24.5977 12.9526 23.5911 11.2089 23.5911H6.79525V28.1654H11.2089C12.9526 28.1654 13.9118 27.1164 13.9118 25.8783Z"
                fill="#289BAC"
              />
              <path
                d="M38.8246 33.9972H22.7958V7.04297H28.9956V27.7973H38.8246V33.9972Z"
                fill="#289BAC"
              />
              <path
                d="M58.5866 13.2752H47.5148V17.3438H56.4979V23.5437H47.5148V27.7966H58.5866V33.9965H41.3149V7.11316L58.5866 7.08008V13.2752Z"
                fill="#289BAC"
              />
              <path
                d="M98.4933 33.9589C96.7591 33.9589 95.0626 33.6376 93.4134 32.9855C91.7642 32.3381 90.2757 31.3552 88.9573 30.032C87.6862 28.7372 86.7127 27.2771 86.0417 25.6515C85.3707 24.0259 85.0352 22.3106 85.0352 20.5007C85.0352 18.6672 85.3707 16.9377 86.0417 15.3121C86.7127 13.6866 87.6862 12.2406 88.9573 10.9647C90.2757 9.64627 91.769 8.65392 93.4324 7.99235C95.0957 7.33078 96.7874 7 98.5122 7C100.237 7 101.924 7.33078 103.573 7.99235C105.222 8.65392 106.706 9.64155 108.029 10.9647L103.663 15.3263C102.954 14.6175 102.146 14.0882 101.244 13.7338C100.341 13.3794 99.4243 13.1998 98.4933 13.1998C97.5624 13.1998 96.6551 13.3794 95.762 13.7338C94.8689 14.0882 94.0703 14.6175 93.3615 15.3263C92.6763 16.0351 92.1517 16.829 91.7832 17.708C91.4146 18.5869 91.235 19.5178 91.235 20.496C91.235 21.4742 91.4193 22.3956 91.7832 23.2651C92.1517 24.1346 92.6763 24.9238 93.3615 25.6326C94.0703 26.3414 94.8689 26.8754 95.762 27.2298C96.6551 27.5842 97.5671 27.7591 98.4933 27.7591C99.4195 27.7591 100.341 27.5842 101.244 27.2298C102.146 26.8754 102.954 26.3461 103.663 25.6326L108.029 30.032C106.711 31.3504 105.232 32.3381 103.592 32.9855C101.952 33.6328 100.256 33.9589 98.4933 33.9589Z"
                fill="#289BAC"
              />
              <path
                d="M76.7514 8.31849L76.3497 7.07568H70.4806L70.0553 8.31849L61.3085 33.9921H67.8722L68.9732 30.7268H77.446L78.5092 33.9921H85.0351L76.7514 8.31849ZM76.5056 22.4004C76.4489 22.6178 76.3686 22.8257 76.2741 23.0195C75.8015 23.9835 74.8895 24.697 73.8168 24.8908C71.6384 25.283 69.734 23.6102 69.734 21.5026C69.734 20.8552 69.923 20.2173 70.2963 19.6077C70.6366 19.0548 71.1517 18.4594 71.6951 17.8262C72.1818 17.2638 72.6922 16.6684 73.1789 16.0069C73.6656 16.6637 74.176 17.2544 74.658 17.8167C75.2156 18.4641 75.7259 19.0595 76.0614 19.603C76.6096 20.4961 76.7608 21.4459 76.5056 22.4004Z"
                fill="#289BAC"
              />
              <g clip-path="url(#clip0_1399_213)">
                <path
                  d="M134.83 3.5601H135.62C135.93 3.5601 136.16 3.4901 136.31 3.3401C136.47 3.1901 136.54 2.9901 136.54 2.7401C136.54 2.4901 136.47 2.2801 136.32 2.1401C136.17 2.0001 135.94 1.9301 135.61 1.9301H134.82V3.5601H134.83ZM136.52 5.5201L135.55 3.8801H134.83V5.5201H134.44V1.6001H135.62C136.06 1.6001 136.39 1.7001 136.62 1.9101C136.85 2.1201 136.96 2.3901 136.96 2.7301C136.96 3.0101 136.88 3.2601 136.72 3.4601C136.56 3.6601 136.32 3.7901 136 3.8501L137.01 5.5201H136.54H136.52Z"
                  fill="#1D1D1B"
                />
                <path
                  d="M135.56 7.12C133.6 7.12 132 5.52 132 3.56C132 1.6 133.6 0 135.56 0C137.52 0 139.12 1.6 139.12 3.56C139.12 5.52 137.52 7.12 135.56 7.12ZM135.56 0.26C133.74 0.26 132.26 1.74 132.26 3.56C132.26 5.38 133.74 6.86 135.55 6.86C137.36 6.86 138.85 5.38 138.85 3.56C138.85 1.74 137.38 0.26 135.56 0.26Z"
                  fill="#1D1D1B"
                />
              </g>
              <defs>
                <clipPath id="clip0_1399_213">
                  <rect
                    width="7.12"
                    height="7.12"
                    fill="white"
                    transform="translate(132)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="ma">
            <v-icon color="primary" @click="dialog = false, num=true,reg = false,log= false">
              mdi-close-circle-outline
            </v-icon>
          </div>
        </div>
        <div class="h-100">
          <div class="py-10">
            <div class="login-text">Login/Register</div>
            <div v-if="num">
              <div class="d-flex mt-10 login-container">
                <div class="service-content pa-2 lg-phone">+965</div>
                <div style="flex-grow: 1">
                  <input
                    type="number"
                    class="w-100 h-100 login-input pl-2"
                    placeholder="Mobile Number"
                    v-validate="'required|length:8'"
                name="mphoe"
                data-vv-as="Mobile Number"
                    v-model="phone"
                  />
                </div>
                
              </div>
                  <span class="red-color">{{ errors.first("mphoe") }}</span>
                  <v-alert
                  v-if="otpsentfail"
             class="mt-5"
      dense
      text
      type="error"
    >
     <span class="red-color"> Otp sent failed</span>
    </v-alert>

              <div class="text-center mt-5">
                <button
                  v-if="!loader"
                  class="btn-otp"
                  :class="phone != '' ? 'active' : ''"
                  @click="otpSubmit()"
                >
                  <span> Get OTP</span>
                </button>
                <v-progress-circular
                  v-else
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
            </div>
            <div class="" v-if="log">
              <div class="service-content pt-10 pb-8">
                {{ phone }}
              </div>
               <v-alert
             v-if="errorFlag"
             class="mt-5"
      dense
      text
      type="error"
    >
     <span class="red-color"> {{errortext}}</span>
    </v-alert>
              <v-otp-input class="otp-min-height" length="6" v-model="logOtp" plain type="number"></v-otp-input>
              <div class="d-flex">
                <div style="flex-grow: 1" class="otp-time">
                 <countdown :key="visible" ref="countdown" :time="2 *60 * 1000" @end="timeEnd()">
                  <template slot-scope="props">
                     {{props.minutes}}:{{props.seconds}}
                  </template>
               
                </countdown>
                </div>
                <div class="otp-time pointer" v-if="resend" @click="resentOtp()">Resend OTP</div>
              </div>
              <div class="text-center mt-5">
                <button
                  class="btn-otp active"
                  @click="reqLog()"
                  v-if="logOtp.length == 6"
                >
                  Login
                </button>
                <button
                  class="btn-otp"
                  v-else
                >
                  Login
                </button>
              </div>
            </div>
            <div class="" v-if="reg">
              <div class="service-content pt-10 pb-8">
                {{ phone }}
              </div>
                <v-alert
             v-if="errorFlag"
             class="mt-5"
      dense
      text
      type="error"
    >
     <span class="red-color"> {{errortext2}}</span>
    </v-alert>
              <v-otp-input
              class="otp-min-height"
              @input="chechRegFormFilled"
                length="6"
                v-model="regCustomer.customer_otp"
                plain
                type="number"
                 v-validate="'required'"
                name="otp"
                data-vv-as="otp"
              ></v-otp-input>
              <span class="red-color">{{ errors.first("otp") }}</span>

              <div class="d-flex">
                <div style="flex-grow: 1" class="otp-time">
                 <countdown :key="visible" ref="countdown" :time="2 *60 * 1000" @end="timeEnd()">
                  <template slot-scope="props">
                     {{props.minutes}}:{{props.seconds}}
                  </template>
               
                </countdown>
                </div>
                <div class="otp-time pointer" v-if="resend" @click="resentOtp()">Resend OTP</div>
              </div>
              <v-text-field
              @input="chechRegFormFilled"

                class="mt-15"
                v-model="regCustomer.name"
                v-validate="'required'"
                name="name"
                data-vv-as="Name"
              >
                <template v-slot:label>
                  <span class="login-label">Name</span>
                </template>
              </v-text-field>
              <span class="red-color">{{ errors.first("name") }}</span>
              <!-- <v-text-field > 
                 <template  v-slot:label>
                            <span class="login-label">Last Name</span>
                          </template>
              </v-text-field> -->
              <v-text-field
              @input="chechRegFormFilled"

                type="email"
                v-model="regCustomer.email"
                v-validate="'required|email'"
                name="email"
                data-vv-as="email"
              >
                <template v-slot:label>
                  <span class="login-label">Email</span>
                </template>
              </v-text-field>
              <span class="red-color">{{ errors.first("email") }}</span>

              <v-select
              @input="chechRegFormFilled"
              
                v-validate="'required'"
                name="gender"
                data-vv-as="gender"
                v-model="regCustomer.gender"
                :items="genders"
                append-icon="mdi-chevron-down"
              >
                <template v-slot:label>
                  <span class="login-label">Gender</span>
                </template>
              </v-select>
              <span class="red-color">{{ errors.first("gender") }}</span>
              <v-select
              @input="chechRegFormFilled"

              v-validate="'required'" name="type" data-vv-as="type"
                v-model="regCustomer.customer_type"
                :items="items"
                append-icon="mdi-chevron-down"
              >
                <template v-slot:label>
                  <span class="login-label">Type</span>
                </template>
              </v-select>
              <span class="red-color">{{ errors.first("type") }}</span>
               <v-text-field
               v-if="regCustomer.customer_type =='CORPORATE'"
                 @input="chechRegFormFilled"
                v-model="regCustomer.company"
                v-validate="'required'"
                name="cname"
                data-vv-as="Company name"
              >
                <template v-slot:label>
                  <span class="login-label">Company name</span>
                </template>
              </v-text-field>
              <span class="red-color">{{ errors.first("cname") }}</span>
               <v-text-field
               v-if="regCustomer.customer_type =='CORPORATE'"
                 @input="chechRegFormFilled"
                v-model="regCustomer.job_title"
                v-validate="'required'"
                name="jobtitile"
                data-vv-as="Job title"
              >
                <template v-slot:label>
                  <span class="login-label">Job title</span>
                </template>
              </v-text-field>
              <span class="red-color">{{ errors.first("jobtitile") }}</span>
              <div class="text-center mt-5">
                <button class="btn-otp" :class="regFlag?'active':''" v-if="!loader" @click="regSubmit">Register</button>
                 <v-progress-circular
                  v-else
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import service from "../../services/service";
import stepper from "../../components/stepper.vue";
import moment from "moment";

export default {
  components: {
    stepper,
  },
   computed: {
      ...mapState({
      evaluation: state => state.evaluation,
     
    })
  },
  data() {
    return {
      otpsentfail:false,
      regFlag:false,
      step:1,
      visible:1,
      resend:false,
      errortext:'Something went wrong',
      errortext2:'Something went wrong',
      errorFlag:false,
      regCustomer: {
        customer_otp: "",
        name: "",
        gender: "",
        customer_type: "",
        sms_preference: "ENGLISH",
        is_sms: true,
        nationality: "KW",
        email: "",
        company:'',
        job_title:'',
        customer_mobile:''
      },
      genders: ["MALE", "FEMALE", "OTHER"],
      items: ["INDIVIDUAL", "RETAIL", "CORPORATE"],
      loader: false,
      loaded: false,
      available_slots: [],
      regOtp: "",
      phone: "",
      logOtp:'',
      num: true,
      reg: false,
      log: false,
      dialog: false,
      selected_slots: null,
      render: false,
      picker: new Date().toISOString().substr(0, 10),
      today: new Date().toISOString().substr(0, 10),
      time_slots: [
        {
          start_time: "08:00 AM",
          end_time: "09:00 AM",
        },
        {
          start_time: "09:00 AM",
          end_time: "10:00 AM",
        },
        {
          start_time: "10:00 AM",
          end_time: "11:00 AM",
        },
        {
          start_time: "11:00 AM",
          end_time: "12:00 PM",
        },
        {
          start_time: "12:00 PM",
          end_time: "01:00 PM",
        },
        {
          start_time: "01:00 PM",
          end_time: "02:00 PM",
        },
        {
          start_time: "02:00 PM",
          end_time: "03:00 PM",
        },
        {
          start_time: "03:00 PM",
          end_time: "04:00 PM",
        },
        {
          start_time: "04:00 PM",
          end_time: "05:00 PM",
        },
        {
          start_time: "05:00 PM",
          end_time: "06:00 PM",
        },
        {
          start_time: "06:00 PM",
          end_time: "07:00 PM",
        },
        {
          start_time: "07:00 PM",
          end_time: "08:00 PM",
        },
      ],
    };
  },
  mounted() {
    if(this.evaluation.booking_date != null){
      var dt = new Date(this.today);
      dt.setDate(dt.getDate() + 1);
      this.today = dt.toISOString().substr(0, 10);
      var sp =this.evaluation.booking_date.split('-');
      var dd = new Date(sp[1]+'-'+sp[0]+"-"+sp[2])
      this.picker = moment(dd).format("YYYY-MM-DD");

      this.selected_slots = this.evaluation.slot_index;
      this.getSolts(this.picker);

    }else{
       var d = new Date(this.today);
      d.setDate(d.getDate() + 1);
      this.today = d.toISOString().substr(0, 10);
      this.picker = this.today;
      this.getSolts(this.picker);
    }
   
  },
  methods: {
    chechRegFormFilled(){
      if(this.regCustomer.customer_otp != '' && this.regCustomer.name != '' && this.regCustomer.gender != '' && this.regCustomer.customer_type != '' && this.regCustomer.email != ''){
        this.regFlag = true
        if(this.regCustomer.customer_type =='CORPORATE'){
          if(this.regCustomer.job_title != '' && this.regCustomer.company != ''){
            this.regFlag = true
          }else{
            this.regFlag = false
          }
        }
      }else{
        this.regFlag = false

      }
    },
    checkLoged(){
      if(localStorage.getItem('bleach-tk')){
        var d = moment(this.picker).format("DD-MM-YYYY");
        var ob = {
          d:d,
          t:this.time_slots[this.selected_slots].start_time,
          i:this.selected_slots
        }
        
         this.$store.commit("set_eval_date_time", ob);
        this.$store.commit("set_evalutionStep", 2);
        this.$router.push('address')
      }else{
         this.dialog = true
      }
    },
    timerSart(){
      this.resend = false
    },
    timeEnd(){
      this.resend = true

    },
   async  resentOtp(){

     if (this.phone != "" && !this.loader) {
       
        this.resend = false
        this.loader = true;
        let result = await service._post(
          "api/evaluation-booking-customer-otp-generation/",
          { mobile_number: this.phone }
        );
        this.loader = false;
        if (result.status == 200) {
          this.visible = this.visible + 1;
          if (result.data.sms_status != "false") {
            if (result.data.is_new_customer) {
              this.num = false;
              this.reg = true;
            } else {
              this.num = false;
              this.log = true;
            }
          } else {
            this.otpsentfail = true;
          }
        } else {
          this.otpsentfail = true;
        }
        
      }
   },
    async reqLog(){
        this.loader = true
      this.errorFlag = false
      let result = await service._post('api/evaluation-booking-customer-otp-verification/',{
        customer_otp:this.logOtp,
        customer_mobile:this.phone
      });
      this.loader = false
      if(result.status ==200){
        if(result.data.otp_verified){
          
           localStorage.setItem('bleach-tk',result.data.token)
           this.$store.commit("set_login_token", result.data.token);
           this.$store.dispatch('getUserCart')
        var d = moment(this.picker).format("DD-MM-YYYY");
           var ob = {
          d:d,
          t:this.time_slots[this.selected_slots].start_time,
          i:this.selected_slots
        }
        
         this.$store.commit("set_eval_date_time", ob);
        this.$store.commit("set_evalutionStep", 2);
        this.$router.push('address')
        }else{
          this.errortext = result.data.otp_message
          this.errorFlag = true
        }
       
      }else{
          this.errortext = "Something went wrong"

      this.errorFlag = true
      }
    },
    async regReq(){
      this.loader = true
      this.errorFlag = false
      this.regCustomer.customer_mobile = this.phone
      var fd = new FormData()
      fd.append('customer_otp',this.regCustomer.customer_otp)
      fd.append('customer_mobile',this.regCustomer.customer_mobile)
      fd.append('name',this.regCustomer.name)
      fd.append('gender',this.regCustomer.gender)
      fd.append('customer_type',this.regCustomer.customer_type)
      fd.append('sms_preference',this.regCustomer.sms_preference)
      fd.append('is_sms',this.regCustomer.is_sms)
      fd.append('nationality',this.regCustomer.nationality)
      fd.append('email',this.regCustomer.email)
      fd.append('company',this.regCustomer.company)
      fd.append('job_title',this.regCustomer.job_title)

      let result = await service._post('api/evaluation-booking-customer-otp-verification/',fd);
      this.loader = false
      if(result.status ==200){
        if(result.data.otp_verified){
        var d = moment(this.picker).format("DD-MM-YYYY");
          var ob = {
          d:d,
          t:this.time_slots[this.selected_slots].start_time,
          i:this.selected_slots
        }
      
         this.$store.commit("set_eval_date_time", ob);
        this.$store.commit("set_evalutionStep", 2);
          localStorage.setItem('bleach-tk',result.data.token)
          this.$store.commit("set_login_token", result.data.token);
          this.$store.dispatch('getUserCart')
           this.$router.push('address')
        }else{
          this.errortext2 = result.data.otp_message
          this.errorFlag = true
        }
        // console.log(result)
        //
      }else{
          this.errortext2 = "Something went wrong"

      this.errorFlag = true
      }
      
    },
    async getOtp() {
      
      if (this.phone != "" && !this.loader) {
        this.otpsentfail = false
        this.loader = true;
        let result = await service._post(
          "api/evaluation-booking-customer-otp-generation/",
          { mobile_number: this.phone }
        );
        this.loader = false;
        if (result.status == 200) {
          if (result.data.sms_status != "false") {
            if (result.data.is_new_customer) {
              this.num = false;
              this.reg = true;
            } else {
              this.num = false;
              this.log = true;
            }
          } else {
            this.otpsentfail = true;
          }
        } else {
          this.otpsentfail = true;
        }
        console.log(result);
      }
    },
     goto(loc) {
      window.location = loc;
    },
    regSubmit() {
      this.$validator.validateAll().then((result) => {
        console.log(result);
        if (!result) {
          return;
        }
        this.regReq()
      });
    },
    otpSubmit() {
      this.$validator.validateAll().then((result) => {
        console.log(result);
        if (!result) {
          return;
        }
        this.getOtp()
      });
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    dateChange() {
      this.selected_slots = null;
      
      this.getSolts(this.picker);
    },
    async getSolts(day) {
      this.loaded = false;
      var d = moment(day).format("DD-MM-YYYY");
      let results = await service._post("api/evaluation-booking-slots/", {
        cleaning_date: d,
      });
      if (results.status == 200) {
        this.loaded = true;
        var available_slots = results.data.available_slots;
        this.available_slots = [];
        for (const [key, value] of Object.entries(available_slots)) {
          this.available_slots.push({ key, value });
        }
      }
    },
    isAvailableSlot(index) {
    
      if (this.available_slots[index].value.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    selectSolt() {
      //console.log(item);
    },
    checkSelected(index) {
      return this.selected_slots.includes(index);
    },
    addSlot(start, end, slot) {
      this.selected_slots = slot;
    
    },
    removeSelected() {
      
      //var index = this.selected_slots.indexOf(item);
      // if (index !== -1) {
      //     var temp = this.selected_slots.length;
      //     for(var i =0; i <temp;i++){
      //       if(this.selected_slots[i]>item){
      //         this.selected_slots.splice(i, 1);
      //       }
      //     }
      //     this.selected_slots.splice(index, 1);

      //   }
      // this.selected_slots.splice(index, 1);
    },
    allowedDates: (val) => parseInt(val.split("-")[2], 10) % 2 === 0,
  },
};
</script>
<style lang="scss">
.v-select__selection{
      color: #6d6d6e !important;
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: 400;
}
.inactive-slot {
  cursor: not-allowed;
  background: #f1f3f5 !important;
}
.v-date-picker-header {
  padding: 4px 26px !important;
}
.v-picker__body {
  padding-top: 10px !important;
  border-top: 1.7px solid #289bac !important;
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: #c4c4c4 !important;
  // border-bottom: 1px solid #c4c4c4 !important;
}
.v-otp-input input {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 23.2034px !important;
  line-height: 35px !important;
  color: #898e93 !important;
}

.v-text-field {
  padding-top: 0px !important;
  margin-top: 4px;
}
.theme--light.v-input input,
.theme--light.v-input textarea {
  color: #6d6d6e !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400;
}
.v-picker--full-width {
  border: 1px solid #e9ecef !important;
  border-radius: 5px !important;
}
.v-date-picker-table {
  padding: 0 25px !important;
}
.v-picker__title {
  padding: 15px 35px 15px 35px !important;
}
.v-picker__title__btn:not(.v-picker__title__btn--active) {
  opacity: 1 !important;
  cursor: pointer;
}
</style>
