<template>
  <div>
    <div>
      <section class="">
        <div class="bg-header-img-about bg-header"></div>
      </section>
    </div>
    <div>
      <div v-if="selectlan == 'EN'">
        <div class="bcontainer">
          <div class="pt-15">
            <div class="page-head">About <span class="w500">us</span></div>
            <div class="sub-heading mt-5">
              Our <span class="w500">story </span>
            </div>
            <div class="sub-content mt-4">
              BLEACH® was established for the express purpose of providing
              professional cleaning services with first-class customer care. We
              at BLEACH® strive to meet our customer’s needs for exceptional
              cleaning services in their residences and offices. These services
              are executed by our own highly trained and qualified cleaning
              teams that only use premium quality products and equipment.
            </div>
          </div>
        </div>
        <div class="">
          <div class="bcontainer">
            <div class="py-15">
              <div class="gray-bg-2 pa-9">
                <div class="sub-heading">
                  Our <span class="w500">Founding </span>
                </div>
                <div class="row mt-5">
                  <div class="col-lg-2 col-md-3 h-100">
                    <img
                      src="../../assets/images/about-img.png"
                      style="border-radius: 7px; width: 100%"
                      alt=""
                    />
                  </div>
                  <div class="col-lg-10 col-md-9 m-mt-20">
                    <div
                      class="text-center h-100"
                      style="position: relative; width: 100%"
                    >
                      <p class="sub-content mt-5" style="text-align: justify">
                        We commenced our cleaning operations in Kuwait in the
                        year 2013 under the leadership of Ahmad Al Hendi and
                        Bader Al Mansour. BLEACH® continued to reach newer
                        heights under the sole leadership of Ahmad Al Hendi in
                        the latter stages and is growing exponentially even
                        today.
                        <br /><br />
                        Our team and headship’s vision and determination to make
                        cleanliness a culture in the society at every level –
                        from domestic kitchens to restaurants, and from houses
                        to corporate offices and governates – have made BLEACH®
                        a synonym for cleanliness in Kuwait
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bcontainer">
          <div class="pt-5 pb-10">
            <div class="row">
              <div class="col-lg-3 col-md-6 mt-5">
                <div class="service-card">
                  <div class="service-card-circle">
                    <img src="../../assets/icons/abouts/1.png" alt="" />
                  </div>
                  <div class="about-items text-center mt-4">
                    9+ Years of <br />
                    Trusted Services
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 mt-5">
                <div class="service-card">
                  <div class="service-card-circle">
                    <img
                      class="mt-2"
                      src="../../assets/icons/abouts/2.png"
                      alt=""
                    />
                  </div>
                  <div class="about-items text-center mt-4">
                    10000+ Satisfied <br />
                    Customers
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 mt-5">
                <div class="service-card">
                  <div class="service-card-circle">
                    <img src="../../assets/icons/abouts/3.png" alt="" />
                  </div>
                  <div class="about-items text-center mt-4">
                    5+ Specialized
                    <br />
                    Cleaning Services
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 mt-5">
                <div class="service-card">
                  <div class="service-card-circle">
                    <img src="../../assets/icons/abouts/4.png" alt="" />
                  </div>
                  <div class="about-items text-center mt-4">
                    2 Globally Recognized <br />
                    Certifications
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="gray-bg-2">
          <div class="bcontainer">
              <div class="py-15">
                  <div class="sub-heading" >
                 Our <span class="w500">Objective </span>
              </div>
              <div class="sub-content mt-5" >
                  BLEACH® has a clear objective to provide high-end expert cleaning services in Kuwait.Investing in people and their training is our company’s policy to deliver the best customer service experience. Additionally, our cleaning products are specially sourced to give customers and cleaners a non-toxic and healthy environment. We at BLEACH® aim to make your house and offices a healthier surrounding that is germs-, dirt-, dust-, and infection-free.
              </div>
              </div>
          </div>
      </div>
       -->
        <!-- <div class="bcontainer">
          <div class="py-15">
              <div class="sub-heading">
                  our <span class="w500">Certifications</span>
              </div>
              <div class="sub-content mt-4" >
                  BLEACH® is a globally recognized cleaning company with acknowledgments from leading certification organizations such as GBAC, ISSA®, and CIMS® certifications
              </div>
              <div class="bleach-link mt-5" >
                   Learn more about our certicicaitons <img src="../../assets/icons/blue-arrow.png" alt="">
              </div>
          </div>
      </div> -->
        <div class="bcontainer">
          <div class="pb-15 pt-5">
            <div class="row">
              <div class="col-md-6 mt-5">
                <div class="gray-bg-2 pa-9 h-100">
                  <div class="sub-heading">
                    Our <span class="w500">MISSION </span>
                  </div>
                  <div class="service-content mt-4">
                    Creating a new evolution for the maintenance and hospitality
                    industry based on smart solutions, minimum resources and
                    less time duration with the best quality.
                  </div>
                </div>
              </div>
              <div class="col-md-6 mt-5">
                <div class="gray-bg-2 pa-9 h-100">
                  <div class="sub-heading">
                    Our <span class="w500">VISION </span>
                  </div>
                  <div class="service-content mt-4">
                    To be a 100% green and eco-friendly professional cleaning
                    service provider.
                  </div>
                </div>
              </div>
              <div class="col-md-6 mt-5">
                <div class="gray-bg-2 pa-9 h-100">
                  <div class="sub-heading">
                    Our <span class="w500">OBJECTIVE </span>
                  </div>
                  <div class="service-content mt-4">
                    BLEACH® has a clear objective to provide high-end expert
                    cleaning services in Kuwait.Investing in people and their
                    training is our company’s policy to deliver the best
                    customer service experience. Additionally, our cleaning
                    products are specially sourced to give customers and
                    cleaners a non-toxic and healthy environment. We at BLEACH®
                    aim to make your house and offices a healthier surrounding
                    that is germs-, dirt-, dust-, and infection-free.
                  </div>
                </div>
              </div>
              <div class="col-md-6 mt-5">
                <div class="gray-bg-2 pa-9 h-100">
                  <div class="sub-heading">
                    Our <span class="w500">CERTIFICATIONS </span>
                  </div>
                  <div class="service-content mt-4">
                    BLEACH® is a globally recognized cleaning company with
                    acknowledgments from leading certification organizations
                    such as GBAC, ISSA®, and CIMS® certifications
                  </div>
                  <div class="bleach-link mt-5" @click="goto('certifications')">
                    Learn more about our certicicaitons
                    <img src="../../assets/icons/blue-arrow.png" alt="" />
                  </div>
                </div>
              </div>
              <div
                @click="download_dialog = true"
                class="bleach-link col-md-6 mt-5"
              >
                Download Bleach's Marketing Material<v-icon color="#2b9fad">
                  mdi-chevron-right</v-icon
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="selectlan == 'ARABIC'" dir="rtl">
        <div class="bcontainer">
          <div class="pt-15">
            <div class="page-head">نبذة عنا</div>
            <div class="sub-heading mt-5">حكايتنا</div>
            <div class="sub-content mt-4">
              تأسست شركة بليتش® لغرضٍ صريحٍ يتمثل في تقديم خدمات تنظيف احترافية
              مع رعاية من الدرجة الأولى للعملاء. ونحن في بليتش® نسعى جاهدين
              لتلبية احتياجات عملائنا لخدمات التنظيف الاستثنائية في مساكنهم
              ومكاتبهم. وتُنفّذ هذه الخدمات من قبل فرق التنظيف المدربة تدريباً
              عالياً والمؤهلة على أعلى مستوى والحريصة على استخدام المنتجات
              والمعدات عالية الجودة حصراً.
            </div>
          </div>
        </div>
        <div class="">
          <div class="bcontainer">
            <div class="py-15">
              <div class="gray-bg-2 pa-9">
                <div class="sub-heading">مؤسستنا</div>
                <div class="row mt-5">
                  <div class="col-lg-2 col-md-3 h-100">
                    <img
                      src="../../assets/images/about-img.png"
                      style="border-radius: 7px; width: 100%"
                      alt=""
                    />
                  </div>
                  <div class="col-lg-10 col-md-9 m-mt-20">
                    <div
                      class="text-center h-100"
                      style="position: relative; width: 100%"
                    >
                      <p class="sub-content mt-5" style="text-align: justify">
                        بدأنا عمليات تقديم خدمات التنظيف في الكويت عام 2013 تحت
                        قيادة أحمد الهندي وبدر المنصور. وواصلت شركة بليتش® مسيرة
                        التطور والتوسع تحت قيادة أحمد الهندي في المراحل اللاحقة،
                        كما أنها تثابر على النمو الأسي حتى اليوم.
                        <br /><br />
                        وقد نجحت رؤية فريقنا وقيادتنا وتصميمنا على جعل النظافة
                        ثقافة في المجتمع على جميع المستويات، بدءاً من المطابخ
                        المحلية إلى المطاعم، ومن المنازل إلى مكاتب الشركات
                        والمحافظات، وجعلت من شركة بليتش® مرادفًا للنظافة في
                        الكويت.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bcontainer">
          <div class="pt-5 pb-10">
            <div class="row">
              <div class="col-lg-3 col-md-6 mt-5">
                <div class="service-card">
                  <div class="service-card-circle">
                    <img src="../../assets/icons/abouts/1.png" alt="" />
                  </div>
                  <div class="about-items text-center mt-4">
                    أكثر من 9 سنوات من الخدمات الموثوقة
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 mt-5">
                <div class="service-card">
                  <div class="service-card-circle">
                    <img
                      class="mt-2"
                      src="../../assets/icons/abouts/2.png"
                      alt=""
                    />
                  </div>
                  <div class="about-items text-center mt-4">
                    أكثر من 10000 عميل راضٍ
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 mt-5">
                <div class="service-card">
                  <div class="service-card-circle">
                    <img src="../../assets/icons/abouts/3.png" alt="" />
                  </div>
                  <div class="about-items text-center mt-4">
                    أكثر من 25 خدمة تنظيف متخصصة
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 mt-5">
                <div class="service-card">
                  <div class="service-card-circle">
                    <img src="../../assets/icons/abouts/4.png" alt="" />
                  </div>
                  <div class="about-items text-center mt-4">
                    شهادتان معترف بهما عالميًا
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="gray-bg-2">
          <div class="bcontainer">
              <div class="py-15">
                  <div class="sub-heading" >
                 Our <span class="w500">Objective </span>
              </div>
              <div class="sub-content mt-5" >
                  BLEACH® has a clear objective to provide high-end expert cleaning services in Kuwait.Investing in people and their training is our company’s policy to deliver the best customer service experience. Additionally, our cleaning products are specially sourced to give customers and cleaners a non-toxic and healthy environment. We at BLEACH® aim to make your house and offices a healthier surrounding that is germs-, dirt-, dust-, and infection-free.
              </div>
              </div>
          </div>
      </div>
       -->
        <!-- <div class="bcontainer">
          <div class="py-15">
              <div class="sub-heading">
                  our <span class="w500">Certifications</span>
              </div>
              <div class="sub-content mt-4" >
                  BLEACH® is a globally recognized cleaning company with acknowledgments from leading certification organizations such as GBAC, ISSA®, and CIMS® certifications
              </div>
              <div class="bleach-link mt-5" >
                   Learn more about our certicicaitons <img src="../../assets/icons/blue-arrow.png" alt="">
              </div>
          </div>
      </div> -->
        <div class="bcontainer">
          <div class="pb-15 pt-5">
            <div class="row">
              <div class="col-md-6 mt-5">
                <div class="gray-bg-2 pa-9 h-100">
                  <div class="sub-heading">المهمة</div>
                  <div class="service-content mt-4">
                    لخلق ثورة في الخدمات المقدّمة بقطاع التنظيف و الصيانة تتمحور
                    أساليب تقديمها بالحلول التكنولوجية المبتكرة، التقنين
                    بالموارد، التقليل بمدة العمل مع جودة فائقة بالخدمة
                  </div>
                </div>
              </div>
              <div class="col-md-6 mt-5">
                <div class="gray-bg-2 pa-9 h-100">
                  <div class="sub-heading">الرؤية</div>
                  <div class="service-content mt-4">
                    أن تكون كل خدمات التنظيف المقدمة تعتمد اعتماد شامل على
                    المواد والمعدّات الصديقة للبيئة
                  </div>
                </div>
              </div>
              <div class="col-md-6 mt-5">
                <div class="gray-bg-2 pa-9 h-100">
                  <div class="sub-heading">هدفنا</div>
                  <div class="service-content mt-4">
                    لدى بليتش® هدف واضح يتمثل بتقديم خدمات تنظيف متخصصة راقية في
                    الكويت.
                    <br /><br />
                    ويعدّ الاستثمار في الأشخاص وتدريبهم على أفضل تقنيات العمل
                    الأساسية المتبعة من قبل شركتنا بهدف تقديم أفضل تجربة لخدمة
                    العملاء. كما نحرص على استخدام منتجات التنظيف الخاصة بنا
                    حرصاً منا على منح العملاء والمنظفين بيئة سليمة وصحية. و نهدف
                    في بليتش® إلى جعل منزلك ومكتبك مكاناً آمناً أكثر صحة ولا
                    مجال فيه لتراكم الجراثيم والأوساخ والغبار وأسباب المرض.
                  </div>
                </div>
              </div>
              <div class="col-md-6 mt-5">
                <div class="gray-bg-2 pa-9 h-100">
                  <div class="sub-heading">الشهادات</div>
                  <div class="service-content mt-4">
                    بليتش® شركة تنظيف معترف بها عالميًا مع شهادات من منظمات
                    الاعتماد الرائدة مثل المجلس الاستشاري العالمي للمخاطر
                    البيولوجية GBAC ورابطة التجارة الدولية لصناعة التنظيف ISSA ®
                    وشهادة معايير إدارة صناعة التنظيف CIMS®.
                  </div>
                  <div class="bleach-link mt-5" @click="goto('certifications')">
                    تعرف على المزيد حول شهاداتنا
                    <img src="../../assets/icons/blue-arrow.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <v-dialog
        scrollable
        transition="dialog-bottom-transition"
        max-width="1122"
        v-model="download_dialog"
        :fullscreen="$vuetify.breakpoint.name == 'xs' ? true : false"
      >
        <div>
          <div class="ad-model">
            <div>
              <div class="modal-side">
                <svg
                  width="110"
                  height="28"
                  viewBox="0 0 110 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M98.6775 5.75269H103.58V27.035H98.6775V18.8001H92.5311V27.035H87.629V5.75269H92.5311V13.898H98.6775V5.75269Z"
                    fill="white"
                  />
                  <path
                    d="M15.8422 20.8029C15.8422 24.543 13.563 27.1809 8.35826 27.1809H0V5.7229H8.04814C12.2964 5.7229 14.5756 8.08429 14.5756 11.6376C14.5756 12.8631 14.1272 14.6416 12.7485 15.7438C15.0239 16.7265 15.8422 18.8413 15.8422 20.8029ZM5.3729 10.9389V13.8831H8.07429C9.28488 13.8831 9.84533 13.2405 9.84533 12.411C9.84533 11.5815 9.28114 10.9389 8.07429 10.9389H5.3729V10.9389ZM10.9999 20.6161C10.9999 19.6035 10.2414 18.8077 8.86267 18.8077H5.3729V22.4245H8.86267C10.2414 22.4245 10.9999 21.595 10.9999 20.6161Z"
                    fill="white"
                  />
                  <path
                    d="M30.6981 27.0356H18.0243V5.72339H22.9264V22.1335H30.6981V27.0356Z"
                    fill="white"
                  />
                  <path
                    d="M46.3235 10.6511H37.5692V13.8681H44.6721V18.7702H37.5692V22.1329H46.3235V27.035H32.6671V5.77884L46.3235 5.75269V10.6511Z"
                    fill="white"
                  />
                  <path
                    d="M77.8771 27.0052C76.5059 27.0052 75.1645 26.7511 73.8605 26.2355C72.5566 25.7236 71.3796 24.9465 70.3371 23.9003C69.3321 22.8765 68.5624 21.722 68.0318 20.4367C67.5012 19.1513 67.236 17.795 67.236 16.364C67.236 14.9143 67.5012 13.5468 68.0318 12.2615C68.5624 10.9762 69.3321 9.83284 70.3371 8.82402C71.3796 7.78158 72.5603 6.99694 73.8755 6.47385C75.1907 5.95075 76.5283 5.68921 77.8921 5.68921C79.2559 5.68921 80.5897 5.95075 81.8937 6.47385C83.1977 6.99694 84.371 7.77784 85.4171 8.82402L81.9647 12.2727C81.4043 11.7122 80.7654 11.2938 80.0517 11.0135C79.3381 10.7333 78.6132 10.5913 77.8771 10.5913C77.1411 10.5913 76.4237 10.7333 75.7175 11.0135C75.0113 11.2938 74.3799 11.7122 73.8194 12.2727C73.2777 12.8331 72.8629 13.4609 72.5715 14.1558C72.2801 14.8508 72.1381 15.5869 72.1381 16.3603C72.1381 17.1337 72.2838 17.8623 72.5715 18.5498C72.8629 19.2373 73.2777 19.8613 73.8194 20.4217C74.3799 20.9822 75.0113 21.4044 75.7175 21.6846C76.4237 21.9648 77.1448 22.1031 77.8771 22.1031C78.6095 22.1031 79.3381 21.9648 80.0517 21.6846C80.7654 21.4044 81.4043 20.9859 81.9647 20.4217L85.4171 23.9003C84.3747 24.9427 83.2052 25.7236 81.9087 26.2355C80.6122 26.7474 79.2708 27.0052 77.8771 27.0052Z"
                    fill="white"
                  />
                  <path
                    d="M60.6862 6.73193L60.3686 5.74927H55.728L55.3917 6.73193L48.4757 27.0316H53.6655L54.5361 24.4498H61.2354L62.0761 27.0316H67.236L60.6862 6.73193ZM60.4919 17.8663C60.447 18.0382 60.3835 18.2026 60.3088 18.3558C59.9352 19.118 59.214 19.6822 58.3659 19.8354C56.6434 20.1455 55.1377 18.8228 55.1377 17.1564C55.1377 16.6445 55.2871 16.1401 55.5823 15.6581C55.8513 15.221 56.2586 14.7502 56.6882 14.2495C57.0731 13.8049 57.4766 13.3341 57.8615 12.811C58.2463 13.3304 58.6498 13.7974 59.031 14.242C59.4718 14.7539 59.8754 15.2247 60.1407 15.6544C60.5741 16.3606 60.6936 17.1116 60.4919 17.8663Z"
                    fill="white"
                  />
                  <g clip-path="url(#clip0_1683_1019)">
                    <path
                      d="M106.608 2.96942H107.233C107.478 2.96942 107.66 2.91407 107.778 2.79547C107.905 2.67687 107.96 2.51873 107.96 2.32106C107.96 2.12339 107.905 1.95734 107.786 1.84665C107.667 1.73595 107.486 1.6806 107.225 1.6806H106.6V2.96942H106.608ZM107.944 4.51916L107.177 3.22244H106.608V4.51916H106.3V1.41968H107.233C107.58 1.41968 107.841 1.49875 108.023 1.66479C108.205 1.83083 108.292 2.04432 108.292 2.31315C108.292 2.53454 108.229 2.73221 108.102 2.89035C107.976 3.04849 107.786 3.15128 107.533 3.19872L108.332 4.51916H107.96H107.944Z"
                      fill="white"
                    />
                    <path
                      d="M107.185 5.78421C105.635 5.78421 104.37 4.51912 104.37 2.96938C104.37 1.41964 105.635 0.154541 107.185 0.154541C108.735 0.154541 110 1.41964 110 2.96938C110 4.51912 108.735 5.78421 107.185 5.78421ZM107.185 0.360119C105.746 0.360119 104.576 1.53033 104.576 2.96938C104.576 4.40842 105.746 5.57864 107.177 5.57864C108.608 5.57864 109.786 4.40842 109.786 2.96938C109.786 1.53033 108.624 0.360119 107.185 0.360119Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1683_1019">
                      <rect
                        width="5.62967"
                        height="5.62967"
                        fill="white"
                        transform="translate(104.37 0.154541)"
                      />
                    </clipPath>
                  </defs>
                </svg>

                <!-- <div class="model-side-text mt-4">
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                </div> -->
                <div class="text-center py-20">
                  <img
                    height="130"
                    width="130"
                    src="../../assets/images/about-us-icon.png"
                  />
                </div>
              </div>
            </div>
            <div
              class="full-flex pa-9"
              style="background: #fff; position: relative"
            >
              <div class="cart-close">
                <v-icon class="pointer" @click="download_dialog = false">
                  mdi-close
                </v-icon>
              </div>
              <div
                class="sub-heading mt-0 text-left"
                style="text-transform: none"
              >
                Bleach's Marketing Material
              </div>
              <div class="fs-12-en" style="color: #2b9fad">
                Click to download our marketing material in pdf
              </div>

              <v-card flat color="p-0 mt-5">
                <div class="row">
                  <div class="col-md-6 pt-4">
                    <div class="about-us-card" @click="downloadPdf('cp_english')">
                      <div
                        class="about-us-card-text"
                        style="padding-top: 120px"
                      >
                        <div class="mt-4">
                          Company Profile <br />
                          <span>English</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 pt-4">
                    <div
                      class="about-us-card-2"
                      @click="downloadPdf('cp_arabic')"
                    >
                      <div
                        class="about-us-card-text"
                        style="padding-top: 120px"
                      >
                        <div class="mt-4">
                          Company Profile <br />
                          <span>Arabic</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 pt-4">
                    <div
                      class="about-us-card-3"
                      @click="downloadPdf('brochure_english')"
                    >
                      <div
                        class="about-us-card-text"
                        style="padding-top: 140px"
                      >
                        <div class="mt-4">Brochure English</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 pt-4">
                    <div
                      class="about-us-card-4"
                      @click="downloadPdf('brochure_arabic')"
                    >
                      <div
                        class="about-us-card-text"
                        style="padding-top: 140px"
                      >
                        <div class="mt-4">Brochure Arabic</div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-card>
            </div>
          </div>
          <!-- <div class="mp48 h-100" style="background:#fff;position: relative;" v-if="selectlan =='ARABIC'">
        <div class="cart-close">
          <v-icon class="pointer" @click="dia = false">
            mdi-close
          </v-icon>
        </div>
        <div class="text-center section-heading" >
                Our Services  <span class="w400">Category</span>
            </div>
            <div class="service-content text-center py-7 show-full">

              إن بليتش® شركة تنظيف تعتمد على التكنولوجيا تقدم خدمات راقية عالية الجودة للعملاء الأكثر تميزًا. نقدم لعملائنا قيمة استثنائية من خلال نموذج التنظيف ثلاثي المستويات، الذي يتميز بأداء فائق الجودة لعملائنا من كبار الشخصيات، والمواعيد المرنة، وتجربة حجز سهلة توفر خيارات رائعة
            </div>

             <div class="pb-15" >
      
        </div>
      </div>   -->
        </div>
      </v-dialog>
    </div>
  </div>
</template>

<script>
// import { mapState } from "vuex";
// import service from "../../services/service";  
//import pageheading from '../../components/pageheading.vue'
export default {
  components: {},
  data() {
    return {
      download_dialog: false,
      dc: false,
      selectlan: localStorage.getItem("bleach-lang"),
    };
  },
  methods: {
    goto(loc) {
      window.location = loc;
    },

     async downloadPdf(file_type){
      // let result = await service._get('customer/customer-evaluation-booking/download/'+this.eval_data.booking_id);
      // console.log(result);
      let pdfUrl='https://my.bleachkw.com/api/download-pdf-file/?file_type='+file_type
      window.open(pdfUrl, '_blank');
      // window.location.href = 
    },
  },
  // computed: {
  //   ...mapState({
  //     download_dialog_box: (state) => state.download_dialog_box,
  //   }),
  // },
  mounted() {
    // this.download_dialog = true;
    // this.download_dialog = this.download_dialog_box
    // this.$store.commit("set_downloads", false);
  },
};
</script>

<style>
</style>