<template>
  <div>
      <div v-if="selectlan == 'EN'" class="gray-bg-2">
        <div class="bcontainer">
            <div class="row py-15">
                <div class="col-md-4 col-lg-3">
                    <ul class="service-ui show-head">
                        <li  @click="$router.push('general')"   >
                          <span class="dot-li"></span>
                            General Questions
                        </li> 
                        <li @click="$router.push('service')" >
                            <span class="dot-li"></span>
                            Service Procedure
                        </li>
                        <li class="service-ui-active" >
                           <div class="dot-li-active"></div>
                            Technical Questions
                        </li>
                    </ul>
                                     <div  class="show-callus px-5">
               <div class=" py-2 mb-3" style="border-bottom:1px solid #C4C4C4;">
              <div class="d-flex pointer " @click="dc = !dc">
                 <div class="mobile-sub-link mobile-sub-header h-100 ma">
                 Technical Questions
               </div>
               <div>
                 <img class="mr-1" :class="{ 'fa-arrow-down-open': dc }" src="../../assets/ar.png" alt="">
               </div>
              </div>
            </div>
            <div class="drop-service">
               <transition-expand>
              <div v-if="dc" >
                <ul class="drop-list">
                     <li  @click="$router.push('general')">
                                  <a>
                                    General Questions
                                  </a>
                    </li>
                    <li  @click="$router.push('service')">
                        <a>
                                    Service Procedure
                        </a>
                    </li>

                </ul>
              </div> 
            </transition-expand>
        </div>
</div>
                </div>
                 <div class="col-md-8 col-lg-9">
                    <div class="px-5">
                         <expanadcard v-for="(item,index) in qs" :key="index" :qstn="item.qstn" :ans="item.answer" />
                          <div style="border-bottom:1px solid #C4C4C4;">
                                    <div class="d-flex pointer py-3" @click="showSection = !showSection">
                 <div style="flex-grow:1; font-size: 16px;line-height: 24px;" :class="{'active-qst': showSection}" class="sub-content h-100  text-left">
               Does your sterlizationprocess kill Coronavirus and novel coronavirus germs too?
               </div>
               <div>
                 <img class="mr-1" :class="{ 'fa-arrow-down-open': showSection }" src="../../assets/ar.png" alt="">
               </div>
              </div>
              <transition-expand>
                  <div class="sub-content mt-1 mb-3" v-if="showSection">
                      Yes, our sterlization process is 100% effective in killing any strain of Coronavirus (including the emerging ones too).
                      <br><br>
                      For Reference: <a target="_blank" href="https://www.epa.gov/coronavirus/do-disinfectants-kill-newer-variants-coronavirus"> https://www.epa.gov/coronavirus/do-disinfectants-kill-newer-variants-coronavirus</a>
                  </div>
              </transition-expand>
              </div>

                    </div>
                   

                 </div>
            </div>
        </div>
      </div>
       <div v-if="selectlan == 'ARABIC'" class="gray-bg-2">
        <div class="bcontainer">
            <div class="row py-15">
                <div class="col-md-4 col-lg-3">
                    <ul class="service-ui-ar show-head">
                        <li  @click="$router.push('general')"    class="arabic-font">
                          <span class="dot-li-ar"></span>
                            الأسئلة العامة
                        </li> 
                        <li @click="$router.push('service')" class="arabic-font"  >
                            <span class="dot-li-ar"></span>
                            أسئلة إجراءات الخدمة
                        </li>
                        <li class="service-ui-active arabic-font" >
                           <div class="dot-li-ar-active"></div>
                            الأسئلة الفنية
                        </li>
                    </ul>
                                     <div  class="show-callus px-5">
               <div class=" py-2 mb-3" style="border-bottom:1px solid #C4C4C4;">
              <div class="d-flex pointer " @click="dc = !dc">
                 <div class="mobile-sub-link mobile-sub-header h-100 ma">
                 الأسئلة الفنية
               </div>
               <div>
                 <img class="mr-1" :class="{ 'fa-arrow-down-open': dc }" src="../../assets/ar.png" alt="">
               </div>
              </div>
            </div>
            <div class="drop-service">
               <transition-expand>
              <div v-if="dc" >
                <ul class="drop-list">
                     <li  @click="$router.push('general')">
                                  <a class="arabic-font">
                                    الأسئلة العامة
                                  </a>
                    </li>
                    <li  @click="$router.push('service')">
                        <a class="arabic-font">
                                    أسئلة إجراءات الخدمة
                        </a>
                    </li>

                </ul>
              </div> 
            </transition-expand>
        </div>
</div>
                </div>
                 <div class="col-md-8 col-lg-9">
                    <div class="px-5">
                         <expanadcard v-for="(item,index) in qsAr" :key="index" :qstn="item.qstn" :ans="item.answer" />
                          <div style="border-bottom:1px solid #C4C4C4;">
                                    <div class="d-flex pointer py-3" @click="showSection = !showSection">
                 <div style="flex-grow:1; font-size: 16px;line-height: 24px;" :class="{'active-qst': showSection}" class="sub-content arabic-font h-100  text-right">
               هل عملية تعقيمك تقتل فيروس كورونا وأنماط فيروس كورونا الجديدة أيضًا؟
               </div>
               <div>
                 <img class="mr-1" :class="{ 'fa-arrow-down-open': showSection }" src="../../assets/ar.png" alt="">
               </div>
              </div>
              <transition-expand>
                  <div class="sub-content mt-1 mb-3 arabic-font" v-if="showSection">
                      نعم، عملية التعقيم المتبعة لدينا فعالة بنسبة 100 ٪ في قتل أي سلالة من فيروس كورونا (بما في ذلك الفيروسات الناشئة أيضًا).
                      <br><br>
                      للمزيد يرجى الرجوع إلى: <a target="_blank" href="https://www.epa.gov/coronavirus/do-disinfectants-kill-newer-variants-coronavirus"> https://www.epa.gov/coronavirus/do-disinfectants-kill-newer-variants-coronavirus</a>
                  </div>
              </transition-expand>
              </div>

                    </div>
                   

                 </div>
            </div>
        </div>
      </div>
  </div>
</template>

<script>
    import expanadcard from '../../components/expanadcard.vue'
export default {
    components:{
        expanadcard
    },
    data(){
        return{
              selectlan: localStorage.getItem("bleach-lang"),
            dc:false,
            showSection: false,
          qs:[
              {
    qstn:'Do you clean domestic kitchens or restaurants?',
    answer:'We clean both domestic and commercial kitchens with expert cleaning teams.'
},
{
    qstn:'Do you clean kitchen cabinets and kitchen wood too?',
    answer:'Yes. We offer a comprehensive kitchen cleaning service that covers kitchen surfaces and wood as well.'
},
{
    qstn:'Do you clean kitchen appliances?',
    answer:'Yes, we do. We provide deep kitchen cleaning services as per your needs. BLEACH® deep cleans appliances such as an exhaust, flooring, stocking racks, walk-in refrigerators, ovens, stoves, and chopping stations.'
},
{
    qstn:'Can you clean greasy and oily stains on the kitchen surface?',
    answer:'Our professional team of cleaning technicians is highly experienced in addressing stubborn grime and tough stains in every corner of your kitchen. They are equipped with cleaning solutions and specialized tools and machines that are sure to keep your kitchen squeaky clean.'
},
{
    qstn:'Is it vital to get kitchen cleaning from professionals?',
    answer:'While daily regular cleaning may partially keep your kitchen clean, it is also vital for your kitchen to receive deep cleaning and sanitization to reduce the risk of bacterial growth in the food processing area that can otherwise cause serious illnesses.'
},
{
    qstn:'Are mattresses sanitized in the home or at the company workshop?',
    answer:'At home. Our staff is well-equipped with tools and high-quality chemicals to clean your mattresses on your premises.'
},
{
    qstn:'What are the materials used for cleaning mattresses?',
    answer:'We use products that are safe for the earth, non-toxic, and environmentally friendly to kill the dust mites and bedbugs from within the mattress, removing all types of stains and spots and sanitizing it to make it a bacteria-free mattress, restoring its sheen.'
},
{
    qstn:'Do you clean carpets?',
    answer:'Yes, we do clean carpets.'
},
{
    qstn:'Do you remove stains from the mattress?',
    answer:'Yes. We provide special care to remove stains.'
},
{
    qstn:'How do you clean the carpet and upholstery? What is used for its cleaning?',
    answer:'Carpets and upholstery need exceptional care. BLEACH® cleaners are equipped with high-grade tools that we use to clean your valuable assets such as sofas, carpets, and upholstery without damaging them or compromising their durability or appearance.'
},
{
    qstn:'Do you clean curtains?',
    answer:'Yes, we deep clean curtains by using our steam cleaning machines.'
},
{
    qstn:'How many times do I need to have an infection control service at my place?',
    answer:"Sanitization or sterlization depends upon the risk assessment at the customer's residence or office. Infection control helps remove germs, bacteria, viruses, and other harmful allergens from your home. Frequent sanitization service protects you and your household from common cold or flu, allergies, and more."
},
{
    qstn:'I have an allergy. Can sanitization aggravate it?',
    answer:'Our state-of-the-art cleaning products are safe-to-use by cleaners and customers alike. These products are free from ammonia, ethylene glycol monobutyl acetate, sodium hypochlorite, and/or trisodium phosphate, and other harmful chemicals –approved by MOH.We advise customers to keep the place vacant for 15 minutes once the sanitization process is completed. Ventilate the place before and after the sterilizing process.'
},
{
    qstn:'Do you sanitize kitchen cupboards and cloth cupboards?',
    answer:'Kitchen cupboards and cloth cupboards include corners that areotherwise hard to reach. The cupboards are left empty for easy and effective sanitization and stacked back again once done.'
},
{
    qstn:'I bought new furniture. Can you sanitize it without damaging the fabric?',
    answer:'You can trust BLEACH® with your delicate & precious assets. Our high-grade chemicals are designed to maintain as well as restore the upholstery (new and aged).'
},
{
    qstn:'What products are used for Sanitization?',
    answer:'We use high-grade, MOH-approved sanitization products that have the perfect blend (of alcoholic and non-alcoholic) chemicals that make your area 100% germ-free.'
},
{
    qstn:'Do you clean Persian carpets? What is the price per meter?',
    answer:"Yes, we clean Persian carpetsin most cases. But in specific cases, it requires cleaning in a carpet cleaning workshop which we don't have. You may know the prices from the price list of our services in the communication channels accounts of the company."
},
{
    qstn:'How do you clean the carpet?',
    answer:'First, the carpet is checked to determine the appropriate cleaning method according to the type of material. If there are any spots, the source, and age of the spots, and finally fix the position of the carpet before starting its cleaning.'
}
          ],
          qsAr:[
              {
                  qstn:'هل تقدمون خدمة تنظيف المطابخ المنزلية أو المطاعم؟',
                  answer:'نقوم بتنظيف المطابخ المحلية والتجارية بواسطة فرق تنظيف متخصصة.'
              },
              {
                  qstn:'هل تنظفون خزائن المطبخ وخشب المطبخ أيضاً؟',
                  answer:'نعم. نقدم خدمة تنظيف شاملة للمطبخ تغطي أسطح المطبخ والخشب أيضًا.'
              },
              {
                  qstn:'هل تقدمون خدمة تنظيف أجهزة المطبخ؟',
                  answer:'نعم. نقدم خدمات التنظيف العميق للمطبخ حسب احتياجاتك. يقوم فريق بليتش® بالتنظيف العميق للأجهزة مثل العادم والأرضيات وأرفف التخزين والثلاجات والأفران والمواقد وأسطح التقطيع.'
              },
              {
                  qstn:'هل يمكنكم تنظيف البقع الدهنية والزيتية العالقة على سطح المطبخ؟',
                  answer:'يتمتع فريقنا المحترف من فنيي التنظيف بخبرة عالية في معالجة الأوساخ العنيدة والبقع الصعبة في كل ركن من أركان مطبخك. وهم مجهزون بحلول التنظيف والأدوات والآلات المتخصصة التي من المؤكد أنها تحافظ على مطبخك نظيفًا.'
              },
              {
                  qstn:'هل من الضروري الحصول على تنظيف المطبخ من قبل المحترفين ؟',
                  answer:'في حين أن التنظيف اليومي المنتظم قد يحافظ جزئيًا على نظافة مطبخك، فمن الضروري أيضًا أن يتلقى مطبخك تنظيفًا عميقًا وتعقيمًا لتقليل خطر نمو البكتيريا في منطقة تحضير الطعام التي يمكن أن تسبب أمراضًا خطيرة.'
              },
              {
                  qstn:'هل يجري تعقيم المراتب في المنزل أو في ورشة الشركة؟',
                  answer:'في المنزل موظفونا مجهزون تجهيزًا جيدًا بالأدوات والمواد الكيميائية عالية الجودة لتنظيف المراتب.'
              },
              {
                  qstn:'ما المواد المستخدمة لتنظيف المراتب؟',
                  answer:'نستخدم منتجات آمنة للأرض وغير سامة وصديقة للبيئة لقتل عث الغبار وبق الفراش من داخل المرتبة، وإزالة جميع أنواع البقع وتعقيمها لجعلها مرتبة خالية من البكتيريا، واستعادة لمعانها.'
              },
              {
                  qstn:'هل تقدمون خدمة تنظيف السجاد؟',
                  answer:'نعم، نحن نقدم خدمة تنظيف السجاد.'
              },
              {
                  qstn:'هل تزيلون البقع من المراتب؟',
                  answer:'نعم. نحن نقدم عناية خاصة لإزالة البقع.'
              },
              {
                  qstn:'كيف تنظفون السجادة والمفروشات؟ ما الذي يستخدم لتنظيفه؟',
                  answer:'يحتاج السجاد وقماش التنجيد إلى عناية استثنائية. إنّ منظفات بليتش® مجهزة بمواد عالية الجودة نستخدمها لتنظيف ممتلكاتك الثمينة مثل الأرائك والسجاد ومواد التنجيد دون الإضرار بها أو المساس بمتانتها أو مظهرها.'
              },
              {
                  qstn:'هل تقدمون خدمة تنظيف الستائر؟',
                  answer:'نعم، نقوم بتنظيف الستائر باستخدام آلات التنظيف بالبخار.'
              },
              {
                  qstn:'كم عدد المرات التي أحتاج فيها إلى خدمة مكافحة العدوى في منزلي؟',
                  answer:'يعتمد التعقيم أو التطهير على تقييم المخاطر في مقر إقامة العميل أو مكتبه. تساعد مكافحة العدوى على إزالة الجراثيم والبكتيريا والفيروسات وغيرها من مسببات الحساسية الضارة من منزلك. تحميك خدمة التعقيم المتكررة أنت وأسرتك من نزلات البرد أو الإنفلونزا الشائعة والحساسية وما إلى ذلك.'
              },
              {
                  qstn:'لدي حساسية. هل يمكن أن يؤدي التعقيم إلى تفاقمهما؟',
                  answer:'منتجات التنظيف الحديثة المستخدمة لدينا آمنة للاستخدام من قبل عمال النظافة والعملاء على حد سواء. هذه المنتجات خالية من الأمونيا وأسيتات أحادي بيوتيل الإيثيلين وهيبوكلوريت الصوديوم و/أو فوسفات ثلاثي الصوديوم والموادالكيميائية الضارة الأخرى – المعتمدة من وزارة الصحة. ننصح العملاء بإبقاء المكان شاغرًا لمدة 15 دقيقة بمجرد اكتمال عملية التعقيم. يجب تهوية المكان قبل وبعد عملية التعقيم.'
              },
              {
                  qstn:'هل لديكم خدمة تعقيم خزائن المطبخ وخزائن الملابس؟',
                  answer:'تحتوي خزائن المطبخ وخزائن الملابس على زوايا يصعب الوصول إليها. تترك الخزائن فارغة للتعقيم السهل والفعال ويعاد ترتيب محتوياتها مرة أخرى بمجرد الانتهاء منها.'
              },
              {
                  qstn:'اشتريت أثاثاً جديداً. هل يمكنكم تعقيمها دون إتلاف أقمشتها؟',
                  answer:'يمكنك الوثوق بشركة بليتش® لرعاية ممتلكاتك الحساسة والثمينة. صممت موادنا الكيميائية عالية الجودة للحفاظ على المفروشات (الجديدة والقديمة) وكذلك ترميمها.'
              },
              {
                  qstn:'ما المنتجات المستخدمة للتعقيم؟',
                  answer:'نحن نستخدم منتجات تعقيم عالية الجودة معتمدة من وزارة الصحة تحتوي على مزيج مثالي من المواد الكيميائية (الكحولية وغير الكحولية) التي تجعل مكانك خالياً من الجراثيم بنسبة 100 ٪.'
              },
              {
                  qstn:'هل تقدمون خدمة تنظيف السجاد الفارسي؟ ما سعر تنظيف المتر؟',
                  answer:'نعم، نحن ننظف السجاد الفارسي في معظم الحالات. ولكن في حالات محددة، يتطلب التنظيف العمل في ورشة خاصة لتنظيف السجاد والتي لا نملكها. يمكنك التعرف على الأسعار من قائمة أسعار خدماتنا في حسابات قنوات التواصل الخاصة بالشركة.'
              },
              {
                  qstn:'كيف تنظفون السجاد؟',
                  answer:'أولاً، تُفحص السجادة لتحديد طريقة التنظيف المناسبة وفقًا لنوع النسيج. إذا كانت هناك أي بقع، يجب ذكر مصدر وعمر البقع، وأخيرًا تثبيت موضع السجادة قبل البدء في تنظيفها.'
              }
          ]
        }
    }
}
</script>

<style>

</style>