<template>
 <div>
      <div>
      <section class=""  >
         <div class="bg-header-img-teams bg-header  ">
         </div>
      </section>
  </div>
     <div v-if="selectlan =='EN'">
    <div class="bcontainer">
       
      <div class="py-15">
           <div class="page-head">
            OUR <span class="w400"> Team</span>
        </div>
        <div class="sub-content text-left">
           We at BLEACH® believe in building a hearty and healthy work culture where personal and
professional growth is just as important as business growth. We trust amazing people to do
amazing things. At BLEACH®, you have ownership over work that directly impacts the
business. You can move fast and learn even faster.
        </div>
      </div>
    </div>
    <div class="gray-bg-2">
        <div class="py-12">
            <div class="bcontainer">
            <div class="sub-heading mt-5"> MEET OUR <span class="w500">TEAM </span></div>
            <div class="mt-4   sub-content">
            Employees are whole people, with families, hobbies, and lives outside of work. Say Hi! to a
few of the key BLEACH® members whose efforts make us proud and successful.
 
            </div>
            <div class="mpx-10 mt-10">
                <div class="row mt-6">
                    <div class="col-md-6 mb-7">
                        <div class="member-card h-100">
                            <div class="emp-header">
                                <div>
                                    <img src="../../assets/images/members/1.png" alt="">
                                </div>
                                <div class="emp-name full-flex ma ml-5">
                                    Ahmed Abdou <br>
                                    <span class="service-content">Sales Executive </span>
                                </div>
                            </div>
                            <div class="service-content mt-3">
                             A Sales Executive at BLEACH®, Ahmed is a fanatic when it comes to driving business sales for
us. He is a humble guy who is easy to approach. Ahmed loves playing football when away
from work.
                            </div>
                        </div>
                    </div>
                     <div class="col-md-6 mb-7">
                        <div class="member-card h-100">
                            <div class="emp-header">
                                <div>
                                    <img src="../../assets/images/members/2.png" alt="">
                                </div>
                                <div class="emp-name full-flex ma ml-5">
                                    Abdulhamid Sarrag <br>
                                    <span class="service-content">Team Leader </span>
                                </div>
                            </div>
                            <div class="service-content mt-3">
                            Leading the Customer Care Operations at BLEACH®, Abdulhamid is a team player with years
of experience. He loves to stay active and plays basketball to keep his spirits high.
Abdulhamid is a swimmer too. At times we too wonder if there’s anything he can’t do!
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-7">
                        <div class="member-card h-100">
                            <div class="emp-header">
                                <div>
                                    <img src="../../assets/images/members/3.png" alt="">
                                </div>
                                <div class="emp-name full-flex ma ml-5">
                                    Sheik Musthaffa <br>
                                    <span class="service-content">Operation Officer </span>
                                </div>
                            </div>
                            <div class="service-content mt-3">
                           Operation Officer at BLEACH®, Sheik is our go-to guy for all day-to-day work processes. He
loves amplifying sales for the company. While he’s aggressive at work, his other side is to
read books, climb mountains, sing and play cricket. He is an athlete by nature.
                            </div>
                        </div>
                    </div>
                     <div class="col-md-6 mb-7">
                        <div class="member-card h-100">
                            <div class="emp-header">
                                <div>
                                    <img src="../../assets/images/members/4.png" alt="">
                                </div>
                                <div class="emp-name full-flex ma ml-5">
                                    Jouie Decapia <br>
                                    <span class="service-content">Storekeeper </span>
                                </div>
                            </div>
                            <div class="service-content mt-3">
                           A Storekeeper at BLEACH®, Jouie takes full charge of what comes IN and goes OUT in our
inventory after a thorough assessment. Nothing escapes Jouie’s eyes. A believer in good
thoughts, Jouie loves playing basketball, and watching movies, and has some scrumptious
cooking skills.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-7">
                        <div class="member-card h-100">
                            <div class="emp-header">
                                <div>
                                    <img src="../../assets/images/members/5.png" alt="">
                                </div>
                                <div class="emp-name full-flex ma ml-5">
                                    Anush Poojary <br>
                                    <span class="service-content">Team In-Charge </span>
                                </div>
                            </div>
                            <div class="service-content mt-3">
                          Anush is an integral part of the BLEACH® family. Presently working as a Team In-Charge in
our company, he is extremely good at communication and loves being a team player. Anush
has been with us for 5 years now. Time flies!
                            </div>
                        </div>
                    </div>
                      <div class="col-md-6 mb-7">
                        <div class="member-card h-100">
                            <div class="emp-header">
                                <div>
                                    <img src="../../assets/images/members/6.png" alt="">
                                </div>
                                <div class="emp-name full-flex ma ml-5">
                                    Rowena Miguel <br>
                                    <span class="service-content">Bleach Staff </span>
                                </div>
                            </div>
                            <div class="service-content mt-3">
                         One of our oldest employees, Rowena takes care of multiple things – from assisting
operations to providing customer support. She loves singing, swimming, mountain climbing,
playing basketball, and is a foodie too. Rowena is a believer in making cleanliness a habit.
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <!-- <div class="mpx-15">

            </div> -->
        </div>
        </div>
    </div>
  </div>
   <div  v-if="selectlan =='ARABIC'" dir="rtl">

    <div class="bcontainer">
       
      <div class="py-15">
           <div class="page-head arabic-font">
            فريقنا
        </div>
        <div class="sub-content text-right arabic-font">
           نحن في بليتش® نؤمن ببناء ثقافة عمل صحية وسليمة حيث النمو الشخصي والمهني لا يقل أهمية عن نمو الأعمال. نحن نثق بمهارات أشخاص رائعين للقيام بإنجازات رائعة. في بليتش®، تمتلك سيطرة على العمل الذي يؤثر بشكل مباشر على نشاطك اليومي. يمكنك التحرك بسرعة والتعلم بشكل أسرع.
        </div>
      </div>
    </div>
    <div class="gray-bg-2">
        <div class="py-12">
            <div class="bcontainer">
            <div class="sub-heading mt-5 arabic-font" > تعرّف على أعضاء فريقنا الرئيسيين</div>
            <div class="mt-4   sub-content arabic-font">
            موظفونا أشخاص طبيعيون لديهم أسرهم وهواياتهم وحياة كاملة خارج نطاق العمل. . تعرف على نخبةٍ من أعضاء فريق بليتش® الرئيسيين الذين نشعر بالفخر والنجاح بفضل جهودهم المخلصة.
 
            </div>
            <div class="mpx-10 mt-10">
                <div class="row mt-6">
                    <div class="col-md-6 mb-7">
                        <div class="member-card h-100">
                            <div class="emp-header">
                                <div>
                                    <img src="../../assets/images/members/1.png" alt="">
                                </div>
                                <div class="emp-name arabic-font full-flex ma mr-5">
                                    أحمد عبدو <br>
                                    <span class="service-content arabic-font">رئيس فريق المبيعات و خدمة العملاء</span>
                                </div>
                            </div>
                            <div class="service-content arabic-font mt-3">
                            أحمد مسؤول تنفيذي في قسم المبيعات بشركة بليتش®، ولديه حماس لا يقاوم عندما يتعلق الأمر بزيادة مبيعات الشركة. إنه رجل متواضع يسهل التواصل معه. يهوى أحمد لعب كرة القدم خارج أوقات العمل.
                            </div>
                        </div>
                    </div>
                     <div class="col-md-6 mb-7">
                        <div class="member-card h-100">
                            <div class="emp-header">
                                <div>
                                    <img src="../../assets/images/members/2.png" alt="">
                                </div>
                                <div class="emp-name arabic-font full-flex ma mr-5">
                                    عبد الحميد سراج <br>
                                    <span class="service-content arabic-font ">قائد الفريق </span>
                                </div>
                            </div>
                            <div class="service-content arabic-font mt-3">
                            يقود عبد الحميد عمليات رعاية العملاء في شركة بليتش®، وهو موظف يتقن العمل الجماعي ولديه سنوات من الخبرة. يحب أن يبقى نشطًا ويلعب كرة السلة للحفاظ على معنوبات مرتفعة.

عبد الحميد سبّاح أيضا. نتساءل في بعض الأحيان إن كان ثمة شيء لا يمكنه القيام به!
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-7">
                        <div class="member-card h-100">
                            <div class="emp-header">
                                <div>
                                    <img src="../../assets/images/members/3.png" alt="">
                                </div>
                                <div class="emp-name arabic-font full-flex ma mr-5">
                                    شيخ مصطفى <br>
                                    <span class="service-content arabic-font">موظف العمليات </span>
                                </div>
                            </div>
                            <div class="service-content arabic-font mt-3">
                          مسؤول العمليات في شركة بليتش®، شيخ هو الرجل المناسب لجميع ممارسات العمل اليومية. ويهوى تضخيم مبيعات الشركة. وفي حين أنه هجومي في العمل، فإن جانبه الآخر يهوى قراءة الكتب وتسلق الجبال والغناء ولعب الكريكيت. إنه رياضي بطبيعته.
                            </div>
                        </div>
                    </div>
                     <div class="col-md-6 mb-7">
                        <div class="member-card h-100">
                            <div class="emp-header">
                                <div>
                                    <img src="../../assets/images/members/4.png" alt="">
                                </div>
                                <div class="emp-name arabic-font full-flex ma mr-5">
                                    جوي ديكابيا <br>
                                    <span class="service-content arabic-font">امين المخزن </span>
                                </div>
                            </div>
                            <div class="service-content mt-3 arabic-font">
                          يتولى جوي، وهو أمين المخزن في شركة بليتش®، لديه المسؤولية الكاملة عما يدخل ويخرج من المخزن بعد إجراء تقييم شامل. لا شيء يهرب من عيني جوي. يؤمن جوي بالأفكار الحميدة ويحب لعب كرة السلة

ومشاهدة الأفلام ولديه بعض مهارات الطهي اللذيذة.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-7">
                        <div class="member-card h-100">
                            <div class="emp-header">
                                <div>
                                    <img src="../../assets/images/members/5.png" alt="">
                                </div>
                                <div class="emp-name arabic-font full-flex ma mr-5">
                                    أنوش بوجاري <br>
                                    <span class="service-content arabic-font">Team In-Charge </span>
                                </div>
                            </div>
                            <div class="service-content arabic-font mt-3">
                        أنوش جزء لا يتجزأ من عائلة بليتش®. يتولى حاليًا مهام الفريق المسؤول في شركتنا، وهو بارع للغاية في التواصل ويحبّ أن يكون عضواً فاعلاً في الفريق. يعمل أنوش معنا منذ 5 سنوات. إنّ الوقت يمضي كالبرق...
                            </div>
                        </div>
                    </div>
                      <div class="col-md-6 mb-7">
                        <div class="member-card h-100">
                            <div class="emp-header">
                                <div>
                                    <img src="../../assets/images/members/6.png" alt="">
                                </div>
                                <div class="emp-name arabic-font full-flex ma mr-5">
                                    روينا ميغيل <br>
                                    <span class="service-content arabic-font">Bleach Staff </span>
                                </div>
                            </div>
                            <div class="service-content arabic-font mt-3">
                       تهتم روينا، وهي من أقدم موظفينا، بأشياء متعددة؛ من مساعدة قسم العمليات إلى تقديم الدعم للعملاء. إنها تحب الغناء والسباحة وتسلق الجبال ولعب كرة السلة، كما تحبّ الطعام أيضًا. تؤمن روينا بجعل النظافة عادة.
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <!-- <div class="mpx-15">

            </div> -->
        </div>
        </div>
    </div>
  </div>
 </div>
</template>

<script>
//import pageheading from "../../components/pageheading.vue";
export default {
  components: {  },
  data(){
      return{
          btnselected:'',
           selectlan:localStorage.getItem('bleach-lang'),
      };
  }
};
</script>

<style lang="scss">
.emp-header{
    display: flex;
    padding-bottom: 16px;
    border-bottom: 1px solid #2B9FAD;
}
.key-member-head{
    font-style: normal;
font-weight: 600;
font-size: 22px;
line-height: 33px;
text-transform: uppercase;
color: #2B9FAD;
}
.vertical {
  padding-top: 10px;
  border-left: 1px solid #5d5d5d;
  height: 80%;
}
.active-btn-form{
background: #E0F6F9 !important;
color: #2B9FAD !important;
}
.member-card{
padding: 30px;
background: #FFFFFF;
border-radius: 20px;
}
.emp-name{
    font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
text-transform: uppercase;

color: #2B9FAD;
}
</style>
