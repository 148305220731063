<template>
<div>
      <div v-if="selectlan == 'EN'">
              <div style="border-bottom:1px solid #C4C4C4;">
                                    <div class="d-flex pointer py-3" @click="showSection = !showSection">
                 <div style="flex-grow:1; font-size: 16px;line-height: 24px;" :class="{'active-qst': showSection}" class="sub-content h-100  text-left">
                 {{qstn}}
               </div>
               <div>
                 <img class="mr-1" :class="{ 'fa-arrow-down-open': showSection }" src="../assets/ar.png" alt="">
               </div>
              </div>
              <transition-expand>
                  <div class="sub-content mt-1 mb-3" v-if="showSection">
                      {{ans}}
                  </div>
              </transition-expand>
              </div>
    </div>
      <div v-if="selectlan == 'ARABIC'">
              <div style="border-bottom:1px solid #C4C4C4;">
                                    <div class="d-flex pointer py-3" @click="showSection = !showSection">
                 <div style="flex-grow:1; font-size: 16px;line-height: 24px;" :class="{'active-qst': showSection}" class="sub-content h-100 arabic-font text-right">
                 {{qstn}}
               </div>
               <div>
                 <img class="mr-1" :class="{ 'fa-arrow-down-open': showSection }" src="../assets/ar.png" alt="">
               </div>
              </div>
              <transition-expand>
                  <div class="sub-content arabic-font  mt-1 mb-3" v-if="showSection">
                      {{ans}}
                  </div>
              </transition-expand>
              </div>
    </div>
</div>
</template>

<script>
export default {
    props:['qstn','ans'],
    data(){
        return{
            showSection: false,
            selectlan: localStorage.getItem("bleach-lang"),
            
        }
    }
}
</script>

<style>
.active-qst{
    color: #2B9FAD !important;
}
</style>