<template>
  <div>
    <div class="text-center emptycart-icon">
              <svg
                width="243"
                height="153"
                viewBox="0 0 243 153"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M225.884 110.223C227.305 109.518 228.148 108.655 228.148 107.728C228.148 105.349 222.673 103.412 215.915 103.412C212.651 103.412 209.693 103.865 207.493 104.602C185.544 99.3908 155.036 96.1589 121.307 96.1589C54.4798 96.1589 0.306641 108.834 0.306641 124.456C0.306641 132.267 13.8341 139.331 35.6992 144.458C34.9412 145.037 34.4991 145.69 34.4991 146.384C34.4991 148.764 39.5206 150.701 45.7106 150.701C50.1636 150.701 53.9955 149.7 55.8062 148.248C74.6815 151.101 97.1677 152.753 121.307 152.753C188.133 152.753 242.307 140.079 242.307 124.456C242.307 119.266 236.327 114.403 225.884 110.213V110.223Z"
                  fill="#E4E4E4"
                />
                <path
                  d="M32.0653 88.5107C36.9026 88.5107 40.824 84.5893 40.824 79.7521C40.824 74.9148 36.9026 70.9934 32.0653 70.9934C27.228 70.9934 23.3066 74.9148 23.3066 79.7521C23.3066 84.5893 27.228 88.5107 32.0653 88.5107Z"
                  fill="#DCEBCB"
                />
                <path
                  d="M32.9711 79.752H31.1709V101.817H32.9711V79.752Z"
                  fill="#DCEBCB"
                />
                <path
                  d="M196.371 51.122C203.586 51.122 209.435 45.2729 209.435 38.0577C209.435 30.8425 203.586 24.9934 196.371 24.9934C189.156 24.9934 183.307 30.8425 183.307 38.0577C183.307 45.2729 189.156 51.122 196.371 51.122Z"
                  fill="#DCEBCB"
                />
                <path
                  d="M197.708 38.0579H195.023V70.987H197.708V38.0579Z"
                  fill="#DCEBCB"
                />
                <path
                  d="M222.834 93.0473C226.991 93.0473 230.361 89.6774 230.361 85.5204C230.361 81.3633 226.991 77.9934 222.834 77.9934C218.677 77.9934 215.307 81.3633 215.307 85.5204C215.307 89.6774 218.677 93.0473 222.834 93.0473Z"
                  fill="#DCEBCB"
                />
                <path
                  d="M223.602 85.5203H222.055V104.48H223.602V85.5203Z"
                  fill="#DCEBCB"
                />
                <path
                  d="M225.884 107.392C227.305 106.686 228.148 105.823 228.148 104.897C228.148 102.517 222.673 100.58 215.915 100.58C212.651 100.58 209.693 101.033 207.493 101.77C185.544 96.559 155.036 93.3271 121.307 93.3271C54.4798 93.3271 0.306641 106.002 0.306641 121.624C0.306641 129.436 13.8341 136.499 35.6992 141.626C34.9412 142.205 34.4991 142.858 34.4991 143.553C34.4991 145.932 39.5206 147.869 45.7106 147.869C50.1636 147.869 53.9955 146.869 55.8062 145.416C74.6815 148.269 97.1677 149.922 121.307 149.922C188.133 149.922 242.307 137.247 242.307 121.624C242.307 116.434 236.327 111.571 225.884 107.381V107.392Z"
                  fill="#F0F0F0"
                />
                <path
                  d="M67.5587 33.0508L62.0795 11.114C61.7131 9.65125 60.8684 8.35287 59.6796 7.42512C58.4908 6.49737 57.0261 5.99345 55.5181 5.99341H47.2656L67.5587 33.0508ZM87.8517 127.752H101.38H87.8517ZM141.966 127.752H155.495H141.966Z"
                  fill="#2B9FAD"
                />
                <path
                  d="M141.966 127.752H155.495M67.5587 33.0508L62.0795 11.114C61.7131 9.65125 60.8684 8.35287 59.6796 7.42512C58.4908 6.49737 57.0261 5.99345 55.5181 5.99341H47.2656L67.5587 33.0508ZM87.8517 127.752H101.38H87.8517Z"
                  stroke="#C4C4C4"
                  stroke-width="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M67.5586 28.0527C66.0607 28.0527 64.6417 28.7243 63.692 29.8827C62.7423 31.0411 62.362 32.5642 62.6557 34.0331L74.0062 90.7991L74.0063 90.7995C74.8462 95.0013 77.1164 98.7821 80.4305 101.498C83.7442 104.214 87.8965 105.697 92.1809 105.696H92.1828H147.827C146.595 102.559 145.803 99.2001 145.528 95.6962H92.1809H92.1789C90.2065 95.697 88.2948 95.0141 86.7693 93.7639C85.2438 92.5136 84.1988 90.7733 83.8122 88.8391L83.8122 88.8388L73.6574 38.0527H160.839H160.839C162.03 38.0528 163.209 38.3025 164.298 38.7858C165.387 39.2691 166.363 39.9752 167.162 40.8587C167.962 41.7422 168.567 42.7834 168.94 43.9153C169.312 45.0471 169.443 46.2444 169.325 47.43L168.048 60.1955C171.271 58.9802 174.721 58.2272 178.316 58.0176L179.275 48.4251L174.3 47.9275L179.275 48.425C179.533 45.8492 179.248 43.248 178.439 40.7891C177.629 38.3302 176.314 36.0682 174.577 34.1488C172.84 32.2294 170.72 30.6953 168.354 29.6453C165.988 28.5954 163.428 28.0528 160.839 28.0527H160.839H67.5586Z"
                  fill="#C4C4C4"
                />
                <circle cx="180.383" cy="92.9211" r="29.1897" fill="#D9D9D9" />
                <path
                  d="M176.048 93.0843L166.529 83.5912C165.916 82.9581 165.576 82.1101 165.584 81.23C165.592 80.3498 165.946 79.5079 166.57 78.8855C167.194 78.2631 168.038 77.9101 168.921 77.9025C169.803 77.8948 170.653 78.2332 171.288 78.8446L180.807 88.3378L190.326 78.8446C190.636 78.524 191.008 78.2683 191.419 78.0924C191.829 77.9164 192.271 77.8238 192.718 77.82C193.165 77.8161 193.608 77.901 194.022 78.0698C194.435 78.2386 194.811 78.4878 195.127 78.803C195.443 79.1182 195.693 79.493 195.862 79.9055C196.032 80.3181 196.117 80.7601 196.113 81.2058C196.109 81.6515 196.016 82.092 195.84 82.5016C195.663 82.9111 195.407 83.2815 195.085 83.5912L185.567 93.0843L195.085 102.577C195.407 102.887 195.663 103.257 195.84 103.667C196.016 104.077 196.109 104.517 196.113 104.963C196.117 105.409 196.032 105.851 195.862 106.263C195.693 106.676 195.443 107.05 195.127 107.366C194.811 107.681 194.435 107.93 194.022 108.099C193.608 108.268 193.165 108.353 192.718 108.349C192.271 108.345 191.829 108.252 191.419 108.076C191.008 107.9 190.636 107.645 190.326 107.324L180.807 97.8309L171.288 107.324C170.653 107.935 169.803 108.274 168.921 108.266C168.038 108.259 167.194 107.905 166.57 107.283C165.946 106.661 165.592 105.819 165.584 104.939C165.576 104.058 165.916 103.211 166.529 102.577L176.048 93.0843Z"
                  fill="white"
                />
              </svg>
            </div>
            <div class="cart-empty-head-text mt-6">
              Your shopping cart is empty
            </div>
            <div class="cart-empty-subcontent">
              Once you have added items to your shopping cart,<br />
              You can check out form here.
            </div>
            <div class="text-center pb-15">
              <button
                class="mt-5 book-now-btn active"
                style="width: 343px"
                @click="goto('service')"
              >
                Add service in cart
              </button>
            </div>
  </div>
</template>

<script>
export default {
    methods:{
        goto() {
      // window.location = loc;
       this.$store.commit("set_cart", true);
    this.$router.push('/')
    },
    }
}
</script>

<style>

</style>