<template>
<div>
        <div v-if="selectlan=='EN'">
      <div class="gray-bg-2">
      <div class="bcontainer">
         <div class="row py-15">
             <div class="col-md-4 col-lg-3">                    
                <ul class="service-ui show-head">
                    <li  @click="$router.push('domesticsector')"  >
                        <span class="dot-li"></span>
                        Domestic Sector
                    </li> 
                     <li @click="$router.push('babycare')">
                        <span class="dot-li"></span>
                       Baby Care
                    </li>
                    <li  @click="$router.push('moving')" >
                        <span class="dot-li"></span>
                        Moving In/Out
                    </li>
                     <li class="service-ui-active" >
                         <div class="dot-li-active"></div>
                        Special Events Care
                    </li>                     
                 </ul>
                  <resmenu :name="'Special Events Care'"/>
                   <div class="mt-5">
                    <div class="recommended-head">
                        RECOMMENDED SERVICES
                    </div>
                    <div class="rec-item-name mt-3">
                        <img src="../../assets/icons/recommend/time.png" alt="" class="ml-2 mr-2"> Time and Material Services
                    </div>
                 </div>
             </div>
             <div class="col-md-8 col-lg-9">
                 <div class="cleaning-head show-head">Special Events<span class="w400"> Care</span>  </div>
                   <div class="sub-content mt-2">
                  Our event cleaning service ensures your special day is just right. Leave all the dusting,
sanitizing, and waste management to us inside/outside the venues. We specialize in eventspecific cleanings, including weddings and birthdays.

                 </div>
                 <div class="bold-heading mt-10">
                     DID YOU KNOW ?
                 </div>
                 <div class="mt-3 sub-content">
                     Private and public events are quite frequent; however, a large number of these occasions
lack the standard level of hygiene and cleaning where so many of the used upholsteries or
carpets are badly damaged. The life range of those assets ends up being disposed of due to
low maintenance and utilizing the wrong types of materials in such public or high traffic
events

                 </div>
                  <div class="bold-heading mt-10">
                     BENEFITS OF CLEANING POST A SPECIAL DAY
                 </div>
                 <div class="mt-5 sub-content">
                    BLEACH® grants customers a promising advantage to gain bigger value for their assets at
events/occasions. Technological methods of cleaning, and coating assets and area as a
whole add another advantage for the event organizer. Such cleaning and maintenance give
customers more opportunities to utilize their facility and assets many times in future events
to come. And of course, we make sure you live stress-free from the aftermath of the special
day and enjoy the memories.
                 </div>
             </div>
         </div>
      </div>
  </div>
 
</div>
   <div v-if="selectlan=='ARABIC'">
      <div class="gray-bg-2">
      <div class="bcontainer">
         <div class="row py-15">
             <div class="col-md-4 col-lg-3">                    
                <ul class="service-ui-ar show-head">
                    <li  @click="$router.push('domesticsector')" class="arabic-font">
                        <span class="dot-li-ar"></span>
                        قطاع األعمال المنزلية 
                    </li> 
                     <li @click="$router.push('babycare')" class="arabic-font">
                        <span class="dot-li-ar"></span>
                       رعاية األطفال
                    </li>
                    <li  @click="$router.push('moving')" class="arabic-font" >
                        <span class="dot-li-ar"></span>
                       أعمال انتقال المأجر
                    </li>
                     <li class="service-ui-active arabic-font"  >
                         <div class="dot-li-ar-active"></div>
                        رعاية المناسبات الخاصة
                    </li>                     
                 </ul>
                  <resmenu :name="'رعاية المناسبات الخاصة'"/>
             </div>
             <div class="col-md-8 col-lg-9">
                 <div class="cleaning-head show-head arabic-font">رعاية المناسبات <span class="w400"> الخاصة</span>  </div>
                   <div class="sub-content mt-2 arabic-font">
                 تضمن خدمات تنظيف مواقع إنعقاد الأحداث والمناسبات لدينا أن يومك المحتفى به سيكون مميزاً، كل ما عليك هو ترك كل الغبار والتعقيم وإدارة النفايات لنا سواء في داخل أو خارج مواقع الإحتفال، نحن متخصصون في تنظيف مواقع إنعقاد الأحداث والمناسبات الخاصة بما في ذلك حفلات الزفاف وأعياد الميلاد. 
                 </div>
                 <div class="bold-heading mt-10 arabic-font">
                     هل تعلم؟ 
                 </div>
                 <div class="mt-3 sub-content arabic-font">
                   كثيراً ما تتكرر الأحداث الخاصة والعامة؛ ومع ذلك، فإن عددًا كبيرًا من هذه المناسبات يفتقر إلى المستوى المعياري من الصحة والنظافة في المواقع التي تتعرض فيها أقمشة المفروشات أو السجاد لأضرار بالغة، فينتهي العمر الافتراضي لتلك الأصول بالتخلص منها بسبب قلة الصيانة ولاستخدام الأنواع الخاطئة من المواد في مثل هذه الأحداث العامة أو ذات الازدحام الشديد.

                 </div>
                  <div class="bold-heading mt-10 arabic-font">
                    فوائد التنظيف بعد إنتهاء المناسبات الخاصة
                 </div>
                 <div class="mt-5 sub-content arabic-font">
                   تمنح بليتش® العملاء ميزة واعدة لاكتساب قيمة أكبر لأصولهم المستخدمة في الأحداث أو المناسبات، كما يضيف إستخدام الأساليب التكنولوجية للتنظيف، وإضافة طبقة خارجية عازلة لكل من لأصول ومنطقة إنعقاد الحدث ميزة أخرى لمنظم الحدث، فكل اساليب التنظيف والصيانة تعطي العملاء المزيد من الفرص لاستخدام منشآتهم وأصولهم عدة مرات في المستقبل، وبالتأكيد نحن نحرص على أن تستمر الحياة بلا توتر في أعقاب يوم الحدث لتتمتع أنت بذكريات هذا اليوم. 

                 </div>
             </div>
         </div>
      </div>
  </div>
  
</div>

</div>
</template>

<script>
import resmenu from '../../components/menu/resmenu.vue'
export default {
    components:{
        resmenu
    },
 data(){
        return{
            selectlan:localStorage.getItem('bleach-lang')
        }
    },
}
</script>

<style>

</style>