<template>
  <div>
    <div>
      <section class="">
        <div class="bg-header-img-ic bg-header"></div>
      </section>
    </div>
    <div v-if="selectlan == 'EN'">
      <div class="bcontainer">
        <div class="pmy-15">
          <div class="page-head">PEST <span class="w400">CONTROL</span></div>
          <div class="sub-content mt-2 text-left">
            Bleach is proud to announce our strategic partnership with Rentokil
            Boecker®, part of the global Rentokil Initial, a leading public
            health company in the MENA region since 1994. This collaboration
            enhances our service offerings, combining Bleach's local expertise
            with Rentokil Boecker's extensive experience and resources. Through
            Rentokil Boecker's® partnership with Bleach® and using their
            resources which includes 2,000 employees in 11 branches, they
            provide comprehensive public health solutions for commercial and
            residential properties across the region, including pest control,
            germ control, food safety, hygiene, and scenting services. This
            partnership leverages the strengths of both entities — Bleach's
            commitment to customer satisfaction and Rentokil Boecker's
            reputation for innovative products and efficient service delivery.
            Together, both are committed to protecting people, enhancing lives,
            and preserving our planet through sustainable innovations and
            best-in-class service.

            <!-- <span v-if="!dc" style="color: #2b9fad" @click="dc = true"
              >Read more</span
            >
            <transition-expand>
              <div v-if="dc">
                <br />
                Transportation Sanitation & Disinfection – It is designed to
                sterilize your vehicles to fight the spread of unwanted germs
                and diseases. Transportation vehicles are susceptible to
                transmitting harmful viruses if not maintained properly due to
                the accumulation of outside germs. With our thorough
                sanitization process, you can rest assured that you and your
                vehicle are in good hands.
                <div v-if="width < 768">
                  <span style="color: #2b9fad" v-if="dc" @click="dc = false"
                    >Read less</span
                  >
                </div>
              </div>
            </transition-expand> -->
          </div>
        </div>
        <div></div>
      </div>
      <div id="id_sec">
        <router-view />
      </div>
    </div>
    <div v-if="selectlan == 'ARABIC'">
      <div class="bcontainer">
        <div class="pmy-15">
          <div class="page-head arabic-font" dir="rtl">مكافحة الحشرات</div>
          <div class="sub-content arabic-font mt-2" dir="rtl">
            نفخر في Bleach® للإعلان عن شراكة استراتيجية مع شركة Rentokil
            Boecker®، التي تنتمي لمجموعة Global Rentokil Initial العالمية،
            الرائدة في خدمات الصحة العامة منذ 1994. وهذه الشراكة تعتبر إضافة
            مهمة لضمان التنوّع في خدماتنا، من خلال توحيد خبرة Bleach® في قطاع
            مقاولات النظافة والضيافة مع خبرة وموارد شركة Rentokil Boecker®
            العالمية. شراكة Rentokil Boecker® مع Bleach® ستمكنهم من استخدام
            مواردهم التي تتكون من 2000 موظف في 11 فرع بمختلف دول العالم، توفّر
            حلول مبتكرة بخدمات الصحة العامة للمنشئات السكنية والتجارية، مثل خدمة
            مبيدات الحشرات، مكافحة الجراثيم، الصحة/السلامة الغذائية، التعقيم
            والنظافة، والتعطير. هذه الشراكة تعزّز قوة كلا الطرفين – Bleach® تحرص
            على ضمان رضاء عملائها، و شهرة Rentokil Boecker® تعطي وتوفر منتجات
            مبتكرة وخدمات متنوعة. معاً، يلتزم الطرفين بحماية صحة الناس،
            والمحافظة على سلامة البيئة الكونية من خلال آليات توفّر الاستدامة مع
            خدمة عالية الجودة.

            <!-- <span v-if="!dc" style="color: #2b9fad" @click="dc = true"
              >المزيد</span
            >
            <transition-expand>
              <div v-if="dc">
                <br /><br />
                تطهير وتعقيم الأسطح - توفر هذه الخدمة حلول تنظيف صديقة للبيئة
                للقضاء على البكتيريا والفيروسات تمامًا من الهواء وجميع الأسطح.
                نستخدم أدوات متخصصة لتعقيم الأماكن ذات الزدحام الشديد مثل قاعات
                الاجتماعات والحمامات والمطابخ والمكاتب ومناطق الاستقبال، من بين
                أمور أخرى.
                <br /><br />
                تعقيم وتطهير وسائل النقل – صممت هذه الخدمة لتعقيم مركباتك ومنع
                انتشار الجراثيم والأمراض غير المرغوب فيها. مركبات النقل عرضة
                لانتقال الفيروسات وتراكم الجراثيم الضارة إذا لم تعقم بشكل صحيح.
                ومن خلال عملية التعقيم الشاملة، يمكنك أن تطمئن إلى أنك وسيارتك
                في أيد أمينة.
                <div v-if="width < 768">
                  <span style="color: #2b9fad" v-if="dc" @click="dc = false"
                    >المختصر</span
                  >
                </div>
              </div>
            </transition-expand> -->
          </div>
        </div>
        <div></div>
      </div>
      <div id="id_sec">
        <router-view dir="rtl" />
      </div>
    </div>
  </div>
</template>
<script>
//import pageheading from '../../../components/pageheading.vue'
export default {
  mounted() {
    this.width = screen.width;
    if (this.width < 768) {
      this.dc = false;
    } else {
      if (localStorage.getItem("bleach-dc")) {
        localStorage.removeItem("bleach-dc");
      } else {
        this.$nextTick(() =>
          window.document.getElementById("id_sec").scrollIntoView()
        );
      }
    }
  },
  components: {},
  data() {
    return {
      dc: true,
      width: null,
      selectlan: localStorage.getItem("bleach-lang"),
    };
  },
  methods: {
    isCar() {
      if (this.width != null) {
        if (this.width < 768) {
          return true;
        }
        return false;
      }
      return false;
    },
  },
};
</script>
<style></style>
