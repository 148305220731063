<template>
  <div>
  <div  class="content-user">
    <p><b >Added</b> To cart 
    </p> 
          <vs-button
          @click="goto()"
        border
         color="#2b9fad"
      >
        View cart  <i class='bx bxs-chevron-right' style="margin-top:4px;  color:#2B9FAD;"></i>
      </vs-button>

  </div>
  </div>
</template>

<script>
//import cartsvgVue from './utils/cartsvg.vue'
export default {
    components:{
       // cartsvgVue
    },
    methods:{
        goto(){
            window.location ='/cart'
        }
    }
}
</script>

<style>
.content-user{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.content-user p{
    color:#2B9FAD;
    font-family: 'Poppins' !important;
font-style: normal !important;
    font-size: .8rem;
    max-width: 200px;
    padding: 0;
    margin: 0
}
</style>