<template>
 <div>
   <div>
      <section class=""  >
         <div class="bg-header-img-privacy bg-header  ">
         </div>
      </section>
  </div>
   <div v-if="selectlan=='EN'">
    <div class="bcontainer">
      <div class="py-15">
            <div class="page-head ">
                  Privacy <span class="w400">policy</span>
              </div>
         
             <div class="mt-10 service-content">
            Effective Date: 01/01/2022
            <br>
            <br>
            <br>
            1. We at Bleach International Building and City Cleaning WLL understand the
importance of your privacy for you, and from this standpoint, we are committed to
respecting your privacy and protecting your personal data, and any information by
which you can be identified. This privacy policy shows how we deal with and protect
your personal data regarding the commercial activities of Bleach International
Company for Building Cleaning and Cities WLL, headquartered in Qibla Bank Elite
Tower, Block 11, Street 55, Floor No. 15, Unit No. 1, a company registered in
accordance with the laws of the State of Kuwait under Commercial Registration No.
362934 (hereinafter referred to as "the Company")
<br><br>
2. The company provides building, residence, and road cleaning services in the State of
Kuwait (hereinafter referred to as the “Services”), and customers, through the
website (hereinafter referred to as the “Website”), request these services. This
policy states how the company collects, uses, stores, shares and protects your
personal information, regardless of how these services are accessed and from what
type of device.
<br><br>

3. When you register for an account on our Website, you agree to provide and store
this information on our computer servers or with a third party.
<br><br>

4. Your continued use of the Website shall be deemed as your consent to authorize us
to process your personal information in accordance with the terms of the Privacy
Policy.
<br><br>

5. This Privacy Policy is related to the collection and use of information and services
provided by the Website in connection with the services that are made available to
you through the Website, therefore you consent to the collection and use of
information as described in this Privacy Policy.
<br><br>

6. The Website and the services provided are not designed or directed to those who
are under the legal age in the State of Kuwait of 21 years or younger, and the
company does not knowingly collect or retain personal data for those under this
age.
<br><br>

7. Personal information we collect from you.
<br><br>

8. When you visit our website, use the services provided to you or the users in general,
or interact with any existing content for commercial, statistical or advertising
purposes, the Website will automatically collect information sent from your device, 
and your use of the Website and/or account registration is considered as your
consent for the company to reserve the right to preserve the following information:
<br><br>

<div class="pl-15">
  a) Information collected through your interaction with the Website; for example, your
device number and device type, geographic information, Internet connection
information, page view statistics, visits to and from the Website, returned links,
advertising data, and protocol addresses, Internet access information and any other
such information.
<br><br>

b) The personal information we will require in order to fulfill your request – may
require us to verify this information to ensure that you are not impersonating
another person or claiming a false identity. We may contact you directly through
your mobile phone or email to verify your identity - which includes Your name,
address, contact information (email address, address, phone number), date,
location, place of birth, and other personal information.
<br><br>

c) Information you may enter voluntarily while using any of our services.
d) Information provided in the course of dispute resolution, or correspondence with
the Company.
<br><br>

e) Any information related to your communication with our customer support team,
the results of your responses to an inquiry or survey, including your comments,
ratings, and related to your use and interaction with the Website or other users.
<br><br>

f) Why and how your personal information will be used:

When using and registering on our Website, you acknowledge and agree that the
Website and the company can use your personal information for the following
purposes:
<br><br>

g) Permit you to access our services, as well as in the event that the management
responsible for the management of the Website or if the customer service team
needs to communicate with you via e-mail or phone.
<br><br>

h) To prevent potential harm, or disclosure during an investigation with competent
authorities, when there would be a possibility of prohibited or illegal activities, or
when the terms and conditions of the Website have not complied with the law.
<br><br>

i) To evaluate and improve the quality of the Company's services, content, or
advertisements.
<br><br>

j) To communicate with you via emails, notifications, text messages, or phone calls for
inquiry of the Company’s services in regard to marketing purposes, updates, or
promotions or for any purpose as set forth in the Privacy Policy or any modified
versions thereof.
</div>
<br><br>
9. We will make efforts in maintaining the privacy of your information that is registered
with us, and the Company may be obligated to disclose your personal information to
governmental or private entities, or any third party if required to do so by a court
order or an order issued by a competent authority, and we may also disclose your
information personality according to:
<br><br>
<div class="pl-15">
  a) In order to report legal infringements.
<br><br>

b) To investigate a possible violation of the Website’s Terms and Conditions.
<br><br>

c) In the event of the sale of the Company, your personal information may be disclosed
to any potential buyer, for the purpose of proceeding with the sale or providing due
diligence for development on the part of the Company.
<br><br>

d) When you register with the Website, you agree to receive promotional emails,
messages about our services, and emails regarding changes and new features on the
Website from time to time.
<br><br>

e) We may use comments you have made regarding the Website for marketing
purposes. By writing such comments you expressly agree to allow us to use such
comments for general marketing purposes.
<br><br>
</div>

10. The Company shall retain personal data, as necessary, for the duration of the
relevant business relationship. We may also retain personal data for longer than the
life of the business relationship when needed in order to protect ourselves against
legal claims or to use it for analysis or historical record-keeping. If you ask us to
delete your personal data, the Company will make reasonable attempts to delete all
instances of the information in its entirety.
<br><br>

11. The Company may use cookies (small files placed on your device) when you visit
certain pages and sections that are very appropriate to provide appropriate speed,
browsing, and usage, as well as for other reasons.
<br><br>

12. The company does not disclose the tracking records that we use to collect any
personal information about you, but rather its goal is to help us follow and improve
your experience on the Website. It also contributes to our follow-up to the most
visited sections, so you always have the freedom to refuse the use of cookies if your
browser allows it, although doing so will prevent you from receiving the optimum
experience in your use of the Website.
<br><br>
13. External Websites
The Website may contain links to third-party websites. Therefore, the Company does not
control the privacy policy or the content of any of our business partners, advertisers, or
sponsors, whereby you are obligated to check the third-party privacy policies and terms of
use when visiting any other websites, knowing that the Company is not responsible for the 
above.
<br><br>

14. Consent to modifications to the Privacy Policy
By using the Website, you consent to our collection, use, and storage of your personal data
in the manner described in this Privacy Policy, and we reserve the right to amend this
Privacy Policy from time to time. You will be notified of any modifications with regards to
updating this Privacy Policy. In the event that we make material changes to this Privacy
Policy that increases our rights to use the personal data we have previously collected about
you, we will seek your consent either through a message to your registered email address or
by posting information about the changes in a prominent position on our website.
<br><br>

15. Your rights as a user
You have the right to request access to your personal data to review or amend it or even
request its deletion, and to request that any errors in it be corrected. In certain cases, you
can also request that we stop processing your personal data.
Also, if you need more information or have any questions about the privacy policy, please
feel free to contact our customer service team at 1882707 or through our email at
customercare@bleach-kw.com
             </div>
      </div>
    </div>
  </div>
   <div v-if="selectlan=='ARABIC'" dir="rtl">
    <div class="bcontainer">
      <div class="py-15">
            <div class="page-head arabic-font">
                  سياسة الخصوصية
              </div>
         
             <div class="mt-10 service-content arabic-font">
           تاريخ السريان: .. .. 2022
            <br>
            <br>
            <br>
            1.ندرك في شركة بليتش العالمية لمقاولات تنظيف المباني والمدن ذ.م.م اهمية خصوصيتك بالنسبة لك ومن هذا المنطلق فإننا نلتزم باحترام خصوصيتك وحماية بياناتك الشخصية، وأي معلومات يمكن من خلالها تحديد هويتك حيث تبين سياسة الخصوصية هذه كيفية تعاملنا مع بياناتك الشخصية وحمايتها فيما يتعلق بالأنشطة التجارية الخاصة بشركة بليتش العالمية لمقاولات تنظيف المباني والمدن ذ.م.م ومقرها الكائن في قبلة بنك برج ايليت، ، قطعة 11، شارع 55، دور رقم 15، وحدة رقم 1وهي شركة مسجلة وفق قوانين دولة الكويت تحت سجل تجاري رقم 362934 (ويشار اليها فيما بعد بـــــــــ "الشركة")
<br><br>
2. تقدم الشركة خدمات تنظييف المباني وطرق في دولة الكويت (ويشار اليها فيما بعد بـــــــــ "الخدمات") ويقوم العميل من خلال الموقع الإلكتروني www.bleach-kw.com (ويشار اليها فيما بعد بـــــــــ "الموقع") بطلب هذه الخدمات، تتعلق هذه السياسة بجمع واستخدام وتخزين ومشاركة وحماية معلوماتك الشخصية ، بغض النظر عن طريقة الوصول إلى هذه الخدمات ومن أي نوع من الاجهزة.
<br><br>

3. عند تسجيلك حساب، فإنك توافق على تقديم هذه المعلومات وتخزينها في خدماتنا الحاسوبية أو مع طرف ثالث
<br><br>
4. يتم اعتبار استمرار استخدامك للموقع بمثابة تفويض صريح منك لنا لمواصلة معالجة معلوماتك الشخصية وفقًا لشروط سياسة الخصوصية
<br><br>

5. تتعلق سياسة الخصوصية هذه بجمع المعلومات واستخدام الخدمات المقدمة من الموقع فيما يتعلق بالخدمات والتي يتم توفيرها لك من خلال الموقع، وعليه فإنك توافق على جمع واستخدام المعلومات كما هو موضح في سياسة الخصوصية هذه
<br><br>

6. الموقع والخدمات المقدمة غير مصممة أو موجهة لمن هم اقل من السن القانونية في دولة الكويت 21 عامًا أو أقل ولا تجمع الشركة أو تحتفظ عن قصد ببيانات شخصية لِمَن هم دون هذه السن.
<br><br>

7 . المعلومات الشخصية التي نجمعها منك
<br><br>

8. عند زيارتك إلى الموقع أو استخدام الخدمات التي يقدمها لك أو للمستخدمين بشكل عام أو تفاعلك مع أي محتوى موجود ولأغراض تجارية أو إحصائية أو إعلانية، سيقوم الموقع تلقائيا بجمع المعلومات المرسلة من جهازك، ، كما يعتبر استخدامك للموقع و/ أو التسجيل وانشاء حساب موافقة منك على حقنا في الإحتفاظ بالمعلومات التالية::
<br><br>

<div class="pr-15">
  1) المعلومات التي يتم جمعها من خلال تفاعلك مع الموقع وهم على سبيل المثال، رقم جهازك ونوع الجهاز، والمعلومات الجغرافية، ومعلومات اتصال الإنترنت، وإحصاءات عرض الصفحة، والزيارات من وإلى الموقع، والروابط المرتجعة، وبيانات الإعلان وعناوين البروتوكول، ومعلومات الوصول إلى الإنترنت وأي معلومات أخرى من هذا القبيل
<br><br>

2) . ، المعلومات الشخصية التي سنقتضيها حتى نتمكن من تلبية طلبك- قد يتطلب منا التحقق من هذه المعلومات للتأكد من أنك لا تنتحل شخصية شخص آخر أو تدعي هوية مزيفة، قد نتصل بك مباشرة من خلال هاتفك المحمول أو بريدك الإلكتروني للتحقق من هويتك -التي تتضمن اسمك وعنوانك ومعلومات الاتصال (عنوان البريد الإلكتروني والعنوان ورقم الهاتف) والتاريخ والمكان ومكان الميلاد وغيرها من المعلومات الشخصية.
<br><br>

3) المعلومات قد تدخلها طواعية أثناء استخدامك لأي من خدماتنا.
<br><br>
4) المعلومات المقدمة في سياق تسوية المنازعات، أو المراسلات مع الشركة
<br><br>

5) . أي معلومات تتعلق بتواصلك مع فريق دعم العملاء لدينا، ونتائج ردودك على استفسار أو استطلاع، وحتى تعليقاتك وتقييماتك وتعليقاتك الأخرى المتعلقة باستخدامك الموقع أو مستخدمين آخرين والتفاعل معهم..
<br><br>

6) . لماذا وكيف سيتم استخدام معلوماتك الشخصية:

عند استخدام والتسجيل في الموقع، فإنك تقر وتوافق على أن الموقع سيستخدم معلوماتك الشخصية للأغراض التالية
<br><br>

1)لتمكينك من الوصول إلى الخدمات التي نقدمها، وكذلك في حالة احتياج إدارة الموقع أو فريق خدمة العملاء للتواصل معك عبر البريد الإلكتروني أو الهاتف
<br><br>

2) لمنع الضرر المحتمل، أو الإفصاح أثناء التحقيق مع سلطات مختصة، أو عندما يكون هناك احتمال لأنشطة محظورة أو غير قانونية، أو عندما لا يتم الامتثال لشروط والأحكام الخاصة بالموقع.
<br><br>

3) لتقييم وتحسين جودة خدمات الشركة أو المحتوى أو الإعلانات
<br><br>

4) للتواصل معك عبر البريد الإلكتروني، أو الإشعارات، أو الرسائل النصية، أو المكالمات الهاتفية للاستعلام عن خدمات الشركة لأغراض التسويق، أو التحديثات أو العروض الترويجية أو لأي غرض على النحو المنصوص عليه في سياسة الخصوصية أو أي إصدارات معدلة لها
</div>
<br><br>
9) . سنبذل جهودًا للحفاظ على خصوصية معلوماتك المسجلة لدينا، وقد تكون الشركة ملزمة بالكشف عن معلوماتك الشخصية إلى جهات حكومية أو خاصة، أو أي طرف ثالث، إذا طُلب منها القيام بذلك بموجب حكم قضائي أو أمر صادر من سلطة مختصة، وقد نكشف أيضًا عن معلوماتك الشخصية وفقًا للاتي
<br><br>
<div class="pr-15">
  أ. بهدف الإبلاغ عن تعديات قانونية،
<br><br>

ب. للتحقيق في انتهاك محتمل للشروط وأحكام الخاصة بالموقع،
<br><br>

ت. في حالة بيع الشركة، قد يتم الكشف عن معلوماتك الشخصية لأي مشتري محتمل، وذلك لغرض متابعة البيع أو تقديم العناية الواجبة للتطوير من جانب الشركة
<br><br>

ث. عند تسجيلك في الموقع، فإنك توافق صراحة على تلقي رسائل بريد إلكتروني الترويجية ورسائل حول خدماتنا ورسائل البريد الإلكتروني المتعلقة بالتغييرات والميزات الجديدة على الموقع من وقت لآخر
<br><br>

ج. يجوز لنا استخدام التعليقات التي أدليت بها بخصوص الموقع لأغراض التسويق، من خلال كتابتك لهذه التعليقات فإنك توافق صراحة على السماح لنا باستخدام هذه التعليقات لأغراض التسويق بشكل عام
<br><br>
</div>

10. تحتفظ الشركة بالبيانات الشخصية، حسب الضرورة، طوال مدة علاقة الأعمال ذات الصلة. ويمكننا أيضًا الاحتفاظ بالبيانات الشخصية لمدة أطول من فترة علاقة الأعمال عند الحاجة من أجل حماية أنفسنا ضد المطالبات القانونية أو لاستخدامها بغرض التحليل أو حفظ السجلات التاريخية. فإذا طلبت منا حذف البيانات الشخصية الخاصة بك، ستقوم الشركة بمحاولات معقولة لحذف جميع حالات المعلومات بأكملها.
<br><br>

11. قد تستخدم الشركة سجلات التتبع (ملفات صغيرة موضوعة على جهازك) وهي ما تعرف بالكوكيز، وذلك عندما تزور صفحات وأقسام معينة للغاية من تقديم السرعة المناسبة والتصفح والاستخدام وكذلك لأسباب أخرى
<br><br>

12.لا تكشف الشركة عن سجلات التتبع التي نستخدمها عن أي معلومات شخصية تخصك ، بل هدفها هو مساعدتنا على متابعة تجربتك وتحسينها في الموقع، كما تساهم في متابعتنا للأقسام الأكثر زيارة، ولذلك لديك دائماً حرية رفض استخدام ملفات تعريف الارتباط إذا كان المستعرض الخاص بك يسمح بذلك، على الرغم من أن القيام بذلك سيمنعك من تلقي التجربة المثلى في إستخدامك للموقع.
<br><br>
13. . المواقع الخارجية
<br><br>
قد يحتوي الموقع على روابط لمواقع أطراف ثالثة؛ لذلك، لا تتحكم الشركة في سياسة الخصوصية أو محتوى أي من شركائنا في العمل أو المعلنين أو مقدم رعاية، لذلك، أنت ملزم بالتحقق من السياسة الخصوصية الخاصة بأطراف ثالثة وشروط الاستخدام عند زيارة أي مواقع أخرى، على العلم بان الشركة ليست مسؤولة عن المذكور أعلاه.
<br><br>

14. الموافقة على التعديلات في سياسة الخصوصية
<br><br>
باستخدامك للمواقع، فإنك توافق على جمعنا لبياناتك الشخصية واستخدامها وتخزينها بالأسلوب المبين في سياسة الخصوصية هذه كما نحتفظ بالحق في إجراء تغييرات على سياسة الخصوصية هذه من وقتٍ لآخر. حيث سيتم اخطارك بأي من هذه التعديلات من خلال تحديث سياسة الخصوصية هذه. وفي حال قيامنا بإدخال تعديلات جوهرية على سياسة الخصوصية هذه مما من شأنه زيادة حقوقنا في استخدام البيانات الشخصية التي جمعناها عنك سابقًا، سنسعى للحصول على موافقتك إما من خلال رسالة على عنوان بريدك الإلكتروني المُسجل أو عن طريق نشر المعلومات الخاصة بالتغييرات في موضع بارز على موقعنا
<br><br>

15. حقوقك كمستخدم
<br><br>
يحق لك، طلب الوصول إلى البيانات الشخصية الخاصة بك لمراجعتها أو تعديلها أو حتى طلب حذفها وطلب تصحيح أي أخطاء فيها. وفي حالات معينة، يمكنك أيضًا طلب وقف معالجة بياناتك الشخصية.

كما يحق لك إذا كنت بحاجة إلى مزيد من المعلومات أو لديك أية أسئلة عن سياسة الخصوصية، لا تتردد في الاتصال بفريق خدمة العملاء 1882707 او من خلال بريدنا الالكتروني kw.com-customercare@bleach
             </div>
      </div>
    </div>
  </div>
 </div>
</template>

<script>
 import 'vue-collapsible-component/lib/vue-collapsible.css';
 // import Collapsible from 'vue-collapsible-component';
//import Collapse from 'vue-collapse'
//import privacyexpand from '../../components/privacyexpand.vue'
//import pageheading from "../../components/pageheading.vue";
export default {
  components: {  },
  data(){
      return{
          btnselected:'',
          selectlan:localStorage.getItem('bleach-lang')
      };
  }
};
</script>

<style scoped>
/* .Collapsible{
  margin-top:10px !important;
  padding-bottom: 8px !important;
  border-bottom: 1px solid #e9ecef !important;
} */
</style>