<template>
  <div>
         <router-view/>
         <div>
           <v-dialog  v-model="cart_dialog" scrollable width="1112">
      <div class="ad-model">
        <div >
            <div class="modal-side" >
                      <svg width="110" height="28" viewBox="0 0 110 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M98.6775 5.75269H103.58V27.035H98.6775V18.8001H92.5311V27.035H87.629V5.75269H92.5311V13.898H98.6775V5.75269Z" fill="white"/>
                          <path d="M15.8422 20.8029C15.8422 24.543 13.563 27.1809 8.35826 27.1809H0V5.7229H8.04814C12.2964 5.7229 14.5756 8.08429 14.5756 11.6376C14.5756 12.8631 14.1272 14.6416 12.7485 15.7438C15.0239 16.7265 15.8422 18.8413 15.8422 20.8029ZM5.3729 10.9389V13.8831H8.07429C9.28488 13.8831 9.84533 13.2405 9.84533 12.411C9.84533 11.5815 9.28114 10.9389 8.07429 10.9389H5.3729V10.9389ZM10.9999 20.6161C10.9999 19.6035 10.2414 18.8077 8.86267 18.8077H5.3729V22.4245H8.86267C10.2414 22.4245 10.9999 21.595 10.9999 20.6161Z" fill="white"/>
                          <path d="M30.6981 27.0356H18.0243V5.72339H22.9264V22.1335H30.6981V27.0356Z" fill="white"/>
                          <path d="M46.3235 10.6511H37.5692V13.8681H44.6721V18.7702H37.5692V22.1329H46.3235V27.035H32.6671V5.77884L46.3235 5.75269V10.6511Z" fill="white"/>
                          <path d="M77.8771 27.0052C76.5059 27.0052 75.1645 26.7511 73.8605 26.2355C72.5566 25.7236 71.3796 24.9465 70.3371 23.9003C69.3321 22.8765 68.5624 21.722 68.0318 20.4367C67.5012 19.1513 67.236 17.795 67.236 16.364C67.236 14.9143 67.5012 13.5468 68.0318 12.2615C68.5624 10.9762 69.3321 9.83284 70.3371 8.82402C71.3796 7.78158 72.5603 6.99694 73.8755 6.47385C75.1907 5.95075 76.5283 5.68921 77.8921 5.68921C79.2559 5.68921 80.5897 5.95075 81.8937 6.47385C83.1977 6.99694 84.371 7.77784 85.4171 8.82402L81.9647 12.2727C81.4043 11.7122 80.7654 11.2938 80.0517 11.0135C79.3381 10.7333 78.6132 10.5913 77.8771 10.5913C77.1411 10.5913 76.4237 10.7333 75.7175 11.0135C75.0113 11.2938 74.3799 11.7122 73.8194 12.2727C73.2777 12.8331 72.8629 13.4609 72.5715 14.1558C72.2801 14.8508 72.1381 15.5869 72.1381 16.3603C72.1381 17.1337 72.2838 17.8623 72.5715 18.5498C72.8629 19.2373 73.2777 19.8613 73.8194 20.4217C74.3799 20.9822 75.0113 21.4044 75.7175 21.6846C76.4237 21.9648 77.1448 22.1031 77.8771 22.1031C78.6095 22.1031 79.3381 21.9648 80.0517 21.6846C80.7654 21.4044 81.4043 20.9859 81.9647 20.4217L85.4171 23.9003C84.3747 24.9427 83.2052 25.7236 81.9087 26.2355C80.6122 26.7474 79.2708 27.0052 77.8771 27.0052Z" fill="white"/>
                          <path d="M60.6862 6.73193L60.3686 5.74927H55.728L55.3917 6.73193L48.4757 27.0316H53.6655L54.5361 24.4498H61.2354L62.0761 27.0316H67.236L60.6862 6.73193ZM60.4919 17.8663C60.447 18.0382 60.3835 18.2026 60.3088 18.3558C59.9352 19.118 59.214 19.6822 58.3659 19.8354C56.6434 20.1455 55.1377 18.8228 55.1377 17.1564C55.1377 16.6445 55.2871 16.1401 55.5823 15.6581C55.8513 15.221 56.2586 14.7502 56.6882 14.2495C57.0731 13.8049 57.4766 13.3341 57.8615 12.811C58.2463 13.3304 58.6498 13.7974 59.031 14.242C59.4718 14.7539 59.8754 15.2247 60.1407 15.6544C60.5741 16.3606 60.6936 17.1116 60.4919 17.8663Z" fill="white"/>
                          <g clip-path="url(#clip0_1683_1019)">
                          <path d="M106.608 2.96942H107.233C107.478 2.96942 107.66 2.91407 107.778 2.79547C107.905 2.67687 107.96 2.51873 107.96 2.32106C107.96 2.12339 107.905 1.95734 107.786 1.84665C107.667 1.73595 107.486 1.6806 107.225 1.6806H106.6V2.96942H106.608ZM107.944 4.51916L107.177 3.22244H106.608V4.51916H106.3V1.41968H107.233C107.58 1.41968 107.841 1.49875 108.023 1.66479C108.205 1.83083 108.292 2.04432 108.292 2.31315C108.292 2.53454 108.229 2.73221 108.102 2.89035C107.976 3.04849 107.786 3.15128 107.533 3.19872L108.332 4.51916H107.96H107.944Z" fill="white"/>
                          <path d="M107.185 5.78421C105.635 5.78421 104.37 4.51912 104.37 2.96938C104.37 1.41964 105.635 0.154541 107.185 0.154541C108.735 0.154541 110 1.41964 110 2.96938C110 4.51912 108.735 5.78421 107.185 5.78421ZM107.185 0.360119C105.746 0.360119 104.576 1.53033 104.576 2.96938C104.576 4.40842 105.746 5.57864 107.177 5.57864C108.608 5.57864 109.786 4.40842 109.786 2.96938C109.786 1.53033 108.624 0.360119 107.185 0.360119Z" fill="white"/>
                          </g>
                          <defs>
                          <clipPath id="clip0_1683_1019">
                          <rect width="5.62967" height="5.62967" fill="white" transform="translate(104.37 0.154541)"/>
                          </clipPath>
                          </defs>
                          </svg>

                  <div class="model-side-text mt-10 ">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, 
                  </div>
                  <div class="text-center py-20">
                    <svg width="77" height="73" viewBox="0 0 77 73" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.0105 17.6445H66.4141C67.476 17.6446 68.526 17.8671 69.4966 18.2978C70.4671 18.7285 71.3367 19.3578 72.0492 20.1451C72.7616 20.9324 73.3012 21.8602 73.6332 22.8688C73.9651 23.8775 74.082 24.9444 73.9764 26.001L71.6963 48.8017C71.5088 50.6771 70.6311 52.4159 69.2337 53.6806C67.8363 54.9453 66.0188 55.6456 64.1341 55.6457H27.8429C26.0853 55.6464 24.3817 55.0379 23.0223 53.9237C21.6628 52.8096 20.7316 51.2587 20.3871 49.5351L14.0105 17.6445Z" stroke="#E0F6F9" stroke-width="4.09105" stroke-linejoin="round"/>
                      <path d="M55.8117 70.8462H63.4119M14.0103 17.6446L10.9322 5.32078C10.7264 4.49902 10.2518 3.76961 9.58399 3.24841C8.91614 2.72721 8.09329 2.44411 7.24613 2.44409H2.60999L14.0103 17.6446ZM25.4107 70.8462H33.0109H25.4107Z" stroke="#E0F6F9" stroke-width="4.09105" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>               

              </div>   
        </div>
        <div class="full-flex pa-9" style="background:#fff">
        
            <div class="sub-heading mt-15 text-center" style="text-transform: none">
                     Sample Text
                    </div>
                  
                    <v-card flat color="p-0 mt-5">
        
              <div class="">
                <div class="row mt-5">
                <div class="col-md-6">
                  <div class="mpx-10">
                     <v-select
                        class="mt-5" 
                          :items="items"
                          
                          append-icon="mdi-chevron-down"
                        >
                        <template  v-slot:label>
                            <span class="add-text">Location Type</span>
                          </template>
                        </v-select>
                  </div>
                       
                      </div>
                      <div class="col-md-6">
                        <div class="mpx-10">
                             <v-select
                        class="mt-5" 
                          :items="items"
                         
                          append-icon="mdi-chevron-down"
                        >
                           <template  v-slot:label>
                            <span class="add-text">Area Type</span>
                          </template>
                        </v-select>
                        </div>
                     
                      </div>
                         <div class="col-md-6">
                           <div class="mpx-10">
                              <v-select
                        class="mt-5" 
                          :items="items"
                         
                          append-icon="mdi-chevron-down"
                        >
                           <template  v-slot:label>
                            <span class="add-text">Size</span>
                          </template>
                        </v-select>
                           </div>
                       
                      </div>
                      <div class="col-md-6">
                        <div class="mpx-10">
                           <v-select
                        class="mt-5" 
                          :items="items"
                         
                          append-icon="mdi-chevron-down"
                        >
                           <template  v-slot:label>
                            <span class="add-text">Wall Type</span>
                          </template>
                        </v-select>
                        </div>
                       
                      </div>
                      <div class="col-md-6">
                        <div class="mpx-10">
                          <v-select
                        class="mt-5" 
                          :items="items"
                         
                          append-icon="mdi-chevron-down"
                        >
                           <template  v-slot:label>
                            <span class="add-text">Floor Type</span>
                          </template>
                        </v-select>
                        </div>
                        
                      </div>
                      <div class="col-md-6">
                        <div class="mpx-10">
                          <v-select
                        class="mt-5" 
                          :items="items"
                         
                          append-icon="mdi-chevron-down"
                        >
                           <template  v-slot:label>
                            <span class="add-text">Celling Type</span>
                          </template>
                        </v-select>
                        </div>
                      </div>

                      <div class="col-md-6">
                       <div class="mpx-10">
                          <v-text-field   class="mt-5" type="number">
                           <template  v-slot:label>
                            <span class="add-text">No of Rooms</span>
                          </template>
                        </v-text-field>
                       </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mpx-10">
                          <v-text-field   class="mt-5" >
                           <template  v-slot:label>
                            <span class="add-text">No of bathrooms</span>
                          </template>
                        </v-text-field>
                        </div>
                      </div>
                       <div class="col-md-6">
                       <div class="mpx-10">
                          <v-text-field   class="mt-5" >
                           <template  v-slot:label>
                            <span class="add-text">No of Windows</span>
                          </template>
                        </v-text-field>
                       </div>
                      </div>
                       <div class="col-md-6">
                       <div class="mpx-10">
                       
                           <v-radio-group
                           style="margin-top:-1px"
                          row
                        >
                         <template  v-slot:label>
                            <span class="add-text">Do you want to clean your kitchen?</span>
                          </template>
                          <v-radio
                            color="primary"
                            value="yes"
                          >
                           <template  v-slot:label>
                            <span class="add-text">Yes</span>
                          </template>
                          </v-radio>
                          <v-radio
                            color="primary"
                            value="no"
                          >
                           <template  v-slot:label>
                            <span class="add-text">No</span>
                          </template>
                          </v-radio>
                           </v-radio-group>
                       </div>
                      </div>
              </div>
              </div>
              <div class="row">
                 <div class="col-md-3 offset-md-3">
                   <button
                  @click="closCartDialog"
                    type="button"
                    class="align-self-end bl-cancel-btn w-100"
                    style="margin-top: 20px"
                  >
                    Cancel
                  </button>
                </div>
                <div class="col-md-3">
                   <button
                  
                    type="button"
                    class="align-self-end bl-primary-btn w-100"
                    style="margin-top: 20px"
                  >
                    Add  To Cart
                  </button>
                </div>
              </div>
          
        </v-card>
                  
        </div>
      </div>
      </v-dialog>
         </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  mounted(){
      this.$store.commit("set_cartdialog", false);
  },
  methods:{
    closCartDialog(){
      this.$store.commit("set_cartdialog", false);

    }
  },
  computed: {
    ...mapState({
      cart_dialog: (state) => state.cart_dialog,
    }),
  },
}
</script>

<style lang="scss">
.v-select__selection{
      color: #6d6d6e !important;
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: 400;
}
.inactive-slot {
  cursor: not-allowed;
  background: #f1f3f5 !important;
}
.v-date-picker-header {
  padding: 4px 26px !important;
}
.v-picker__body {
  padding-top: 10px !important;
  border-top: 1.7px solid #289bac !important;
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: #c4c4c4 !important;
  // border-bottom: 1px solid #c4c4c4 !important;
}
.v-otp-input input {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 23.2034px !important;
  line-height: 35px !important;
  color: #898e93 !important;
}

.v-text-field {
  padding-top: 0px !important;
  margin-top: 4px;
}
.theme--light.v-input input,
.theme--light.v-input textarea {
  color: #6d6d6e !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400;
}
.v-picker--full-width {
  border: 1px solid #e9ecef !important;
  border-radius: 5px !important;
}
.v-date-picker-table {
  padding: 0 25px !important;
}
.v-picker__title {
  padding: 15px 35px 15px 35px !important;
}
.v-picker__title__btn:not(.v-picker__title__btn--active) {
  opacity: 1 !important;
  cursor: pointer;
}
</style>
