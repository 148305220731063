<template>
  <div class="pb-15">
         
         <div class="">

             <div class="topnav mt-4">
                <a  @click="$router.push('profile')">My Profile</a>
                <a  @click="$router.push('orders')">My Orders</a>
                <a class="active" >My Evaluation</a>
                <a @click="$router.push('address')">My Address</a>
            </div>
           <div v-if="evalutions.length != 0">
             <div class="order-card-wrapper mt-6" v-for="(item,index) in evalutions" :key="index">
                <div class="order-card-inner">
                    <div class="d-flex">
                        <div class="full-flex">
                            <div class="order-number">
                                Order Number- {{item.evaluation.booking_evaluation[0].booking_id    }}
                            </div>
                            <div class="order-date">
                                Order Placed on {{item.evaluation.booking_evaluation[0].booking_date}}
                            </div>
                        </div>
                        <!-- <div class="order-price ma">
                            KD 40.000
                        </div> -->
                        <div class="ma" @click="downloadPdf(item.evaluation.booking_evaluation[0].booking_id)">
                            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 16H0V14.4H12V16ZM6 12.8L0.857143 8L2.06571 6.872L5.14286 9.736V0H6.85714V9.736L9.93429 6.872L11.1429 8L6 12.8Z" fill="#2B9FAD"/>
</svg>
&nbsp;
<span class="download-text"> Recipt</span>


                        </div>
                    </div>
                </div>
                <div class="order-card-inner-2">
                    <div class="d-flex">
                        <div class="full-flex order-address" >
                             {{item.address.apartment}},
                        {{item.address.floor}},
                        {{item.address.building}},
                        {{item.address.block}},
                        <br>
                        {{item.address.street}},
                        {{item.address.avenue}},
                        {{item.address.area.name}},
                        {{item.address.governorate.name}}
                        </div>
                        <div class="order-address">
                            Orders Status - <span class="order-status">{{item.status}}</span>
                        </div>
                    </div>
                      <hr class="order-hr" />
                     <div class="d-flex">
                         <div>
                             <img src="../../assets/ev.png" alt="">
                         </div>
                         <div class="order-address w500 ma ml-4">
                             {{item.evaluator.name}}   &nbsp;&nbsp;<span class="order-address"> Visit on {{item.proposed_time}} </span>
                         </div>
                        
                     </div>
                </div>
            </div>
           </div>
            <div v-else>
                        <div class="text-center emptycart-icon">
                  <svg width="236" height="200" viewBox="0 0 236 200" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M219.985 157.712C221.371 157.024 222.192 156.182 222.192 155.279C222.192 152.959 216.853 151.07 210.263 151.07C207.08 151.07 204.195 151.511 202.05 152.23C180.645 147.148 150.893 143.996 118 143.996C52.83 143.996 0 156.357 0 171.592C0 179.209 13.1921 186.098 34.515 191.098C33.7759 191.662 33.3447 192.299 33.3447 192.977C33.3447 195.297 38.2417 197.186 44.2782 197.186C48.6208 197.186 52.3577 196.21 54.1235 194.794C72.5309 197.576 94.4595 199.188 118 199.188C183.17 199.188 236 186.827 236 171.592C236 166.531 230.169 161.788 219.985 157.702V157.712Z" fill="#E4E4E4"/>
<path d="M30.9712 136.538C35.6885 136.538 39.5127 132.714 39.5127 127.996C39.5127 123.279 35.6885 119.455 30.9712 119.455C26.2538 119.455 22.4297 123.279 22.4297 127.996C22.4297 132.714 26.2538 136.538 30.9712 136.538Z" fill="#DCEBCB"/>
<path d="M31.8542 127.996H30.0986V149.514H31.8542V127.996Z" fill="#DCEBCB"/>
<path d="M191.203 100.076C198.24 100.076 203.944 94.3719 203.944 87.3356C203.944 80.2993 198.24 74.5952 191.203 74.5952C184.167 74.5952 178.463 80.2993 178.463 87.3356C178.463 94.3719 184.167 100.076 191.203 100.076Z" fill="#DCEBCB"/>
<path d="M192.507 87.3357H189.889V119.448H192.507V87.3357Z" fill="#DCEBCB"/>
<path d="M217.01 140.962C221.064 140.962 224.35 137.675 224.35 133.621C224.35 129.568 221.064 126.281 217.01 126.281C212.956 126.281 209.669 129.568 209.669 133.621C209.669 137.675 212.956 140.962 217.01 140.962Z" fill="#DCEBCB"/>
<path d="M217.759 133.622H216.25V152.111H217.759V133.622Z" fill="#DCEBCB"/>
<path d="M219.985 154.95C221.371 154.263 222.192 153.421 222.192 152.517C222.192 150.197 216.853 148.308 210.263 148.308C207.08 148.308 204.195 148.75 202.05 149.468C180.645 144.386 150.893 141.235 118 141.235C52.83 141.235 0 153.595 0 168.83C0 176.448 13.1921 183.337 34.515 188.336C33.7759 188.901 33.3447 189.537 33.3447 190.215C33.3447 192.535 38.2417 194.424 44.2782 194.424C48.6208 194.424 52.3577 193.449 54.1235 192.032C72.5309 194.814 94.4595 196.426 118 196.426C183.17 196.426 236 184.065 236 168.83C236 163.769 230.169 159.026 219.985 154.94V154.95Z" fill="#F0F0F0"/>
<path d="M151.011 23.074H168.304C172.34 23.074 175.611 26.7986 175.611 31.3948V140.909C175.611 145.505 172.34 149.23 168.304 149.23H74.7495C70.7132 149.23 67.4424 145.505 67.4424 140.909V31.3948C67.4424 26.7986 70.7132 23.074 74.7495 23.074H94.6103" fill="white"/>
<path d="M151.011 23.074H168.304C172.34 23.074 175.611 26.7986 175.611 31.3948V140.909C175.611 145.505 172.34 149.23 168.304 149.23H74.7495C70.7132 149.23 67.4424 145.505 67.4424 140.909V31.3948C67.4424 26.7986 70.7132 23.074 74.7495 23.074H94.6103" stroke="#C4C4C4" stroke-width="6" stroke-miterlimit="10"/>
<path d="M145.677 12.5993H131.449C131.531 12.0723 131.581 11.53 131.581 10.9777C131.581 5.78349 127.809 1.57239 123.155 1.57239C118.502 1.57239 114.729 5.78349 114.729 10.9777C114.729 11.53 114.779 12.0723 114.861 12.5993H100.633C97.1832 12.5993 94.3867 15.7209 94.3867 19.5722V29.7883H151.924V19.5722C151.924 15.7209 149.127 12.5993 145.677 12.5993Z" fill="#C4C4C4" stroke="#C4C4C4" stroke-width="3" stroke-miterlimit="10"/>
<path d="M93.8119 105.142C99.958 105.142 104.94 100.162 104.94 94.0189C104.94 87.8758 99.958 82.8958 93.8119 82.8958C87.6659 82.8958 82.6836 87.8758 82.6836 94.0189C82.6836 100.162 87.6659 105.142 93.8119 105.142Z" stroke="#C4C4C4" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M120.14 49.9061H162.65" stroke="#C4C4C4" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M120.14 63.5277H162.65" stroke="#C4C4C4" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M120.14 87.2083H162.65" stroke="#C4C4C4" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M120.14 100.83H162.65" stroke="#C4C4C4" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M79.5757 52.5009L89.4112 62.4788L106.507 45.5533" stroke="#C4C4C4" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="175.611" cy="138.755" r="28.4659" fill="#D9D9D9"/>
<path d="M171.384 138.914L162.101 129.657C161.503 129.039 161.172 128.212 161.18 127.354C161.187 126.496 161.533 125.675 162.141 125.068C162.75 124.461 163.573 124.116 164.434 124.109C165.294 124.101 166.123 124.431 166.743 125.028L176.025 134.285L185.308 125.028C185.611 124.715 185.973 124.466 186.374 124.294C186.774 124.123 187.205 124.032 187.641 124.028C188.077 124.025 188.509 124.107 188.912 124.272C189.316 124.437 189.682 124.68 189.99 124.987C190.299 125.295 190.542 125.66 190.707 126.062C190.872 126.465 190.955 126.896 190.952 127.33C190.948 127.765 190.857 128.195 190.685 128.594C190.513 128.993 190.263 129.355 189.95 129.657L180.667 138.914L189.95 148.172C190.263 148.474 190.513 148.835 190.685 149.235C190.857 149.634 190.948 150.064 190.952 150.498C190.955 150.933 190.872 151.364 190.707 151.766C190.542 152.169 190.299 152.534 189.99 152.842C189.682 153.149 189.316 153.392 188.912 153.557C188.509 153.721 188.077 153.804 187.641 153.8C187.205 153.796 186.774 153.706 186.374 153.535C185.973 153.363 185.611 153.114 185.308 152.801L176.025 143.543L166.743 152.801C166.123 153.397 165.294 153.727 164.434 153.72C163.573 153.712 162.75 153.368 162.141 152.761C161.533 152.154 161.187 151.333 161.18 150.475C161.172 149.616 161.503 148.79 162.101 148.172L171.384 138.914Z" fill="white"/>
<path d="M123.155 14.3999C125.315 14.3999 127.066 12.6499 127.066 10.491C127.066 8.33222 125.315 6.58215 123.155 6.58215C120.996 6.58215 119.245 8.33222 119.245 10.491C119.245 12.6499 120.996 14.3999 123.155 14.3999Z" stroke="white" stroke-width="3" stroke-miterlimit="10"/>
</svg>

                </div>
                <div class="cart-empty-head-text mt-6">
                    No Evaluation Found
                </div>
                <div class="cart-empty-subcontent mb-15">
                   Looks like you haven’e made <br>
your evaluation yet
                </div>
            </div>
           

                
         </div>
  </div>
</template>

<script>
import service from '../../services/service'
import moment from "moment";
//import utils from '../../utils/utils';
export default {
    data(){
        return{
            empty:false,
            evalutions:[],
            customer:null
        }
    },
    mounted(){
        this.getEvalutions();
        this.getCustomerData()
    },
    methods:{
        async getCustomerData(){
            let result = await service._get('api/customer-details/'+localStorage.getItem('bleach-tk')+'/');
            console.log(result)
            if(result.status ==200){
                this.customer = result.data.customer_data
            }
        },
        async getEvalutions(){
            let result = await service._get('api/customer-evaluations/'+localStorage.getItem('bleach-tk')+'/');
            console.log(result)
            if(result.status ==200){
                this.evalutions = result.data.data
            }
        },
        formatDate(d){
            var a = new Date(d)
            return moment(a).format('DD-MM-YYYY HH:mm:ss')
        },
        async downloadPdf(id){
      // let result = await service._get('customer/customer-evaluation-booking/download/'+this.eval_data.booking_id);
      // console.log(result);
      window.location.href = service.api+'customer/customer-evaluation-booking/download/'+id
    },
    }
}
</script>

<style>
.download-text{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 21px;
text-align: right;
text-transform: capitalize;
color: #2B9FAD;
cursor: pointer;
}
</style>    