<template>
  <div>
    <div class="gray-bg-2">
      <div class="bcontainer">
        <div class="py-15">
            <div class="text-center success-head">
                You have successfully book an evaluator visit
            </div>
            <div class="mpx-20 mt-10">
                <div class="mpx-20">
                    <div style="background:white">
                        <div class="el-success-head">
                            Booking Summery
                        </div>
                        <div class="pa-6">
                            <div class="d-flex">
                                <div class="service-content full-flex text-right pr-10">
                                    Booking ID
                                    <br> 
                                    Visit Day
                                    <br>
                                     Booking ID
                                    <br> 
                                    Visit Day
                                </div>
                                <div class="service-content">
                                    :
                                    <br>
                                    :
                                    <br>
                                    :
                                    <br>
                                    :
                                </div>
                                <div class="service-content full-flex pl-10">
                                    00000
                                    <br>
                                    0000
                                    <br>
                                    00000
                                    <br>
                                    0000
                                </div>
                            </div>
                            <div class="text-center success-in mt-10">
                                Instruction for evaluator
                            </div>
                            <div class="service-content text-center mpx-20">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam Lorem ipsum dolor sit amet, consectetur adipiscing 
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center mt-10">
                <svg width="22" height="30"  viewBox="0 0 22 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22 30H0V27H22V30ZM11 24L1.57143 15L3.78714 12.885L9.42857 18.255V0H12.5714V18.255L18.2129 12.885L20.4286 15L11 24Z" fill="#2B9FAD">
   
</path>
</svg>

            </div>
            <div class="text-center">
                 <button
                 
                  type="button"
                  class="align-self-end bl-primary-btn "
                  style="margin-top: 10px"
                >
                  Download Recipt
                </button>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
    
  },
  data() {
    return {
      checked: false,
    };
  },
  methods: {},
};
</script>
<style lang="scss">
@keyframes bluebtn {
  to {
    fill: #2B9FAD;
  }
}
#right-eye {
  animation: bluebtn 1s infinite ;
}
</style>
