<template>
<div >
    <div v-if="selectlan =='EN'"> 
      <div class="gray-bg-2">
      <div class="bcontainer">
         <div class="row py-15">
             <div class="col-md-4 col-lg-3">
                 <ul class="service-ui show-head">
                     <li @click="$router.push('industrial')" >
                         <span class="dot-li"></span>
                       Industrial Sector
                    </li>
                    <li @click="$router.push('corporate')" >
                         <span class="dot-li"></span>
                        Corporate Offices
                    </li>
                    <li class="service-ui-active"  >
                         <div class="dot-li-active"></div>
                        Educational Facilities
                    </li>
                     <li @click="$router.push('health')" >
                         <span class="dot-li"></span>
                        Health Care Sector
                    </li>
                    <li @click="$router.push('retail')" >
                         <span class="dot-li"></span>
                        Retail Sector
                    </li>
                    <li @click="$router.push('sports')" >
                         <span class="dot-li"></span>
                        Sports & Fitness
                    </li>
                    <li @click="$router.push('foods')" >
                         <span class="dot-li"></span>
                        Foods & Beverages
                    </li>
                     <li @click="$router.push('hotels')" >
                         <span class="dot-li"></span>
                        Hotels & Luxury
                    </li>
                 </ul>
                 <commenu :name="'EDUCATIONAL FACILITIES MAINTENANCE'"/>
                  <div class="mt-5">
                    <div class="recommended-head">
                        RECOMMENDED SERVICES
                    </div>
                   <div class="rec-item-name mt-3"  @click="goto('/service/detailedcleaning/deepcleaning')">
                        <img src="../../assets/icons/recommend/deep.png" alt="" class="ml-2 mr-2">  Deep Cleaning
                    </div>
                    <div class="rec-item-name mt-3" @click="goto('/service/detailedcleaning/generalcleaning')">
                        <img src="../../assets/icons/recommend/general.png" alt="" class="ml-2 mr-2">  General Cleaning
                    </div>
                    <div class="rec-item-name mt-3" @click="goto('/service/pestcontrol/pestcontrol')">
                        <img src="../../assets/icons/recommend/inf.png" alt="" class="ml-2 mr-2">  Pest Control
                    </div>
                 </div>
             </div>
             <div class="col-md-8 col-lg-9">
                 <div class="cleaning-head show-head">EDUCATIONAL FACILITIES <span class="w400">MAINTENANCE</span></div>
                   <div class="sub-content mt-2">
              BLEACH® provides 100% Green Cleaning Services to schools, colleges, and universities. We
are committed to providing tailor-made cleaning services to educational facilities to ensure
the hygiene and health of teachers as well as students. Our international benchmarking
systems, high-quality cleaning products, and trained staff ensure educational settings
receive the best care and attention.

                 </div>
                 <div class="bold-heading mt-10">
                     DID YOU KNOW ?
                 </div>
                 <div class="mt-3 sub-content">
                     There are currently 591,359 students enrolled in Kuwait's schools which makes up
approximately 20% of the entire population. Unfortunately, half of them are being exposed
to polluted indoor air, including chemical fumes, pesticides, molds, and other toxins at
schools.

                 </div>
                  <div class="bold-heading mt-10">
                     BENEFITS Of CLEAN EDUCATIONAL FACILITIES
                 </div>
                 <div class="mt-5 sub-content">
                     “Green cleaning products and practices minimize troubling exposures and reduce
respiratory problems among the workforce. Green cleaning is truly a winning strategy for
promoting worker and occupant health,” said Darryl Alexander, Director of Health & Safety
at the American Federation of Teachers.
BLEACH® has designed a Green Cleaning Program that can help reduce these harmful
exposures.
                     <!-- <div class="row">
                         <div class="col-md-6 col-lg-4 mt-5">
                             <img src="../../assets/icons/gray-arrow.png" class="mr-4" alt=""> <span class="sub-content">  Great impression to clients</span>
                         </div>
                         <div class="col-md-6 col-lg-4 mt-5">
                             <img src="../../assets/icons/gray-arrow.png" class="mr-4" alt=""> <span class="sub-content">   Increase employees productivity  </span>
                         </div>
                         <div class="col-md-6 col-lg-4 mt-5">
                             <img src="../../assets/icons/gray-arrow.png" class="mr-4" alt=""> <span class="sub-content">  Happy Employee</span>
                         </div>
                          <div class="col-md-6 col-lg-4 mt-5">
                             <img src="../../assets/icons/gray-arrow.png" class="mr-4" alt=""> <span class="sub-content">  Safeguarding employees’ health </span>
                         </div>
                         <div class="col-md-6 col-lg-4 mt-5">
                             <img src="../../assets/icons/gray-arrow.png" class="mr-4" alt=""> <span class="sub-content">  Reduce hazards</span>
                         </div>
                     </div> -->
                 </div>
             </div>
         </div>
      </div>
  </div>
  
</div>
   <div v-if="selectlan =='ARABIC'"> 
      <div class="gray-bg-2">
      <div class="bcontainer">
         <div class="row py-15">
             <div class="col-md-4 col-lg-3">
                 <ul class="service-ui-ar show-head">
                     <li class="arabic-font" @click="$router.push('industrial')" >
                         <span class="dot-li-ar"></span>
                       صيانة القطاع الصناعي 
                    </li>
                    <li class="arabic-font" @click="$router.push('corporate')" >
                         <span class="dot-li-ar"></span>
                        مقرات ومكاتب الشركات والمؤسسات
                    </li>
                    <li class="service-ui-active arabic-font"  >
                         <div class="dot-li-ar-active"></div>
                       صيانة المرافق التربوية والتعليمية 
                    </li>
                     <li class="arabic-font" @click="$router.push('health')" >
                         <span class="dot-li-ar"></span>
                       الإهتمام بقطاع الرعاية الصحية 
                    </li>
                    <li class="arabic-font" @click="$router.push('retail')" >
                         <span class="dot-li-ar"></span>
                        خدمات النظافة لقطاع البيع بالتجزئةARABIC
                    </li>
                    <li class="arabic-font" @click="$router.push('sports')" >
                         <span class="dot-li-ar"></span>
                       رياضة ولياقة بدنية
                    </li>
                    <li class="arabic-font" @click="$router.push('foods')" >
                         <span class="dot-li-ar"></span>
                        صيانة مرافق التغذية والمرطبات 
                    </li>
                     <li class="arabic-font" @click="$router.push('hotels')" >
                         <span class="dot-li-ar"></span>
                        قطاع الفنادق والرفاهية
                    </li>
                 </ul>
                 <commenu :name="'صيانة المرافق التربوية والتعليمية'"/>
                 
             </div>
             <div class="col-md-8 col-lg-9">
                 <div class="cleaning-head show-head arabic-font">صيانة المرافق <span class="w400">التربوية والتعليمية</span></div>
                   <div class="sub-content mt-2 arabic-font ">
            توفر بليتش® الى المدارس والكليات والجامعات خدمات تنظيف صديقة للبيئة 100%. نحن ملتزمون بتقديم خدمات التنظيف المصممة خصيصًا للمرافق التعليمية لضمان النظافة والصحة للمدرسين وكذلك الطلاب. تضمن أنظمتنا المعيارية الدولية ومنتجات التنظيف عالية الجودة والموظفون المدربون لدينا أن تحصل المواقع التعليمية على أفضل رعاية واهتمام.

                 </div>
                 <div class="bold-heading mt-10 arabic-font">
                     هل تعلم؟ 
                 </div>
                 <div class="mt-3 sub-content arabic-font">
              ينخرط حالياً 591,359 طالب في مدارس الكويت ما يمثل 20% تقريباً من تعداد الدولة الكلي، وللأسف يتعرض نصفهم الى الهواء الداخلي الملوث وتشمل الأبخرة الكيميائية ومبيدات الحشرات، والعفن، والسموم الأخرى في المدارس
                 </div>
                  <div class="bold-heading mt-10 arabic-font">
                     فوائد المرافق التعليمية والتربوية النظيفة
                 </div>
                 <div class="mt-5 sub-content arabic-font">
                 قال داريل ألكسندر، مدير الصحة والسلامة في الاتحاد الأمريكي للمعلمين: "منتجات وممارسات التنظيف الصديق للبيئة تقلل من التعرض المزعج للأمراض، وتقلل من مشاكل الجهاز التنفسي بين القوى العاملة. التنظيف الصديق للبيئة هو حقًا استراتيجية ناجحة لتعزيز صحة المعلمين والمتواجدين" في المرافق التعليمية. 
                 <br>
                 لقد صممت بليتش® برامج تنظيف صديقة للبيئة للمساعدة في التقليل من آثار التعرض المضرة. 
                 </div>
             </div>
         </div>
      </div>
  </div>
 
</div>

</div>
</template>

<script>
import commenu from '../../components/menu/commenu.vue'

export default {
  components: { commenu },
  data(){
        return{
            selectlan:localStorage.getItem('bleach-lang')
        }
    },
    methods:{
         goto(loc) {
         window.location = loc;
        },
     }
}
</script>

<style>

</style>