<template>
  <div>
     <div>
      <section class=""  >
         <div class="bg-header-img-ceritifcation bg-header  ">
         </div>
      </section>
  </div>
    <div v-if="selectlan == 'EN'">
   
      <div class="bcontainer"  >
          <div class="py-15 ">
              <div class="page-head">
               CERTIFICATIONS
              </div>

              <div class="sub-content " >
                  BLEACH®takes pride in holding global recognitions such as GBAC, ISSA®, and CIMS®
certifications as a comprehensive cleaning services company in Kuwait.
              </div>   
               <div class="emp-say-head mt-15">
                WHY IS IT VITAL TO BE AN ISSA CIMS CERTIFIED CLEANING COMPANY
            </div>          
            <div class="service-content mt-7">
                BLEACH® has strict environmental and sustainable policies, and certifications like GBAC,
ISSA®, and CIMS® validate the efforts made by the company.We are one of the only four companies in the Middle East to be CIMIS certified.
<br><br>
DO YOU KNOW ISSA CIMS certification acquisition requires an organization to undergo a
comprehensive assessment and satisfactorily demonstrate compliance with the required
percentage of CIMS elements? An organization must meet 100% of the mandatory elements
and 60% of the recommended elements, per section, to be certified.
<br><br>
As <a href="https://www.issa.com/certification-standards/cleaning-industry-management-standard-cims/certified-organizations">BLEACH® is ISSA CIMS certified </a> , it validates that the company operates as per the
established standards for cleaning quality and efficiency by ISSA. Additionally, it verifies that
Bleach is strict about using proper cleaning and disinfection protocols to prevent and
mitigate infections and biohazards.
<br><br>
Organizations that conform to ISSA standards are some of the cleanest service companies
that achieve these standards and are recognized for having a higher quality, stronger service
reputation, and a proactive cleaning response – and BLEACH® is one of the very few to have
achieved the feat.
            </div>
            <div class="emp-say-head mt-15">
                WHAT MAKES BLEACH UNIQUE WITH GBAC RECOGNITION
            </div>          
            <div class="service-content mt-7">
                GBAC STAR™ is the cleaning industry’s only outbreak prevention, response, and recovery
accreditation for facilities. It is the gold standard of prepared facilities. This accreditation
means that BLEACH® has: <br><br>
  <ul class="content-ui ">
                            <li>Successfully established and maintained a cleaning, disinfection, and
infectious disease prevention program to minimize risks associated with
infectious agents.</li>
                            <li>Proper cleaning protocols, disinfection techniques, and work practices are in
place to combat biohazards and infectious diseases.</li>
                            <li> Highly informed cleaning professionals who are trained for an outbreak and
infectious disease preparation and response.</li>
                            
                        </ul>
                        BLEACH® has demonstrated correct work practices, procedures, and systems are in place to
prepare, respond, and recover from outbreaks and pandemics. <br>
Our Company achieved the GBAC STAR accreditation based on quality management system
principles and other relevant industry programs like ISO 9001, ISO 45001, and ISO 35001.

            </div>
              <div class="emp-say-head mt-15">
                GREEN FUTURE AHEAD
            </div> 
            <div class="service-content mt-7">
                BLEACH® is thriving to attain more certifications like GREEN, ISO, and many more in the
years near to serve locals while being globally compliant. Stay tuned for updates!
            </div>
          </div>
      </div>
       
   
  </div>
   <div v-if="selectlan == 'ARABIC'" dir="rtl">
   
      <div class="bcontainer"  >
          <div class="py-15 ">
              <div class="page-head">
               الشهادات 
              </div>

              <div class="sub-content " >
                  تفخر بليتش® بحيازتها لشهادات عالمية مثل شهادة عضويتها بال ®ISSA و ال CIMS® باعتبارها شركة خدمات تنظيف شاملة في الكويت. 
              </div>   
               <div class="emp-say-head mt-15">
                لماذا من الضروري أن تكون شركة تنظيف معتمدة من قبل ال ISSA و ال CIMS®
            </div>          
            <div class="service-content mt-7">
              لدى بليتش® سياسات بيئية مستدامة وصارمة، وتصادق شهادات مثل شهادة العضوية بال ®ISSA وشهادة ال CIMS® على الجهود التي تبذلها الشركة. 
<br><br>
هل تعلم أن الحصول  على عضوية ال ®ISSA وشهادة ال CIMS® يتطلب من المؤسسة الخضوع لتقييم شامل وإثبات الامتثال بشكل مرضٍ للنسبة المئوية المطلوبة من ال CIMS؟ يجب أن تفي الشركة بنسبة 100 ٪ من المقومات الإلزامية و 60 ٪ من المقومات الموصى بها لكل قسم، من أجل الموافقة على اعتمادها. 
<br><br>
نظرًا لأن <a href="https://www.issa.com/certification-standards/cleaning-industry-management-standard-cims/certified-organizations"> بليتش® معتمدة من قبل الISSA  من حيث معيار إدارة صناعة التنظيف </a>، فإن هذا يؤكد أن الشركة تعمل وفقًا للمعايير المعمول بها من حيث جودة وكفاءة التنظيف وفقاً لل CIMS. بالإضافة إلى ذلك، فإنها تؤكد على أن بليتش صارمة بشأن استخدام بروتوكولات التنظيف والتطهير المناسبة لمنع وتخفيف العدوى والأخطار البيولوجية. 
<br><br>
وتعدّ المؤسسات التي تتوافق مع معايير CIMS® من أنظف شركات الخدمة التي تحقق هذه المعايير ومعروفة بسمعتها الخدمية العالية والأقوى والاستجابة الاستباقية لمتطلبات التنظيف – وبليتش® من بين القلة القليلة التي حققت هذا الإنجاز. 
            </div>
            <div class="emp-say-head mt-15">
   ما الذي يجعل بليتش فريدًا من نوعها باعتراف هذه المنظمات 
            </div>          
            <div class="service-content mt-7">
                برنامج ال GBAC STAR™ هو الاعتماد الوحيد المطلوب من المؤسسات في قطاع التنظيف للوقاية من تفشي الأمراض والاستجابة لها والتعافي منها. وهو المعيار الذهبي للمرافق المجهزة. يعني هذا الاعتماد أن بليتش®: 

  <br><br>
  <ul class="content-ui ">
                            <li>نجحت في إنشاء برنامج للتنظيف والتطهير والوقاية من الأمراض المعدية والحفاظ عليه للحدّ من المخاطر المرتبطة بالعوامل الممرضة المعدية. </li>
                            <li>نجحت في وضع بروتوكولات التنظيف المناسبة وتقنيات التطهير وممارسات العمل لمكافحة المخاطر البيولوجية والأمراض المعدية.</li>
                            <li>لديها أخصائيين في التنظيف على دراية عالية ممن تدربوا على الاستعداد والاستجابة لانتشار الأمراض المعدية.</li>
                            
                        </ul>
                       أثبتت بليتش® وجود ممارسات وإجراءات وأنظمة عمل صحيحة للتحضير والاستجابة والتعافي من تفشي الأمراض والأوبئة.  <br>
حققت شركتنا اعتماد GBAC STAR بناءً على مبادئ نظام إدارة الجودة وبرامج الصناعة الأخرى ذات الصلة مثل آيزو9001 وآيزو45001 وآيزو 35001. 

            </div>
              <div class="emp-say-head mt-15">
                نحو مستقبل صديقٍ للبيئة 
            </div> 
            <div class="service-content mt-7">
              تسعى بليتش® للحصول على المزيد من الشهادات مثل شهادة Green Seal والآيزو ISO وغيرها الكثير في السنوات القريبة لخدمة العملاء المحليين عبر الامتثال عالميًا. ترقب آخر المستجدات. 
            </div>
          </div>
      </div>
       
   
  </div>

  </div>
</template>

<script>
//import pageheading from '../../components/pageheading.vue'
export default {
    components:{},
    data(){
        return{
            dc:false,
            selectlan:localStorage.getItem('bleach-lang')
        };
    }

}
</script>

<style>
.emp-say-head{
    font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 22px;
line-height: 33px;
text-transform: uppercase;
color: #2B9FAD;
}
</style>