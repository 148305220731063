<template>
 <div>
   <div>
      <section class=""  >
         <div class="bg-header-img-special bg-header  ">
         </div>
      </section>
  </div>
    <div v-if="selectlan=='EN'">
     
         <div class="bcontainer">
      <div class="pmy-15">
         <div class="page-head">
                SPECIAL  <span class="w400">CARE</span>
              </div>
        <div class="sub-content mt-2 text-left">
        Clean premises matter! The right cleaning routine can prevent illnesses and make your
home and office space a more comfortable place to live and enjoy time with your family
members or with colleagues at work, that too in the healthiest surrounding.<span v-if="!dc" style="color:#2B9FAD" @click="dc = true">Read more</span>

  <transition-expand>
<div v-if="dc">
  <br><br>
Keeping upholstery clean can be a challenge, both because it is difficult to move heavy
furniture for cleaning and there are so many different types of upholstery and materials.
Our team does not have a one-size-fits-all approach. Instead, we carefully assess each
situation and then determine which products are best suited to clean the fabric without
damaging it. We also use the right method for removing stains based on the type of stain,
the material it has stained, and how long the stain has been there.
<br><br>
Mattresses and carpets are other difficult but essential elements in the home to keep clean.
Our cleaning services use special-formulated detergents to break down dirt and oils that
accumulate over time.
<br><br>
BLEACH® is all about making sure your home is not only clean and sanitary but also a
sanctuary to which you can retreat after a long day.
<div v-if="width<768">
  <span style="color:#2B9FAD" v-if="dc" @click="dc = false">Read less</span>
</div>
</div>
  </transition-expand>
        </div>
      </div>
      <div>
   

      </div>
    </div>
    <div id="id_sec">
       <router-view/>
       </div>
  </div>
   <div v-if="selectlan=='ARABIC'">
      
         <div class="bcontainer">
      <div class="pmy-15">
        <div class="page-head arabic-font" dir="rtl">
                الرعاية   <span class="w400">الخاصة</span>
              </div>
        <div class="sub-content mt-2 arabic-font" dir="rtl">
       الأماكن النظيفة مهمة! يمكن أن يحدّ روتين التنظيف الصحيح من انتشار الأمراض ويجعل منزلك ومساحة مكتبك مكانًا أكثر راحة للعيش والاستمتاع بوقتك مع أفراد عائلتك أو مع زملائك في العمل، وذلك أيضًا في البيئة المحيطة الأكثر صحة.
<span v-if="!dc" style="color:#2B9FAD" @click="dc = true">المزيد </span>

  <transition-expand>
<div v-if="dc">
  <br><br>
    إنّ الحفاظ على نظافة المفروشات يمثل تحديًا صعباً نظرًا لصعوبة نقل الأثاث الثقيل من أجل التنظيف إلى جانب وجود العديد من مواد التنجيد المختلفة. وفريقنا لديه طيف واسع من أساليب التعامل مع هذا النوع التنظيف على النحو الذي يناسب الجميع. إننا نقوم بتقييم كل موقف بعناية ثم نحدد المنتجات الأنسب لتنظيف القماش دون الإضرار به. نستخدم أيضًا الطريقة الصحيحة لإزالة البقع استنادًا إلى نوع البقع والمواد التي سببتها وعمر البقع.
<br><br>
  كما أن المراتب والسجاد من ضمن الأشياء الأخرى التي يصعب تنظيفها لكن من الضروري الحفاظ على نظافتها سيما داخل المنزل. تعتمد خدمة التنظيف لدينا على استخدام منظفات خاصة لتفكيك الأوساخ والزيوت التي تتراكم بمرور الوقت.
<br><br>
  تسعى بليتش® إلى التأكد من أن منزلك ليس نظيفاا وصحياا فحسب، بل مالذ آمن نتوق للعودة إليه
بعد يوم طويل. 
<div v-if="width<768">
  <span style="color:#2B9FAD" v-if="dc" @click="dc = false">المختصر</span>
</div>
</div>
  </transition-expand>
        </div>
      </div>
      <div>
   
 
      </div>
    </div>
     <div id="id_sec">
       <router-view dir="rtl"/>
       </div>
  </div>
 </div>
</template>

<script>
//import pageheading from '../../../components/pageheading.vue'
export default {
  mounted() {
    
    this.width = screen.width;
    if(this.width<768){
      
          this.dc = false
        }else{
           if(localStorage.getItem('bleach-dc')){
              localStorage.removeItem('bleach-dc')
            }else{
              this.$nextTick(()=> window.document.getElementById("id_sec").scrollIntoView());
            }
        }
  },
    components:{},
     data(){
        return{
          dc:true,
          width:null,
            selectlan:localStorage.getItem('bleach-lang')
        }
    },
    methods:{
       isCar(){
      if(this.width != null){
        if(this.width<768){
          return true
        }
        return false
      }
      return false
    },
    }

}
</script>


<style>

</style>