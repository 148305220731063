<template>
  <div>
    <div class="gray-bg-2">
      <br /><br />
      <div class="bcontainer">
        <div class="py-4 py-sm-15">
          <div class="cleaning-head">
            Service <span class="w400">Cart</span>
          </div>
          <div v-if="cartItems.length != 0" class="">
            <span @click="goto()" class="active fs-14-en" style="color: #30b474; cursor: pointer"><u>Add more
                services</u></span>
            <!-- <button
                class="mt-5 book-now-btn active"
                style="width: 343px"
                @click="goto('service')"md
              >
                Add service in cart
              </button> -->
          </div>

          <div v-if="cartItems.length != 0" class="row">
            <div class="col-md-7 mt-4">
              <div class="cart-service-card" v-for="item in cartItems" :key="item.id">
                <div v-if="!schedule" class="text-right pointer">
                  <v-icon color="#8A8A8A" @click="(deleteDialog = true), (delId = item.id)">
                    mdi-close-circle-outline
                  </v-icon>
                </div>
                <div v-else class="text-right pointer">
                  <v-icon color="#8A8A8A"> </v-icon>
                </div>
                <div class="cart-service-card-inner">
                  <div class="d-flex">
                    <div class="full-flex cart-service-head" v-if="item.addon_name == null">
                      {{ item.service_type.name }}
                    </div>
                    <div class="full-flex cart-service-head" v-else>
                      {{ item.addon_name }}
                    </div>

                    <div class="cart-service-head">KD{{ item.total_cost }}</div>
                  </div>
                  <hr class="ser-line" />
                  <div class="d-flex">
                    <div class="service-card-card-size full-flex" v-if="item.addon_name == null">
                      <span v-if="item.cart_service_floor.length == 0">{{
                        item.size
                      }}</span>
                      <span v-for="(s, index) in item.cart_service_floor" :key="index">
                        {{ s.size
                        }}<span v-if="index != item.cart_service_floor.length - 1">,</span>
                      </span>
                    </div>
                    <div class="service-card-card-size full-flex" v-else>
                      {{ item.addon_category }}
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
              <div class="cart-service-card" style="display: none">
                <div class="text-right pointer">
                  <v-icon color="#8A8A8A" @click="deleteDialog = true">
                    mdi-close-circle-outline
                  </v-icon>
                </div>
                <div class="cart-service-card-inner">
                  <div class="d-flex">
                    <div class="full-flex cart-service-head">Services 1</div>
                    <div class="cart-service-head">KD40</div>
                  </div>
                  <hr class="ser-line" />
                  <div class="d-flex">
                    <div class="service-card-card-size full-flex">
                      Small 0-50 SQM
                    </div>
                    <div class="service-card-card-size green-text">
                      2 % off 2 Offer apllied
                      <v-icon small color="#30B474">mdi-information</v-icon>
                      <del>KD 45.000</del>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-right" v-if="!schedule">
                <div class="fs-14-en pb-3">
                  <v-icon size="15">mdi-information</v-icon>
                  Click here to schedule your service(s)
                </div>
                <button class="schedule-btn" @click="newdurationcalculation(), resetDialogData()">
                  Schedule
                </button>
              </div>
              <div v-else>
                <div class="scheduled-text show-mobile float-right">
                  <v-icon color="#30B474"> mdi-check-circle </v-icon>
                  Scheduled
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-9">
                    <v-btn @click="resetDialog = true" plain color="#000000">
                      <v-icon left> mdi-autorenew </v-icon>
                      <span class="reset-sh"> Reset Schedule</span>
                    </v-btn>
                  </div>
                  <div class="col-md-3 scheduled-text show-desktop">
                    <v-icon color="#30B474"> mdi-check-circle </v-icon>
                    Scheduled
                  </div>
                </div>
                <div class="fs-14-en pb-3 pl-4">
                  <v-icon size="15">mdi-information</v-icon>
                  Click the reset schedule button to remove the selected
                  schedule.
                </div>
                <div class="fs-14-en pb-3 pl-4">
                  <v-icon size="15">mdi-information</v-icon>
                  If you wish to add/remove any of the service(s), Kindly reset
                  the schedule.
                </div>
              </div>
            </div>
            <div class="col-md-5 mt-4" v-if="mobile_schedule">
              <div class="cart-service-card" style="padding: 33px 38px">
                <div class="side-card-head">Services</div>
                <div class="mt-5">
                  <div class="my-2 d-flex" v-for="item in cartItems" :key="item.id">
                    <div class="full-flex service-content">
                      <span v-if="schedule">{{ no_of_visits }} x </span>
                      {{ item.service_type.name }}
                    </div>
                    <div class="service-content">KD {{ item.total_cost }}</div>
                  </div>

                  <hr class="hr-side" />
                  <div class="my-2 d-flex">
                    <div class="full-flex service-content black-hash-text">
                      Total:
                    </div>
                    <div class="service-content black-hash-text">
                      KD {{ getTotalPrice() }}
                    </div>
                  </div>
                  <hr class="hr-side" />
                </div>
                <!-- <div class="my-3">
                  <span class="service-card-card-size w400"
                    >Special online booking discount of 15% upto 75.000 KD</span
                  >
                  <br />

                  <span class="side-card-head yellow-text w400">
                    Your Savings : KD {{ formatAmount(discount_amount) }}</span
                  >
                </div> -->
                <hr class="hr-side" />
                <div class="my-4 row">
                  <div class="side-card-head col-md-5 w700 ma" style="color: #2b9fad; font-weight: 700">
                    Payment
                  </div>
                  <div v-if="cart_final_cost" class="payment-final col-md-7">
                    KD {{ formatAmount(cart_final_cost) }}
                  </div>
                  <div v-else class="payment-final col-md-7">
                    KD 0
                  </div>
                </div>
                <hr class="hr-side" />
           
                <div v-if="promoCode" class="my-6">
                  <div class="cart-add-head">Promo Code</div>
                <div class="row coup-applied-container hidden-xs-only ml-1 " style="padding: 0px 35px 0px 10px;" >
                  <div class="col-md-1" style="padding: 20px 0px;">
                     <v-icon color="success" size="30">mdi-check-circle-outline</v-icon>
                  </div>
                  <div  class="col-md-9 pl-3 full-flex service-content" style="padding: 10px 0px;">
                    <span style="font-size:16px" ><b>{{ promoCode }}</b> is applied</span><br>
                    <span style="font-size:12px;">{{promocodeNotes}}</span>
                  </div>
                  <div class="col-md-2" style="padding: 20px 0px;" >
                    <span @click="removePromocode()" style="color:red;cursor:pointer">Remove</span>
                  </div>
                </div>
                <div class="row coup-applied-container hidden-sm-and-up ml-1 ml-sm-1" style="padding: 0px  8px 0px 8px;" >
                  <!-- <div style="padding: 20px 0px;">
                     <v-icon color="success" size="20">mdi-check-circle-outline</v-icon>
                  </div> -->
                  <div  class="col-9  full-flex service-content" style="padding: 10px 0px;">
                    <span style="font-size:12px" ><b>{{ promoCode }}</b> is applied</span><br>
                    <span style="font-size:10px;">{{promocodeNotes}}</span>
                  </div>
                  <div class="col-3  text-right" style="padding: 20px 0px;" >
                    <span @click="removePromocode()" style="font-size:12px;color:red;cursor:pointer">Remove</span>
                  </div>
                </div>

              </div>
              <div v-else  class="d-flex coup-container my-6">
                <div style="flex-grow: 1">
                  <input type="text" class="w-100 h-100 login-input pl-2" placeholder="Promocode"
                    v-model="promo_code" />
                </div>
               
                <div style="padding: 6px 35px;" class="coup-btn-container service-content">
                  <v-progress-circular v-if="promoLoader" class=" mx-3" indeterminate size="25" color="primary"></v-progress-circular>
                
                <div v-else @click="applyPromocode()" 
                  style="cursor:pointer">
                  Submit
                </div>
              </div>
              </div>
                <span v-if="promo_error" style="color:red;font-size:12px;">This field is required</span>
                <hr class="hr-side" />
                 <div class="small-green-btn mt-5" v-if="address.length == 0"
                  @click="(addressdialog = true), (newadd = true)">
                  Add Address
                </div>
                <div class="d-flex mt-2" v-if="address.length != 0">
                  <div class="cart-add-head ma full-flex">Address</div>
                  <div>
                    <button class="add-change-btn" @click="(addressdialog = true), (newadd = false)">
                      Change
                    </button>
                  </div>
                </div>
                <div class="" v-if="address.length != 0">
                  <div class="service-card-card-size w400 mt-2">
                    {{ address[selectedAddress].apartment }},
                    {{ address[selectedAddress].floor }},
                    {{ address[selectedAddress].building }},
                    {{ address[selectedAddress].block }},
                    {{ address[selectedAddress].street }},
                    {{ address[selectedAddress].avenue }},
                    {{ address[selectedAddress].area.name }},
                    {{ address[selectedAddress].governorate.name }}
                  </div>
                </div>
                <div class="d-flex mt-5">
                  <div>
                    <label class="checkcontainer" style="margin-top: 0px">
                      <input type="checkbox" name="kitchwe" v-model="privacyCheck" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="tc-text pointer" @click="openPrivacy()">
                    I agree to the Terms and Conditions
                  </div>
                </div>
                <div @click="bookNow()" v-if="schedule && privacyCheck" class="mt-5 book-now-btn active">
                  Book Now
                </div>
                <div v-else class="mt-5 book-now-btn">Book Now</div>
                <div v-if="!schedule" class="fs-14-en pt-3">
                  <v-icon size="15">mdi-information</v-icon>
                  Kindly schedule your service(s) for booking.
                </div>
                <div v-if="address.length == 0" class="fs-14-en pt-3">
                  <v-icon size="15">mdi-information</v-icon>
                  Kindly add your address before booking.
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <emptycart />
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="dialog" scrollable width="1150" persistent>
      <div class="ad-model" style="min-height: 750px">
        <div>
          <dialogboxside />
        </div>

        <div class="full-flex cart-dia" style="background: #fff; position: relative" id="id_dialog">
          <stepper :step="step" />
          <div v-if="step == 1">
            <div class="sub-heading mt-5" style="text-transform: none">
              Select Plan
            </div>
            <div class="service-content">
              Now Choose Date & Time at Your Convenience for a Hassle-free
              BLEACH Cleaning Experience
            </div>
            <div v-if="no_of_day > 1" class="pt-2 service-content" style="color: red">
              You can only choose ‘One-Time’ service plan for the selected
              services.
            </div>

            <div class="row mt-5">
              <div class="col-md-4 col-lg-3" v-for="item in plans" :key="item.id">
                <div class="plan-card h-100" style="position: relative" @click="selectAddress(item.id)"
                  :class="isActiceBox(item.id)">
                  <div class="select-plan" v-if="selected == item.id">
                    <selecticon />
                  </div>
                  <h4>{{ item.name }}</h4>
                  <div class="service-content">
                    {{ item.description }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="plan_error_flag" class="red-color mt-2">
              You need to select a plan
            </div>
            <div class="dialog-btn-section">
              <button @click="dialog = false" type="button" class="align-self-end bl-cancel-btn ma-3"
                style="min-width: 162.37px">
                Cancel
              </button>
              <v-progress-circular v-if="loader" indeterminate color="primary"></v-progress-circular>
              <button v-else @click="checkPlanselect(), goTopDialog()" type="button" class="align-self-end bl-primary-btn"
                style="min-width: 162.37px">
                Next
              </button>
            </div>
          </div>
          <div v-if="step == 2">
            <div v-if="selected == 4">
              <div class="sub-heading mt-5" style="text-transform: none">
                Weekly
              </div>
              <div class="service-content">
                Choose your weekly cleaning schedule with BLEACH
              </div>
              <div>
                <div class="row mt-5">
                  <div class="col-md-6">
                    <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y
                      min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-validate="'required'" name="date" data-vv-as="Starting date" v-model="date"
                          label="Picker without buttons" append-icon="mdi-calendar-month" readonly v-bind="attrs"
                          v-on="on">
                          <template v-slot:label>
                            <span class="add-text">Select starting date</span>
                          </template>
                        </v-text-field>
                      </template>
                      <v-date-picker header-color="transparent" color="primary" v-model="date" :min="today"
                        @input="menu2 = false"></v-date-picker>
                    </v-menu>
                    <div style="margin-top: -8px" class="red-color">
                      {{ errors.first("date") }}
                    </div>
                    <v-select v-validate="'required'" name="time" data-vv-as="Time slot" v-model="selectSolt"
                      item-text="full_text" :items="time_slots" item-value="start_time" append-icon="mdi-chevron-down">
                      <template v-slot:label>
                        <span class="add-text">Select time slots</span>
                      </template>
                    </v-select>
                    <div style="margin-top: -8px" class="red-color">
                      {{ errors.first("time") }}
                    </div>
                    <v-text-field v-validate="'required'" name="visit" data-vv-as="Number of visit" @input="checkPrefDays"
                      v-model="getDate.total_visits" type="number" min="1">
                      <template v-slot:label>
                        <span class="add-text">Number of visit</span>
                      </template>
                    </v-text-field>
                    <div style="margin-top: -8px" class="red-color">
                      {{ errors.first("visit") }}
                    </div>
                  </div>
                  <div class="prefered-day mr-7 ma ml-0 mb-2">
                    Preferred days
                  </div>
                  <div class="pref-day-block">
                    <div class="day-btn" v-for="(item, index) in days" :key="index" @click="addPreferredDays(index)"
                      :class="isPreferredSelected(index) ? 'active' : ''">
                      {{ item }}
                    </div>
                  </div>
                  <div style="margin-top: 4px" v-if="preferred_day_flag" class="red-color">
                    Need to select preferred days
                  </div>
                  <div style="margin-top: 15px" v-if="max_select_flag" class="red-color">
                    No. of visits must be greater than or equals to total
                    selected days
                  </div>
                </div>
              </div>
              <div class="dialog-btn-section">
                <button @click="step = step - 1" type="button" class="align-self-end bl-cancel-btn ma-3"
                  style="min-width: 162.37px">
                  Back
                </button>
                <v-progress-circular v-if="loader" indeterminate color="primary"></v-progress-circular>
                <button v-else @click="nextStepValidate(), (edit_slot_flag = false)" type="button"
                  class="align-self-end bl-primary-btn" style="min-width: 162.37px">
                  Next
                </button>
              </div>
            </div>
            <div v-if="selected == 5">
              <div class="sub-heading mt-5" style="text-transform: none">
                Alternative Week
              </div>
              <div class="service-content">
                Book your cleaning schedule for every two weeks
              </div>
              <div>
                <div class="row mt-5">
                  <div class="col-md-6">
                    <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y
                      min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-validate="'required'" name="date" data-vv-as="Starting date" v-model="date"
                          label="Picker without buttons" append-icon="mdi-calendar-month" readonly v-bind="attrs"
                          v-on="on">
                          <template v-slot:label>
                            <span class="add-text">Select starting date</span>
                          </template>
                        </v-text-field>
                      </template>
                      <v-date-picker header-color="transparent" color="primary" v-model="date" :min="today"
                        @input="menu2 = false"></v-date-picker>
                    </v-menu>
                    <div style="margin-top: -8px" class="red-color">
                      {{ errors.first("date") }}
                    </div>
                    <v-select v-validate="'required'" name="time" data-vv-as="Time slot" v-model="selectSolt"
                      item-text="full_text" :items="time_slots" item-value="start_time" append-icon="mdi-chevron-down">
                      <template v-slot:label>
                        <span class="add-text">Select time slots</span>
                      </template>
                    </v-select>
                    <div style="margin-top: -8px" class="red-color">
                      {{ errors.first("time") }}
                    </div>
                    <v-text-field v-validate="'required'" name="visit" @input="checkPrefDays" data-vv-as="Number of visit"
                      v-model="getDate.total_visits" type="number">
                      <template v-slot:label>
                        <span class="add-text">Number of visit</span>
                      </template>
                    </v-text-field>
                    <div style="margin-top: -8px" class="red-color">
                      {{ errors.first("visit") }}
                    </div>
                  </div>
                  <div class="prefered-day mr-7 ma ml-0 mb-2">
                    Preferred days
                  </div>
                  <div class="pref-day-block">
                    <div class="day-btn" v-for="(item, index) in days" :key="index" @click="addPreferredDays(index)"
                      :class="isPreferredSelected(index) ? 'active' : ''">
                      {{ item }}
                    </div>
                  </div>
                  <div style="margin-top: 4px" v-if="preferred_day_flag" class="red-color">
                    Need to select preferred days
                  </div>
                  <div style="margin-top: 4px" v-if="max_select_flag" class="red-color">
                    No. of visits must be greater than or equals to total
                    selected days
                  </div>
                </div>
              </div>
              <div class="dialog-btn-section">
                <button @click="(step = step - 1), (max_select_flag = false)" type="button"
                  class="align-self-end bl-cancel-btn ma-3" style="min-width: 162.37px">
                  Back
                </button>
                <v-progress-circular v-if="loader" indeterminate color="primary"></v-progress-circular>
                <button v-else @click="nextStepValidate(), (edit_slot_flag = false)" type="button"
                  class="align-self-end bl-primary-btn" style="min-width: 162.37px">
                  Next
                </button>
              </div>
            </div>
            <div v-if="selected == 2">
              <div class="sub-heading mt-5" style="text-transform: none">
                Daily
              </div>
              <div class="service-content">
                Book your daily cleaning schedule with BLEACH
              </div>
              <div>
                <div class="row mt-5">
                  <div class="col-md-6">
                    <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y
                      min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-validate="'required'" name="date" data-vv-as="Starting date" v-model="date"
                          label="Picker without buttons" append-icon="mdi-calendar-month" readonly v-bind="attrs"
                          v-on="on">
                          <template v-slot:label>
                            <span class="add-text">Select starting date</span>
                          </template>
                        </v-text-field>
                      </template>
                      <v-date-picker header-color="transparent" color="primary" v-model="date" :min="today"
                        @input="menu2 = false"></v-date-picker>
                    </v-menu>
                    <div style="margin-top: -8px" class="red-color">
                      {{ errors.first("date") }}
                    </div>
                    <v-select v-validate="'required'" name="time" data-vv-as="Time slot" v-model="selectSolt"
                      item-text="full_text" :items="time_slots" item-value="start_time" append-icon="mdi-chevron-down">
                      <template v-slot:label>
                        <span class="add-text">Select time slot</span>
                      </template>
                    </v-select>
                    <div style="margin-top: -8px" class="red-color">
                      {{ errors.first("time") }}
                    </div>
                    <v-text-field v-validate="'required'" name="visit" data-vv-as="Number of visit"
                      v-model="getDate.total_visits" type="number">
                      <template v-slot:label>
                        <span class="add-text">Number of visit</span>
                      </template>
                    </v-text-field>
                    <div style="margin-top: -8px" class="red-color">
                      {{ errors.first("visit") }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="dialog-btn-section">
                <button @click="step = step - 1" type="button" class="align-self-end bl-cancel-btn ma-3"
                  style="min-width: 162.37px">
                  Back
                </button>
                <v-progress-circular v-if="loader" indeterminate color="primary"></v-progress-circular>
                <button v-else @click="nextStepValidate(), (edit_slot_flag = false)" type="button"
                  class="align-self-end bl-primary-btn" style="min-width: 162.37px">
                  Next
                </button>
              </div>
            </div>
            <div v-if="selected == 3">
              <div class="sub-heading mt-5" style="text-transform: none">
                Alternate Day
              </div>
              <div class="service-content">
                Book your cleaning schedule for every two days
              </div>
              <div>
                <div class="row mt-5">
                  <div class="col-md-6">
                    <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y
                      min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-validate="'required'" name="date" data-vv-as="Starting date" v-model="date"
                          label="Picker without buttons" append-icon="mdi-calendar-month" readonly v-bind="attrs"
                          v-on="on">
                          <template v-slot:label>
                            <span class="add-text">Select starting date</span>
                          </template>
                        </v-text-field>
                      </template>
                      <v-date-picker header-color="transparent" color="primary" v-model="date" :min="today"
                        @input="menu2 = false"></v-date-picker>
                    </v-menu>
                    <div style="margin-top: -8px" class="red-color">
                      {{ errors.first("date") }}
                    </div>
                    <v-select v-validate="'required'" name="time" data-vv-as="Time slot" v-model="selectSolt"
                      item-text="full_text" :items="time_slots" item-value="start_time" append-icon="mdi-chevron-down">
                      <template v-slot:label>
                        <span class="add-text">Select time slots</span>
                      </template>
                    </v-select>
                    <div style="margin-top: -8px" class="red-color">
                      {{ errors.first("time") }}
                    </div>
                    <v-text-field v-validate="'required'" name="visit" data-vv-as="Number of visit"
                      v-model="getDate.total_visits" type="number">
                      <template v-slot:label>
                        <span class="add-text">Number of visit</span>
                      </template>
                    </v-text-field>
                    <div style="margin-top: -8px" class="red-color">
                      {{ errors.first("visit") }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="dialog-btn-section">
                <button @click="step = step - 1" type="button" class="align-self-end bl-cancel-btn ma-3"
                  style="min-width: 162.37px">
                  Back
                </button>
                <v-progress-circular v-if="loader" indeterminate color="primary"></v-progress-circular>
                <button v-else @click="nextStepValidate(), (edit_slot_flag = false)" type="button"
                  class="align-self-end bl-primary-btn" style="min-width: 162.37px">
                  Next
                </button>
              </div>
            </div>
            <div v-if="selected == 6">
              <div class="sub-heading mt-5" style="text-transform: none">
                Monthly
              </div>
              <div class="service-content">
                Select the day of the month & time you want BLEACH experts to
                visit
              </div>
              <div>
                <div class="row mt-5">
                  <div class="col-md-6">
                    <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y
                      min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-validate="'required'" name="date" data-vv-as="Starting date" v-model="date"
                          label="Picker without buttons" append-icon="mdi-calendar-month" readonly v-bind="attrs"
                          v-on="on">
                          <template v-slot:label>
                            <span class="add-text">Select starting date</span>
                          </template>
                        </v-text-field>
                      </template>
                      <v-date-picker header-color="transparent" color="primary" v-model="date" :min="today"
                        @input="(menu2 = false), getmonthDate()"></v-date-picker>
                    </v-menu>
                    <div style="margin-top: -8px" class="red-color">
                      {{ errors.first("date") }}
                    </div>
                    <v-select v-validate="'required'" name="time" data-vv-as="Time slot" v-model="selectSolt"
                      item-text="full_text" :items="time_slots" item-value="start_time" append-icon="mdi-chevron-down">
                      <template v-slot:label>
                        <span class="add-text">Select time slots</span>
                      </template>
                    </v-select>
                    <div style="margin-top: -8px" class="red-color">
                      {{ errors.first("time") }}
                    </div>
                    <v-text-field v-validate="'required'" name="visit" @input="checkSelectDays"
                      data-vv-as="Number of visit" v-model="getDate.total_visits" type="number">
                      <template v-slot:label>
                        <span class="add-text">Number of visit</span>
                      </template>
                    </v-text-field>
                    <div style="margin-top: -8px" class="red-color">
                      {{ errors.first("visit") }}
                    </div>
                    <div class="month-reapt-text">
                      Selected dates to be repeated
                    </div>
                    <div class="cal-container">
                      <div class="cal-row">
                        <div class="cal-date" @click="addselectdays(1)" :class="isdayselected(1) ? 'active' : ''">
                          1
                        </div>
                        <div class="cal-date" @click="addselectdays(2)" :class="isdayselected(2) ? 'active' : ''">
                          2
                        </div>
                        <div class="cal-date" @click="addselectdays(3)" :class="isdayselected(3) ? 'active' : ''">
                          3
                        </div>
                        <div class="cal-date" @click="addselectdays(4)" :class="isdayselected(4) ? 'active' : ''">
                          4
                        </div>
                        <div class="cal-date" @click="addselectdays(5)" :class="isdayselected(5) ? 'active' : ''">
                          5
                        </div>
                        <div class="cal-date" @click="addselectdays(6)" :class="isdayselected(6) ? 'active' : ''">
                          6
                        </div>
                        <div class="cal-date" @click="addselectdays(7)" :class="isdayselected(7) ? 'active' : ''">
                          7
                        </div>
                      </div>
                      <div class="cal-row">
                        <div class="cal-date" @click="addselectdays(8)" :class="isdayselected(8) ? 'active' : ''">
                          8
                        </div>
                        <div class="cal-date" @click="addselectdays(9)" :class="isdayselected(9) ? 'active' : ''">
                          9
                        </div>
                        <div class="cal-date" @click="addselectdays(10)" :class="isdayselected(10) ? 'active' : ''">
                          10
                        </div>
                        <div class="cal-date" @click="addselectdays(11)" :class="isdayselected(11) ? 'active' : ''">
                          11
                        </div>
                        <div class="cal-date" @click="addselectdays(12)" :class="isdayselected(12) ? 'active' : ''">
                          12
                        </div>
                        <div class="cal-date" @click="addselectdays(13)" :class="isdayselected(13) ? 'active' : ''">
                          13
                        </div>
                        <div class="cal-date" @click="addselectdays(14)" :class="isdayselected(14) ? 'active' : ''">
                          14
                        </div>
                      </div>
                      <div class="cal-row">
                        <div class="cal-date" @click="addselectdays(15)" :class="isdayselected(15) ? 'active' : ''">
                          15
                        </div>
                        <div class="cal-date" @click="addselectdays(16)" :class="isdayselected(16) ? 'active' : ''">
                          16
                        </div>
                        <div class="cal-date" @click="addselectdays(17)" :class="isdayselected(17) ? 'active' : ''">
                          17
                        </div>
                        <div class="cal-date" @click="addselectdays(18)" :class="isdayselected(18) ? 'active' : ''">
                          18
                        </div>
                        <div class="cal-date" @click="addselectdays(19)" :class="isdayselected(19) ? 'active' : ''">
                          19
                        </div>
                        <div class="cal-date" @click="addselectdays(20)" :class="isdayselected(20) ? 'active' : ''">
                          20
                        </div>
                        <div class="cal-date" @click="addselectdays(21)" :class="isdayselected(21) ? 'active' : ''">
                          21
                        </div>
                      </div>
                      <div class="cal-row">
                        <div class="cal-date" @click="addselectdays(22)" :class="isdayselected(22) ? 'active' : ''">
                          22
                        </div>
                        <div class="cal-date" @click="addselectdays(23)" :class="isdayselected(23) ? 'active' : ''">
                          23
                        </div>
                        <div class="cal-date" @click="addselectdays(24)" :class="isdayselected(24) ? 'active' : ''">
                          24
                        </div>
                        <div class="cal-date" @click="addselectdays(25)" :class="isdayselected(25) ? 'active' : ''">
                          25
                        </div>
                        <div class="cal-date" @click="addselectdays(26)" :class="isdayselected(26) ? 'active' : ''">
                          26
                        </div>
                        <div class="cal-date" @click="addselectdays(27)" :class="isdayselected(27) ? 'active' : ''">
                          27
                        </div>
                        <div class="cal-date" @click="addselectdays(28)" :class="isdayselected(28) ? 'active' : ''">
                          28
                        </div>
                      </div>
                      <div class="cal-row">
                        <div class="cal-date2" @click="addselectdays(29)" :class="isdayselected(29) ? 'active' : ''">
                          29
                        </div>
                        <div class="cal-date2" @click="addselectdays(30)" :class="isdayselected(30) ? 'active' : ''">
                          30
                        </div>
                        <div class="cal-date2" @click="addselectdays(31)" :class="isdayselected(31) ? 'active' : ''">
                          31
                        </div>
                      </div>
                    </div>
                    <div style="margin-top: 4px" v-if="selected_day_flag" class="red-color">
                      Need to select dates to be repeated
                    </div>
                    <div style="margin-top: 4px" v-if="max_select_flag" class="red-color">
                      No. of visits must be greater than or equals to total
                      selected days
                    </div>
                  </div>
                </div>
              </div>
              <div class="dialog-btn-section">
                <button @click="step = step - 1" type="button" class="align-self-end bl-cancel-btn ma-3"
                  style="min-width: 162.37px">
                  Back
                </button>
                <v-progress-circular v-if="loader" indeterminate color="primary"></v-progress-circular>
                <button v-else @click="nextStepValidate(), (edit_slot_flag = false)" type="button"
                  class="align-self-end bl-primary-btn" style="min-width: 162.37px">
                  Next
                </button>
              </div>
            </div>
            <div v-if="selected == 1 && no_of_day == 0">
              <div class="sub-heading mt-5" style="text-transform: none">
                One-Time
              </div>
              <div class="service-content">
                Choose Day & Time for Single Cleaning Session
              </div>
              <div class="row mt-10">
                <!-- <div>
                  <div>

                    <v-date-picker
                      header-color="transparent"
                      color="primary"
                      v-model="picker"
                    >
                    </v-date-picker>
                  </div>
                </div> -->
                <div class="col-lg-12 show-mobile">
                  <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y
                    min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-validate="'required'" name="date" data-vv-as="Starting date" v-model="picker"
                        label="Picker without buttons" append-icon="mdi-calendar-month" readonly v-bind="attrs" v-on="on">
                        <template v-slot:label>
                          <span class="add-text">Select date</span>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker header-color="transparent" color="primary" v-model="picker" :min="today"
                      @input="(menu2 = false), oneTimeSlotAvailable()"></v-date-picker>
                  </v-menu>
                  <div style="margin-top: -8px" class="red-color">
                    {{ errors.first("date") }}
                  </div>
                </div>
                <div class="col-lg-5 col-md-6 show-desktop">
                  <v-date-picker @input="oneTimeSlotAvailable()" full-width header-color="transparent" color="primary"
                    v-model="picker" :min="today"></v-date-picker>
                  <div class="col-12 red-color mt-n2 pt-0 pt-md-3" v-if="onetime_slot_flag">
                    you need to select time slots
                  </div>
                </div>
                <div class="col-lg-12 show-mobile">
                  <v-select v-validate="'required'" name="time" data-vv-as="Time slot" v-model="onetime_select"
                    item-text="full_text" :items="time_slots_one" item-value="time" append-icon="mdi-chevron-down">
                    <template v-slot:label>
                      <span class="add-text">Select time slots</span>
                    </template>
                  </v-select>
                  <div class="col-12 red-color mt-n2" v-if="onetime_slot_flag">
                    you need to select time slots
                  </div>
                </div>

                <div class="col-lg-7 col-md-6 show-desktop">
                  <div style="border-bottom: 0.785176px solid #2b9fad" class="select-info-text">
                    Please select your desired time slot
                  </div>
                  <div class="row mt-6" v-if="!slot_loader">
                    <div v-if="time_slots_one.length == 0" class="fs-14-en text-center" style="color: red">
                      Sorry, we are completely booked for this day.
                      <br /><span>Please choose another date</span>
                    </div>

                    <div class="col-lg-4 col-md-6 col-12" v-for="(item, index) in time_slots_one" :key="index">
                      <div class="">
                        <div @click="oneTimeSlot = index" style="font-size: 12px" class="slot-box h-100"
                          :class="oneTimeSlot == index ? 'active' : ''">
                          {{ item.time }} - {{ getEndTime(item.time) }}
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mt-2 red-color" v-if="onetime_slot_flag">
                      you need to select time slots
                    </div>
                  </div>

                  <div class="text-center h-100" style="position: relative" v-else>
                    <div style="position: absolute; top: 45%; left: 45%">
                      <v-progress-circular class="ma" indeterminate color="primary"></v-progress-circular>
                    </div>
                  </div>
                </div>
              </div>
              <div class="dialog-btn-section">
                <button @click="step = step - 1" type="button" class="align-self-end bl-cancel-btn ma-3"
                  style="min-width: 162.37px">
                  Back
                </button>
                <v-progress-circular v-if="loader" indeterminate color="primary"></v-progress-circular>
                <button v-else @click="(edit_slot_flag = false), onetimeNext()" type="button"
                  class="align-self-end bl-primary-btn" style="min-width: 162.37px">
                  Next
                </button>
              </div>
            </div>
            <div v-if="selected == 1 && no_of_day > 1">
              <div class="sub-heading mt-5" style="text-transform: none">
                One-Time {{ no_of_day }}
              </div>
              <div class="service-content">
                Choose Days & Time for Cleaning Session
              </div>
              <div class="row mt-5">
                <div class="col-lg-12 show-mobile">
                  <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y
                    min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-validate="'required'" name="date" data-vv-as="Starting date"
                        v-model="multiDates[multi_day_selected].selected_date" label="Picker without buttons"
                        append-icon="mdi-calendar-month" readonly v-bind="attrs" v-on="on">
                        <template v-slot:label>
                          <span class="add-text">Select date</span>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker header-color="transparent" color="primary"
                      v-model="multiDates[multi_day_selected].selected_date" :min="today"
                      @input="(menu2 = false), oneTimeSlotAvailable()"></v-date-picker>
                  </v-menu>
                  <div style="margin-top: -8px" class="red-color">
                    {{ errors.first("date") }}
                  </div>
                </div>
                <div class="col-lg-12 show-mobile">
                  <v-select v-validate="'required'" name="time" data-vv-as="Time slot"
                    v-model="multiDates[multi_day_selected].selected_time" item-text="full_text" :items="time_slots_one"
                    item-value="index" append-icon="mdi-chevron-down">
                    <template v-slot:label>
                      <span class="add-text">Select time slots</span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 mt-2 red-color mt-n2" v-if="onetime_slot_flag && time_slots_one.length != 0">
                  You need to select time slots
                </div>
                <div class="col-lg-5 col-md-6 show-desktop">
                  <v-date-picker :min="today" @input="oneTimeSlotAvailable()" full-width header-color="transparent"
                    color="primary" v-model="multiDates[multi_day_selected].selected_date"></v-date-picker>
                </div>
                <div class="col-lg-7 col-md-6 show-desktop">
                  <div style="border-bottom: 0.785176px solid #2b9fad" class="select-info-text">
                    Please select your desired time slot
                  </div>
                  <div class="row mt-6" v-if="!slot_loader">
                    <div v-if="time_slots_one.length == 0" class="fs-14-en text-center" style="color: red">
                      Sorry, we are completely booked for this day.
                      <br /><span>Please choose another date</span>
                    </div>

                    <div class="col-lg-4 col-md-6 col-12" v-for="(item, index) in time_slots_one" :key="index">
                      <div class="">
                        <div @click="
                          multiDates[multi_day_selected].selected_time = index
                        " style="font-size: 12px" class="slot-box h-100" :class="
  multiDates[multi_day_selected].selected_time ==
    index
    ? 'active'
    : ''
">
                          {{ item.time }} - {{ getEndTime(item.time) }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 mt-3 service-content" v-if="time_slots_one.length == 0">
                      No time slot available on selected date
                    </div>
                    <div class="col-12 mt-2 red-color" v-if="onetime_slot_flag && time_slots_one.length != 0">
                      You need to select time slots
                    </div>
                  </div>
                  <div class="text-center h-100" style="position: relative" v-else>
                    <div style="position: absolute; top: 45%; left: 45%">
                      <v-progress-circular class="ma" indeterminate color="primary"></v-progress-circular>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-md-3 mt-5" v-for="(item, index) in multiDates" :key="index">
                  <div class="day-btn-onetime" @click="dayChange(index)"
                    :class="multi_day_selected == index ? 'focus' : ''">
                    {{ item.day }}
                    <span class="f-400" v-if="
                      item.selected_date != '' && item.selected_time != null
                    ">
                      <br />
                      {{ item.selected_date }}
                      <span v-if="time_slots_one[item.selected_time]">
                        {{ time_slots_one[item.selected_time].time }}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="text-right info-text-onetime mt-2 show-desktop">
                This Service need more than 1 Visit to complete
              </div>
              <div class="text-right show-desktop">
                <button @click="step = step - 1" type="button" class="align-self-end bl-cancel-btn ma-3"
                  style="min-width: 162.37px">
                  Back
                </button>

                <button v-if="multi_day_selected + 1 < multiDates.length" @click="onetimeNextDay()" type="button"
                  class="align-self-end bl-primary-btn" style="min-width: 162.37px">
                  <!-- {{ multiDates[multi_day_selected + 1].day }} -->
                  Next
                </button>
                <button v-else @click="(edit_slot_flag = false), selectedMultipleDay()" type="button"
                  class="align-self-end bl-primary-btn" style="min-width: 162.37px">
                  Next
                </button>
              </div>
              <div class="text-center mt-10 show-mobile">
                <div class="info-text-onetime mb-2">
                  This Service need more than 1 Visit to complete
                </div>
                <button @click="step = step - 1" type="button" class="align-self-end bl-cancel-btn ma-3"
                  style="min-width: 162.37px">
                  Back
                </button>

                <button v-if="multi_day_selected + 1 < multiDates.length" @click="onetimeNextDay()" type="button"
                  class="align-self-end bl-primary-btn" style="min-width: 162.37px">
                  <!-- {{ multiDates[multi_day_selected + 1].day }} -->
                  Next
                </button>
                <button v-else @click="(edit_slot_flag = false), selectedMultipleDay()" type="button"
                  class="align-self-end bl-primary-btn" style="min-width: 162.37px">
                  Next
                </button>
              </div>
            </div>
            <div v-if="selected == 7">
              <div class="sub-heading mt-5" style="text-transform: none">
                Custom
              </div>
              <div class="service-content">
                Customize your cleaning plan with BLEACH. Create a custom plan
                by selecting the date(s) and time slot(s) of your choice.
              </div>

              <div class="row mt-10">
                <div class="col-lg-12 show-mobile">
                  <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y
                    min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-validate="'required'" name="date" data-vv-as="Starting date" v-model="picker"
                        label="Picker without buttons" append-icon="mdi-calendar-month" readonly v-bind="attrs" v-on="on">
                        <template v-slot:label>
                          <span class="add-text">Select date</span>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker header-color="transparent" color="primary" v-model="picker"
                      :allowed-dates="getAllowedDates" :events="getAllowedDates1" event-color="success" :min="today"
                      @input="(menu2 = false), customSlotAvailable()"></v-date-picker>
                  </v-menu>
                  <div style="margin-top: -8px" class="red-color">
                    {{ errors.first("date") }}
                  </div>
                </div>
                <div class="col-lg-12 show-mobile">
                  <v-select v-validate="'required'" name="time" data-vv-as="Time slot" v-model="customSlotIndex"
                    item-text="full_text" :items="time_slots_custom" item-value="time" append-icon="mdi-chevron-down">
                    <template v-slot:label>
                      <span class="add-text">Select time slots</span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 mt-2 red-color mt-n2 show-mobile" v-if="custom_slot_flag">
                  You need to select time slots
                </div>
                <div class="col-lg-5 col-md-6 show-desktop">
                  <v-date-picker full-width @input="customSlotAvailable()" header-color="transparent"
                    :allowed-dates="getAllowedDates" :events="getAllowedDates1" event-color="success" color="primary"
                    :min="today" v-model="picker"></v-date-picker>
                </div>
                <div class="col-lg-7 col-md-6 show-desktop">
                  <div style="border-bottom: 0.785176px solid #2b9fad" class="select-info-text">
                    Please Select Your Desired Time Slot
                  </div>
                  <div class="row mt-6" v-if="!slot_loader">
                    <div v-if="time_slots_custom.length == 0" class="fs-14-en text-center" style="color: red">
                      Sorry, we are completely booked for this day.
                      <br /><span>Please choose another date</span>
                    </div>
                    <div class="col-lg-4 col-md-6" v-for="(item, index) in time_slots_custom" :key="index">
                      <div class="">
                        <div @click="customSlotIndex = index" style="font-size: 12px" class="slot-box h-100"
                          :class="customSlotIndex == index ? 'active' : ''">
                          {{ item.time }} - {{ getEndTime(item.time) }}
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mt-2 red-color" v-if="custom_slot_flag">
                      You need to select time slots
                    </div>
                    <div class="text-right">
                      <button class="btn-autofix mt-2" @click="addCustomSlots()">
                        Add Visit
                      </button>
                    </div>
                    <div class="fs-14-en pt-3">
                      <v-icon size="15">mdi-information</v-icon>
                      After selecting your desired date and time slot please
                      click the 'Add Visit' button.
                    </div>
                  </div>

                  <div class="text-center h-100" style="position: relative" v-else>
                    <div style="position: absolute; top: 45%; left: 45%">
                      <v-progress-circular class="ma" indeterminate color="primary"></v-progress-circular>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 show-mobile">
                  <div class="text-right">
                    <button class="btn-autofix mt-2" @click="addCustomSlots()">
                      Add Visit
                    </button>
                  </div>
                </div>
              </div>

              <div class="row mt-6 mb-1">
                <div class="col-lg-3 col-md-6 col-6" v-for="(item, index) in customSelectedSlots" :key="index">
                  <div class="">
                    <v-badge icon="mdi-close" class="cp" @click.native="removeCustomSlots(index)" dark>
                      <div class="slot-box h-100 show-desktop">
                        <span style="font-weight: 700">Cleaning {{ index + 1 }}</span>
                        : {{ item.date }} <br />
                        {{ item.s.start_time }} - {{ item.s.end_time }}
                      </div>
                    </v-badge>
                    <div class="slot-box h-100 show-mobile" style="font-size: 12px; padding: 6px">
                      <span style="font-weight: 700">Visit {{ index + 1 }}</span>
                      {{ item.date }}, {{ item.s.start_time }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center show-mobile">
                <button @click="(step = step - 1), clearCustomVariables()" type="button"
                  class="align-self-end bl-cancel-btn ma-3" style="min-width: 162.37px">
                  Back
                </button>
                <v-progress-circular v-if="loader" indeterminate color="primary"></v-progress-circular>
                <button v-else @click="customNext()" :disabled="customSelectedSlots.length > 1 ? false : true"
                  type="button" class="align-self-end bl-primary-btn" style="min-width: 162.37px">
                  Next
                </button>
              </div>
              <div class="text-right show-desktop">
                <button @click="(step = step - 1), clearCustomVariables()" type="button"
                  class="align-self-end bl-cancel-btn mr-3" style="min-width: 162.37px">
                  Back
                </button>
                <v-progress-circular v-if="loader" indeterminate color="primary"></v-progress-circular>
                <button v-else @click="customNext()" :disabled="customSelectedSlots.length > 1 ? false : true"
                  type="button" class="align-self-end bl-primary-btn" style="min-width: 162.37px">
                  Next
                </button>
              </div>
            </div>
          </div>
          <div v-if="step == 3">
            <div v-if="edit_slot_flag">
              <div class="row mt-10" v-if="edit_slot_flag">
                <div class="col-lg-12 show-mobile">
                  <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y
                    min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-validate="'required'" name="date" data-vv-as="Starting date"
                        v-model="edit_slot_date" label="Picker without buttons" append-icon="mdi-calendar-month" readonly
                        v-bind="attrs" v-on="on">
                        <template v-slot:label>
                          <span class="add-text">Select date</span>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker header-color="transparent" color="primary" v-model="edit_slot_date" :min="today"
                      @input="menu2 = false"></v-date-picker>
                  </v-menu>
                  <div style="margin-top: -8px" class="red-color">
                    {{ errors.first("date") }}
                  </div>
                </div>
                <div class="col-lg-5 col-md-6 show-desktop">
                  <v-date-picker full-width header-color="transparent" color="primary" :min="today"
                    v-model="edit_slot_date">
                  </v-date-picker>
                </div>

                <div class="col-lg-7 col-md-6">
                  <div style="border-bottom: 0.785176px solid #2b9fad" class="select-info-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit ut
                    aliquam
                  </div>
                  <div class="row mt-6">
                    <div class="col-lg-4 col-md-6 col-12" v-for="(item, index) in time_slots" :key="index">
                      <div class="">
                        <div @click="selected_edit_slot = index" style="font-size: 11px" class="slot-box h-100"
                          :class="selected_edit_slot == index ? 'active' : ''">
                          {{ item.start_time }} - {{ item.end_time }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="dialog-btn-section">
                <button @click="step = step - 1" type="button" class="align-self-end bl-cancel-btn ma-3"
                  style="min-width: 162.37px">
                  Back
                </button>
                <v-progress-circular v-if="loader" indeterminate color="primary"></v-progress-circular>
                <button v-else @click="checkAvailByDate()" type="button" class="align-self-end bl-primary-btn"
                  style="min-width: 162.37px">
                  Next
                </button>
              </div>
            </div>
            <div v-else>
              <div class="sub-heading mt-5" style="text-transform: none">
                Selected Visits
              </div>
              <div class="service-content">
                Please confirm the date and time of your visits. If any of the
                listed visits are unavailable, Kindly update the visits by using
                the edit option.
              </div>

              <div v-if="auto_fix && autofix_flag" class="pt-4 service-content" style="color: #ff5f00">
                The 'Autofix' button will help you automatically select a
                different time slot for the selected day, if available.
              </div>
              <div>
                <div class="visiting-tab-wrapp">
                  <v-progress-linear v-if="aval_loader" class="" indeterminate color="primary"></v-progress-linear>
                  <div style="overflow-x: auto" class="scroll-container">
                    <table class="visiting-tab">
                      <thead>
                        <tr>
                          <th>Visit</th>
                          <th v-if="is_mobile">Schedule</th>
                          <th v-if="!is_mobile">Date</th>
                          <th v-if="!is_mobile">time</th>
                          <th v-if="!is_mobile">Availability</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in avail_date_slot" :key="index" :class="
                          !item.is_available ? 'visiting-unavailable' : ''
                        ">
                          <td>
                            <span v-if="!is_mobile">Visit</span> {{ index + 1 }}
                          </td>
                          <td v-if="is_mobile">
                            {{ item.date }}, {{ item.time }}
                          </td>
                          <td v-if="!is_mobile">{{ item.date }}</td>
                          <td v-if="!is_mobile">{{ item.time }}</td>
                          <td v-if="item.is_available && !is_mobile">
                            Available
                          </td>
                          <td v-if="!item.is_available && !is_mobile">
                            Unavailable
                          </td>
                          <td v-if="item.is_available">
                            <v-icon small class="mr-2" @click="
                              (slot_edit_index = index),
                              (edit_slot_flag = true),
                              editslotdate(item.time)
                            ">
                              mdi-pencil-outline
                            </v-icon>
                            <span v-if="!(selected == 1 && no_of_day > 1)">
                              <v-icon :disabled="
                                avail_date_slot.length > 1 ? false : true
                              " small class="ml-2" @click="deleteAvalslot(index)">
                                mdi-trash-can-outline
                              </v-icon>
                            </span>
                          </td>
                          <td v-if="!item.is_available">
                            <v-icon small class="mr-2" @click="
                              (slot_edit_index = index),
                              (edit_slot_flag = true),
                              editslotdate(item.time)
                            " color="#FF5F00">
                              mdi-pencil-outline
                            </v-icon>
                            <v-icon v-if="!(selected == 1 && no_of_day > 1)" small class="ml-2" color="#FF5F00"
                              @click="deleteAvalslot(index)">
                              mdi-trash-can-outline
                            </v-icon>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-md-6">
                    <!-- v-if="auto_fix" -->
                    <div class="d-flex" v-if="auto_fix">
                      <!-- v-if="autofix_flag" -->

                      <!-- v-else -->
                      <div class="service-content ma ml-0" style="color: red" v-if="!autofix_flag">
                        Please change unavailable slots manually
                      </div>
                      <!-- v-if="autofix_flag" -->
                      <button v-if="autofix_flag" class="btn-autofix" @click="autoFix()">
                        Autofix
                      </button>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="dialog-btn-section">
                      <button @click="step = step - 1" type="button" class="align-self-end bl-cancel-btn ma-3"
                        style="min-width: 162.37px">
                        Back
                      </button>
                      <v-progress-circular v-if="scheduelloader" indeterminate color="primary"></v-progress-circular>
                      <button v-else @click="scheduleService()" :disabled="checkisInactiveNext() ? true : false"
                        type="button" class="align-self-end bl-primary-btn" style="min-width: 162.37px">
                        Next
                      </button>
                    </div>

                    <!-- <div class="text-right col-md-6 bleach-link ma">
                    Add Visit
                    <img src="../../assets/icons/blue-arrow.png" alt="" />
                  </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-dialog>
    <v-dialog v-model="addressdialog" scrollable width="1112">
      <div class="ad-model">
        <div>
          <dialogboxside />
        </div>
        <div class="full-flex pa-10" style="background: #fff">
          <div v-if="!newadd && !editadd">
            <div class="sub-heading mt-15" style="text-transform: none">
              Select Address
            </div>
            <div class="service-content">
              Confirm the address for the required service
            </div>
            <div class="row mt-5">
              <div class="col-md-6 mb-5" style="position: relative" v-for="(item, index) in address" :key="index">
                <div style="position: absolute; right: 30px; top: 20px" class="d-flex">
                  <div class="pointer" @click="editAddressData(item)">
                    <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M9.37516 10.7965H0.627462C0.461745 10.7965 0.302816 10.8599 0.185636 10.9728C0.068457 11.0856 0.00262615 11.2387 0.00262615 11.3983C0.00262615 11.5579 0.068457 11.7109 0.185636 11.8238C0.302816 11.9366 0.461745 12 0.627462 12H9.37516C9.54088 12 9.69981 11.9366 9.81699 11.8238C9.93417 11.7109 10 11.5579 10 11.3983C10 11.2387 9.93417 11.0856 9.81699 10.9728C9.69981 10.8599 9.54088 10.7965 9.37516 10.7965ZM0.627462 9.59305H0.683697L3.28926 9.36439C3.57469 9.33701 3.84164 9.2159 4.04531 9.0214L9.66884 3.60576C9.8871 3.3837 10.0051 3.08737 9.99686 2.7817C9.98866 2.47603 9.85498 2.18596 9.6251 1.97505L7.91305 0.326287C7.6896 0.124161 7.3968 0.00818336 7.09035 0.000416858C6.78389 -0.00734964 6.48516 0.0936365 6.25098 0.284165L0.627462 5.69981C0.425494 5.89595 0.299738 6.15304 0.271305 6.42791L0.00262615 8.93715C-0.00579103 9.02529 0.0060842 9.11413 0.0374053 9.19734C0.0687263 9.28055 0.118722 9.35608 0.183829 9.41854C0.242213 9.47431 0.311455 9.51844 0.387584 9.54838C0.463712 9.57833 0.545229 9.59351 0.627462 9.59305ZM7.04453 1.16872L8.75033 2.81146L7.50066 3.98485L5.8261 2.3722L7.04453 1.16872ZM1.48349 6.5302L5.00131 3.16649L6.68837 4.79118L3.18929 8.16091L1.31478 8.3294L1.48349 6.5302Z"
                        fill="#8A8A8A" />
                    </svg>
                  </div>
                  <div class="ml-5 pointer" @click="(deleteAddId = item.id), (adddeleteDialog = true)">
                    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M3.625 1.17H3.5C3.56875 1.17 3.625 1.1115 3.625 1.04V1.17H8.375V1.04C8.375 1.1115 8.43125 1.17 8.5 1.17H8.375V2.34H9.5V1.04C9.5 0.466375 9.05156 0 8.5 0H3.5C2.94844 0 2.5 0.466375 2.5 1.04V2.34H3.625V1.17ZM11.5 2.34H0.5C0.223437 2.34 0 2.57237 0 2.86V3.38C0 3.4515 0.05625 3.51 0.125 3.51H1.06875L1.45469 12.0087C1.47969 12.5629 1.92031 13 2.45312 13H9.54688C10.0813 13 10.5203 12.5645 10.5453 12.0087L10.9312 3.51H11.875C11.9438 3.51 12 3.4515 12 3.38V2.86C12 2.57237 11.7766 2.34 11.5 2.34ZM9.42656 11.83H2.57344L2.19531 3.51H9.80469L9.42656 11.83Z"
                        fill="#8A8A8A" />
                    </svg>
                  </div>
                </div>
                <div class="address-box h-100" :class="selectedAddress == index ? 'active' : ''"
                  @click="changeAddress(index)">
                  <div class="d-flex">
                    <div class="full-flex service-content f-16">
                      Address {{ index + 1 }}
                    </div>
                  </div>
                  <div class="service-content mt-2">
                    {{ item.apartment }}, {{ item.floor }}, {{ item.building }},
                    {{ item.block }}, {{ item.street }}, {{ item.avenue }},
                    {{ item.area.name }},
                    {{ item.governorate.name }}
                  </div>
                </div>
              </div>
            </div>
            <div class="bleach-link mt-1" style="text-decoration: underline" @click="newadd = true">
              Add new address
            </div>
          </div>
          <div v-if="newadd && !editadd">
            <div class="sub-heading mt-15" style="text-transform: none">
              Add New Address
            </div>
            <div class="service-content">
              New Location? No worries! Share the details below
            </div>
            <v-card flat color="p-0 mt-5">
              <div class="row mt-5">
                <div class="col-md-4">
                  <v-select v-validate="'required'" name="Location" data-vv-as="Location" :items="locationType"
                    item-text="name" item-value="name" v-model="newaddress.location" append-icon="mdi-chevron-down">
                    <template v-slot:label>
                      <span class="add-text">Location</span>
                    </template>
                  </v-select>
                  <div style="margin-top: -10px" class="red-color">
                    {{ errors.first("Location") }}
                  </div>
                </div>
                <div class="col-md-4">
                  <v-select v-validate="'required'" name="Governorate" data-vv-as="Governorate" @input="getAreas()"
                    :items="governorates" item-text="name" item-value="id" v-model="newaddress.governorate"
                    append-icon="mdi-chevron-down">
                    <template v-slot:label>
                      <span class="add-text">Governorate</span>
                    </template>
                  </v-select>
                  <div style="margin-top: -10px" class="red-color">
                    {{ errors.first("Governorate") }}
                  </div>
                </div>
                <div class="col-md-4">
                  <v-select v-validate="'required'" name="Area" data-vv-as="Area" :items="areas" item-text="name"
                    item-value="id" append-icon="mdi-chevron-down" v-model="newaddress.area">
                    <template v-slot:label>
                      <span class="add-text">Area</span>
                    </template>
                  </v-select>
                  <div style="margin-top: -10px" class="red-color">
                    {{ errors.first("Area") }}
                  </div>
                </div>
                <!-- <div class="col-md-12">
                        <v-text-field   class="mt-5" >
                             <template  v-slot:label>
                            <span class="add-text">Address Line 1</span>
                          </template>
                        </v-text-field>
                      </div>
                      <div class="col-md-12">
                        <v-text-field   class="mt-5" >
                            <template  v-slot:label>
                            <span class="add-text">Address Line 2</span>
                          </template>
                        </v-text-field>
                      </div> -->
                <div class="col-md-4">
                  <v-text-field v-model="newaddress.floor" class="mt-5">
                    <template v-slot:label>
                      <span class="add-text">Floor</span>
                    </template>
                  </v-text-field>
                </div>
                <div class="col-md-4">
                  <v-text-field v-validate="'required'" name="Apartment" data-vv-as="Apartment"
                    v-model="newaddress.apartment" class="mt-5">
                    <template v-slot:label>
                      <span class="add-text">Apartment</span>
                    </template>
                  </v-text-field>
                  <div style="margin-top: -10px" class="red-color">
                    {{ errors.first("Apartment") }}
                  </div>
                </div>
                <div class="col-md-4">
                  <v-text-field v-validate="'required'" name="Building" data-vv-as="Building"
                    v-model="newaddress.building" class="mt-5">
                    <template v-slot:label>
                      <span class="add-text">Building</span>
                    </template>
                  </v-text-field>
                  <div style="margin-top: -10px" class="red-color">
                    {{ errors.first("Building") }}
                  </div>
                </div>
                <div class="col-md-4">
                  <v-text-field v-validate="'required'" name="Block" data-vv-as="Block" v-model="newaddress.block"
                    class="mt-5">
                    <template v-slot:label>
                      <span class="add-text">Block</span>
                    </template>
                  </v-text-field>
                  <div style="margin-top: -10px" class="red-color">
                    {{ errors.first("Block") }}
                  </div>
                </div>
                <div class="col-md-4">
                  <v-text-field v-validate="'required'" name="Street" data-vv-as="Street" v-model="newaddress.street"
                    class="mt-5">
                    <template v-slot:label>
                      <span class="add-text">Street</span>
                    </template>
                  </v-text-field>
                  <div style="margin-top: -10px" class="red-color">
                    {{ errors.first("Street") }}
                  </div>
                </div>
                <div class="col-md-4">
                  <v-text-field v-model="newaddress.avenue" class="mt-5">
                    <template v-slot:label>
                      <span class="add-text">Avenue</span>
                    </template>
                  </v-text-field>
                </div>
              </div>

              <div class="row">
                <div class="col-md-3 offset-md-6">
                  <button @click="(newadd = false), checkAddressLen()" type="button"
                    class="align-self-end bl-cancel-btn w-100" style="margin-top: 20px">
                    Cancel
                  </button>
                </div>
                <div class="col-md-3">
                  <v-progress-circular v-if="loaderAdd" indeterminate color="primary"></v-progress-circular>
                  <button v-else @click="addNewaddSubmit()" type="button" class="align-self-end bl-primary-btn w-100"
                    style="margin-top: 20px">
                    Save
                  </button>
                </div>
              </div>
            </v-card>
          </div>
          <div v-if="editadd">
            <div class="sub-heading mt-15" style="text-transform: none">
              Edit Addresss
            </div>
            <div class="service-content">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam
            </div>
            <div class="row mt-5">
              <div class="col-md-4">
                <v-select v-validate="'required'" name="Location" data-vv-as="Location" :items="locationType"
                  item-text="name" item-value="name" v-model="editaddress.location" append-icon="mdi-chevron-down">
                  <template v-slot:label>
                    <span class="add-text">Location</span>
                  </template>
                </v-select>
                <div style="margin-top: -10px" class="red-color">
                  {{ errors.first("Location") }}
                </div>
              </div>
              <div class="col-md-4">
                <v-select v-validate="'required'" name="Governorate" data-vv-as="Governorate"
                  @input="getAreasEdit(editaddress.governorate)" :items="governorates" item-text="name" item-value="id"
                  v-model="editaddress.governorate" append-icon="mdi-chevron-down">
                  <template v-slot:label>
                    <span class="add-text">Governorate</span>
                  </template>
                </v-select>
                <div style="margin-top: -10px" class="red-color">
                  {{ errors.first("Governorate") }}
                </div>
              </div>
              <div class="col-md-4">
                <v-select v-validate="'required'" name="Area" data-vv-as="Area" :items="areas" item-text="name"
                  item-value="id" append-icon="mdi-chevron-down" v-model="editaddress.area">
                  <template v-slot:label>
                    <span class="add-text">Area</span>
                  </template>
                </v-select>
                <div style="margin-top: -10px" class="red-color">
                  {{ errors.first("Area") }}
                </div>
              </div>
              <!-- <div class="col-md-12">
                        <v-text-field   class="mt-5" >
                             <template  v-slot:label>
                            <span class="add-text">Address Line 1</span>
                          </template>
                        </v-text-field>
                      </div>
                      <div class="col-md-12">
                        <v-text-field   class="mt-5" >
                            <template  v-slot:label>
                            <span class="add-text">Address Line 2</span>
                          </template>
                        </v-text-field>
                      </div> -->
              <div class="col-md-4">
                <v-text-field v-model="editaddress.floor" class="mt-5">
                  <template v-slot:label>
                    <span class="add-text">Floor</span>
                  </template>
                </v-text-field>
              </div>
              <div class="col-md-4">
                <v-text-field v-validate="'required'" name="Apartment" data-vv-as="Apartment"
                  v-model="editaddress.apartment" class="mt-5">
                  <template v-slot:label>
                    <span class="add-text">Apartment</span>
                  </template>
                </v-text-field>
                <div style="margin-top: -10px" class="red-color">
                  {{ errors.first("Apartment") }}
                </div>
              </div>
              <div class="col-md-4">
                <v-text-field v-validate="'required'" name="Building" data-vv-as="Building" v-model="editaddress.building"
                  class="mt-5">
                  <template v-slot:label>
                    <span class="add-text">Building</span>
                  </template>
                </v-text-field>
                <div style="margin-top: -10px" class="red-color">
                  {{ errors.first("Building") }}
                </div>
              </div>
              <div class="col-md-4">
                <v-text-field v-validate="'required'" name="Block" data-vv-as="Block" v-model="editaddress.block"
                  class="mt-5">
                  <template v-slot:label>
                    <span class="add-text">Block</span>
                  </template>
                </v-text-field>
                <div style="margin-top: -10px" class="red-color">
                  {{ errors.first("Block") }}
                </div>
              </div>
              <div class="col-md-4">
                <v-text-field v-validate="'required'" name="Street" data-vv-as="Street" v-model="editaddress.street"
                  class="mt-5">
                  <template v-slot:label>
                    <span class="add-text">Street</span>
                  </template>
                </v-text-field>
                <div style="margin-top: -10px" class="red-color">
                  {{ errors.first("Street") }}
                </div>
              </div>
              <div class="col-md-4">
                <v-text-field v-model="editaddress.avenue" class="mt-5">
                  <template v-slot:label>
                    <span class="add-text">Avenue</span>
                  </template>
                </v-text-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 offset-md-6">
                <button @click="editadd = false" type="button" class="align-self-end bl-cancel-btn w-100"
                  style="margin-top: 20px">
                  Cancel
                </button>
              </div>
              <div class="col-md-3">
                <button @click="editSubmit()" type="button" class="align-self-end bl-primary-btn w-100"
                  style="margin-top: 20px">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-dialog>
    <v-dialog v-model="deleteDialog" scrollable width="418">
      <div class="w-100 bg-white pa-8">
        <div class="text-center sub-heading">Delete ?</div>
        <div class="service-content text-center mt-4">
          Do you want to delete ?
        </div>
        <div class="row mt-5">
          <div class="col-md-6">
            <button @click="deleteDialog = false" type="button" class="align-self-end bl-cancel-btn w-100"
              style="margin-top: 20px">
              No
            </button>
          </div>
          <div class="col-md-6">
            <button @click="removeCart()" type="button" class="align-self-end bl-primary-btn w-100"
              style="margin-top: 20px">
              YES
            </button>
          </div>
        </div>
      </div>
    </v-dialog>
    <v-dialog v-model="resetDialog" persistent scrollable width="418">
      <div class="w-100 bg-white pa-8">
        <div class="text-center sub-heading">Reset ?</div>
        <div class="service-content text-center mt-4">
          Do you want to reset schedule ?
        </div>
        <div class="text-center pt-5" v-if="resetloader">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
        <div class="row mt-5" v-else>
          <div class="col-md-6">
            <button @click="resetDialog = false" type="button" class="align-self-end bl-cancel-btn w-100"
              style="margin-top: 20px">
              No
            </button>
          </div>
          <div class="col-md-6">
            <button @click="resetSchedule()" type="button" class="align-self-end bl-primary-btn w-100"
              style="margin-top: 20px">
              YES
            </button>
          </div>
        </div>
      </div>
    </v-dialog>
    <v-dialog v-model="paymentDialog" scrollable width="418">
      <div class="w-100 bg-white pa-8">
        <div class="text-right">
          <v-icon color="#C4C4C4" class="pointer" @click="paymentDialog = false">
            mdi-close-circle-outline
          </v-icon>
        </div>
        <div>
          <div class="service-card-card-size w400 mb-2">Total Payment</div>
          <hr class="hr-side" />
          <div v-if="cart_final_cost" class="payment-final mt-1" style="color: #30b474">
            KD. {{ formatAmount(cart_final_cost) }}
          </div>
          <div v-else class="payment-final mt-1" style="color: #30b474">
            KD. 0
          </div>
          <hr class="hr-side" />
          <div class="service-card-card-size w400 mb-1 mt-2">
            Choose payment method below
          </div>
          <div class="d-flex mt-3">
            <div @click="selected_pay_type = 'knet'" class="mr-3 pointer">
              <div class="payment-card" :class="selected_pay_type == 'knet' ? 'active' : ''">
                <div style="
                      background: #015499;
                      border-radius: 2px;
                      padding: 5.4px 12px;
                    " class="text-center">
                  <svg width="33" height="24" viewBox="0 0 33 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M32.2965 0.246582H0.818359V6.49359H32.2965V0.246582Z" fill="#015499" />
                    <path d="M32.2965 7.10022H0.818359V16.9263H32.2965V7.10022Z" fill="#015499" />
                    <path d="M32.2965 17.533H0.818359V23.7806H32.2965V17.533Z" fill="#015499" />
                    <path d="M28.2326 4.99805H27.3057V5.57426H28.2326V4.99805Z" fill="white" />
                    <path d="M27.051 4.99805H26.124V5.57426H27.051V4.99805Z" fill="white" />
                    <path
                      d="M13.1518 3.91583H12.5855V3.10304H11.4431V3.91583H3.18955L3.08942 3.70151L3.87173 2.68318V1.95471H2.74804L1.50781 3.56917L2.214 5.0788H14.2942V3.10304H13.1518V3.91583Z"
                      fill="white" />
                    <path d="M14.2131 2.04309H13.2861V2.61931H14.2131V2.04309Z" fill="white" />
                    <path d="M5.51972 2.21765H4.59277V2.79387H5.51972V2.21765Z" fill="white" />
                    <path d="M6.72382 2.21765H5.79688V2.79387H6.72382V2.21765Z" fill="white" />
                    <path
                      d="M30.8951 2.86944H27.5187L27.0052 2.44138H30.5912L30.217 1.78845H25.7146L25.6425 2.01976L27.189 3.30863H29.8498V3.65119H26.9747L25.9986 2.83723H24.0486V4.56061H20.838V3.85439H23.5456V2.83723H19.168V5.39565H25.2045V4.03592L25.8856 4.60394H30.9396L31.6481 3.87782V3.64124L30.8951 2.86944Z"
                      fill="white" />
                    <path d="M17.5588 3.4187H15.4209V4.35212H17.5588V3.4187Z" fill="white" />
                    <path
                      d="M28.134 15.956L22.3551 11.6087L28.0321 8.13147H17.8626L12.8883 11.1783V8.13147H5.7959V15.956H12.8883V11.8827L17.4404 15.956H28.134Z"
                      fill="#FFED00" />
                    <path
                      d="M4.38184 22.9402V19.0636H5.39838V19.5239C5.5436 19.3359 5.71458 19.1954 5.91192 19.1011C6.10925 19.0074 6.32942 18.96 6.57185 18.96C6.99989 18.96 7.3202 19.0712 7.53276 19.2932C7.74532 19.5157 7.85189 19.85 7.85189 20.2974V22.9402H6.81427V20.5973C6.81427 20.3191 6.76742 20.1206 6.67315 20.0017C6.57946 19.8828 6.42663 19.8237 6.21465 19.8237C5.96989 19.8237 5.77606 19.8969 5.63319 20.0433C5.49031 20.1897 5.41946 20.3888 5.41946 20.6406V22.9408H4.38184V22.9402Z"
                      fill="white" />
                    <path
                      d="M17.2138 21.7152H18.2479C18.1431 22.1245 17.9323 22.4448 17.6149 22.6767C17.2975 22.9086 16.9105 23.0245 16.4537 23.0245C15.8927 23.0245 15.4477 22.836 15.1198 22.4594C14.7919 22.0829 14.6279 21.5723 14.6279 20.9275C14.6279 20.2828 14.7895 19.7921 15.1128 19.4261C15.436 19.0601 15.8781 18.8768 16.4397 18.8768C17.034 18.8768 17.4931 19.0572 17.8163 19.4173C18.1396 19.7775 18.3012 20.2904 18.3012 20.9562C18.3012 21.03 18.3006 21.0851 18.2994 21.122C18.2982 21.1589 18.2953 21.194 18.2906 21.2274H15.7013C15.7153 21.5319 15.7897 21.7614 15.9244 21.9154C16.0585 22.07 16.2517 22.1473 16.5041 22.1473C16.6827 22.1473 16.8285 22.1122 16.9427 22.0419C17.0568 21.9716 17.147 21.8633 17.2138 21.7157V21.7152ZM15.7019 20.5457H17.2284C17.2191 20.284 17.1494 20.0849 17.02 19.9485C16.8905 19.812 16.7043 19.7435 16.4619 19.7435C16.2359 19.7435 16.0585 19.8126 15.9285 19.9502C15.7991 20.0884 15.7235 20.2869 15.7019 20.5457Z"
                      fill="white" />
                    <path
                      d="M28.6061 23.4216C28.5112 23.4239 28.3976 23.428 28.2653 23.4339C28.1335 23.4397 28.0498 23.4427 28.0141 23.4427C27.617 23.4427 27.3453 23.3683 27.1989 23.2196C27.0526 23.0708 26.9794 22.778 26.9794 22.3406V20.2436H26.4658V19.5157H26.9794V18.4564H28.0064V19.5157H28.6055V20.2436H28.0064V22.391C28.0064 22.4934 28.0293 22.5602 28.0744 22.5924C28.1195 22.6246 28.212 22.6404 28.3525 22.6404H28.6055V23.4216H28.6061Z"
                      fill="white" />
                  </svg>
                </div>
                <div class="payment-card-seleted" v-if="selected_pay_type == 'knet'">
                  <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.3333 0.5H3.66667C2.19391 0.5 1 1.69391 1 3.16667V9.83333C1 11.3061 2.19391 12.5 3.66667 12.5H10.3333C11.8061 12.5 13 11.3061 13 9.83333V3.16667C13 1.69391 11.8061 0.5 10.3333 0.5Z"
                      fill="white" stroke="#30B474" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M5 6.49996L6.5 7.83329L9 5.16663" fill="white" />
                    <path d="M5 6.49996L6.5 7.83329L9 5.16663" stroke="#30B474" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
              <div class="service-card-card-size w400 mt-1 text-center">
                K Net
              </div>
            </div>
            <div class="pointer" @click="selected_pay_type = 'credit'">
              <div class="payment-card" :class="selected_pay_type == 'credit' ? 'active' : ''">
                <svg width="56" height="36" viewBox="0 0 56 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect y="0.5" width="55.5169" height="34.8157" rx="2" fill="#01ADFF" />
                  <rect x="5" y="8.5" width="12" height="9" rx="2" fill="#FFC145" />
                  <rect x="5" y="20.5" width="9" height="6" rx="2" fill="#D9D9D9" />
                  <rect x="17" y="20.5" width="9" height="6" rx="2" fill="#D9D9D9" />
                  <rect x="29" y="20.5" width="9" height="6" rx="2" fill="#D9D9D9" />
                  <rect x="41" y="20.5" width="9" height="6" rx="2" fill="#D9D9D9" />
                  <rect x="17" y="4.5" width="22" height="2" rx="1" fill="#D9D9D9" />
                  <rect x="5" y="29.5" width="16" height="2" rx="1" fill="#D9D9D9" />
                  <rect x="41" y="4.5" width="9" height="2" rx="1" fill="#D9D9D9" />
                  <rect x="22" y="29.5" width="7" height="2" rx="1" fill="#D9D9D9" />
                </svg>
                <div class="payment-card-seleted" v-if="selected_pay_type == 'credit'">
                  <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.3333 0.5H3.66667C2.19391 0.5 1 1.69391 1 3.16667V9.83333C1 11.3061 2.19391 12.5 3.66667 12.5H10.3333C11.8061 12.5 13 11.3061 13 9.83333V3.16667C13 1.69391 11.8061 0.5 10.3333 0.5Z"
                      fill="white" stroke="#30B474" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M5 6.49996L6.5 7.83329L9 5.16663" fill="white" />
                    <path d="M5 6.49996L6.5 7.83329L9 5.16663" stroke="#30B474" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
              <div class="service-card-card-size w400 mt-1 text-center">
                Credit Card
              </div>
            </div>
          </div>
          <div class="text-center pb-8">
            <button @click="paymentDialog = false" type="button" class="align-self-end bl-secondary-btn ma-3"
              style="min-width: 162.37px">
              Back
            </button>
            <button @click="payAmount()" type="button" class="align-self-end bl-primary-btn"
              style="min-width: 162.37px; background: #30b474">
              Proceed
            </button>
          </div>
        </div>
      </div>
    </v-dialog>
    <!-- test https://testpay.bleach-kw.com/SendPerformREQuest.php -->
    <!-- live - debbit-card action="https://payment.bleachkw.com/SendPerformREQuest.php" -->
    <form method="POST" action="https://payment.bleachkw.com/SendPerformREQuest.php">
      <input name="price" hidden :value="debit_data.price" />
      <input type="text" name="payment_mode" v-model="debit_data.payment_mode" hidden />
      <input type="text" name="qty" v-model="debit_data.qty" hidden />
      <input type="text" name="evaluation_id" v-model="debit_data.evaluation_id" hidden />
      <input type="text" name="order_status" v-model="debit_data.order_status" hidden />
      <input type="text" name="address_id" v-model="debit_data.address_id" hidden />

      <input type="submit" hidden ref="eval_sub" />
    </form>
    <v-dialog v-model="adddeleteDialog" scrollable width="418">
      <div class="w-100 bg-white pa-8">
        <div class="text-center sub-heading">Delete ?</div>
        <div class="service-content text-center mt-4">
          Do you want to delete ?
        </div>
        <div class="row mt-5">
          <div class="col-md-6">
            <button @click="adddeleteDialog = false" type="button" class="align-self-end bl-cancel-btn w-100"
              style="margin-top: 20px">
              No
            </button>
          </div>
          <div class="col-md-6">
            <button @click="deleteAddress()" type="button" class="align-self-end bl-primary-btn w-100"
              style="margin-top: 20px">
              YES
            </button>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import dialogboxside from "../../components/dialogboxside.vue";
import emptycart from "./emprtcart.vue";
import service from "../../services/service";
import stepper from "../../components/stepper.vue";
import selecticon from "../../components/selecticon.vue";
import utils from "../../utils/utils";
import moment from "moment";
export default {
  mounted() {
    this.getUserCart();
    this.listaddress();
    this.getAddressDatas();
    var d = new Date(this.today);
    d.setDate(d.getDate() + 1);
    this.today = d.toISOString().substr(0, 10);
    this.picker = d.toISOString().substr(0, 10);
    this.date = d.toISOString().substr(0, 10);
    // this.setColor();
  },
  components: {
    dialogboxside,
    selecticon,
    stepper,
    emptycart,
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  data() {
    return {
      onetime_day_select: null,
      onetime_select: null,
      is_mobile: false,
      mobile_schedule: false,
      plan_error_flag: false,
      deleteAddId: null,
      adddeleteDialog: false,
      editadd: false,
      loaderAdd: false,
      governorates: [],
      areas: [],
      locationType: [],
      newaddress: {
        location: "",
        currently_active: "true",
        area: null,
        governorate: null,
        block: "",
        avenue: "",
        building: "",
        street: "",
        floor: "",
        apartment: "",
      },
      editaddress: {
        address_id: null,
        location: "",
        currently_active: "true",
        area: null,
        governorate: null,
        block: "",
        avenue: "",
        building: "",
        street: "",
        floor: "",
        apartment: "",
      },
      auto_fix: false,
      time_slots_one: [],
      time_slots_custom: [],
      multi_day_selected: null,
      multiDates: [],
      no_of_day: 0,
      no_of_visits: 0,
      onetime: false,
      privacyCheck: false,
      customSelectedSlots: [],
      customSlotIndex: null,
      select_days: [],
      no_day: 0,
      no_of_week: 0,
      scheduelloader: false,
      oneTimeSlot: null,
      selectedAddress: null,
      resetloader: false,
      resetDialog: false,
      debit_data: {
        price: null,
        payment_mode: "prepaid",
        qty: 1,
        evaluation_id: null,
        order_status: "CUSTOMER_BOOKING",
        address_id: null,
      },
      cart_id: null,
      customer: null,
      customer_ip_address: null,
      address: [],
      // selected_pay_type: "credit",
      selected_pay_type: "knet",
      paymentDialog: false,
      selected_edit_slot: null,
      edit_slot_date: null,
      edit_slot_flag: false,
      slot_edit_index: null,
      autofix_flag: false,
      aval_loader: false,
      selectSolt: null,
      avail_date: [],
      avail_date_slot: [],
      items: [1, 2, 3, 4, 5, 6],
      prefDays: [],
      getDate: {
        action_type: "",
        start_date: "",
        total_visits: "",
        days: [],
        coming_days: [],
      },

      loader: false,
      deleteDialog: false,
      delId: null,
      cartItems: [],
      picker: new Date().toISOString().substr(0, 10),
      today: new Date().toISOString().substr(0, 10),
      newadd: false,
      addressdialog: false,
      schedule: false,
      days: ["MON", "TUE", "WED", "THE", "FRI", "SAT", "SUN"],
      date: new Date().toISOString().substr(0, 10),
      menu2: false,
      step: 1,
      selected: null,
      dialog: false,
      cart_final_cost: false,
      discount_amount: false,
      plans: [
        {
          cleaning_policy: "ONE TIME SERVICE",
          id: 1,
          req_name: "daily",
          name: "OneTime",
          description:
            "Select this option for one time service(s) only. Service is not repeatable in this option.",
        },

        {
          cleaning_policy: "SUBSCRIPTION",
          id: 2,
          req_name: "daily",
          name: "Daily",
          description:
            "Select this option to repeat the service(s) continuously every day.",
        },
        {
          cleaning_policy: "SUBSCRIPTION",
          id: 3,
          req_name: "alternate_daily",
          name: "Alternate Day",
          description:
            "Select this option to repeat the service(s) on alternate days.",
        },
        {
          cleaning_policy: "SUBSCRIPTION",
          id: 4,
          req_name: "weekly",
          name: "Weekly",
          description:
            "Select this option to repeat the service(s) on specific day(s) each week.",
        },
        {
          cleaning_policy: "SUBSCRIPTION",
          id: 5,
          req_name: "alternate_weekly",
          name: "Alternate Week",
          description:
            "Select this option to repeat the service(s) on specific day(s) every alternate week.",
        },
        {
          cleaning_policy: "SUBSCRIPTION",
          id: 6,
          req_name: "monthly",
          name: "Monthly",
          description:
            "Select this option to repeat the service(s) on specific date(s) every month.",
        },
        {
          cleaning_policy: "SUBSCRIPTION",
          id: 7,
          req_name: "custom",
          name: "Custom",
          description:
            "Select this option to repeat the service(s) on dates of your choice.",
        },
      ],
      time_slots_filter: [
        {
          start_time: "08:00 AM",
          end_time: "10:00 AM",
          full_text: "08:00 AM - 10:00 AM",
        },
        {
          start_time: "10:00 AM",
          end_time: "12:00 PM",
          full_text: "10:00 AM - 12:00 PM",
        },
        {
          start_time: "12:00 PM",
          end_time: "02:00 PM",
          full_text: "12:00 PM - 02:00 PM",
        },
        {
          start_time: "02:00 PM",
          end_time: "04:00 PM",
          full_text: "02:00 PM - 04:00 PM",
        },
        {
          start_time: "04:00 PM",
          end_time: "06:00 PM",
          full_text: "04:00 PM - 06:00 PM",
        },
        {
          start_time: "06:00 PM",
          end_time: "08:00 PM",
          full_text: "06:00 PM - 08:00 PM",
        },
        {
          start_time: "08:00 PM",
          end_time: "10:00 PM",
          full_text: "08:00 PM - 10:00 PM",
        },
      ],
      time_slots: [],
      del_index: null,
      subStat: "",
      max_cleaners: [],
      min_cleaners: [],
      max_hours: [],
      min_hours: [],
      duration: [],
      totalmanhour: 0,
      schedule_serviceTypes: [],
      durationData: {},
      schedule_serviceTypes_selected: [],
      sofa_size: 0,
      chair_size: 0,
      maxCleaners: [],
      calculatedService: [],
      n: 0,
      new_kitchen_cabinet_size: 0,
      old_kitchen_cabinet_size: 0,
      new_kitchen_nocabinet_size: 0,
      old_kitchen_nocabinet_size: 0,
      cleaning_set: [],
      selectedDuration: {},
      preferred_day_flag: false,
      selected_day_flag: false,
      slot_loader: false,
      onetime_slot_flag: false,
      custom_slot_flag: false,
      errorFlag: false,
      customSelectedSlotsDates: [],
      max_select_flag: false,
      tempId: null,
      isInactiveNext: true,
      promo_code: null,
      promoCode:null,
      promocodeNotes:null,
      promoLoader: false,
      promo_error:false,
    };
  },
  methods: {
    clearCustomVariables() {
      this.customSelectedSlotsDates = [];
      this.customSelectedSlots = [];
      this.customSlotIndex = null;
      const today = new Date();
      var tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      this.picker = moment(tomorrow).format("YYYY-MM-DD");
      // this.custom_slot_flag = false;
    },
    goto() {
      // window.location = loc;
      // this.$router.push('/service')
      this.$store.commit("set_cart", true);
      this.$router.push("/");
    },
    checkisInactiveNext() {
      var flag = false;
      //console.log(this.avail_date_slot, "avil derd");
      for (var i = 0; i < this.avail_date_slot.length; i++) {
        if (this.avail_date_slot[i].is_available == false) {
          flag = true;
          break;
        } else flag = false;
      }
      if (flag == true) return true;
    },

    // checkOneTime()
    // {

    // }
    checkAddressLen() {
      if (this.address.length == 0) this.addressdialog = false;
    },
    // dateClicked(val) {
    //   var allDates = document.querySelectorAll(
    //     ".v-date-picker-table .v-btn .v-btn__content"
    //   );
    //   var dates = this.customSelectedSlotsDates.map((x) =>
    //     parseInt(x.split("-")[2])
    //   );
    //   allDates.forEach((x, i) => {
    //     if (
    //       dates.includes(parseInt(val.split("-")[2])) &&
    //       parseInt(val.split("-")[2]) == x.innerHTML
    //     ) {
    //       x.parentNode.style = "background-color: #00000 !important";
    //     } else {
    //       x.parentNode.style = "";
    //     }
    //   });
    // },
    // setColor() {
    //   var allDates = document.querySelectorAll(
    //     ".v-date-picker-table .v-btn .v-btn__content"
    //   );
    //   var dates = this.customSelectedSlotsDates.map((x) =>
    //     parseInt(x.split("-")[2])
    //   );
    //   //console.log(dates);
    //   allDates.forEach((x, i) => {
    //     if (dates.includes(parseInt(x.innerHTML))) {
    //       allDates[i].classList.add("date-color");
    //     }
    //   });
    // },
    //      allowedDates(val)
    //      {
    // for(var i=0;i<this.customSelectedSlots.length;i++)
    // {
    //   if(this.customSelectedSlots[i].date=val)
    //   return this.customSelectedSlots[i].date
    // }
    //      },
    getAllowedDates(val) {
      if (this.customSelectedSlotsDates.indexOf(val) == -1) {
        return true;
      } else {
        return false;
      }
    },
    getAllowedDates1(val) {
      if (this.customSelectedSlotsDates.indexOf(val) !== -1) {
        return true;
      } else {
        return false;
      }
    },

    //  allowedDates: val => el1.indexOf(val) == -1,
    // allowedDates: val => parseInt(val.split('-')[2], 10) % 2 === 0,
    goTopDialog() {
      if (screen.width < 768) {
        this.$nextTick(() =>
          window.document.getElementById("id_dialog").scrollIntoView()
        );
      }
    },
    myEventHandler() {
      if (screen.width < 768) {
        this.is_mobile = true;
        if (this.schedule) {
          this.mobile_schedule = true;
        } else {
          this.mobile_schedule = false;
        }
      } else {
        this.is_mobile = false;
        this.mobile_schedule = true;
      }
    },
    checkPlanselect() {
      this.plan_error_flag = false;
      if (this.selected == null) {
        this.plan_error_flag = true;
      } else {
        this.resetDateData();
        this.step = this.step + 1;
        this.customSelectedSlots = [];
        this.prefDays = [];
        this.oneTimeSlotAvailable();
        this.customSlotAvailable();
      }
    },
    bookNow() {
      console.log(this.cart_final_cost,"final")

      if (this.address.length == 0) {
        this.newadd = true;
        this.addressdialog = true;
        return;
      }
      else if(this.cart_final_cost==0)
      {
        location.href ='https://my.bleachkw.com/customer/payment/response/?udf1='+this.cart_id+'&amt='+this.cart_final_cost+'&udf3=CUSTOMER_BOOKING'+'&udf5='+this.address[this.selectedAddress].id+'&result=ZEROPAYMENT'
        // location.href ='https://mytest.bleach-kw.com/customer/payment/response/?udf1='+this.cart_id+'&amt='+this.cart_final_cost+'&udf3=CUSTOMER_BOOKING'+'&udf5='+this.address[this.selectedAddress].id+'&result=ZEROPAYMENT'
     return;
      }
      this.paymentDialog = true;
    },
    async deleteAddress() {
      let result = await service._post(
        "api/customer-addresses/" +
        localStorage.getItem("bleach-tk") +
        "/?action=disable_address",
        {
          address_id: this.deleteAddId,
        }
      );
      if (utils.isReqSuccess(result)) {
        this.listaddress();
        this.adddeleteDialog = false;
      } else {
        this.adddeleteDialog = false;
        this.$vs.notification({
          duration: "3000",
          position: "top-right",
          text: "Try again",
          border: "danger",
          title: "Address delete failed",
        });
      }
    },
    async saveAddress() {
      this.errorFlag = false;
      if (!(this.editaddress.block.includes("Block") || this.editaddress.block.includes("block")))
        this.editaddress.block = this.editaddress.block + " Block";
      if (!(this.editaddress.avenue.includes("Avenue") || this.editaddress.avenue.includes("avenue")))
        this.editaddress.avenue = this.editaddress.avenue + " Avenue";
      if (!(this.editaddress.apartment.includes("Apartment") || this.editaddress.apartment.includes("apartment")))
        this.editaddress.apartment = this.editaddress.apartment + " Apartment";
      if (!(this.editaddress.street.includes("Street") || this.editaddress.street.includes("street")))
        this.editaddress.street = this.editaddress.street + " Street";
      if (!(this.editaddress.building.includes("Building") || this.editaddress.building.includes("building")))
        this.editaddress.building = this.editaddress.building + " Building";
      if (!(this.editaddress.floor.includes("Floor") || this.editaddress.floor.includes("floor")))
        this.editaddress.floor = this.editaddress.floor + " Floor";

      let result = await service._post(
        "api/customer-addresses/" +
        localStorage.getItem("bleach-tk") +
        "/?action=edit_address",
        this.editaddress
      );
      if (result.status == 200) {
        this.editaddress = {
          address_id: null,
          location: "",
          currently_active: "true",
          area: null,
          governorate: null,
          block: "",
          avenue: "",
          building: "",
          street: "",
          floor: "",
          apartment: "",
        };
        this.editadd = false;
        this.listaddress();
      } else if (result.status == 500) {
        this.errorFlag = true;
      }
    },
    editSubmit() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }
        this.saveAddress();
      });
    },
    editAddressData(data) {
      this.getAreasEdit(data.governorate.id);
      this.editaddress.address_id = data.id;
      this.editaddress.location = data.location;
      this.editaddress.apartment = data.apartment;
      this.editaddress.area = data.area.id;
      this.editaddress.avenue = data.avenue;
      this.editaddress.block = data.block;
      this.editaddress.building = data.building;
      this.editaddress.floor = data.floor;
      this.editaddress.governorate = data.governorate.id;
      this.editaddress.street = data.street;
      this.editadd = true;
    },
    async getAreas() {
      let result = await service._get(
        "api/areas/" + this.newaddress.governorate + "/"
      );
      if (result.status == 200) {
        this.areas = result.data.areas;
      }
    },
    async addNewAddress() {
      this.loaderAdd = true;
      this.errorFlag = false;

      // this.newaddress.governorate = this.newaddress.governorate.id;
      // this.newaddress.area = this.newaddress.area.id;
      if (!(this.newaddress.block.includes("Block") || this.newaddress.block.includes("block")))
        this.newaddress.block = this.newaddress.block + " Block";
      if (!(this.newaddress.avenue.includes("Avenue") || this.newaddress.avenue.includes("avenue")))
        this.newaddress.avenue = this.newaddress.avenue + " Avenue";
      if (!(this.newaddress.apartment.includes("Apartment") || this.newaddress.apartment.includes("apartment")))
        this.newaddress.apartment = this.newaddress.apartment + " Apartment";
      if (!(this.newaddress.street.includes("Street") || this.newaddress.street.includes("street")))
        this.newaddress.street = this.newaddress.street + " Street";
      if (!(this.newaddress.building.includes("Building") || this.newaddress.building.includes("building")))
        this.newaddress.building = this.newaddress.building + " Building";
      if (!(this.newaddress.floor.includes("Floor") || this.newaddress.floor.includes("floor")))
        this.newaddress.floor = this.newaddress.floor + " Floor";
      let result = await service._post(
        "api/customer-addresses/" +
        localStorage.getItem("bleach-tk") +
        "/?action=add_address",
        { addresses: [this.newaddress] }
      );
      this.loaderAdd = false;
      if (result.status == 200) {
        this.$vs.notification({
          duration: "3000",
          position: "top-right",
          border: "success",
          title: result.data.message,
        });
        this.addressdialog = false;
        this.listaddress();
        this.newadd = false;
        this.newaddress = {
          location: "",
          currently_active: "true",
          area: null,
          governorate: null,
          block: "",
          avenue: "",
          building: "",
          street: "",
          floor: "",
          apartment: "",
        };
        // this.dialog = false
      
      } else if (result.status == 500) {
        this.errorFlag = true;
      }
    },
    addNewaddSubmit() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }
        this.addNewAddress();
      });
    },
    async getAreasEdit(id) {
      let result = await service._get("api/areas/" + id + "/");
      if (result.status == 200) {
        this.areas = result.data.areas;
      }
    },
    async getAddressDatas() {
      let result2 = await service._get("api/location-types/");
      if (result2.status == 200) {
        this.locationType = result2.data.location_types;
      }
      let result = await service._get("api/governorates/");
      if (result.status == 200) {
        this.governorates = result.data.governorates;
      }
    },
    customNext() {
      this.custom_slot_flag = false;
      this.edit_slot_flag = false;

      if (this.customSelectedSlots.length == 0) {
        if (this.customSlotIndex == null) {
          this.custom_slot_flag = true;
        } else {
          this.addCustomSlots();
          this.step = this.step + 1;
          this.customDateAvail();
        }
      } else {
        this.step = this.step + 1;
        this.customDateAvail();
      }
    },
    dayChange(ind) {
      this.onetime_slot_flag = false;
      if (this.multiDates[this.multi_day_selected].selected_time == null) {
        this.onetime_slot_flag = true;
        return;
      } else {
        this.multi_day_selected = ind;
        this.oneTimeSlotAvailable();
      }
    },
    onetimeNextDay() {
      this.onetime_slot_flag = false;
      if (this.multiDates[this.multi_day_selected].selected_time == null) {
        this.onetime_slot_flag = true;
      } else {
        this.multi_day_selected++;
        this.oneTimeSlotAvailable();
      }
    },
    resetDateData() {
      var d = new Date();
      d.setDate(d.getDate() + 1);
      this.today = d.toISOString().substr(0, 10);
      this.date = d.toISOString().substr(0, 10);
      this.selectSolt = null;
      this.getDate.total_visits = "";
      this.prefDays = [];
      this.select_days = [];
      this.selected_day_flag = false;
      this.preferred_day_flag = false;
      this.onetime_slot_flag = false;
      this.onetime_select = null;
      this.$validator.reset();
    },
    resetDialogData() {
      this.onetime_select = null;

      this.plan_error_flag = false;
      this.no_of_day = 0;
      this.step = 1;
      this.selected = null;
      var d = new Date();
      d.setDate(d.getDate() + 1);
      this.date = d.toISOString().substr(0, 10);
      this.selectSolt = null;
      this.getDate.total_visits = "";
      this.prefDays = [];
      this.select_days = [];
      this.selected_day_flag = false;
      this.preferred_day_flag = false;
      this.onetime_slot_flag = false;
      this.$validator.reset();
    },
    nextStepValidate() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }
        if (this.selected == 4 || this.selected == 5) {
          this.preferred_day_flag = false;
          if (this.prefDays.length == 0) {
            this.preferred_day_flag = true;
            return;
          }
        }
        if (this.selected == 6) {
          this.selected_day_flag = false;
          if (this.select_days.length == 0) {
            this.selected_day_flag = true;
            return;
          }
        }
        this.getavaliableDate();
      });
      //this.getavaliableDate()
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    getEndTime(t) {
      for (var i = 0; i < this.time_slots.length; i++) {
        if (t == this.time_slots[i].start_time) {
          return this.time_slots[i].end_time;
        }
      }
    },
    selectedMultipleDay() {
      this.onetime_slot_flag = false;
      if (this.multiDates[this.multi_day_selected].selected_time == null) {
        this.onetime_slot_flag = true;
      } else {
        this.avail_date_slot = [];
        for (var i = 0; i < this.multiDates.length; i++) {
          // var sp = this.multiDates[i].selected_date.split("-");
          var dd = new Date(this.multiDates[i].selected_date);
          var time;
          if (this.time_slots_one[this.multiDates[i].selected_time])
            time = this.time_slots_one[this.multiDates[i].selected_time].time;
          var a = {
            date: moment(dd).format("DD-MM-YYYY"),
            time: time,
            is_available: true,
          };
          this.avail_date_slot.push(a);
        }
        this.step++;
      }
    },
    async oneTimeSlotAvailable() {
      //console.log("one-time");
      this.onetime_slot_flag = false;
      if (this.selected == 1 && this.no_of_day == 0) {
        this.slot_loader = true;
        this.time_slots_one = [];
        // var sp = this.picker.split("-");
        var dd = new Date(this.picker);
        var tempServ = [];
        for (var j = 0; j < this.cartItems.length; j++) {
          tempServ.push(this.cartItems[j].service_type.name);
        }
        let times = [];
        for (var i = 0; i < this.time_slots.length; i++) {
          var a = [
            moment(dd).format("DD-MM-YYYY"),
            this.time_slots[i].start_time,
            this.time_slots[i].end_time,
          ];
          times.push(a);
        }
        let result = await service._post(
          "customer/ajax/multipleservice/multipledates/cleaningslotes/",
          {
            cleaning_datetimes: times,
            policy: "onetime",
            cleaning_hours: this.selectedDuration.selectedDuration,
            number_of_cleaners: this.selectedDuration.cleaners,
            service_types: tempServ,
            shift_availability_check: true,
          }
        );
        this.slot_loader = false;
        if (result.status == 200) {
          for (var q = 0; q < result.data.combined_slots.length; q++) {
            if (result.data.combined_slots[q].is_available) {
              var aa = {
                date: result.data.combined_slots[q].date,
                is_available: result.data.combined_slots[q].is_available,
                time: result.data.combined_slots[q].time,
                full_text:
                  result.data.combined_slots[q].time +
                  " - " +
                  this.getEndTime(result.data.combined_slots[q].time),
              };
              this.time_slots_one.push(aa);
            }
          }
        }
      } else if (this.selected == 1 && this.no_of_day > 1) {
        this.getMultidayavil();
      }
    },
    async customSlotAvailable() {
      this.custom_slot_flag = false;

      this.slot_loader = true;
      this.time_slots_custom = [];
      // var sp = this.picker.split("-");
      var dd = new Date(this.picker);
      var tempServ = [];
      for (var j = 0; j < this.cartItems.length; j++) {
        tempServ.push(this.cartItems[j].service_type.name);
      }
      let times = [];
      for (var i = 0; i < this.time_slots.length; i++) {
        var a = [
          moment(dd).format("DD-MM-YYYY"),
          this.time_slots[i].start_time,
          this.time_slots[i].end_time,
        ];
        times.push(a);
      }
      let result = await service._post(
        "customer/ajax/multipleservice/multipledates/cleaningslotes/",
        {
          cleaning_datetimes: times,
          policy: "onetime",
          cleaning_hours: this.selectedDuration.selectedDuration,
          number_of_cleaners: this.selectedDuration.cleaners,
          service_types: tempServ,
          shift_availability_check: true,
        }
      );
      this.slot_loader = false;
      if (result.status == 200) {
        for (var q = 0; q < result.data.combined_slots.length; q++) {
          if (result.data.combined_slots[q].is_available) {
            var aa = {
              date: result.data.combined_slots[q].date,
              is_available: result.data.combined_slots[q].is_available,
              time: result.data.combined_slots[q].time,
              full_text:
                result.data.combined_slots[q].time +
                " - " +
                this.getEndTime(result.data.combined_slots[q].time),
            };
            this.time_slots_custom.push(aa);
          }
        }
      }
    },
    async getMultidayavil() {
      //this.multiDates[this.multi_day_selected].selected_time = null;
      this.slot_loader = true;
      this.time_slots_one = [];
      // var sp =
      //   this.multiDates[this.multi_day_selected].selected_date.split("-");
      var dd = new Date(this.multiDates[this.multi_day_selected].selected_date);
      var tempServ = [];
      for (var j = 0; j < this.cartItems.length; j++) {
        tempServ.push(this.cartItems[j].service_type.name);
      }
      let times = [];
      for (var i = 0; i < this.time_slots.length; i++) {
        var a = [
          moment(dd).format("DD-MM-YYYY"),
          this.time_slots[i].start_time,
          this.time_slots[i].end_time,
        ];
        times.push(a);
      }
      let result = await service._post(
        "customer/ajax/multipleservice/multipledates/cleaningslotes/",
        {
          cleaning_datetimes: times,
          policy: "onetime",
          cleaning_hours: this.selectedDuration.selectedDuration,
          number_of_cleaners: this.selectedDuration.cleaners,
          service_types: tempServ,
          shift_availability_check: true,
        }
      );
      this.slot_loader = false;
      if (result.status == 200) {
        let aaa = 0;
        for (var q = 0; q < result.data.combined_slots.length; q++) {
          if (result.data.combined_slots[q].is_available) {
            var aa = {
              index: aaa,
              date: result.data.combined_slots[q].date,
              is_available: result.data.combined_slots[q].is_available,
              time: result.data.combined_slots[q].time,
              full_text:
                result.data.combined_slots[q].time +
                " - " +
                this.getEndTime(result.data.combined_slots[q].time),
            };
            this.time_slots_one.push(aa);
            aaa++;
          }
        }
      }
    },
    isActiceBox(id) {
      let cls = "";
      if (this.selected == id) {
        this.tempId = id;
        cls = cls + " active";
      }
      if (this.onetime && id != 1) {
        cls = cls + " inactivate";
      }
      return cls;
    },
    openPrivacy() {
      window.location = "terms";
    },
    // checkCustomPicker()
    // {
    //    for (var i = 0; i < this.customSelectedSlotsDates.length; i++) {
    //        if(this.customSelectedSlotsDates[i]==this.picker)
    //       this.picker=null
    //       }
    // },
    async customDateAvail() {
      this.avail_date_slot = [];
      this.aval_loader = true;
      var tempServ = [];
      for (var i = 0; i < this.cartItems.length; i++) {
        tempServ.push(this.cartItems[i].service_type.name);
      }
      var cleaning_datetimes = [];
      for (var j = 0; j < this.customSelectedSlots.length; j++) {
        cleaning_datetimes.push(
          this.customSelectedSlots[j].date +
          " " +
          this.customSelectedSlots[j].s.start_time
        );
      }
      //console.log(cleaning_datetimes);

      var reqBody = {
        cleaning_datetimes: cleaning_datetimes,
        cleaning_hours: this.selectedDuration.selectedDuration,
        number_of_cleaners: this.selectedDuration.cleaners,
        service_types: tempServ,
        shift_availability_check: true,
      };
      let result = await service._post(
        "customer/ajax/multipleservice/multipledates/cleaningslotes/",
        reqBody
      );
      this.aval_loader = false;

      if (utils.isReqSuccess(result)) {
        this.avail_date_slot = result.data.combined_slots;
      }
      //console.log(result);
    },
    subscriptionHourCalculation() {
      var n = this.totalmanhour;
      //console.log(n, "man hour");

      if (n % 2 == 1) {
        n = n + 1;
      }
      //console.log("new n", n);
      // console.log(
      //   "min man data is" +
      //     this.min_cleaners +
      //     " maxman:" +
      //     this.max_cleaners +
      //     "minhr:" +
      //     this.min_hours +
      //     "maxhr:" +
      //     this.max_hours
      // );
      var minman = Math.max(...this.min_cleaners);
      var maxman = Math.max(...this.max_cleaners);
      var minhr = Math.max(...this.min_hours);
      var maxhr = Math.max(...this.max_hours);
      // console.log(
      //   "min man is" +
      //     minman +
      //     " maxman:" +
      //     maxman +
      //     "minhr:" +
      //     minhr +
      //     "maxhr:" +
      //     maxhr
      // );

      //allowed calculation
      var allowed = [];
      for (var i = minhr; i <= maxhr; i++) {
        this.dialog = true;
        if (i % 2 == 0 && i != 8) {
          allowed.push(i);
        }
      }
      // //console.log(allowed, "allowed");
      //initialization

      var maxn = maxman * maxhr;
      // //console.log(maxn, "maxn");
      //var minn= minman*minhr

      var rem = n % maxn;
      // //console.log(rem, "rem");

      var cleaningset = [];

      //Append Each Days Pair
      // //console.log(n - rem, "nrem");
      var days = parseInt((n - rem) / maxn);
      // //console.log(days, "dayst");

      for (var q = 0; q < days; q++) {
        cleaningset.push([maxhr, maxman]);
      }
      // //console.log(cleaningset, "cleaninf=g set old");

      //Append Remining Low Pair
      if (rem != 0) {
        var lowpair = [allowed[0], Math.round(rem / allowed[0])];

        for (var w = 0; w < allowed.length; w++) {
          if (
            lowpair[0] + lowpair[1] >
            allowed[w] + Math.round(rem / allowed[w])
          ) {
            lowpair = [allowed[w], Math.round(rem / allowed[w])];
          }
        }
        // //console.log(lowpair, "lowpair");

        if (lowpair[1] != 0 && lowpair[1] < minman) {
          cleaningset.push([minhr, minman]);
        } else if (lowpair[1] != 0 && lowpair[1] > maxman) {
          for (var e = 0; e < allowed.reverse(); e++) {
            var rev = allowed.reverse();
            if (Math.round(rem / rev[e]) <= maxman) {
              cleaningset.push(rev[e], Math.round(rem / rev[e]));
              break;
            }
          }
        } else if (
          lowpair[1] != 0 &&
          lowpair[1] >= minman &&
          lowpair[1] <= maxman
        ) {
          cleaningset.push(lowpair);
        }
      }
      if (cleaningset.length == 0 && n != 0) {
        cleaningset = [minhr, minman];
      }
      // //console.log(cleaningset, "cleaningset 1");

      //cleaning set smoothening for 2-D array
      //var last_set_length
      //var last_set;
      if (cleaningset.length > 1 && !Number.isInteger(cleaningset[0])) {
        // //console.log("hi 1");
        var last_set_length = cleaningset.length;
        //var last_set        = cleaningset[last_set_length-1]

        var fixed_hour, variable_cleaner;
        try {
          fixed_hour = cleaningset[0][0];
          variable_cleaner = cleaningset[0][1];
        } catch {
          fixed_hour = cleaningset[0];
          variable_cleaner = cleaningset[1];
        }
        for (var t = 1; t <= variable_cleaner; t++) {
          if (last_set_length * fixed_hour * t >= n && t >= minman) {
            // //console.log(t, "t vale");
            cleaningset = [];
            for (var j = 0; j < last_set_length; j++) {
              cleaningset.push([fixed_hour, t]);
            }

            break;
          }
        }
      }

      //1D array to 2D array
      if (cleaningset.length > 0) {
        // //console.log("hi 2");

        if (Number.isInteger(cleaningset[0])) {
          // //console.log("ooo");
          cleaningset = [cleaningset];
        }
      }

      this.cleaning_set = cleaningset;
      // //console.log(cleaningset, "new cleaning set");
      this.onetime = false;
      this.no_of_day = 0;
      this.multiDates = [];
      if (cleaningset.length > 1) {
        this.onetime = true;
        this.no_of_day = cleaningset.length;
        this.multi_day_selected = null;
        for (var b = 0; b < this.no_of_day; b++) {
          var dd = new Date();
          dd.setDate(dd.getDate() + (b + 1));

          var a = {
            day: `Day ${b + 1}`,
            day1: `Next`,
            selected_date: dd.toISOString().substr(0, 10),
            selected_time: null,
            idFilled: false,
          };
          this.multiDates.push(a);
        }
        this.multi_day_selected = 0;
        // this.multiDates[0].selected_date = new Date().toISOString().substr(0, 10)
      }
      var sub_cleaners = 0;
      for (var cs = 0; cs < this.cleaning_set.length; cs++) {
        sub_cleaners = sub_cleaners + this.cleaning_set[cs][1];
      }
      this.selectedDuration = {
        cleaners: sub_cleaners,
        selectedDuration: this.cleaning_set[0][0],
        slots: Math.ceil(this.cleaning_set[0][0] / 2),
      };
      this.time_slots = [];
      // //console.log(
      //   this.selectedDuration,
      //   this.selectedDuration.selectedDuration,
      //   "selelct duration"
      // );
      for (var k = 0; k < this.time_slots_filter.length; k++) {
        ////console.log(this.time_slots_filter[k],this.time_slots_filter[k+this.selectedDuration.slots-1])
        var obj = {
          index: k,
          full_text:
            this.time_slots_filter[k].start_time +
            " - " +
            this.time_slots_filter[k + this.selectedDuration.slots - 1]
              .end_time,
          start_time: this.time_slots_filter[k].start_time,
          end_time:
            this.time_slots_filter[k + this.selectedDuration.slots - 1]
              .end_time,
        };
        ////console.log(obj)
        this.time_slots.push(obj);
      }
      // //console.log(this.time_slots, "time slots");
    },
    calculateSize() {
      this.sofa_size = 0;
      this.chair_size = 0;
      for (var i = 0; i < this.cartItems.length; i++) {
        if (this.cartItems[i].service_type.name == "Upholstery Cleaning") {
          if (this.cartItems[i].service_price_range.upholstery_type == "SOFA") {
            this.sofa_size =
              this.sofa_size +
              parseInt(this.cartItems[i].service_price_range.maximum_area);
          }
          if (
            this.cartItems[i].service_price_range.upholstery_type == "CURTAIN"
            // this.cartItems[i].service_price_range.upholstery_type == "CHAIR"
          ) {
            this.chair_size =
              this.chair_size +
              parseInt(this.cartItems[i].service_price_range.maximum_area);
          }
        }
      }
      this.new_kitchen_nocabinet_size = 0;
      this.new_kitchen_cabinet_size = 0;
      this.old_kitchen_cabinet_size = 0;
      this.old_kitchen_nocabinet_size = 0;
      for (var j = 0; j < this.cartItems.length; j++) {
        if (this.cartItems[j].service_type.name == "Kitchen Cleaning") {
          if (this.cartItems[j].service_price_range.is_newkitchen) {
            if (this.cartItems[j].service_price_range.is_cabinet) {
              this.new_kitchen_cabinet_size =
                this.new_kitchen_cabinet_size +
                this.cartItems[j].service_price_range.maximum_area;
            } else {
              this.new_kitchen_nocabinet_size =
                this.new_kitchen_nocabinet_size +
                this.cartItems[j].service_price_range.maximum_area;
            }
          } else {
            if (this.cartItems[j].service_price_range.is_cabinet) {
              this.old_kitchen_cabinet_size =
                this.old_kitchen_cabinet_size +
                this.cartItems[j].service_price_range.maximum_area;
            } else {
              this.old_kitchen_nocabinet_size =
                this.old_kitchen_nocabinet_size +
                this.cartItems[j].service_price_range.maximum_area;
            }
          }
        }
      }
    },
    doSomethingAsync(k) {
      return new Promise((resolve) => {
        var service_to_select;
        // if (this.schedule_serviceTypes[k] == "Kitchen Appliances") {
        //   service_to_select = "Kitchen Cleaning";
        // } else
        if (this.schedule_serviceTypes[k] == "Hourly Cleaning") {
          service_to_select = "General Cleaning";
        } else {
          service_to_select = this.schedule_serviceTypes[k];
        }
        var manhour = 0;
        var addon_name = null;
        var addon_cat = null;
        if (this.schedule_serviceTypes[k] == "Kitchen Appliances") {
          addon_name = this.cartItems[k].addon_name;
          if (addon_name == "Refrigerator & Freezer")
            addon_name = "Refrigerator %26 Freezer";
          addon_cat = this.cartItems[k].addon_category;
        }
        axios
          .get(
            service.api +
            "customer/ajax/getserviceproductivity?service_type=" +
            service_to_select +
            "&addon_name=" +
            addon_name +
            "&addon_category=" +
            addon_cat
          )
          .then((response) => {
            // var total_estimated_size =0
            var total_highpricewindow_size = 0;
            var total_lowpricewindow_size = 0;

            if (
              this.cartItems[k].addon_name == "Cooking Range" ||
              this.cartItems[k].addon_name == "Fryers" ||
              this.cartItems[k].addon_name == "Exhaust Filters" ||
              this.cartItems[k].addon_name == "Grillers" ||
              this.cartItems[k].addon_name == "Microwave Oven" ||
              this.cartItems[k].addon_name == "Refrigerator & Freezer"
            ) {
              this.max_cleaners.push(8);
              this.min_cleaners.push(1);
              this.max_hours.push(10);
              this.min_hours.push(2);
            } else {
              this.max_cleaners.push(response.data.max_cleaners);
              this.min_cleaners.push(response.data.min_cleaners);
              this.max_hours.push(response.data.max_hours);
              this.min_hours.push(response.data.min_hours);
            }

            var selected_service = this.schedule_serviceTypes[k];
            this.durationData[this.schedule_serviceTypes[k]] = response.data;
            var data = response.data;
            // to find total size and manhour
            //console.log("hi im in1", this.calculatedService);

            if (
              selected_service == "Upholstery Cleaning" &&
              !this.calculatedService.includes(selected_service)
            ) {
              //console.log(selected_service, "checl");
              this.calculatedService.push(selected_service);
              var total_sofa_size = this.sofa_size;
              var total_chair_size = this.chair_size;

              manhour =
                parseInt(total_sofa_size / data["sofa_perhour_cleaning"]) +
                parseInt(total_chair_size / data["chair_perhour_cleaning"]);
            } else if (
              selected_service == "Window Cleaning" &&
              !this.calculatedService.includes(selected_service)
            ) {
              this.calculatedService.push(selected_service);
              for (var i = 0; i < this.cartItems.length; i++) {
                if (this.cartItems[i].service_type.name == "Window Cleaning") {
                  if (
                    this.cartItems[i].service_price_range.is_highprice_window
                  ) {
                    total_highpricewindow_size =
                      total_highpricewindow_size +
                      this.cartItems[i].service_price_range.maximum_area;
                  } else {
                    total_lowpricewindow_size =
                      total_lowpricewindow_size +
                      this.cartItems[i].service_price_range.maximum_area;
                  }
                }
              }
              manhour =
                parseInt(
                  total_highpricewindow_size /
                  data["highpricewindow_perhour_cleaning"]
                ) +
                parseInt(
                  total_lowpricewindow_size /
                  data["lowpricewindow_perhour_cleaning"]
                );
            } else if (
              selected_service == "Kitchen Cleaning" &&
              !this.calculatedService.includes(selected_service)
            ) {
              this.calculatedService.push(selected_service);
              manhour =
                parseInt(
                  this.new_kitchen_cabinet_size /
                  data["newkitchenwithcabinet_perhour_cleaning"]
                ) +
                parseInt(
                  this.new_kitchen_nocabinet_size /
                  data["newkitchenwithout_perhour_cleaning"]
                ) +
                parseInt(
                  this.old_kitchen_cabinet_size /
                  data["oldkitchenwithcabinet_perhour_cleaning"]
                ) +
                parseInt(
                  this.old_kitchen_nocabinet_size /
                  data["oldkitchenwithoutcabinet_perhour_cleaning"]
                );
            } else if (selected_service == "Kitchen Appliances") {
              //console.log(selected_service, "sel service");
              this.calculatedService.push(selected_service);

              if (
                this.cartItems[k].addon_name == "Cooking Range" ||
                this.cartItems[k].addon_name == "Fryers" ||
                this.cartItems[k].addon_name == "Exhaust Filters" ||
                this.cartItems[k].addon_name == "Grillers" ||
                this.cartItems[k].addon_name == "Microwave Oven" ||
                this.cartItems[k].addon_name == "Refrigerator & Freezer"
              ) {
                manhour = data[this.cartItems[k].addon_name];

                //console.log(manhour, "kitchen appli manhour");
              }
            } else if (!this.calculatedService.includes(selected_service)) {
              // //console.log(selected_service, "cs");
              this.calculatedService.push(selected_service);
              var total_size = 0;
              for (var x = 0; x < this.cartItems.length; x++) {
                if (this.cartItems[x].service_type.name == selected_service) {
                  if (this.cartItems[x].cart_service_floor.length > 1)
                    total_size =
                      total_size +
                      this.cartItems[x].service_price_range.maximum_area * 2;
                  else
                    total_size =
                      total_size +
                      this.cartItems[x].service_price_range.maximum_area;
                }
              }
              var productivity = data["perhour_cleaning"];
              // //console.log(productivity, "prodctivity");
              manhour = parseInt(total_size / productivity);
              // //console.log(total_size);
            }

            // //console.log(selected_service, manhour);

            //optimal finding
            // //console.log(manhour, "dawd man");
            this.totalmanhour = this.totalmanhour + manhour;
            // console.log(
            //   "tot man hr",
            //   this.totalmanhour,
            //   "simple mahour",
            //   manhour
            // );
            this.totalmanhour = Math.ceil(this.totalmanhour);
            if (this.totalmanhour < 2) {
              this.totalmanhour = 2;
            }
            //   //console.log("total man hour is "+this.totalmanhour)
            var r = 2 ** (this.totalmanhour.toString().length + 1);
            var mod = this.totalmanhour % r;

            if (mod > parseInt(r / 4)) {
              this.n = this.totalmanhour + (r - mod);
            } else {
              this.n = this.totalmanhour - mod;
            }
            //console.log(manhour, "manhour");
            //console.log(r, "r");
            //console.log(mod, "mod");
            //console.log(this.n, "n");
            this.maxCleaners.push(response.data.max_cleaners);
            //console.log(this.max_cleaners, "max_clean");

            resolve("done");
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    newdurationcalculation() {
      this.calculatedService = [];
      this.subStat = "";
      this.max_cleaners = [];
      this.min_cleaners = [];
      this.max_hours = [];
      this.min_hours = [];
      this.duration = [];
      this.totalmanhour = 0;
      let promises = [];
      this.maxCleaners = [];

      for (var k = 0; k < this.cartItems.length; k++) {
        if (
          this.cartItems[k].service_type.name == "Deep Cleaning" ||
          this.cartItems[k].service_type.name == "General Cleaning"
        ) {
          if (this.cartItems[k].cart_service_floor.length > 1) {
            for (
              var w = 0;
              w < this.cartItems[k].cart_service_floor.length;
              w++
            ) {
              promises.push(this.doSomethingAsync(k));
            }
          } else promises.push(this.doSomethingAsync(k));
        } else promises.push(this.doSomethingAsync(k));
      }
      // //console.log(promises, "promises");
      /** Loop ends here  */
      Promise.all(promises).then(() => {
        this.dialog = true;
        this.subscriptionHourCalculation();
      });
    },
    addCustomSlots() {
      this.custom_slot_flag = false;
      if (this.customSlotIndex != null) {
        // var sp = this.picker.split("-");
        // //console.log(this.picker, "this.picer");
        var dd = new Date(this.picker);
        var obj = {
          date: moment(dd).format("DD-MM-YYYY"),
          s: this.time_slots[this.customSlotIndex],
        };
        this.customSelectedSlots.push(obj);
        this.customSelectedSlotsDates.push(moment(dd).format("YYYY-MM-DD"));
        this.customSlotIndex = null;
        var tomorrow = new Date(dd);
        tomorrow.setDate(tomorrow.getDate() + 1);
        var count = 0;

        for (var j = 0; j < this.customSelectedSlotsDates.length; j++) {
          var tempDate = this.customSelectedSlotsDates[j];
          if (tempDate == moment(tomorrow).format("YYYY-MM-DD")) {
            count = count + 1;
            tomorrow.setDate(tomorrow.getDate() + count);
          } else count = 0;
        }

        tomorrow = moment(tomorrow).format("YYYY-MM-DD");
        this.picker = tomorrow;
      } else {
        this.custom_slot_flag = true;
      }
    },
    addselectdays(day) {
      if (this.isdayselected(day)) {
        for (var i = 0; i < this.select_days.length; i++) {
          if (this.select_days[i] == day) {
            this.del_index = i;
            break;
          }
        }

        this.select_days.splice(this.del_index, 1);
      } else {
        var visits = parseInt(this.getDate.total_visits);
        if (this.select_days.length >= visits) {
          this.max_select_flag = true;
          setTimeout(() => (this.max_select_flag = false), 50000);
        } else this.select_days.push(day);
      }
    },
    checkSelectDays() {
      var visits = parseInt(this.getDate.total_visits);
      if (this.select_days.length >= visits) {
        this.max_select_flag = true;
        setTimeout(() => (this.max_select_flag = false), 50000);
        this.getDate.total_visits = this.select_days.length;
      }
    },
    checkPrefDays() {
      var visits = parseInt(this.getDate.total_visits);
      if (this.prefDays.length >= visits) {
        this.max_select_flag = true;
        setTimeout(() => (this.max_select_flag = false), 50000);
        this.getDate.total_visits = this.prefDays.length;
      }
    },
    removeCustomSlots(index) {
      this.customSelectedSlots.splice(index, 1);
      this.customSelectedSlotsDates.splice(index, 1);
    },
    isdayselected(day) {
      for (var i = 0; i < this.select_days.length; i++) {
        if (this.select_days[i] == day) {
          return true;
        }
      }
      return false;
    },
    getmonthDate() {
      // //console.log(this.date);
      var d = new Date(this.date);
      this.no_day = moment(d).daysInMonth();
      this.no_of_week = Math.ceil(moment(d).daysInMonth() / 7);
    },
    onetimeNext() {
      this.onetime_slot_flag = false;
      if (screen.width < 768) {
        if (this.onetime_select != null) {
          this.getDate.total_visits = 1;
          this.date = this.picker;
          this.selectSolt = this.onetime_select;
          this.getavaliableDate();
        } else {
          this.onetime_slot_flag = true;
        }
      } else {
        if (this.oneTimeSlot != null) {
          this.getDate.total_visits = 1;
          this.date = this.picker;
          this.selectSolt = this.time_slots_one[this.oneTimeSlot].time;
          this.getavaliableDate();
        } else {
          this.onetime_slot_flag = true;
        }
      }
    },
    changeAddress(index) {
      this.selectedAddress = index;
      this.addressdialog = false;
      this.debit_data.address_id = this.address[this.selectedAddress].id;
    },
    async applyPromocode() {
      if(this.promo_code)
    {
      this.promoLoader = true;
      let result = await service._post(
        "customer/cart/" + localStorage.getItem("bleach-tk") + "/",
        {
          action: "apply_promocode",
          promo_code: this.promo_code
          
        }
      );
      // //console.log(result);
      if (utils.isReqSuccess(result)) {
        this.promo_code = null
        this.promoLoader = false;
        this.getUserCart();
        this.$vs.notification({
          duration: "3000",
          position: "top-right",
          // text: "Try again",
          border: "success",
          title:result.data.message,
        });
      }

      else
      {
        this.$vs.notification({
          duration: "3000",
          position: "top-right",
          text: "Try again",
          border: "danger",
          title:result.data.message,
        });
        this.promo_code = null
        this.promoCode=null
        this.promoLoader = false;

      }
    }
    else
    
    {
      this.promo_error=true
      setTimeout(() => (this.promo_error = false), 5000);

    }
    },
    async removePromocode() {
      let result = await service._post(
        "customer/cart/" + localStorage.getItem("bleach-tk") + "/",
        {
          action: "remove_promocode",
        }
      );
      // //console.log(result);
      if (utils.isReqSuccess(result)) {
        this.promoCode=null;
        this.promocodeNotes=null
        this.getUserCart();
        this.$vs.notification({
          duration: "3000",
          position: "top-right",
          // text: "Try again",
          border: "success",
          title:result.data.message,
        });
      }

      else
      {
        this.$vs.notification({
          duration: "3000",
          position: "top-right",
          text: "Try again",
          border: "danger",
          title:result.data.message,
        });
        this.promo_code = null
        this.promoCode=null
        this.promoLoader = false;

      }
    },
    async resetSchedule() {
      this.resetloader = true;
      let result = await service._post(
        "customer/cart/" + localStorage.getItem("bleach-tk") + "/",
        {
          action: "reset_schedules",
        }
      );
      // //console.log(result);
      if (utils.isReqSuccess(result)) {
        this.getUserCart();
        this.resetloader = false;
        this.resetDialog = false;
      }
    },
    payAmount() {
      if (this.selected_pay_type == "credit") {
        var namesplit = this.customer.name.split(" ");
        var fname = this.customer.name.split(" ")[1];
        var lname = "";
        namesplit = namesplit.filter(function (element) {
          return element !== "";
        });
        if (namesplit.length > 2) {
          for (var i = 2; i < namesplit.length; i++) {
            lname = lname + " " + namesplit[i];
          }
        }
        // console.log(this.address[this.selectedAddress].id,"adddresid new")
        var urlText =
          "merchant_defined_data1=" +
          this.cart_id +
          "&reference_number=" +
          this.cart_id +
          "&amount=" +
          this.cart_final_cost +
          "&merchant_defined_data2=prepaid&merchant_defined_data3=CUSTOMER_BOOKING&currency=KWD&transaction_type=sale&bill_to_forename=" +
          fname +
          "&bill_to_surname=" +
          lname +
          "&bill_to_phone=" +
          this.customer.mobile_number +
          "&bill_to_email=" +
          this.customer.email +
          "&bill_to_address_country=KW&bill_to_address_city=" +
          this.address[this.selectedAddress].area.name + "&merchant_defined_data13=" +
          this.address[this.selectedAddress].id +
          "&bill_to_address_line1=" +
          this.address[this.selectedAddress].governorate.name +
          "&merchant_defined_data4=ONLINE&merchant_defined_data5=NO&merchant_defined_data7=1&merchant_defined_data20=NO&customer_ip_address=" +
          this.customer_ip_address;

        var final_url =
          "https://payment.bleachkw.com/creditcard/payment_form.php?" + urlText;
        //  "https://testpay.bleach-kw.com/creditcard/payment_form.php?" + urlText;

        // console.log(final_url,"final")
        window.location = final_url;
      } else {
        // //console.log("knet");

        this.paymentDialog = false;
        this.$refs.eval_sub.click();
      }
    },
    async listaddress() {
      this.address = [];
      let result = await service._get(
        "api/customer-addresses/" + localStorage.getItem("bleach-tk") + "/"
      );

      if (result.status == 200) {
        var address = result.data.addresses;
        for (var i = 0; i < address.length; i++) {
          if (address[i].currently_active) {
            this.address.push(address[i]);
          }
        }
        if (this.address.length >= 1) {
          this.selectedAddress = this.address.length - 1;
          this.debit_data.address_id = this.address[this.selectedAddress].id;
        }
      }
    },
    deleteAvalslot(index) {
      this.avail_date_slot.splice(index, 1);
      this.checkisInactiveNext();
      // for(var i=0;i<this.avail_date_slot.length;i++)
      // {
      //   if(this.avail_date_slot[i].is_available==false)
      //  {
      //   //console.log('hii')
      //    this.isInactiveNext=true;
      //   break;
      //  }
      //  else this.isInactiveNext=false;
      // }
    },
    async checkAvailByDate() {
      var tempServ = [];
      for (var i = 0; i < this.cartItems.length; i++) {
        tempServ.push(this.cartItems[i].service_type.name);
      }
      var timesolt = this.time_slots[this.selected_edit_slot].start_time;
      // var sp = this.edit_slot_date.split("-");
      var dd = new Date(this.edit_slot_date);
      var selectDate = moment(dd).format("DD-MM-YYYY");
      let result = await service._post(
        "customer/ajax/multipleservice/multipledates/cleaningslotes/",
        {
          cleaning_hours: this.selectedDuration.selectedDuration,
          number_of_cleaners: this.selectedDuration.cleaners,
          service_types: tempServ,
          shift_availability_check: true,
          cleaning_datetimes: [selectDate + " " + timesolt],
        }
      );
      // //console.log();
      if (utils.isReqSuccess(result)) {
        this.avail_date_slot[this.slot_edit_index] =
          result.data.combined_slots[0];
        this.edit_slot_flag = false;
      }
    },
    editslotdate(time) {
      // //console.log("edit enter");
      for (var i = 0; i < this.time_slots.length; i++) {
        if (this.time_slots[i].start_time == time) {
          this.selected_edit_slot = i;
          break;
        }
      }
      // //console.log(this.avail_date_slot[this.slot_edit_index].date);
      var sp = this.avail_date_slot[this.slot_edit_index].date.split("-");
      // var dd1 = new Date(this.avail_date_slot[this.slot_edit_index].date) ;
      this.edit_slot_date = sp[2] + "-" + sp[1] + "-" + sp[0];
      // //console.log(this.edit_slot_date ,"edit_slot_date");
    },
    async autoFix() {
      this.aval_loader = true;
      var tempServ = [];
      for (var i = 0; i < this.cartItems.length; i++) {
        tempServ.push(this.cartItems[i].service_type.name);
      }
      var cleaning_datetimes = [];
      for (var j = 0; j < this.avail_date_slot.length; j++) {
        if (!this.avail_date_slot[j].is_available) {
          cleaning_datetimes.push(
            this.avail_date_slot[j].date + " " + this.selectSolt
          );
        }
      }

      var reqBody = {
        cleaning_datetimes: cleaning_datetimes,
        cleaning_hours: this.selectedDuration.selectedDuration,
        number_of_cleaners: this.selectedDuration.cleaners,
        service_types: tempServ,
        shift_availability_check: true,
      };
      let result = await service._post(
        "customer/ajax/multipleservice/multipledates/cleaningslotes/autofix/",
        reqBody
      );
      this.aval_loader = false;
      var tempSlots;
      if (utils.isReqSuccess(result)) {
        tempSlots = result.data.slote_details;
        for (var q in tempSlots) {
          // //console.log(q);
          if (tempSlots[q] == "Not Available") {
            this.autofix_flag = false;
          }
        }
        if (this.autofix_flag) {
          for (var x in tempSlots) {
            for (var t = 0; t < this.avail_date_slot.length; t++) {
              if (this.avail_date_slot[t].date == x.split(" ")[0]) {
                this.avail_date_slot[t].time =
                  tempSlots[x].split(" ")[1] + " " + tempSlots[x].split(" ")[2];
                this.avail_date_slot[t].is_available = true;
              }
            }
          }
        }
      }
      // //console.log(result);
    },
    async scheduleService() {
      this.scheduelloader = true;
      var cleaning_datetimes = [];
      for (var j = 0; j < this.avail_date_slot.length; j++) {
        cleaning_datetimes.push(
          this.avail_date_slot[j].date + " " + this.avail_date_slot[j].time
        );
      }
      let result = await service._post(
        "customer/cart/schedule/" + localStorage.getItem("bleach-tk") + "/",
        {
          cleaning_policy: this.plans[this.selected - 1].cleaning_policy,
          datetimes: cleaning_datetimes,
          cleaning_hours: this.selectedDuration.selectedDuration,
          no_of_cleaners: this.selectedDuration.cleaners,
        }
      );
      this.scheduelloader = false;

      if (utils.isReqSuccess(result)) {
        this.$store.dispatch("getUserCart");
        this.getUserCart();
        this.dialog = false;
        this.schedule = true;
        this.mobile_schedule = true;
      }
      //console.log(result);
    },
    addPreferredDays(item) {
      if (this.isPreferredSelected(item)) {
        var ind;
        for (var i = 0; i < this.prefDays.length; i++) {
          if (item == this.prefDays[i]) {
            ind = i;
            break;
          }
        }
        this.prefDays.splice(ind, 1);
      } else {
        var visits = parseInt(this.getDate.total_visits);
        if (this.prefDays.length >= visits) {
          this.max_select_flag = true;
          setTimeout(() => (this.max_select_flag = false), 50000);
        } else this.prefDays.push(item);
      }
    },
    isPreferredSelected(item) {
      for (var i = 0; i < this.prefDays.length; i++) {
        if (item == this.prefDays[i]) {
          return true;
        }
      }
      return false;
    },
    async checkAvailability() {
      this.auto_fix = false;
      this.avail_date_slot = [];
      this.aval_loader = true;
      var tempServ = [];
      for (var i = 0; i < this.cartItems.length; i++) {
        tempServ.push(this.cartItems[i].service_type.name);
      }
      //console.log(this.avail_date, "avail_date");
      var cleaning_datetimes = [];
      for (var j = 0; j < this.avail_date.length; j++) {
        cleaning_datetimes.push(this.avail_date[j] + " " + this.selectSolt);
      }
      // //console.log(cleaning_datetimes);

      var reqBody = {
        cleaning_datetimes: cleaning_datetimes,
        cleaning_hours: this.selectedDuration.selectedDuration,
        number_of_cleaners: this.selectedDuration.cleaners,
        service_types: tempServ,
        shift_availability_check: true,
      };
      let result = await service._post(
        "customer/ajax/multipleservice/multipledates/cleaningslotes/",
        reqBody
      );
      this.aval_loader = false;

      if (utils.isReqSuccess(result)) {
        this.avail_date_slot = result.data.combined_slots;
        // //console.log(this.avail_date_slot, "avail");

        for (var i1 = 0; i1 < this.avail_date_slot.length; i1++) {
          if (this.avail_date_slot[i1].is_available == false) {
            this.isInactiveNext = true;
            break;
          } else this.isInactiveNext = false;
        }
        if (result.data.busy_slotes.length > 0) {
          this.auto_fix = true;
        }
      }
      //console.log(result);
    },
    async getavaliableDate() {
      this.autofix_flag = true;
      this.getDate.coming_days = this.select_days;
      this.getDate.action_type = this.plans[this.selected - 1].req_name;
      //console.log(this.date);
      // var sp = this.date.split("-");
      // var dd = new Date(sp);
      this.getDate.start_date = moment(this.date).format("DD-MM-YYYY");
      this.getDate.days = this.prefDays;
      //console.log(this.getDate);
      this.getDate.total_visits = parseInt(this.getDate.total_visits);
      let result = await service._post("customer/find-dates/", this.getDate);
      if (utils.isReqSuccess(result)) {
        //console.log(result, "result");
        this.avail_date = result.data.dates;
        this.step++;
        this.checkAvailability();
      }
    },
    async removeCart() {
      let result = await service._post(
        "customer/cart/" + localStorage.getItem("bleach-tk") + "/",
        {
          action: "delete_service",
          cart_service_id: this.delId,
        }
      );
      if (utils.isReqSuccess(result)) {
        this.getUserCart();
        this.cartUpdation();
        this.deleteDialog = false;
      }
      ////console.log(result)
    },
    async cartUpdation() {
      let result = await service._get(
        "customer/cart/" + localStorage.getItem("bleach-tk") + "/"
      );
      if (result.status == 200) {
        this.$store.commit("changeCart", result.data.cart_items_count);
      }
    },
    selectAddress(id) {
      if (this.onetime) {
        if (id == 1) {
          this.selected = id;
          return;
        }
        return;
      } else {
        this.selected = id;
      }
    },
    // goto(loc) {
    //   window.location = loc;
    // },
    formatAmount(amount) {
      amount=parseFloat(amount)
      if (amount>0)
       return amount.toFixed(3);
      return 0;
    },
    getTotalPrice() {
      var sum = 0;
      for (var i = 0; i < this.cartItems.length; i++) {
        sum = sum + this.cartItems[i].total_cost;
      }
      return parseFloat(sum).toFixed(3);
    },
    async getUserCart() {
      this.schedule_serviceTypes = [];
      let result = await service._get(
        "customer/cart/" + localStorage.getItem("bleach-tk") + "/"
      );
      //console.log(result);
      if (utils.isReqSuccess(result)) {
        this.no_of_visits = result.data.no_of_visits;
        this.cartItems = result.data.data;
        this.customer = result.data.customer;
        this.customer_ip_address = result.data.customer_ip_address;
        this.cart_id = result.data.cart_id;
        this.schedule = result.data.is_scheduled;
        this.cart_final_cost = result.data.cart_final_cost;
        this.debit_data.price = parseFloat(this.cart_final_cost).toFixed(3);
        this.discount_amount = result.data.discount_amount;
        this.myEventHandler();
        this.promoCode=result.data.promocode
        this.promocodeNotes=result.data.promocode_notes
        this.debit_data.evaluation_id = this.cart_id;
        for (var k = 0; k < this.cartItems.length; k++) {
          this.schedule_serviceTypes.push(this.cartItems[k].service_type.name);
        }
        this.calculateSize();
      }
    },
  },
};
</script>

<style>
.date-color {
  color: #00b300;
  font-weight: 900;
}

.v-btn--active .date-color {
  color: rgb(232, 45, 45);
}

.plan-card.inactivate {
  cursor: not-allowed;
  opacity: 0.6;
}

.month-reapt-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 16px;
  text-transform: capitalize;
  color: #5d5d5d;
}

.add-change-btn {
  border: 1px solid #898e93;
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 14px;
  padding: 1px 10px;
  text-align: center;
  color: #6d6d6e;
}

.reset-sh {
  text-transform: capitalize !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  letter-spacing: 0px !important;
}

.payment-card-seleted {
  position: absolute;
  z-index: 10;
  top: -12px;
  right: -5px;
}

.payment-card {
  position: relative;
  cursor: pointer;
  border: 1px solid rgba(218, 218, 218, 0.6);
  border-radius: 4px;
  padding: 8px 10px;
}

.payment-card.active {
  border: 1px solid #30b474;
}

.add-more-cleaning {
  border: 1px solid #e9ecef;
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 10.0807px;
  line-height: 15px;
  text-align: center;
  text-transform: capitalize;
  color: #898e93;
  padding: 5px 20px;
}

.v-picker__title {
  border-bottom: 1px solid #289bac !important;
}

.day-btn {
  cursor: pointer;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 10.2553px;
  line-height: 15px;
  text-align: center;
  text-transform: capitalize;
  color: #5d5d5d;
  border: 1px solid rgba(218, 218, 218, 0.6);
  border-radius: 4.10212px;
  padding: 12px 8px;
  margin-right: 16px;
}

.day-btn.active {
  background: #2b9fad !important;
  color: #fff;
}

.theme--light.v-text-field>.v-input__control>.v-input__slot:before {
  border-color: #c4c4c4 !important;
}

.select-info-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  color: #8a8a8a;
  padding-bottom: 23px;
}

.hr-side-2 {
  margin: 0 !important;
  border-bottom: 0.5px solid #6d6d6e;
}
</style>
