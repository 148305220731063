
<template>
 <div>
    <div>
      <section class=""  >
         <div class="bg-header-img-faq bg-header  ">
         </div>
      </section>
  </div>
      <div v-if="selectlan == 'EN'">
       
         <div>
             <div class="bcontainer">
      <div class="py-10">
          <div class="page-head">
              FAQ<span style="text-transform:lowercase">s</span>
          </div>
      <div class="qstn-ans-head">
           Questions & Answers
      </div>
      </div>
 
      <div>
   

      </div>
         </div>
              <router-view/>
         </div>
    
  </div>
   <div v-if="selectlan == 'ARABIC'" dir="rtl">
      
         <div>
             <div class="bcontainer">
      <div class="py-10">
          <div class="page-head arabic-font">
              الأسئلة الشائعة
          </div>
      <div class="qstn-ans-head arabic-font">
           أسئلة و أجوبة
      </div>
      </div>
 
      <div>
   

      </div>
         </div>
              <router-view/>
         </div>
    
  </div>
 </div>
</template>

<script>
//import pageheading from '../../components/pageheading.vue'
//import expanadcard from '../../components/expanadcard.vue'

export default {
    components:{},
    data(){
        return{
            selectlan: localStorage.getItem("bleach-lang"),
        };
    }

}
</script>

<style>

</style>