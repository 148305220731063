<template>
  <div>
        <div v-if="selectlan == 'EN'" class="gray-bg-2">
        <div class="bcontainer">
            <div class="row py-15">
                <div class="col-md-4 col-lg-3">
                    <ul class="service-ui show-head">
                        <li  @click="$router.push('general')"   >
                          <span class="dot-li"></span>
                            General Questions
                        </li> 
                        <li class="service-ui-active" >
                           <div class="dot-li-active"></div>
                            Service Procedure
                        </li>
                        <li @click="$router.push('technical')" >
                            <span class="dot-li"></span>
                            Technical Questions
                        </li>
                    </ul>
                                           <div  class="show-callus px-5">
               <div class=" py-2 mb-3" style="border-bottom:1px solid #C4C4C4;">
              <div class="d-flex pointer " @click="dc = !dc">
                 <div class="mobile-sub-link mobile-sub-header h-100 ma">
                 Service Procedure
               </div>
               <div>
                 <img class="mr-1" :class="{ 'fa-arrow-down-open': dc }" src="../../assets/ar.png" alt="">
               </div>
              </div>
            </div>
            <div class="drop-service">
               <transition-expand>
              <div v-if="dc" >
                <ul class="drop-list">
                     <li  @click="$router.push('general')">
                                  <a>
                                    General Questions
                                  </a>
                    </li>
                    <li  @click="$router.push('technical')">
                        <a>
                                    Technical Questions
                        </a>
                    </li>

                </ul>
              </div> 
            </transition-expand>
        </div>
</div>
                </div>
                 <div class="col-md-8 col-lg-9">
                    <div class="px-5">
                         <expanadcard v-for="(item,index) in qs" :key="index" :qstn="item.qstn" :ans="item.answer" />

                    </div>
                   

                 </div>
            </div>
        </div>
      </div>
        <div v-if="selectlan == 'ARABIC'" class="gray-bg-2">
        <div class="bcontainer">
            <div class="row py-15">
                <div class="col-md-4 col-lg-3">
                    <ul class="service-ui-ar show-head">
                        <li  @click="$router.push('general')"  class="arabic-font"  >
                          <span class="dot-li-ar"></span>
                            الأسئلة العامة
                        </li> 
                        <li class="service-ui-active arabic-font" >
                           <div class="dot-li-ar-active"></div>
                            أسئلة إجراءات الخدمة
                        </li>
                        <li @click="$router.push('technical')" class="arabic-font">
                            <span class="dot-li-ar"></span>
                            الأسئلة الفنية
                        </li>
                    </ul>
                                           <div  class="show-callus px-5">
               <div class=" py-2 mb-3" style="border-bottom:1px solid #C4C4C4;">
              <div class="d-flex pointer " @click="dc = !dc">
                 <div class="mobile-sub-link mobile-sub-header h-100 ma arabic-font">
                 أسئلة إجراءات الخدمة
               </div>
               <div>
                 <img class="mr-1" :class="{ 'fa-arrow-down-open': dc }" src="../../assets/ar.png" alt="">
               </div>
              </div>
            </div>
            <div class="drop-service">
               <transition-expand>
              <div v-if="dc" >
                <ul class="drop-list">
                     <li  @click="$router.push('general')">
                                  <a class="arabic-font">
                                    الأسئلة العامة
                                  </a>
                    </li>
                    <li  @click="$router.push('technical')">
                        <a class="arabic-font">
                                    الأسئلة الفنية
                        </a>
                    </li>

                </ul>
              </div> 
            </transition-expand>
        </div>
</div>
                </div>
                 <div class="col-md-8 col-lg-9">
                    <div class="px-5">
                         <expanadcard v-for="(item,index) in qsAr" :key="index" :qstn="item.qstn" :ans="item.answer" />

                    </div>
                   

                 </div>
            </div>
        </div>
      </div>
  </div>
</template>

<script>
    import expanadcard from '../../components/expanadcard.vue'
export default {
    components:{
        expanadcard
    },
    data(){
        return{
            selectlan: localStorage.getItem("bleach-lang"),
            dc:false,
           qs:[
               {
    qstn:'Do you provide cleaning services on an hourly basis too?',
    answer:'No. We work based on the area to be cleaned (in per sq. m.)'
},{
    qstn:'How much time does it take to complete the cleaning? How many cleaners will there be?',
    answer:'Time taken for our service executives to clean the space depends on the service selected and the area covered. Our Inspection team will inform you about the cleaning time as well as the team members’ count before you give us a heads up.'
},
{
    qstn:'What is the cleaning procedure?',
    answer:'First, you book a free evaluation with us. BLEACH®’s Expert Evaluator will visit your location that requires our cleaning services and determine the products to be used and the area to be cleaned.In the next step, we offer you a quotation on the above-mentioned factors. Once you approve the quotation, we allow you to choose the slot for cleaning as per your convenience.Our professional cleaners then visit your space and clean it completely within the slot assigned.'
},
{
    qstn:'There isa contractor working in the house and soon he will finish work. Can I start cleaning?',
    answer:'We do not carry any cleaning work if there are workers from another company working in the same location. Before starting any cleaning work, the place should be completely clear of any workers from another company working in the place. If places cleaned are made dirty by them, they have not cleaned again.'
}
           ],
           qsAr:[
               {
                   qstn:'هل تقدم خدمات التنظيف على أساس الساعة أيضاً؟',
                   answer:'لا. نعمل على أساس المساحة المراد تنظيفها.'
               },
               {
                   qstn:'كم من الوقت يستغرق إكمال التنظيف؟ كم عدد عمال النظافة في الفريق؟',
                   answer:'يعتمد الوقت الذي يستغرقه العمال لتنظيف المسكن على الخدمة المحددة والمساحة المشمولة. سيبلغك فريق التفتيش بموعد التنظيف بالإضافة إلى عدد أعضاء الفريق قبل إعطائنا قراراً بمباشرة العمل.'
               },
               {
                   qstn:'ما المقصود بإجراءات التنظيف؟',
                   answer:'أولاً، يمكنك حجز موعد من أجل إجراء تقييم مجاني. سيقوم خبير التقييم من شركة بليتش® بزيارة موقعك الذي يتطلب خدمات التنظيف وتحديد المنتجات التي سيتم استخدامها والمنطقة التي سيتم تنظيفها.في الخطوة التالية، نقدم لك عرض أسعار حول العوامل المذكورة أعلاه. بمجرد الموافقة على عرض الأسعار، نتيح لك اختيار باقة التنظيف حسب ما يناسبك.ثم يزور عمال النظافة المحترفون مسكنك وينظفونه بالكامل وفقاً للباقة المخصصة.'
               },
               {
                   qstn:'يوجد مقاول يعمل في المنزل وسينهي العمل قريباً. هل يمكنني البدء بالتنظيف؟',
                   answer:'لا نقوم بأي أعمال تنظيف في حال وجود عمال من شركة أخرى يعملون في نفس الموقع. قبل البدء في أي أعمال تنظيف، يجب أن يكون المكان خالياً تماماً من أي عمال من شركة أخرى تعمل في المكان. إذا اتسخت الأماكن التي تم تنظيفها بسببهم، فإنها لن تنظف مرة أخرى.'
               },
               
           ]
        }
    }
}
</script>

<style>

</style>