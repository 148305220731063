<template>
  <div>
   <br> <br>
    <div class="bcontainer">
      <div class=" pb-10">
        <div class="page-head">book your  <span class="w400">Evaluation</span></div>
        <div class="service-content">
Now Book Your Evaluation slot with us in a GO! It is as easy as it sounds.
Select your preferable date and time to let BLEACH® Expert Evaluator visit your space
(Commercial or Residential). Our Inspector will thoroughly examine your residence or office
after understanding your requirements or the type of professional cleaning services you
need. Post evaluation, we will share a quotation with you, based on the services required,
the surface area covered, and the period.
Let us Start Cleaning!          
        </div>
      </div>
    </div>
     <router-view/>
  </div>
</template>

<script>
export default {
  mounted(){
     this.$store.commit("set_evalutionStep", 1);
  }
};
</script>

<style></style>
