<template>
  <div>
    <div class="gray-bg-2">
      <div class="bcontainer">
        <br /><br />
        <img
          src="../../assets/images/blog/traingi-full.png"
          class="blog-img w-100"
          alt=""
        />
        <div class="blog-date mt-10 text-uppercase">
          Business <span class="w400">| MARCH 12 2021 </span>
        </div>
        <div class="sub-heading mt-3">
          TRAINING & DEVELOPMENT DURING COVID ERA
        </div>
        <div class="service-content mt-2">
          Cleaning services have moved into the limelight due to the COVID-19
          pandemic accelerates and people are looking at CLEANING FOR HEALTH
          rather than cleaning for appearance. As a essential service provider
          COVID-19 is keeping us on our toes, but that doesn't stop us from
          helping our clients and boosting our employees.
          <br /><br />
          During late February 2020 when the COVID-19 outbreak began to spread
          in Kuwait. The Cleaning and surface disinfection/sterilization demands
          began to increase. Where one of the biggest challenges has been
          maintaining the health of our workers and customers of the businesses
          and facilities we care for. These persistent concerns regarding
          Infection control (virus pathogens, hygiene, and disinfection) raised
          the expectations of cleanliness from our customers for their
          facilities.
          <br /><br />
          The movement when lockdown happened, lockdown-related restrictions
          have impacted our Training and development department to deliver
          skills based onsite or class room training. As company have
          implemented work-from-home policies for all administrative employees
          to slow the spread of COVID-19, so we had no other choice left than
          opting for online learning classes. Moreover, the training outcome
          results indicate this shift has had little or no effect on the quality
          of employee skills training. Of course, the safety of employees is
          critical but so, too, is the continual upskilling and development of
          those employees.
          <br /><br />
          Training and Development Strategies Adopted by Businesses: As Learning
          and development department we continue to be a key strategy to raise
          employees’ growth potential. Generally, we’ve used the best industry
          approaches to training and development during the lockdown we have our
          Training personnel trained by ISSA-GBAC and later we have trained all
          our janitorial staff to follow the same.
        </div>
        <div class="d-flex mt-8 pb-10">
          <div>
            <v-icon class="mt-1" large color=""> mdi-account-circle </v-icon>
          </div>
          <div class="pl-2 service-content mt-3">
            <b>Bleach Newsdesk</b><br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.blog-img {
  border-radius: 10px;
}
</style>